import { axiosInstance } from '@App/providers';
import {
    getRequestConfigWithUserProfile,
    httpErrorResponse,
} from '@App/requests/shared';

export const getPublicApplicationData = async (
    applicationId: string,
    email: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.get(
            `${url}/public/application/${applicationId}`,
            { params: { email_address: email } },
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const getUserApplication = async (
    accessToken: string,
    idToken: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.get(
            `${url}/application/email-address`,
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const getApplicationById = async (
    id: string,
    token: string,
    idToken: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.get(
            `${url}/application/${id}`,
            getRequestConfigWithUserProfile(token, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
