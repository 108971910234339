import { ChangeEvent, FC } from 'react';

import { ChevronDownIcon, Controller, TextField } from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { selfEmployedLimitedCompany } from '@src/app/formModels/eligibility/incomings.selfEmployed';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import LightbulbIcon from '../../components/Icons/LightbulbIcon';
import SelectBox from '../../components/SelectBox/SelectBox';

import ExpectedDecrease from './IncomingExpectedDecreaseField';
import IncomingOtherIncomesSubView from './IncomingOtherIncomesSubView';

export interface IncomingSelfEmployedViewLimitedCompanyProps {
    setRef?: Function;
    incomeData?: any;
}

const defaultValues = getDefaultValues(selfEmployedLimitedCompany);
const validationSchema = getValidationSchema(selfEmployedLimitedCompany);

const IncomingSelfEmployedViewLimitedCompany: FC<
    IncomingSelfEmployedViewLimitedCompanyProps
> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(
                    props.incomeData,
                    Object.keys(selfEmployedLimitedCompany),
                ),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) =>
                pick(values, Object.keys(selfEmployedLimitedCompany))
            }
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getError, getPropsWithError } = getFormHelpers(
                    selfEmployedLimitedCompany,
                    formikProps,
                );
                if (formikProps.values.whenWasCompanyIncorporated) {
                    formikProps.values.month =
                        formikProps.values.whenWasCompanyIncorporated.split(
                            '/',
                        )[1];
                    formikProps.values.year =
                        formikProps.values.whenWasCompanyIncorporated.split(
                            '/',
                        )[2];
                }

                return (
                    <>
                        <TextField
                            label='What is the company’s registered name?'
                            name='registeredCompanyName'
                            maxLength={255}
                            value={
                                formikProps.values.registeredCompanyName?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('registeredCompanyName')}
                        />
                        <Controller label='When was your company incorporated?'>
                            <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                                <SelectBox
                                    items={
                                        selfEmployedLimitedCompany.month.items
                                    }
                                    suffix={<ChevronDownIcon />}
                                    label='Month'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='month'
                                    validState={!!formikProps.values.month}
                                    value={
                                        formikProps.values.month?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const whenWasCompanyIncorporated = `01/${e.currentTarget.value}/${formikProps.values.year}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.month}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'whenWasCompanyIncorporated',
                                            whenWasCompanyIncorporated,
                                        );
                                        formikProps.setFieldValue(
                                            'month',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('month')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            month: true,
                                        });
                                    }}
                                />
                                <SelectBox
                                    items={
                                        selfEmployedLimitedCompany.year.items
                                    }
                                    suffix={<ChevronDownIcon />}
                                    label='Year'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='year'
                                    validState={!!formikProps.values.year}
                                    value={
                                        formikProps.values.year?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const whenWasCompanyIncorporated = `01/${formikProps.values.month}/${e.currentTarget.value}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.year}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'whenWasCompanyIncorporated',
                                            whenWasCompanyIncorporated,
                                        );
                                        formikProps.setFieldValue(
                                            'year',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('year')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            year: true,
                                        });
                                    }}
                                />
                            </div>
                        </Controller>
                        <Controller
                            label='What is your base salary?'
                            additionalInfo='This should be the annual, pre-tax amount'
                        >
                            <div className='bg-neutral-40 flex p-3 rounded-lg my-4'>
                                <div className='mr-2'>
                                    <LightbulbIcon />
                                </div>
                                <div>
                                    <p className='text-steel-100 font-semibold'>
                                        We may ask for proof of income later.
                                    </p>
                                </div>
                            </div>
                            <TextField
                                type='tel'
                                prefix='£'
                                maxLength={10}
                                mask={'currency' as any}
                                name='incomeAmountSalary'
                                value={
                                    formikProps.values.incomeAmountSalary?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('incomeAmountSalary')}
                            />
                        </Controller>
                        <Controller
                            label='Do you earn any other income?'
                            additionalInfo='Such as bonus, dividends, pension, second job.'
                        >
                            <IncomingOtherIncomesSubView
                                getError={getError}
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                addButtonTitle={
                                    'Add other income from this job'
                                }
                                title={'Other incomes from this job'}
                            />
                        </Controller>
                        <Controller
                            label="At what age do you think you'll retire?"
                            additionalInfo='For most people this will be 66'
                        >
                            <TextField
                                name='estimatedRetirementAge'
                                mask='number'
                                maxLength={2}
                                value={
                                    formikProps.values.estimatedRetirementAge?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('estimatedRetirementAge')}
                            />
                        </Controller>
                        <Controller label='Do you expect your income to decrease in the near future?'>
                            <ExpectedDecrease
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <ErrorContainer formikProps={formikProps} />
                    </>
                );
            }}
        </Formik>
    );
};

export default IncomingSelfEmployedViewLimitedCompany;
