import { FC, useContext } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import { AuthContext } from '@App/contexts/Auth';

import GoodNewsLayout from '../layouts/GoodNewsLayout';
import GoodNewsView from '../views/eligibility/GoodNewsView';

const GoodNews: FC = () => {
    const { loading, offers } = useContext(ApplicationContext);
    const { user } = useContext(AuthContext);

    return (
        <GoodNewsLayout loading={loading} userEmail={user?.email}>
            <GoodNewsView offers={offers} />
        </GoodNewsLayout>
    );
};

export default GoodNews;
