import axios from 'axios';

export const preSignup = async (
    applicationId: string,
    emailAddress: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;
    const body = applicationId ? { applicationId } : { emailAddress };

    const response = await axios.post(`${url}/pre-signup`, body);
    return { applicationId: response.data.applicationId };
};
