import { viewModeAddressDateFormatter } from '@Shared/helpers/date';
import { InputSectionEditMode } from '@Views/eligibility/addressRepeaterView/views/InputSection/EditMode/InputSectionEditMode';

interface InputSectionProps {
    index: number;
    isEdit: boolean;
    notTouched: boolean;
    addressFieldName: string;
    formItems: any;
    formikProps: any;
    isPrimaryApplicant: boolean;
}

export const InputSection = ({
    index,
    isEdit,
    notTouched,
    addressFieldName,
    formItems,
    formikProps,
    isPrimaryApplicant,
}: InputSectionProps) => (
    <>
        {isEdit ? (
            <InputSectionEditMode
                index={index}
                notTouched={notTouched}
                addressFieldName={addressFieldName}
                formItems={formItems}
                formikProps={formikProps}
                isPrimaryApplicant={isPrimaryApplicant}
            />
        ) : (
            <div className='text-md pt-4 block text-steel-60 font-bold'>
                {viewModeAddressDateFormatter(
                    formikProps.values.addresses[index].livedIn,
                )}
            </div>
        )}
    </>
);
