import { MoveInController } from '@Views/eligibility/addressRepeaterView/controllers/MoveInController';
import { MoveOutController } from '@Views/eligibility/addressRepeaterView/controllers/MoveOutController';

interface IAddressRepeaterEditModeProps {
    index: number;
    notTouched: boolean;
    addressFieldName: string;
    formikProps: any;
    isPrimaryApplicant: boolean;
    formItems: any;
}
export const InputSectionEditMode = ({
    index,
    notTouched,
    addressFieldName,
    formikProps,
    isPrimaryApplicant,
    formItems,
}: IAddressRepeaterEditModeProps) => {
    const addressItemName = `${addressFieldName}[${index}]`;

    return (
        <>
            <MoveInController
                index={index}
                notTouched={notTouched}
                isPrimaryApplicant={isPrimaryApplicant}
                formItems={formItems}
                addressItemName={addressItemName}
                formikProps={formikProps}
            />
            {index !== 0 && (
                <MoveOutController
                    index={index}
                    notTouched={notTouched}
                    isPrimaryApplicant={isPrimaryApplicant}
                    formItems={formItems}
                    addressItemName={addressItemName}
                    formikProps={formikProps}
                />
            )}
        </>
    );
};
