import { forwardRef, useContext, useEffect, useRef, useState } from 'react';

import { H2 } from '@selina-finance/ui';

import { ApplicationContext } from '@src/app/contexts/Application';

import BackButton from '../../components/BackButton/BackButton';
import ProgressMenu from '../../components/Progress/ProgressMenu';

import IncomingView from './IncomingView';

export interface IncomingsViewProps {
    className?: string;
    incomingsData?: any;
    applicantsData?: any;
    activeApplicant?: number | null;
    setIncomingsData: Function;
    saveIncomings?: (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => Promise<any>;
}

const IncomingsView = forwardRef((props: IncomingsViewProps, ref) => {
    const [activeApplicant, setActiveApplicant] = useState(
        props.activeApplicant || 0,
    );
    const [applicantCount, setApplicantCount] = useState(1);
    const { applicants, setCurrentForm } = useContext(ApplicationContext);
    const firstViewRef = useRef(null);
    const secondViewRef = useRef(null);

    const topRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.activeApplicant) {
            setActiveApplicant(props.activeApplicant);
            topRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.activeApplicant]);

    useEffect(() => {
        setApplicantCount(applicants.length);
    }, [applicants, setCurrentForm]);

    const isFirst = activeApplicant === 0;
    const isSecond = activeApplicant === 1;

    return (
        <div className='flex justify-between flex-col sm:flex-row' ref={topRef}>
            <div className='w-full sm:w-1/2 lg:w-2/5 mt-20 sm:mt-0'>
                <BackButton />
                {isFirst && <H2>Your income</H2>}
                {isSecond && <H2>{applicants[1].firstName}'s income</H2>}
                <div className='mt-3 border-l-4 border-pink'>
                    <p className='text-steel-100 ml-4 text-xl'>
                        So we can help you understand how much you can borrow
                    </p>
                </div>
                <div className='relative mb-15'>
                    {isFirst && (
                        <IncomingView
                            formRef={(ref: any) => {
                                firstViewRef.current = ref;
                            }}
                            saveRef={ref}
                            setActiveApplicant={setActiveApplicant}
                            applicantCount={applicantCount}
                            setIncomeData={(data: any) =>
                                props.setIncomingsData(data, 0)
                            }
                            incomeData={props.incomingsData?.[0] || {}}
                            saveIncomings={props.saveIncomings}
                            isPrimaryApplicant={true}
                        />
                    )}
                    {applicantCount === 2 && isSecond && (
                        <IncomingView
                            formRef={(ref: any) => {
                                secondViewRef.current = ref;
                            }}
                            saveRef={ref}
                            setActiveApplicant={setActiveApplicant}
                            applicantCount={applicantCount}
                            setIncomeData={(data: any) =>
                                props.setIncomingsData(data, 1)
                            }
                            incomeData={props.incomingsData?.[1] || {}}
                            secondary
                            saveIncomings={props.saveIncomings}
                            isPrimaryApplicant={false}
                        />
                    )}
                </div>
            </div>
            <ProgressMenu />
        </div>
    );
});

export default IncomingsView;
