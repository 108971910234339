import { FC, PropsWithChildren } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Container } from '@src/ui/components/Container';
import { LogoWithText } from '@src/ui/components/Icons';
import ContactEmail from '@src/ui/components/Icons/ContactEmail';
import ContactPhone from '@src/ui/components/Icons/ContactPhone';

const ContactAnchor: FC<
    PropsWithChildren<{
        className?: string;
        link: string;
    }>
> = ({ className, link, children }) => (
    <a className={className} href={link} target='_blank' rel='noreferrer'>
        {children}
    </a>
);

const AnimatedAnchor: FC<{ label: string; link: string }> = ({
    label,
    link,
}) => (
    <a
        className='block hover:translate-x-1 transform'
        rel='noreferrer'
        href={link}
    >
        {label}
    </a>
);

const Contacts: FC = () => {
    const { t } = useTranslation('footer', { keyPrefix: 'contacts' });
    return (
        <div>
            <ContactAnchor link='https://www.selinafinance.co.uk/'>
                <LogoWithText className='h-10 mb-6' />
            </ContactAnchor>
            <div className='flex flex-col gap-1'>
                <ContactAnchor
                    className='font-bold'
                    link='https://goo.gl/maps/yAmeLamf9jnTmNFt5'
                >
                    {t('address')}
                </ContactAnchor>
                <ContactAnchor
                    className='flex gap-2 items-center'
                    link='tel:02045258044'
                >
                    <ContactPhone />
                    {t('phone')}
                </ContactAnchor>
                <ContactAnchor
                    className='flex gap-2 items-center'
                    link={'mailto:hello@selinafinance.co.uk'}
                >
                    <ContactEmail />
                    {t('email')}
                </ContactAnchor>
            </div>
        </div>
    );
};

const Products: FC = () => {
    const { t } = useTranslation('footer', { keyPrefix: 'products' });
    return (
        <div>
            <p className='font-bold'>{t('label')}</p>
            <AnimatedAnchor
                label={t('links.heloc')}
                link='https://www.selinafinance.co.uk/borrow/home-equity-line-of-credit'
            />
            <AnimatedAnchor
                label={t('links.homeownerLoan')}
                link='https://www.selinafinance.co.uk/borrow/homeowner-loan'
            />
        </div>
    );
};

const Intermediaries: FC = () => {
    const { t } = useTranslation('footer', { keyPrefix: 'intermediaries' });
    return (
        <div>
            <p className='font-bold'>{t('label')}</p>
            <AnimatedAnchor
                label={t('links.consumer')}
                link='https://www.selinafinance.co.uk/intermediaries/consumer'
            />
            <AnimatedAnchor
                label={t('links.advice')}
                link='https://www.selinafinance.co.uk/advice'
            />
            <AnimatedAnchor
                label={t('links.partners')}
                link='https://www.selinafinance.co.uk/partner'
            />
        </div>
    );
};

const Company: FC = () => {
    const { t } = useTranslation('footer', { keyPrefix: 'company' });
    return (
        <div>
            <p className='font-bold'>{t('label')}</p>
            <AnimatedAnchor
                label={t('links.about')}
                link='https://www.selinafinance.co.uk/about-us/company'
            />
            <AnimatedAnchor
                label={t('links.faqs')}
                link='https://www.selinafinance.co.uk/about-us/frequently-asked-questions'
            />
            <AnimatedAnchor
                label={t('links.blog')}
                link='https://www.selinafinance.co.uk/blog'
            />
            <AnimatedAnchor
                label={t('links.careers')}
                link='https://selinafinance.recruitee.com/'
            />
            <AnimatedAnchor
                label={t('links.contactUs')}
                link='https://www.selinafinance.co.uk/about-us/contact-us'
            />
            <AnimatedAnchor
                label={t('links.complaints')}
                link='https://www.selinafinance.co.uk/complaints'
            />
            <AnimatedAnchor
                label={t('links.cookiePolicy')}
                link='https://www.selinafinance.co.uk/cookie-policy'
            />
            <AnimatedAnchor
                label={t('links.privacyPolicy')}
                link='https://www.selinafinance.co.uk/privacy-policy'
            />
            <AnimatedAnchor
                label={t('links.terms')}
                link='https://www.selinafinance.co.uk/terms-of-use'
            />
            <AnimatedAnchor
                label={t('links.marketing')}
                link='https://www.selinafinance.co.uk/marketing-claims'
            />
        </div>
    );
};

const Registration: FC = () => {
    const { t } = useTranslation('footer', { keyPrefix: 'registration' });
    return (
        <div className='mb-6 text-steel-60'>
            <p>{t('line1')}</p>
            <p>{t('line2')}</p>
        </div>
    );
};

const Footer: FC<{ className?: string }> = (props) => {
    return (
        <div
            className={clsx(
                props.className,
                'border-t-1 border-neutral-40 bg-white text-md text-steel-100 px-6',
            )}
        >
            <Container variant='wide'>
                <div className='flex flex-col justify-between md:flex-row py-32px gap-6 md:gap-0'>
                    <Contacts />
                    <div className='flex flex-col gap-6 md:flex-row md:gap-15'>
                        <Products />
                        <Intermediaries />
                        <Company />
                    </div>
                </div>
                <div className='border-mono-30 border-t-1 h-1 mb-6 w-full'></div>
                <Registration />
            </Container>
        </div>
    );
};

export default Footer;
