import { FC, useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';
import SignInView from '@src/ui/views/auth/SignInView';

import AuthLayout from '../layouts/AuthLayout';

const Login: FC = () => {
    const { logOut, logIn, isLoggedIn, forgotPassword, loading } =
        useContext(AuthContext);
    const [waiting, setWaiting] = useState(true);
    const email = new URLSearchParams(useLocation().search).get('email') || '';
    const applicationId =
        new URLSearchParams(useLocation().search).get('applicationId') || '';

    const navigate = useNavigate();

    useEffect(() => {
        logOut();
    }, [isLoggedIn, logOut]);

    useEffect(() => {
        setWaiting(true);
        isLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                navigate('/');
            } else {
                setWaiting(false);
            }
        });
    }, [setWaiting, navigate, isLoggedIn]);

    return (
        <AuthLayout
            title={'Log in to your account'}
            loading={waiting || loading}
        >
            <SignInView
                onSubmit={logIn}
                forgotPassword={forgotPassword}
                email={email}
                applicationId={applicationId}
            />
        </AuthLayout>
    );
};

export default Login;
