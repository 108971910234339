import { FC, useContext, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';
import ResetPasswordView from '@src/ui/views/auth/ResetPasswordView';

import AuthLayout from '../layouts/AuthLayout';

const ResetPassword: FC = () => {
    const { isLoggedIn, logOut, resetPassword, loading, forgotPassword } =
        useContext(AuthContext);
    const location = useLocation();
    const search = location.search;
    const email = new URLSearchParams(search).get('email') || '';

    useEffect(() => {
        logOut();
    }, [isLoggedIn, logOut]);

    return (
        <AuthLayout
            title={'Reset your password'}
            subTitle={
                <>
                    We’ve sent a verification code to your email address:{' '}
                    {email ? <strong>{email}</strong> : ''}. Please enter the
                    code to reset your Password.
                </>
            }
            loading={loading}
        >
            <ResetPasswordView
                className={'mt-4'}
                resetPassword={resetPassword}
                resendCode={forgotPassword}
                email={email}
            />
        </AuthLayout>
    );
};

export default ResetPassword;
