import React, { useState } from 'react';

import clsx from 'clsx';

const className = {
    wrapper: 'relative',
    list: 'absolute bg-white shadow-dropdown w-max rounded-lg overflow-auto max-h-215 mt-1 z-30',
};

export type AlignmentType = 'left' | 'right';

export interface DropdownProps {
    alignment: AlignmentType;
    avatar: React.ReactNode;
    children: any;
}

const Dropdown = (props: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const alignment: AlignmentType = props.alignment || 'left';
    const wrapperClassNames = clsx(className.wrapper, `text-${alignment}`);
    const listClassNames = clsx(className.list, `${alignment}-0`);

    return (
        <div className={wrapperClassNames}>
            {isOpen && (
                <div
                    className='fixed z-10 left-0 right-0 bottom-0 top-0'
                    onClick={() => setIsOpen(!isOpen)}
                />
            )}
            <div
                onClick={() => setIsOpen(!isOpen)}
                className='flex z-10 cursor-pointer'
            >
                {props.avatar}
            </div>
            {isOpen && <div className={listClassNames}>{props.children}</div>}
        </div>
    );
};

export default Dropdown;
