const CheckIcon = () => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 2.375C8.39303 2.375 6.82214 2.85152 5.486 3.74431C4.14985 4.6371 3.10844 5.90605 2.49348 7.3907C1.87852 8.87535 1.71762 10.509 2.03112 12.0851C2.34463 13.6612 3.11846 15.1089 4.25476 16.2452C5.39106 17.3815 6.8388 18.1554 8.4149 18.4689C9.99099 18.7824 11.6247 18.6215 13.1093 18.0065C14.594 17.3916 15.8629 16.3502 16.7557 15.014C17.6485 13.6779 18.125 12.107 18.125 10.5C18.1209 8.34638 17.2635 6.28216 15.7407 4.75932C14.2178 3.23648 12.1536 2.37913 10 2.375ZM13.8672 9.07812L9.28907 13.4531C9.17071 13.5645 9.01406 13.626 8.85157 13.625C8.77214 13.6261 8.69328 13.6115 8.61953 13.582C8.54578 13.5525 8.47861 13.5087 8.42188 13.4531L6.13282 11.2656C6.06933 11.2102 6.01769 11.1426 5.98102 11.0667C5.94434 10.9909 5.92338 10.9084 5.9194 10.8242C5.91542 10.74 5.92849 10.6559 5.95784 10.577C5.98719 10.498 6.03221 10.4258 6.09019 10.3646C6.14816 10.3035 6.2179 10.2547 6.29522 10.2212C6.37253 10.1877 6.45582 10.1702 6.54007 10.1697C6.62433 10.1692 6.70781 10.1857 6.78551 10.2183C6.86321 10.2509 6.93351 10.2989 6.99219 10.3594L8.85157 12.1328L13.0078 8.17188C13.1293 8.06585 13.2871 8.01091 13.4482 8.01853C13.6093 8.02615 13.7612 8.09575 13.8722 8.21277C13.9832 8.3298 14.0446 8.48519 14.0437 8.64646C14.0428 8.80773 13.9795 8.9624 13.8672 9.07812Z'
                fill='#469E10'
            />
        </svg>
    );
};

export default CheckIcon;
