import { FC, useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';

import { HttpError } from '../HttpError';

const Calendly: FC<{ onClose: Function }> = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const { setCallDateTime, saveScheduledEventBooking } =
        useContext(ApplicationContext);
    const handleClose = () => {
        props.onClose();
        document.body.style.overflow = 'initial';
    };

    useEffect(() => {
        window.addEventListener('keydown', handleClose);
        return () => window.removeEventListener('keydown', handleClose);
    });

    useEffect(() => {
        const handleEvent = async (e: MessageEvent) => {
            if (e.data && e.data.event === 'calendly.event_scheduled') {
                const eventData = e.data.payload;
                const response = await axios.get(eventData.event.uri, {
                    headers: {
                        Authorization: `Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzIxODI4OTExLCJqdGkiOiI1YmIxNTJlZi01MDIwLTQ4ODQtYTkyZS04ZjRhMmQwNmE5ZTYiLCJ1c2VyX3V1aWQiOiI5MjU3M2U3ZS0zMzJmLTRmMDAtOTBjNC00OTJkYzNhMDkyMDMifQ.9C7laNY0e5Jz60j5wOKfwv4mOwFbn8ShufYxv0M8y6VPDzZ_61J6b9h1rCTwpJxpIi1FNF1zQsg8sii5EOqA-g`,
                    },
                });
                const eventDetails = response.data.resource;
                const callDateTime = eventDetails.start_time;
                const eventMembershipData = eventDetails.event_memberships[0];
                const eventBooking = {
                    name: eventDetails.name,
                    createdAt: eventDetails.created_at,
                    updatedAt: eventDetails.updated_at,
                    eventMemberships: [
                        {
                            bufferedEndTime:
                                eventMembershipData.buffered_end_time,
                            bufferedStartTime:
                                eventMembershipData.buffered_start_time,
                            userEmail: eventMembershipData.user_email,
                            userName: eventMembershipData.user_name,
                        },
                    ],
                };

                if (callDateTime) {
                    setCallDateTime(callDateTime);

                    const response = await saveScheduledEventBooking(
                        eventBooking,
                    );
                    if (response.success) {
                        const isInOfferProcess =
                            window.location.pathname.includes(
                                '/eligibility/offer/finalise',
                            ) ||
                            window.location.pathname.includes(
                                '/eligibility/offer/book-call',
                            );
                        if (isInOfferProcess) {
                            navigate('/eligibility/offer/confirmation');
                        }
                    }
                    if (!response.success) {
                        setError(response.error);
                    }
                }
            }
        };

        window.addEventListener('message', handleEvent);

        const getCalendlyUrl = () => {
            const env = window?._env_?.ENV?.toUpperCase();

            if (env === 'PRODUCTION') {
                return 'https://calendly.com/selinaadvance/advice?background_color=FBFAF9';
            } else {
                return 'https://calendly.com/gearoid-houston-selinafinance/30min?background_color=FBFAF9';
            }
        };

        const calendlyUrl = getCalendlyUrl();

        const calendlyEmbedMobile = document.getElementById(
            'calendly-embed-mobile',
        );

        if (calendlyEmbedMobile && calendlyEmbedMobile.children.length === 0) {
            window.Calendly.initInlineWidget({
                url: calendlyUrl,
                parentElement: calendlyEmbedMobile,
            });
        }

        const calendlyEmbed = document.getElementById('calendly-embed');

        if (calendlyEmbed && calendlyEmbed.children.length === 0) {
            window.Calendly.initInlineWidget({
                url: calendlyUrl,
                parentElement: calendlyEmbed,
            });
        }

        document.body.style.overflow = 'hidden';

        return () => {
            window.removeEventListener('message', handleEvent);
        };
    }, [navigate, setCallDateTime, saveScheduledEventBooking]);

    return (
        <>
            {/* Hidden on larger screens */}
            <div className='block lg:hidden p-4 flex justify-center'>
                <div
                    id='calendly-embed-mobile'
                    className='calendly-inline-widget'
                ></div>
            </div>
            {/* Hidden on smaller screens */}
            <div
                className='hidden lg:block backdrop-blur-xs backdrop-filter bottom-0 fixed h-screen left-0 right-0 top-0 w-screen bg-opacity-40 bg-black'
                onClick={handleClose}
            >
                <div className='fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                    <div
                        id='calendly-embed'
                        className='calendly-inline-widget'
                    ></div>
                    <script
                        type='text/javascript'
                        src='https://assets.calendly.com/assets/external/widget.js'
                    ></script>
                </div>
            </div>
            {error && <HttpError error={error} />}
        </>
    );
};
export default Calendly;
