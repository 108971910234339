import { useContext, useEffect } from 'react';

import cookie from 'cookie';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';
import { ApplicationContext } from '@src/app/contexts/Application';

import AuthLayout from '../layouts/AuthLayout';
import CreatePasswordView from '../views/auth/CreatePasswordView';

const buttonText: Record<string, string> = {
    create_account: 'Get your quote',
    save_for_later: 'Save my application',
};

const CreatePassword = () => {
    const { register, loading, logIn, logOut, isLoggedIn } =
        useContext(AuthContext);
    const { setExternalApplicationId } = useContext(ApplicationContext);
    const location = useLocation();
    const flow =
        (location.state as { intent: string } | undefined)?.intent || 'default';
    const search = location.search;

    const cookies = cookie.parse(document.cookie);
    const appInfo = cookies.application ? JSON.parse(cookies.application) : {};

    // the + in an email was being replaced by a space
    const email = (
        new URLSearchParams(search).get('email') ||
        appInfo.emailAddress ||
        ''
    ).replace(/ /g, '+');

    const url = new URL(window.location.href);
    const urlExternalApplicationId = url.searchParams.get(
        'externalApplicationId',
    );

    useEffect(() => {
        logOut();
        if (urlExternalApplicationId) {
            setExternalApplicationId(urlExternalApplicationId);
            localStorage.setItem(
                'externalApplicationId',
                urlExternalApplicationId,
            );
        }
    }, [
        isLoggedIn,
        logOut,
        setExternalApplicationId,
        urlExternalApplicationId,
    ]);

    return (
        <AuthLayout
            title={flow === 'default' ? 'Create Password' : undefined}
            subTitle={
                flow === 'default' ? (
                    <>
                        Enter a password to create your account for{' '}
                        <strong>{email}</strong>{' '}
                    </>
                ) : undefined
            }
            loading={loading}
        >
            <CreatePasswordView
                register={register}
                email={email}
                logIn={logIn}
                buttonText={buttonText[flow]}
                flow={flow}
            />
        </AuthLayout>
    );
};

export default CreatePassword;
