const TimerIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
        >
            <path
                opacity='0.2'
                d='M12.3334 20.25C16.8897 20.25 20.5834 16.5563 20.5834 12C20.5834 7.44365 16.8897 3.75 12.3334 3.75C7.77702 3.75 4.08337 7.44365 4.08337 12C4.08337 16.5563 7.77702 20.25 12.3334 20.25Z'
                fill='#3A4146'
            />
            <path
                d='M12.3334 20.25C16.8897 20.25 20.5834 16.5563 20.5834 12C20.5834 7.44365 16.8897 3.75 12.3334 3.75C7.77702 3.75 4.08337 7.44365 4.08337 12C4.08337 16.5563 7.77702 20.25 12.3334 20.25Z'
                stroke='#3A4146'
                strokeWidth='1.5'
                strokeMiterlimit='10'
            />
            <path
                d='M12.3334 11.9996L16.0459 8.28711'
                stroke='#3A4146'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.0834 0.75H14.5834'
                stroke='#3A4146'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default TimerIcon;
