import { useContext, useEffect } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import EligibilityLayout from '@src/ui/layouts/EligibilityLayout';
import ConfirmationView from '@src/ui/views/offer/ConfirmationView';

const Confirmation = () => {
    const { loading, selectedOffer, fetchRecommendedOffers, callDateTime } =
        useContext(ApplicationContext);

    const date = new Date(callDateTime);
    const callDate = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });

    const callStartTime = date.toLocaleTimeString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
    });

    const callEndTime = new Date(
        date.getTime() + 30 * 60000,
    ).toLocaleTimeString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    const callTime = `${callStartTime} - ${callEndTime}`;

    useEffect(() => {
        if (!selectedOffer) {
            fetchRecommendedOffers();
        }
    }, [selectedOffer, fetchRecommendedOffers]);

    return (
        <EligibilityLayout loading={loading} offerPage>
            {selectedOffer ? (
                <ConfirmationView
                    offer={selectedOffer}
                    callDate={callDate}
                    callTime={callTime}
                />
            ) : (
                <div></div>
            )}
        </EligibilityLayout>
    );
};

export default Confirmation;
