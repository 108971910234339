import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';

export interface IconBoxProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const IconBox: FC<IconBoxProps> = (props) => {
    return (
        <div
            className={clsx(
                props.className,
                'flex justify-center align-middle',
            )}
            {...omit(props, 'className', 'children')}
        >
            {props.children}
        </div>
    );
};

export default IconBox;
