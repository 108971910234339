import { format, isValid, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';

import { monthNames } from '@Shared/lists';

export const getCurrentFinancialYear = () => {
    const today = new Date();
    let start = today.getFullYear();
    let end = today.getFullYear() + 1;

    if (today.getMonth() + 1 <= 3) {
        start = today.getFullYear() - 1;
        end = today.getFullYear();
    }
    return {
        start,
        end,
    };
};

export const fiscalYears = (interVal: number) => {
    const currentStart = getCurrentFinancialYear().start;
    const result = [];
    for (let i = 0; i < interVal; i++) {
        result.push({
            label: `${currentStart - i}`,
            value: currentStart - i,
        });
    }
    return result;
};

export const isValidDateFormat = (
    date: string,
    dateFormat: string = 'dd/MM/yyyy',
) => {
    const parsedDate = parse(date, dateFormat, new Date());
    return isValid(parsedDate);
};

export const isValidDate = (dateString: string) => {
    const date = parseApiDate(dateString);
    return isValid(date);
};

export const toValidDate = (value: string) => {
    return isValidDateFormat(value, 'yyyy-MM-dd')
        ? format(parseApiDate(value), 'dd/MM/yyyy')
        : '';
};

export const toApiDate = (value: string | Date) => {
    if (value instanceof Date) {
        value = value.toLocaleDateString('en-GB');
    }
    return value
        ? format(parse(value, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
        : undefined;
};

export const parseApiDate = (dateString: string) => {
    return parse(dateString, 'yyyy-MM-dd', new Date(), { locale: enGB });
};

export const parseValidDate = (dateString: string) => {
    return parse(dateString, 'dd/MM/yyyy', new Date(), { locale: enGB });
};

const yearsArray = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i,
);

export const years = yearsArray.map((year) => {
    return { label: year.toString(), value: year.toString() };
});

export const viewModeAddressDateFormatter = (date: any) => {
    const inputFromYear = Number(date.fromYear);
    const inputFromMonth = Number(date.fromMonth);

    const inputToMonth = Number(date.toMonth);
    const inputToYear = Number(date.toYear);

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const toDate =
        inputToMonth === currentMonth && inputToYear === currentYear
            ? 'Present'
            : `${monthNames[inputToMonth - 1]} ${inputToYear}`;

    return `${monthNames[inputFromMonth - 1]} ${inputFromYear} - ${toDate}`;
};

export const formAddressDateParser = (addressDate?: string) => {
    if (!addressDate || addressDate.length !== 7) {
        return null;
    }

    const [month, year] = addressDate.split('/');

    return `${year}-${month}-01`;
};

export const months = [
    { label: 'Jan', value: '01' },
    { label: 'Feb', value: '02' },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04' },
    { label: 'May', value: '05' },
    { label: 'Jun', value: '06' },
    { label: 'Jul', value: '07' },
    { label: 'Aug', value: '08' },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' },
];
