import { ReactNode } from 'react';

import {
    KameleoonProvider,
    SDKConfigurationType,
    createClient,
} from '@kameleoon/react-sdk';

const configuration: Partial<SDKConfigurationType> = {
    environment: window?._env_?.ENV?.toLowerCase(),
};

const client = createClient({
    siteCode: 'jr6r0n042f',
    configuration,
});

export const KameleeonProvider = ({ children }: { children: ReactNode }) => {
    return <KameleoonProvider client={client}>{children}</KameleoonProvider>;
};

export enum KAMELEOON_FEATURE {
    wait = 'wait_flow',
    loan_details = 'loan_details_flow',
}

export const sendFeatureToHeap = (
    variant: string,
    feature: string,
    applicationId: string,
    email: string,
) => {
    const heapData: { [key: string]: string } = {};

    if (
        variant.startsWith('variant') &&
        (window?._env_?.ENV?.toUpperCase() === 'UAT' ||
            window?._env_?.ENV?.toUpperCase() === 'PRODUCTION')
    ) {
        heapData[feature] = variant;
        heapData['email'] = email;

        if (window.heap) {
            window.heap.identify(applicationId);
            window.heap.addUserProperties(heapData);
        }
    }
};
