import { ChangeEvent, FocusEvent } from 'react';

import { isEqualStr, normalizeNumber } from '@Helpers';
import { setNestedObjectValues } from 'formik';
import {
    get,
    has,
    isObject,
    isString,
    isUndefined,
    last,
    omit,
    set,
} from 'lodash';
import * as Yup from 'yup';

import { CustomFormProps } from '@App/formModels/shared';

export const radioButtonOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export const radioButtonOptionsInvert = [
    { label: 'Yes', value: 'false' },
    { label: 'No', value: 'true' },
];

export const getFormPropsHelper = (
    formItems: any,
    formikProps: any,
    name: string,
    groupName?: string,
    labelIndex?: number,
) => {
    let onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
        formikProps.setFieldValue(name, e.currentTarget.value);
    const formItemObject = groupName
        ? get(formItems, groupName)
        : get(formItems, name);

    if (
        formItemObject?.convert === 'number' ||
        formItemObject?.mask === 'number' ||
        formItemObject?.mask === 'year'
    ) {
        onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let value = e.currentTarget.value;
            const isInFloatType = last(value) === '.';
            value = value.replaceAll(',', '');
            formikProps.setFieldValue(
                name,
                isInFloatType ? parseFloat(value) + '.' : parseFloat(value),
            );
        };
    }

    if (formItemObject?.convert === 'boolean') {
        onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let value: any = '' + e.currentTarget.value;

            if (
                isString(value) &&
                (isEqualStr(value, 'yes') ||
                    isEqualStr(value, '1') ||
                    isEqualStr(value, 'true'))
            ) {
                value = true;
            }

            if (
                isString(value) &&
                (isEqualStr(value, 'no') ||
                    isEqualStr(value, '0') ||
                    isEqualStr(value, 'false'))
            ) {
                value = false;
            }

            formikProps.setFieldValue(name, value);
        };
    }

    const tmpValue = get(formikProps.values, name);
    const value = isObject(tmpValue)
        ? tmpValue
        : isUndefined(tmpValue)
        ? ''
        : tmpValue + '';

    return {
        ...{
            value,
            onChange,
            onBlur: formikProps.handleBlur,
            name,
            label: isUndefined(labelIndex)
                ? formItemObject.label
                : formItemObject.label[labelIndex],
        },
        ...omit(formItemObject, 'default', 'validation', 'label', 'transform'),
    };
};

export const setZeroOnBlur = (formikProps: any, fieldName: string) => {
    return {
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
            const value = get(formikProps.values, fieldName);
            if (isUndefined(value) || value === '' || isNaN(value)) {
                formikProps.setFieldValue(fieldName, 0);
            }
            formikProps.handleBlur(e);
        },
    };
};

export const getFieldError = (
    formikProps: any,
    fieldName: string,
    notTouched?: boolean,
) => {
    const errors = formikProps.errors as any;
    const fieldError = get(errors, fieldName);
    return fieldError &&
        (formikProps.getFieldMeta(fieldName).touched || notTouched)
        ? fieldError
        : '';
};

export const setMinOrMaxOnBlur = (
    formikProps: any,
    fieldName: string,
    min: number | undefined,
    max: number | undefined,
) => {
    return {
        onBlur: (e: FocusEvent<HTMLInputElement>) => {
            const value = Math.max(
                min || 0,
                normalizeNumber(e.currentTarget.value),
            );
            formikProps.setFieldValue(fieldName, Math.min(value, max || 0));
            formikProps.handleBlur(e);
        },
    };
};

export const setFocusOnRelatedField = (
    formikProps: any,
    field: string,
    valueToCompare: string,
    fieldToFocus: string,
) => {
    return {
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            formikProps.setFieldValue(field, value);
            if (value === valueToCompare) {
                setTimeout(
                    () => document.getElementsByName(fieldToFocus)?.[0].focus(),
                    0,
                );
            }
            formikProps.handleChange(e);
        },
    };
};

export const getDefaultValues = (ItemDefs: CustomFormProps) => {
    const returnValue: any = {};
    Object.keys(ItemDefs).forEach((key: any) => {
        returnValue[key] = get(ItemDefs[key], 'default', '');
    });
    return returnValue;
};

const getSubValidationSchema = (
    _parentKey: string,
    ItemDefs: CustomFormProps,
) => {
    const returnValue: any = {};
    Object.keys(ItemDefs).forEach((key: any) => {
        if (has(ItemDefs[key], 'validation')) {
            set(returnValue, key, get(ItemDefs[key], 'validation'));
        }
    });

    return returnValue;
};

export const getValidationSchema = (ItemDefs: CustomFormProps) => {
    let returnValue: any = {};
    Object.keys(ItemDefs).forEach((key: any) => {
        if (has(ItemDefs[key], 'validation')) {
            returnValue[key] = get(ItemDefs[key], 'validation');
        }

        if (has(ItemDefs[key], 'nested')) {
            returnValue = {
                ...returnValue,
                [key]: Yup.object({
                    ...getSubValidationSchema(key, ItemDefs[key]),
                }),
            };
        }
    });
    return Yup.object().shape(returnValue);
};

export const handleSubFormValidation = async (subFromik: any) => {
    if (!subFromik) {
        return false;
    }
    const errors = await subFromik.validateForm();
    if (Object.keys(errors).length !== 0) {
        subFromik.setTouched(setNestedObjectValues(errors, true));
        return false;
    }
    return true;
};

export const getFormHelpers = (
    formStructure: CustomFormProps,
    formikProps: any,
) => {
    const getProps = (
        fieldName: string,
        groupName?: string,
        labelIndex?: number,
    ) => {
        return getFormPropsHelper(
            formStructure,
            formikProps,
            fieldName,
            groupName,
            labelIndex,
        );
    };

    const getError = (fieldName: string, notTouched?: boolean) => {
        return getFieldError(formikProps, fieldName, notTouched);
    };

    const getPropsWithError = (
        fieldName: string,
        groupName?: string,
        labelIndex?: number,
    ) => {
        return {
            ...getProps(fieldName, groupName || fieldName, labelIndex),
            error: getError(fieldName),
        };
    };

    const hasError = () => {
        return Object.keys(formikProps.errors).length !== 0;
    };

    const hasFieldError = (fieldName: string) => {
        return getFieldError(formikProps, fieldName) !== '';
    };

    const hasErrorAnyOf = (fieldNames: string[]) => {
        let result = false;
        fieldNames.forEach((fieldName: string) => {
            if (hasFieldError(fieldName)) {
                result = true;
            }
        });
        return result;
    };

    return {
        getProps,
        getError,
        hasFieldError,
        hasErrorAnyOf,
        getPropsWithError,
        hasError,
    };
};
