const HandshakeIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
        >
            <path
                opacity='0.2'
                d='M19.4163 14.3343L15.9663 17.7843C15.8722 17.8723 15.7583 17.9361 15.6342 17.9704C15.5101 18.0047 15.3796 18.0084 15.2538 17.9812L9.81626 16.6218C9.71693 16.5941 9.62438 16.5463 9.54438 16.4812L4.41626 12.4781L7.47251 6.73121L12.2819 5.32496C12.4528 5.27595 12.6355 5.28924 12.7975 5.36246L16.0413 6.83433H14.1006C14.0032 6.83399 13.9067 6.85286 13.8167 6.88986C13.7266 6.92686 13.6447 6.98126 13.5756 7.04996L9.90064 10.7156C9.8256 10.7926 9.76778 10.8847 9.731 10.9858C9.69423 11.0868 9.67934 11.1945 9.68731 11.3018C9.69529 11.409 9.72595 11.5133 9.77727 11.6078C9.82859 11.7023 9.8994 11.7849 9.98501 11.85L10.4913 12.2343C11.0112 12.6224 11.6425 12.832 12.2913 12.832C12.94 12.832 13.5714 12.6224 14.0913 12.2343L15.2913 11.3343L19.4163 14.3343Z'
                fill='#3A4146'
            />
            <path
                d='M23.2321 11.4191L20.9165 12.5722L17.9165 6.83469L20.2603 5.66281C20.4344 5.57403 20.6366 5.55771 20.8228 5.61742C21.009 5.67713 21.164 5.80803 21.254 5.98156L23.5603 10.3972C23.607 10.4856 23.6356 10.5824 23.6444 10.682C23.6532 10.7817 23.642 10.882 23.6114 10.9772C23.5808 11.0724 23.5315 11.1606 23.4664 11.2365C23.4013 11.3123 23.3216 11.3744 23.2321 11.4191V11.4191Z'
                stroke='#3A4146'
                strokeWidth='1.5625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M4.41659 12.4789L2.10096 11.3163C2.0118 11.2727 1.93234 11.2115 1.8673 11.1364C1.80226 11.0614 1.75296 10.9741 1.72235 10.8796C1.69174 10.7852 1.68044 10.6855 1.68911 10.5866C1.69778 10.4877 1.72626 10.3915 1.77284 10.3039L4.07909 5.88822C4.16931 5.71482 4.32366 5.58348 4.50928 5.52219C4.69489 5.46091 4.8971 5.47451 5.07284 5.5601L7.41659 6.73198L4.41659 12.4789Z'
                stroke='#3A4146'
                strokeWidth='1.5625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20.9163 12.5713L19.4163 14.3338L15.9663 17.7838C15.8722 17.8717 15.7583 17.9356 15.6342 17.9698C15.5101 18.0041 15.3796 18.0078 15.2538 17.9807L9.81626 16.6213C9.71693 16.5936 9.62438 16.5457 9.54438 16.4807L4.41626 12.4775'
                stroke='#3A4146'
                strokeWidth='1.5625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.4166 14.334L15.2916 11.334L14.0916 12.234C13.5717 12.622 12.9403 12.8317 12.2916 12.8317C11.6429 12.8317 11.0115 12.622 10.4916 12.234L9.98534 11.8496C9.89973 11.7845 9.82892 11.702 9.7776 11.6075C9.72628 11.513 9.69562 11.4087 9.68764 11.3014C9.67966 11.1942 9.69455 11.0865 9.73133 10.9854C9.7681 10.8844 9.82592 10.7923 9.90096 10.7152L13.576 7.04961C13.645 6.98092 13.7269 6.92652 13.817 6.88952C13.9071 6.85252 14.0036 6.83365 14.101 6.83399H17.9166'
                stroke='#3A4146'
                strokeWidth='1.5625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.47266 6.73121L12.282 5.32496C12.4529 5.27595 12.6357 5.28924 12.7977 5.36246L16.0414 6.83433'
                stroke='#3A4146'
                strokeWidth='1.5625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default HandshakeIcon;
