import { ChangeEvent, FC } from 'react';

import { Controller, RadioButtonGroup, TextField } from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    radioButtonOptions,
} from '@Shared/helpers/input';
import { selfEmployedSoleTrader } from '@src/app/formModels/eligibility/incomings.selfEmployed';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import LightbulbIcon from '../../components/Icons/LightbulbIcon';

import ExpectedDecrease from './IncomingExpectedDecreaseField';
import IncomingOtherIncomesSubView from './IncomingOtherIncomesSubView';

export interface IncomingSelfEmployedViewSoleOrPartnershipProps {
    setRef?: Function;
    incomeData?: any;
}

const defaultValues = getDefaultValues(selfEmployedSoleTrader);
const validationSchema = getValidationSchema(selfEmployedSoleTrader);

const IncomingSelfEmployedViewSoleTrader: FC<
    IncomingSelfEmployedViewSoleOrPartnershipProps
> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(props.incomeData, Object.keys(selfEmployedSoleTrader)),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) =>
                pick(values, Object.keys(selfEmployedSoleTrader))
            }
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getError, getPropsWithError } = getFormHelpers(
                    selfEmployedSoleTrader,
                    formikProps,
                );

                return (
                    <>
                        <Controller label='Do you work under a company name?'>
                            <RadioButtonGroup
                                name='work'
                                id='work'
                                value={
                                    formikProps.values.workUnderCompanyName?.toString() ||
                                    ''
                                }
                                error={{
                                    hasError: false,
                                    errorMessage: getError(
                                        'workUnderCompanyName',
                                    ),
                                }}
                                onChange={(e: any) => {
                                    const value = e.currentTarget.value;
                                    if (value === 'false') {
                                        formikProps.setFieldValue(
                                            'registeredCompanyName',
                                            undefined,
                                        );
                                    }
                                    formikProps.setFieldValue(
                                        'workUnderCompanyName',
                                        value,
                                    );
                                }}
                                options={radioButtonOptions}
                            />
                        </Controller>
                        {formikProps.values.workUnderCompanyName === 'true' && (
                            <TextField
                                label='What is the company’s registered name?'
                                name='registeredCompanyName'
                                maxLength={255}
                                value={
                                    formikProps.values.registeredCompanyName?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('registeredCompanyName')}
                            />
                        )}
                        <TextField
                            label='How long have you been self-employed?'
                            name='lengthSelfEmployed'
                            mask='number'
                            suffix='Years'
                            maxLength={2}
                            value={
                                formikProps.values.lengthSelfEmployed?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('lengthSelfEmployed')}
                        />
                        <Controller
                            label='What is your annual income?'
                            additionalInfo='This should be the annual, pre-tax amount'
                        >
                            <div className='bg-neutral-40 flex p-3 rounded-lg my-4'>
                                <div className='mr-2'>
                                    <LightbulbIcon />
                                </div>
                                <div>
                                    <p className='text-steel-100 font-semibold'>
                                        We may ask for proof of income later.
                                    </p>
                                </div>
                            </div>
                            <TextField
                                type='tel'
                                prefix='£'
                                maxLength={10}
                                mask={'currency' as any}
                                name='incomeAmountNetProfit'
                                value={
                                    formikProps.values.incomeAmountNetProfit?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('incomeAmountNetProfit')}
                            />
                        </Controller>
                        <Controller
                            label='Do you earn any other income?'
                            additionalInfo='Such as bonus, dividends, pension, second job.'
                        >
                            <IncomingOtherIncomesSubView
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                title={'Other incomes'}
                                addButtonTitle={'Add another income source'}
                                getError={getError}
                            />
                        </Controller>
                        <Controller
                            label="At what age do you think you'll retire?"
                            additionalInfo='For most people this will be 66'
                        >
                            <TextField
                                name='estimatedRetirementAge'
                                mask='number'
                                maxLength={2}
                                value={
                                    formikProps.values.estimatedRetirementAge?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('estimatedRetirementAge')}
                            />
                        </Controller>
                        <Controller label='Do you expect your income to decrease in the near future?'>
                            <ExpectedDecrease
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <ErrorContainer formikProps={formikProps} />
                    </>
                );
            }}
        </Formik>
    );
};

export default IncomingSelfEmployedViewSoleTrader;
