import React, { useEffect } from 'react';

const TrustpilotReviews = () => {
    const ref = React.useRef(null);

    useEffect(() => {
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current);
        }
    }, []);

    return (
        <div className='pb-8'>
            <div
                ref={ref}
                data-locale='en-GB'
                data-template-id='54ad5defc6454f065c28af8b'
                data-businessunit-id='60fae03f0687800001e2a1da'
                data-style-height='240px'
                data-style-width='100%'
                data-theme='light'
                data-stars='4,5'
                data-review-languages='en'
            >
                <a
                    href='https://uk.trustpilot.com/review/selinaadvance.com'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Trustpilot
                </a>
            </div>
        </div>
    );
};

export default TrustpilotReviews;
