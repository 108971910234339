import { useState } from 'react';

import { Calendly } from '@src/ui/components/Calendly';
import Help from '@src/ui/components/Help/Help';

const BookCallView = () => {
    const [showCalendly, setShowCalendly] = useState<boolean>(true);
    return (
        <div>
            {showCalendly && (
                <Calendly onClose={() => setShowCalendly(false)} />
            )}
            <Help className='my-0 lg:hidden' />
        </div>
    );
};
export default BookCallView;
