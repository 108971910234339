import React, { FC, FormEventHandler } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';
import { GoChevronRight } from 'react-icons/go';
import { HiArrowLeft } from 'react-icons/hi';

import { Size } from '@src/ui/components/shared';

export interface ButtonState {
    default: string;
    hover?: string;
    active?: string;
    selected?: string;
}

interface Justify {
    start: string;
    end: string;
    center: string;
}

const className = {
    // base
    btn: 'flex content-center items-center align-middle border rounded-full',
    justify: {
        start: '',
        end: 'justify-end',
        center: 'justify-center',
    },
    focus: 'focus:outline-none focus:ring-4  focus:ring-gray-200',
    hover: '',
    active: 'active:ring-0',
    disabled: 'cursor-not-allowed border-transparent',

    // size
    small: 'text-md px-5 ',
    medium: 'text-lg px-6 ',
    large: 'text-lg px-7',

    button_small: 'h-11 text-md px-5 ',
    button_medium: 'h-14 text-lg px-6 ',
    button_large: 'h-16 text-lg px-7',

    adornment: 'border-t-0 border-b-0 border-r-0',
    adornment_small: 'text-md px-5',
    adornment_medium: 'text-lg px-6',
    adornment_large: 'text-lg px-7',

    // outlined ----------------------------------
    color_primary: {
        default: 'border-primary bg-primary text-primary_contrast',
        hover: 'hover:bg-primary_hover',
        active: 'active:bg-primary_contrast active:text-primary',
    },

    color_secondary: {
        default: 'border-secondary bg-secondary text-secondary_contrast',
        hover: 'hover:bg-secondary_hover',
        active: 'active:bg-primary_contrast active:text-secondary',
    },

    color_ghost: {
        default: 'border-ghost_contrast text-ghost_contrast',
        hover: 'hover:bg-ghost_contrast hover:text-ghost',
        active: 'active:text-ghost_contrast active:bg-ghost',
        selected: 'bg-base text-ghost_selected',
    },

    color_link: {
        default: 'border-none text-primary',
        hover: 'hover:text-primary_hover',
    },

    state_disabled: {
        default: 'bg-disabled text-disabled_contrast',
    },

    state_loading: {
        default: 'border-loading bg-loading text-loading_contrast',
    },
};

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonColor = 'primary' | 'secondary' | 'ghost' | 'link';
export type Justification = 'start' | 'center' | 'end';
export type ArrowType = 'front' | 'back';
export interface ButtonProps
    extends Omit<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        'size' | 'type' | 'className' | 'onBlur' | 'onFocus'
    > {
    color?: ButtonColor;
    size?: Size;
    type?: ButtonType;
    className?: string;
    justify?: Justification;
    noHoverChange?: boolean;
    arrow?: ArrowType;
    loading?: boolean;
    isAdornment?: boolean;
    href?: string;
    selected?: boolean;
    onBlur?: FormEventHandler<HTMLButtonElement>;
    onFocus?: FormEventHandler<HTMLButtonElement>;
}

const Button: FC<ButtonProps> = (props) => {
    // get the default values
    const justify: Justification = props.justify || 'center';
    const size: Size = props.size || 'medium';
    const color: ButtonColor = props.color || 'primary';
    const type: ButtonType = props.type || 'button';
    const arrow: ArrowType | undefined = props.arrow || undefined;
    const buttonColorState: ButtonState = className[`color_${color}`];

    const classNames = clsx([
        props.className,
        (props.disabled || props.loading) && className.disabled,
        className.btn,
        className.focus,
        className.hover,
        className.active,
        (className.justify as Justify)[justify],
        props.isAdornment === true
            ? [className.adornment, className[`adornment_${size}`]]
            : className[`button_${size}`],
        props.disabled !== true &&
            props.loading !== true && [
                buttonColorState?.default,
                buttonColorState?.active,
                className[size],
                !props.noHoverChange && buttonColorState?.hover,
            ],
        props.loading && (className['state_loading'] as ButtonState).default,
        props.disabled &&
            !props.loading &&
            (className['state_disabled'] as ButtonState).default,
        props.color === 'ghost' && props.selected && buttonColorState?.selected,
    ]);

    return (
        <button
            type={type}
            className={classNames}
            disabled={Boolean(props.disabled || props.loading)}
            {...omit(props, [
                'noHoverChange',
                'className',
                'size',
                'type',
                'disabled',
                'loading',
                'isAdornment',
            ])}
        >
            {arrow === 'back' && <HiArrowLeft className='mr-2 text-2xl' />}
            {props.children}
            {arrow === 'front' && <GoChevronRight className='ml-2 mt-0.5' />}
        </button>
    );
};

export default Button;
