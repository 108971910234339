import * as Yup from 'yup';

import { IncomeType } from '@App/interfaces/IncomeType';
import { normalizeNumber } from '@src/shared/helpers';

import { CustomFormPropsV2, currency } from '../shared';

import {
    commonDecrease,
    otherIncome,
    otherIncomeForRetired,
} from './incomings.shared';

export const retiredOptions = [
    { label: IncomeType.ARMED_FORCES_PENSION, value: '1', checked: false },
    { label: IncomeType.PRIVATE_PENSION, value: '2', checked: false },
    { label: IncomeType.STATE_PENSION, value: '3', checked: false },
];

export const retired: CustomFormPropsV2 = {
    futurePensionType: {
        label: 'What type of pension do you have?',
        items: retiredOptions,
        default: retiredOptions,
        validation: Yup.array()
            .required('This is a required field')
            .test('selected', 'This is a required field', (pensions: any) => {
                if (!pensions) {
                    return false;
                }
                const selected = pensions.filter(
                    (pension: any) => pension.checked,
                );
                return selected.length > 0;
            }),
        transform: {
            target: 'futurePensionType',
            fromRequest: (_value: any, dependencies: any) => {
                // get selected pension
                const selectedPension = (
                    dependencies?.income?.income || []
                ).filter((item: any) => {
                    return retiredOptions.map((retiredOption) => {
                        return retiredOption.label === item.type
                            ? retiredOption
                            : null;
                    });
                });

                // set checked to true if it was selected
                return retiredOptions.map((option) => {
                    if (
                        selectedPension.find(
                            (el: any) => el.type === option.label,
                        )
                    ) {
                        option.checked = true;
                        return option;
                    } else {
                        return option;
                    }
                });
            },
            toRequest: null,
        },
    },
    futurePensionIncome: {
        label: 'How much do you get from this annually?',
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            toRequest: (value: any, dependencies: any) => {
                const futurePensionTypeValue =
                    dependencies.futurePensionType.filter(
                        (item: any) => item.checked === true,
                    );

                return futurePensionTypeValue?.map((x: any) => ({
                    type: x.label,
                    amount: Math.floor(
                        normalizeNumber(value) / futurePensionTypeValue.length,
                    ),
                }));
            },
            fromRequest: (_value: any, dependencies: any) => {
                const types = retiredOptions.map((a) => a.label);
                const pensions = (dependencies?.income?.income || []).filter(
                    (item: any) => {
                        return types.includes(item.type);
                    },
                );
                return pensions[0].amount * pensions.length;
            },
        },
    },
    ...otherIncome(otherIncomeForRetired),
    ...commonDecrease,
};
