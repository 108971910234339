import { forwardRef, useContext, useMemo, useState } from 'react';

import { Button } from '@selina-finance/ui';
import clsx from 'clsx';
import cookie from 'cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '@src/app/contexts/Auth';
import { Avatar } from '@src/ui/components/Avatar';
import { Container } from '@src/ui/components/Container';
import { Dropdown, DropdownItem } from '@src/ui/components/Dropdown';
import { IconBox, LogoWithText } from '@src/ui/components/Icons';
import ModalLayout from '@src/ui/layouts/ModalLayout';
import CreatePasswordView from '@src/ui/views/auth/CreatePasswordView';

import BackButton from '../BackButton/BackButton';

const className = {
    wrapper: 'flex px-4 py-5 items-center',
    wrapper_border: 'border-b border-disabled_contrast',
    container: 'flex justify-between',
};

const headingText: Record<string, string> = {
    create_account:
        'Your quote is ready. Create an account to access it securely.',
    save_for_later: 'Save your progress',
};
const buttonText: Record<string, string> = {
    create_account: 'Get your quote',
    save_for_later: 'Create account',
};

export interface HeaderProps {
    className?: string;
}
const Header = forwardRef((props: HeaderProps, ref: any) => {
    const wrapperClassName = clsx(className.wrapper);
    const { user, loggedIn, register, logIn } = useContext(AuthContext);
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const flow =
        (location.state as { intent: string } | undefined)?.intent || 'default';
    const search = location.search;
    const cookies = cookie.parse(document.cookie);
    const appInfo = cookies.application ? JSON.parse(cookies.application) : {};

    const userEmail = user?.email || '';
    const saveForLater = async () => {
        ref.current.saveMethod();
    };
    const routesToHideButton = [
        'login',
        'create-account',
        'create-password',
        'reset-password',
        'verify-email',
    ];

    const shouldShowButton = !routesToHideButton.some((route) =>
        location.pathname.includes(route),
    );

    const shouldShowBackButton = useMemo(() => {
        const routesToShowBackButton = ['offer/finalise', 'offer/book-call'];
        return routesToShowBackButton.some((route) =>
            location.pathname.includes(route),
        );
    }, [location.pathname]);

    const email = (
        new URLSearchParams(search).get('email') ||
        appInfo.emailAddress ||
        ''
    ).replace(/ /g, '+');

    return (
        <Container
            variant={'default'}
            className={clsx([props.className, wrapperClassName])}
        >
            <Container variant={'wide'} className={className.container}>
                <div className='block lg:hidden flex'>
                    {shouldShowBackButton && <BackButton />}
                </div>
                <IconBox className='h-10'>
                    <LogoWithText />
                </IconBox>
                <div className={'flex gap-5 items-center'}>
                    {loggedIn && (
                        <>
                            <div className={'hidden sm:block'}>{userEmail}</div>
                            <Dropdown
                                alignment='right'
                                avatar={
                                    <Avatar size={'small'} name={userEmail} />
                                }
                            >
                                <DropdownItem
                                    onClick={() => navigate('/logout')}
                                >
                                    Log out
                                </DropdownItem>
                            </Dropdown>
                        </>
                    )}
                    {shouldShowButton && !loggedIn && (
                        <Button
                            color='outline-dark'
                            type='submit'
                            onClick={async () => {
                                await saveForLater();
                                setOpenResetPasswordModal(true);
                            }}
                        >
                            Save for later
                        </Button>
                    )}
                </div>
                <ModalLayout
                    contentClassName='max-w-xl mx-auto'
                    open={openResetPasswordModal}
                    onClose={() => setOpenResetPasswordModal(false)}
                    title={
                        flow !== 'default'
                            ? headingText[flow]
                            : 'Save your progress'
                    }
                >
                    <CreatePasswordView
                        register={register}
                        email={email}
                        logIn={logIn}
                        buttonText={buttonText[flow]}
                        flow={flow}
                        onClose={() => setOpenResetPasswordModal(false)}
                    />
                </ModalLayout>
            </Container>
        </Container>
    );
});

export default Header;
