import { OldAddressSearch } from '@selina-finance/ui';

import AddressView, { AddressValue } from '@Components/AddressView/AddressView';
import { getFormHelpers } from '@Shared/helpers/input';
import { EditButton } from '@Views/eligibility/addressRepeaterView/views/AddressSection/EditButton';

interface IAddressViewProps {
    index: number;
    notTouched: boolean;
    isEdit: boolean;
    addressFieldName: string;
    formItems: any;
    formikProps: any;
    setIsInputSectionEdit(value: boolean): void;
    setIsAddressSectionEdit(value: boolean): void;
}

export const AddressSection = ({
    index,
    notTouched,
    isEdit,
    addressFieldName,
    formItems,
    formikProps,
    setIsInputSectionEdit,
    setIsAddressSectionEdit,
}: IAddressViewProps) => {
    const onEditButtonClick = () => {
        setIsInputSectionEdit(true);
        setIsAddressSectionEdit(true);
    };

    const addressItemName = `${addressFieldName}[${index}]`;

    const { getProps, getError } = getFormHelpers(formItems, formikProps);

    const value = getProps(
        `${addressItemName}.address`,
        `${addressFieldName}.address`,
    ).value as AddressValue;

    return (
        <div>
            {isEdit ? (
                <>
                    <OldAddressSearch
                        name={`${addressItemName}.address`}
                        value={
                            formikProps.values.addresses[index].address ?? ''
                        }
                        onChange={(e) => {
                            const values = e.currentTarget.value as any;

                            formikProps.setFieldValue(
                                `${addressItemName}.address`,
                                {
                                    line1: values?.line1,
                                    line2: values?.line2,
                                    city: values?.city,
                                    postcode: values?.postcode,
                                    country: values?.country,
                                    county: values?.county,
                                },
                            );
                        }}
                        onBlur={() => {
                            formikProps.setTouched({
                                ...formikProps.touched,
                                address: true,
                            });
                            getError(`${addressItemName}.address`);
                        }}
                        error={getError(
                            `${addressItemName}.address`,
                            notTouched,
                        )}
                    />
                </>
            ) : (
                <AddressView address={value} variant='extended' />
            )}
            {!isEdit && <EditButton onEditButtonClick={onEditButtonClick} />}
        </div>
    );
};
