import { useContext, useEffect, useState } from 'react';

import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';
import { useKameleoonFeature } from '@src/app/hooks/useKameleoon';
import { ApplicationStage } from '@src/app/interfaces/application-stage.enum';
import { ExperimentsConfig } from '@src/app/utils/experiments';
import { KAMELEOON_FEATURE, sendFeatureToHeap } from '@src/app/utils/kameleoon';
import { containsAnySubStr } from '@src/shared/helpers/string';

import PercentageCompleteIcon from '../Icons/PercentageCompleteIcon';
import PercentageIncompleteIcon from '../Icons/PercentageIncompleteIcon';
import PhoneCallIcon from '../Icons/PhoneCallIcon';
import ProgressChevronIcon from '../Icons/ProgressChevron';

const className = {
    base: 'flex flex-col item-center space-y-4 sm:w-80 lg:w-96 hidden sm:block',
    wrapper: 'bg-white p-7 rounded-lg',
    progress_wrapper: 'mb-4',
    progress_paragraph: 'text-base font-bold',
    progress_inner_wrapper: 'h-2 bg-neutral-40 mt-2 text-center rounded-full',
    progress_inner_bar: 'h-full bg-primary opacity-70 rounded-full',
    primary_background: 'text-primary',
    list_wrapper:
        'flex justify-between items-center space-x-2 mb-1 rounded p-2 cursor-pointer',
    unordered_list_wrapper: 'mt-6',
    inner_list_wrapper: 'flex-1 ml-2 opacity-50',
    paragraph: 'text-xl font-bold',
    paragraph_small: 'text-base -mt-1 text-steel-80',
    paragraph_quote: 'flex-1 ml-2 text-xl font-bold opacity-50',
    phone_wrapper: 'mt-4 bg-white px-4 py-3 rounded-lg',
    inner_phone_paragraph: 'text-base font-bold',
    schedule_paragragh: 'text-base ml-8',
    canEdit: 'border-1  border-neutral-100',

    // small screen base
    sm_base:
        'w-full block sm:hidden absolute left-0 p-0 md:relative md:left-auto md:w-auto md:p-4',
    sm_wrapper: 'w-full rounded-b-2xl bg-white shadow-md py-2',
    sm_progress_wrapper: 'w-1/2',
    sm_disclosure_btn:
        'flex w-full justify-between rounded-b-lg bg-white px-4 py-2 text-left hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-white/75',
    sm_disclosure_panel: 'px-4 pb-2 pt-4 text-sm h-screen',
    sm_phone_wrapper:
        'bg-white p-4 rounded-lg mt-16 mb-8 border-1 border-steel-100',
    sm_inner_wrapper: 'flex items-center space-x-2',

    // actions
    hover: 'hover:bg-neutral-40',
    activeBackground: 'bg-neutral-40 border-neutral-100 border-1 text-black',
    inner_active_list_wrapper: 'flex-1 ml-2',
    text_hover: 'hover:opacity-100',
};

export interface ProgressMenuProps {
    className?: string;
}

const ProgressMenu = (props: ProgressMenuProps) => {
    const { applicationId, applicationEmail } = useContext(ApplicationContext);
    const getVariationKey = useKameleoonFeature(KAMELEOON_FEATURE.loan_details);
    const [loanDetailsVariant, setLoanDetailsVariant] = useState<string>('');

    useEffect(() => {
        const fetchVariant = async () => {
            if (applicationEmail) {
                const variationKey = await getVariationKey();
                setLoanDetailsVariant(variationKey);
            }
        };

        fetchVariant();
    }, [getVariationKey, applicationEmail]);

    useEffect(() => {
        sendFeatureToHeap(
            loanDetailsVariant,
            KAMELEOON_FEATURE.loan_details,
            applicationId,
            applicationEmail,
        );
    }, [loanDetailsVariant, applicationId, applicationEmail]);

    const navigate = useNavigate();
    const { dashboardItemStatuses, applicationStage } =
        useContext(ApplicationContext);
    const [isRejected, setIsRejected] = useState<boolean>(false);

    useEffect(
        () =>
            setIsRejected(
                containsAnySubStr(applicationStage || '', [
                    ApplicationStage.DECISIONING_DECLINE,
                    ApplicationStage.CLOSED,
                ]),
            ),
        [applicationStage],
    );

    const url = new URL(window.location.href);

    const baseClassName = clsx([props.className, className.base]);

    const listClassName = clsx([className.list_wrapper, className.hover]);

    const listClassNameWithBackground = clsx([
        className.list_wrapper,
        className.activeBackground,
    ]);

    const innerListClassName = clsx([
        className.inner_list_wrapper,
        className.text_hover,
    ]);

    const progressMobileParagraph = clsx([
        className.progress_paragraph,
        className.primary_background,
    ]);

    const canEdit = clsx([className.canEdit, listClassName]);
    const canHover = clsx(className.list_wrapper);

    const getCompletedStatus = (stage: string) =>
        dashboardItemStatuses.find((item: any) => item.name === stage)
            ?.completed;

    let expectedTotalSteps = 6;
    let adjustedTotalSteps = dashboardItemStatuses.length - 1;

    const completedSteps =
        loanDetailsVariant === ExperimentsConfig.Variants.A
            ? dashboardItemStatuses.filter(
                  (item: { completed: any; name: string }) =>
                      item.completed && item.name !== 'loan-details',
              )
            : dashboardItemStatuses.filter(
                  (item: { completed: any }) => item.completed,
              );

    if (loanDetailsVariant === ExperimentsConfig.Variants.A) {
        adjustedTotalSteps = dashboardItemStatuses.length - 2;
        expectedTotalSteps = 5;
    }

    const completeStepsLength =
        completedSteps.length === expectedTotalSteps
            ? adjustedTotalSteps
            : completedSteps.length;

    const progress = Math.floor(
        (completeStepsLength / adjustedTotalSteps) * 100,
    );

    const generateMenuItem = (
        name: string,
        title: string,
        time: string,
        index: number,
        dashboardItemStatuses: any,
    ) => {
        return {
            name: name,
            title: title,
            time: time,
            disabled: dashboardItemStatuses[index].disabled,
            notCompleteNotdisabled:
                !dashboardItemStatuses[index].disabled &&
                !dashboardItemStatuses[index].completed,
            hoverState: !dashboardItemStatuses[index].completed
                ? className.list_wrapper
                : listClassName,
            innerListHoverState: !dashboardItemStatuses[index].completed
                ? className.inner_list_wrapper
                : innerListClassName,
        };
    };

    const menuItems = [
        generateMenuItem(
            'loan-details',
            'Your loan',
            '1 minute',
            1,
            dashboardItemStatuses,
        ),
        generateMenuItem(
            'applicants',
            'About You',
            '1 minute',
            1,
            dashboardItemStatuses,
        ),
        generateMenuItem(
            'property-details',
            'Your property',
            '2 minutes',
            2,
            dashboardItemStatuses,
        ),
        generateMenuItem(
            'income',
            'Your income',
            '1 minute',
            3,
            dashboardItemStatuses,
        ),
        generateMenuItem(
            'outgoings',
            'Your expenses',
            '1 minute',
            4,
            dashboardItemStatuses,
        ),
    ];

    if (loanDetailsVariant === ExperimentsConfig.Variants.A) {
        delete menuItems[0];
    }

    const progressMenuItems = () => {
        return (
            <>
                {menuItems.map((item) => (
                    <li
                        key={item.name}
                        className={
                            url.href.includes(item.name)
                                ? listClassNameWithBackground
                                : item.notCompleteNotdisabled
                                ? canEdit
                                : item.hoverState
                        }
                        onClick={
                            !item.disabled
                                ? () => navigate(`/eligibility/${item.name}`)
                                : undefined
                        }
                    >
                        <div
                            className={
                                url.href.includes(item.name) ||
                                item.notCompleteNotdisabled
                                    ? className.inner_active_list_wrapper
                                    : item.innerListHoverState
                            }
                        >
                            <p className={className.paragraph}>{item.title}</p>
                            <p className={className.paragraph_small}>
                                {item.time}
                            </p>
                        </div>
                        {getCompletedStatus(item.name) ? (
                            <PercentageCompleteIcon />
                        ) : (
                            <PercentageIncompleteIcon />
                        )}
                    </li>
                ))}

                <li
                    className={
                        !dashboardItemStatuses[5].disabled &&
                        !dashboardItemStatuses[5].completed
                            ? canEdit
                            : listClassName
                            ? canHover
                            : listClassName
                    }
                    onClick={
                        !dashboardItemStatuses[5].disabled
                            ? dashboardItemStatuses[5].completed
                                ? () =>
                                      navigate(
                                          `/eligibility/${
                                              isRejected
                                                  ? 'reject'
                                                  : 'good-news'
                                          }`,
                                      )
                                : () => navigate(`/eligibility/create-account`)
                            : undefined
                    }
                >
                    <div
                        className={
                            !dashboardItemStatuses[5].completed
                                ? className.inner_list_wrapper
                                : innerListClassName
                        }
                    >
                        <p className={className.paragraph}>Your quote</p>
                    </div>
                </li>
            </>
        );
    };

    const contactMenu = () => {
        return (
            <>
                <div className={className.sm_inner_wrapper}>
                    <PhoneCallIcon />
                    <p className={className.inner_phone_paragraph}>
                        Need help?
                    </p>
                </div>
                <p className={className.schedule_paragragh}>
                    020 4525 8044 (Mon - Fri, 9am - 5pm)
                </p>
            </>
        );
    };

    return (
        <>
            <div className={baseClassName}>
                <div className={className.wrapper}>
                    <div className={className.progress_wrapper}>
                        <p className={className.progress_paragraph}>
                            {progress}% complete
                        </p>
                        <div className={className.progress_inner_wrapper}>
                            <div
                                className={className.progress_inner_bar}
                                style={{ width: `${progress}%` }}
                            ></div>
                        </div>
                    </div>

                    <ul className={className.unordered_list_wrapper}>
                        {progressMenuItems()}
                    </ul>
                </div>

                <div className={className.phone_wrapper}>{contactMenu()}</div>
            </div>

            <div className={className.sm_base}>
                <div className={className.sm_wrapper}>
                    <Disclosure as='div'>
                        {({ open }) => (
                            <>
                                <Disclosure.Button
                                    className={className.sm_disclosure_btn}
                                >
                                    <p className={progressMobileParagraph}>
                                        {progress}% complete
                                    </p>
                                    <div
                                        className={
                                            className.sm_progress_wrapper
                                        }
                                    >
                                        <div
                                            className={
                                                className.progress_inner_wrapper
                                            }
                                        >
                                            <div
                                                className={
                                                    className.progress_inner_bar
                                                }
                                                style={{
                                                    width: `${progress}%`,
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <ProgressChevronIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-steel-80`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    className={className.sm_disclosure_panel}
                                >
                                    <ul>{progressMenuItems()}</ul>

                                    <div className={className.sm_phone_wrapper}>
                                        {contactMenu()}
                                    </div>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
            </div>
        </>
    );
};

export default ProgressMenu;
