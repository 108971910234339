export type FamilyEnum = 'Homeowner Loan' | 'HELOC';

export enum Product {
    HELOC = 'HELOC',
    TwoYearFixedWithEarlyCharges = 'TwoYearFixedWithEarlyCharges',
    FiveYearFixedWithEarlyCharges = 'FiveYearFixedWithEarlyCharges',
}

export interface Offer {
    active: boolean;
    affordabilityDeficit: number;
    aprc: number;
    arrangementFeeSelina: number;
    brokerFeesIncluded: number;
    brokerFeesUpfront: number;
    category: string;
    cltv: number;
    decision: string;
    ercData: Erc[];
    ercPeriodYears: number;
    family: FamilyEnum;
    hasFee: boolean;
    id: string;
    initialPayment: number;
    initialRate: number;
    initialTerm: number;
    isVariable: boolean;
    lti: number;
    ltvCap: number;
    maxErc: number | null;
    maximumBalanceEsis: number;
    offerBalance: number;
    procFee: number;
    product: string;
    productCode: string;
    productFee: number;
    productFeeAddedToLoan: boolean;
    productFeeCanAdd: boolean;
    reversionPayment: number;
    reversionTerm: number;
    ruleOutcomes: RuleOutcome[];
    svr: number;
    totalAmountRepaid: number;
    netLoanAmount: number;
    initialMargin: number;
    reversionMargin: number;
    term?: number;
}

interface RuleOutcome {
    code: string;
    description: string;
    message: string;
    name: string;
    outcome: string;
    version: string;
}

interface Erc {
    period: number;
    ercFee: number;
    ercBalance: number;
    ercAmount: number;
}

export interface RecommendedOffers {
    applicationId: string;
    selectedProductCode: string;
    offers: RecommendedOffer[];
}

export interface RecommendedOffer {
    id: string;
    product: string;
    productCode: string;
}
