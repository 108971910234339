const ChatsCircleIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
        >
            <path
                opacity='0.2'
                d='M8.63452 16.491C8.96494 17.4331 9.50048 18.29 10.2025 18.9998C10.9044 19.7096 11.7553 20.2547 12.6936 20.5955C13.6319 20.9364 14.6343 21.0646 15.6282 20.9709C16.6221 20.8771 17.5827 20.5638 18.4408 20.0535L20.7751 20.7191C20.8713 20.7462 20.973 20.7471 21.0696 20.7219C21.1663 20.6966 21.2545 20.646 21.3251 20.5754C21.3958 20.5047 21.4463 20.4165 21.4716 20.3199C21.4969 20.2232 21.4959 20.1216 21.4689 20.0254L20.8033 17.691C21.3975 16.6935 21.724 15.5595 21.7512 14.3987C21.7783 13.2379 21.5052 12.0898 20.9582 11.0657C20.4112 10.0415 19.6089 9.17596 18.6292 8.55297C17.6494 7.92998 16.5253 7.57064 15.3658 7.50977C15.6229 8.22869 15.753 8.98687 15.7501 9.75039C15.7501 11.5406 15.039 13.2575 13.7731 14.5234C12.5072 15.7892 10.7904 16.5004 9.00015 16.5004L8.63452 16.491Z'
                fill='#3A4146'
            />
            <path
                d='M3.19681 13.1899C2.35621 11.7737 2.06157 10.0993 2.36823 8.48122C2.6749 6.86312 3.56176 5.41264 4.86226 4.4022C6.16277 3.39177 7.78743 2.89089 9.43112 2.99365C11.0748 3.0964 12.6245 3.79572 13.789 4.96025C14.9535 6.12479 15.6528 7.67442 15.7556 9.31812C15.8584 10.9618 15.3575 12.5865 14.347 13.887C13.3366 15.1875 11.8861 16.0743 10.268 16.381C8.64992 16.6877 6.97553 16.393 5.55931 15.5524L3.22494 16.2181C3.12875 16.2451 3.02711 16.246 2.93044 16.2208C2.83378 16.1955 2.74559 16.1449 2.67494 16.0743C2.6043 16.0037 2.55374 15.9155 2.52847 15.8188C2.50321 15.7221 2.50414 15.6205 2.53119 15.5243L3.19681 13.1899Z'
                stroke='#3A4146'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M8.63452 16.491C8.96494 17.4331 9.50048 18.29 10.2025 18.9998C10.9044 19.7096 11.7553 20.2547 12.6936 20.5955C13.6319 20.9364 14.6343 21.0646 15.6282 20.9709C16.6221 20.8771 17.5827 20.5638 18.4408 20.0535V20.0535L20.7751 20.7191C20.8713 20.7462 20.973 20.7471 21.0696 20.7219C21.1663 20.6966 21.2545 20.646 21.3251 20.5754C21.3958 20.5047 21.4463 20.4165 21.4716 20.3199C21.4969 20.2232 21.4959 20.1216 21.4689 20.0254L20.8033 17.691C21.3975 16.6935 21.724 15.5595 21.7512 14.3987C21.7783 13.2379 21.5052 12.0898 20.9582 11.0657C20.4112 10.0415 19.6089 9.17596 18.6292 8.55297C17.6494 7.92998 16.5253 7.57064 15.3658 7.50977'
                stroke='#3A4146'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ChatsCircleIcon;
