import * as Yup from 'yup';

import { CustomFormProps } from '../shared';

import { contractor } from './incomings.contractor';
import { employed } from './incomings.employed';
import { notInPaid } from './incomings.notInPaid';
import { retired } from './incomings.retired';
import {
    selfEmployed,
    selfEmployedLimitedCompany,
    selfEmployedPartnership,
    selfEmployedSoleTrader,
} from './incomings.selfEmployed';
import { employmentStatuses } from './incomings.shared';

export const employmentStatusOptions = [
    {
        label: employmentStatuses.employed,
        value: employmentStatuses.employed,
    },
    {
        label: employmentStatuses.selfEmployed,
        value: employmentStatuses.selfEmployed,
    },
    {
        label: employmentStatuses.contractor,
        value: employmentStatuses.contractor,
    },
    {
        label: employmentStatuses.retired,
        value: employmentStatuses.retired,
    },
    {
        label: employmentStatuses.notInPaid,
        value: employmentStatuses.notInPaid,
    },
];

export const formItems: CustomFormProps = {
    employmentStatus: {
        label: "What's your employment status?",
        items: employmentStatusOptions,
        default: employmentStatusOptions,
        validation: Yup.string().required('This is a required field'),
        transform: {
            target: 'employment.employmentStatus',
        },
    },
};

export {
    employed,
    notInPaid,
    retired,
    contractor,
    selfEmployed,
    selfEmployedLimitedCompany,
    selfEmployedSoleTrader,
    selfEmployedPartnership,
};
