import { FC } from 'react';

import { keys, pick } from 'lodash';

import { Info } from '@src/ui/components/Info';

const FormError: FC<{ touched?: {}; fieldErrors?: {}; otherErrors?: {} }> = (
    props,
) => {
    const touchedErrors = pick(
        props.fieldErrors || [],
        Object.keys(props.touched || {}),
    );
    const errors = { ...touchedErrors, ...props.otherErrors };
    const errorKeys = keys(errors);
    const hasError = errorKeys.length > 0;

    if (!hasError) {
        return null;
    }

    return (
        <Info type={'error'} className={'mb-6'}>
            {errorKeys.map((error: any, ind: number) => {
                return (
                    <ul
                        className='whitespace-pre-line'
                        key={`error_${ind}`}
                        dangerouslySetInnerHTML={{
                            __html: (errors as any)[error],
                        }}
                    />
                );
            })}
        </Info>
    );
};

export default FormError;
