const TickIcon = () => {
    return (
        <svg
            width='14'
            height='15'
            viewBox='0 0 14 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.5 3.5L5.1625 10.5L1.5 7'
                stroke='#66CC66'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default TickIcon;
