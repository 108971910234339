const CalenderIcon = ({ colour }: { colour: string }) => {
    return (
        <svg
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M13.75 2H12.25V1.5C12.25 1.36739 12.1973 1.24021 12.1036 1.14645C12.0098 1.05268 11.8826 1 11.75 1C11.6174 1 11.4902 1.05268 11.3964 1.14645C11.3027 1.24021 11.25 1.36739 11.25 1.5V2H6.25V1.5C6.25 1.36739 6.19732 1.24021 6.10355 1.14645C6.00979 1.05268 5.88261 1 5.75 1C5.61739 1 5.49022 1.05268 5.39645 1.14645C5.30268 1.24021 5.25 1.36739 5.25 1.5V2H3.75C3.48478 2 3.23043 2.10536 3.04289 2.29289C2.85536 2.48043 2.75 2.73478 2.75 3V13C2.75 13.2652 2.85536 13.5196 3.04289 13.7071C3.23043 13.8946 3.48478 14 3.75 14H13.75C14.0152 14 14.2696 13.8946 14.4571 13.7071C14.6446 13.5196 14.75 13.2652 14.75 13V3C14.75 2.73478 14.6446 2.48043 14.4571 2.29289C14.2696 2.10536 14.0152 2 13.75 2ZM13.75 5H3.75V3H5.25V3.5C5.25 3.63261 5.30268 3.75979 5.39645 3.85355C5.49022 3.94732 5.61739 4 5.75 4C5.88261 4 6.00979 3.94732 6.10355 3.85355C6.19732 3.75979 6.25 3.63261 6.25 3.5V3H11.25V3.5C11.25 3.63261 11.3027 3.75979 11.3964 3.85355C11.4902 3.94732 11.6174 4 11.75 4C11.8826 4 12.0098 3.94732 12.1036 3.85355C12.1973 3.75979 12.25 3.63261 12.25 3.5V3H13.75V5Z'
                fill={colour}
            />
        </svg>
    );
};

export default CalenderIcon;
