import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';

import { Button } from '@selina-finance/ui';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';
import { Offer, Product } from '@src/app/interfaces/offer';
import { toCurrency } from '@src/shared/helpers';
import Help from '@src/ui/components/Help/Help';
import { HttpError } from '@src/ui/components/HttpError';
import OfferCard from '@src/ui/components/OfferCard/OfferCard';

interface IExploreViewProps {
    offers: Offer[];
    selectedOffer: Offer | null;
    onSelect: Dispatch<SetStateAction<Offer | null>>;
}

export const getProductType = (productCode: string) => {
    if (productCode.startsWith('HE')) return Product.HELOC;
    if (productCode.startsWith('HO02') && productCode.endsWith('1'))
        return Product.TwoYearFixedWithEarlyCharges;
    if (productCode.startsWith('HO05') && productCode.endsWith('1'))
        return Product.FiveYearFixedWithEarlyCharges;
    return null;
};

const ExploreView = ({
    offers,
    selectedOffer,
    onSelect,
}: IExploreViewProps) => {
    const { saveSelectedOffer } = useContext(ApplicationContext);
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const [previousSelectedOffer, setPreviousSelectedOffer] =
        useState<Offer | null>(selectedOffer);

    // may not need this if offers are already sorted in BE
    const sortedOffers = offers.sort((a, b) => {
        const aType = getProductType(a.productCode);
        const bType = getProductType(b.productCode);

        const productOrder = [
            Product.HELOC,
            Product.TwoYearFixedWithEarlyCharges,
            Product.FiveYearFixedWithEarlyCharges,
        ];

        return (
            productOrder.indexOf(aType as Product) -
            productOrder.indexOf(bType as Product)
        );
    });

    const [showMoreInfo, setShowMoreInfo] = useState<boolean[]>([]);

    useEffect(() => {
        if (sortedOffers.length > 0) {
            setShowMoreInfo(sortedOffers.map(() => false));
        }
    }, [sortedOffers]);

    const saveOffer = async () => {
        if (selectedOffer !== previousSelectedOffer) {
            const response = await saveSelectedOffer();
            if (response.success) {
                setPreviousSelectedOffer(selectedOffer);
                navigate('/eligibility/offer/finalise');
            }
            if (!response.success) {
                setError(response.error);
            }
        } else {
            navigate('/eligibility/offer/finalise');
        }
    };

    const toggleMoreInfo = (index: number) => {
        const isLargeScreen = window.innerWidth >= 1024;
        if (isLargeScreen) {
            const allExpanded = showMoreInfo.every((item) => item);
            setShowMoreInfo(sortedOffers.map(() => !allExpanded));
        } else {
            setShowMoreInfo((prevState) =>
                prevState.map((item, i) => (i === index ? !item : item)),
            );
        }
    };

    return (
        <div className='text-steel-100 flex flex-col items-center mx-auto max-w-400 w-full lg:max-w-none'>
            <h1 className='text-28 font-bold leading-100 pt-6 sm:pt-0'>
                {`Choose your offer for £${toCurrency(
                    sortedOffers[0]?.netLoanAmount,
                )} over ${sortedOffers[0]?.term} years`}
            </h1>
            <h2 className='text-xl leading-120% pt-2 pb-7'>
                Based on what you told us, here are some options - subject to
                underwriting
            </h2>
            <div className='w-full flex flex-col lg:flex-row justify-center'>
                {sortedOffers.map((offer, index) => (
                    <OfferCard
                        key={index}
                        offer={offer}
                        selectable
                        onSelect={() => onSelect(offer)}
                        showMoreInfo={showMoreInfo[index]}
                        toggle={() => toggleMoreInfo(index)}
                        selected={offer.id === selectedOffer?.id}
                    />
                ))}
            </div>
            <div className='flex flex-col text-center items-center w-full'>
                <p className='text-md leading-120% mb-10 lg:mb-6 lg:mt-6'>
                    Your home may be repossessed if you do not keep up
                    repayments on a mortgage or any other debt secured on it.
                </p>
                <Button
                    color='secondary'
                    className='w-full mb-2 lg:max-w-400'
                    onClick={saveOffer}
                    disabled={!selectedOffer}
                >
                    Select this offer
                </Button>
                <div className='text-md'>
                    <p className='font-bold '>
                        This won&apos;t impact your credit score
                    </p>
                    <p className='text-md leading-120% mb-10'>
                        You&apos;ll be able to review before submitting an
                        application
                    </p>
                </div>
                <Help className='my-0 lg:hidden' />
                {error && <HttpError error={error} />}
            </div>
        </div>
    );
};

export default ExploreView;
