import { FC } from 'react';

import { toCurrency } from '@Helpers';
import clsx from 'clsx';

import { Offer } from '@App/interfaces/offer';
import { toPercentage } from '@Shared/helpers/numbers';

const TotalCostDisclaimer = (offer: Offer): string =>
    `The total cost over the full term is £${toCurrency(
        offer.totalAmountRepaid,
    )}, including interest of £${toCurrency(
        offer.totalAmountRepaid -
            offer.netLoanAmount -
            offer.productFee -
            offer.arrangementFeeSelina,
    )}, an arrangement fee of £${toCurrency(
        offer.arrangementFeeSelina,
    )}, a product fee of £${toCurrency(
        offer.productFee,
    )} added to the balance. APRC: ${toPercentage(offer.aprc)}.`;

const VariableDisclaimer = (offer: Offer): string =>
    `A loan of £${toCurrency(offer.netLoanAmount)} over ${
        offer.term || 0
    } years results in ${
        (offer.term || 0) * 12
    } monthly payments of ${toPercentage(
        offer.initialMargin || 0,
    )} above the Bank of England Base Rate. ${TotalCostDisclaimer(offer)}`;

const NonVariableDisclaimer = (offer: Offer): string => {
    const isRequestedTermEqualsToInitialTerm = offer.term === offer.initialTerm;

    return `A loan of £${toCurrency(offer.netLoanAmount)} over ${
        offer.term || 0
    } years results in ${
        (offer.initialTerm || 0) * 12
    } monthly payments of £${toCurrency(
        offer.initialPayment || 0,
    )} at a fixed annual rate of ${toPercentage(offer.initialRate)}${
        !isRequestedTermEqualsToInitialTerm
            ? ` and ${
                  (offer.reversionTerm || 0) * 12
              } monthly payments of £${toCurrency(
                  offer.reversionPayment,
              )} at a reversion rate of ${toPercentage(
                  offer.reversionMargin || 0,
              )} above the Bank of England Base Rate.`
            : '.'
    } ${TotalCostDisclaimer(offer)}`;
};

const Legal: FC<{
    offer: Offer;
    className?: string;
}> = ({ offer, className }) => {
    const isVariable = offer.isVariable;
    return (
        <p className={clsx('paragraph-small', className)}>
            {isVariable && VariableDisclaimer(offer)}
            {!isVariable && NonVariableDisclaimer(offer)}
        </p>
    );
};

export default Legal;
