import * as Yup from 'yup';

import {
    CustomFormProps,
    passwordErrorMessage,
    passwordRegex,
} from '@FormModels/shared';

export const formItems: CustomFormProps = {
    email: {
        label: '',
        validation: Yup.string().required(),
    },
    password: {
        label: 'Password',
        validation: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(passwordRegex, passwordErrorMessage),
    },
};
