type RadioUnselectedProps = {
    onClick?: () => void;
};

const RadioUnselected = ({ onClick }: RadioUnselectedProps) => {
    return (
        <button onClick={onClick}>
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <circle
                    cx='12'
                    cy='12'
                    r='11.25'
                    fill='white'
                    stroke='#3A4146'
                    strokeWidth='1.5'
                />
            </svg>
        </button>
    );
};

export default RadioUnselected;
