import { forwardRef, useEffect, useRef, useState } from 'react';

import { H2 } from '@selina-finance/ui';

import BackButton from '../../components/BackButton/BackButton';
import ProgressMenu from '../../components/Progress/ProgressMenu';

import ApplicantView from './ApplicantView';

interface ApplicantsViewProps {
    applicantsData?: any;
    activeApplicant?: number | null;
    saveApplicants?: (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => Promise<any>;
}

const ApplicantsView = forwardRef((props: ApplicantsViewProps, ref) => {
    const [activeApplicant, setActiveApplicant] = useState(
        props.activeApplicant || 0,
    );

    const [applicantCount, setApplicantCount] = useState(1);

    const topRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setApplicantCount(
            props.applicantsData?.[0].hasSecondApplicant === 'true' ? 2 : 1,
        );
    }, [props.applicantsData]);

    useEffect(() => {
        if (props.activeApplicant) {
            setActiveApplicant(props.activeApplicant);
            topRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [props.activeApplicant]);

    const firstViewRef = useRef(null);
    const secondViewRef = useRef(null);

    const isFirst = activeApplicant === 0;
    const isSecond = activeApplicant === 1;

    return (
        <div className='flex flex-row justify-between' ref={topRef}>
            <div className='w-full sm:w-1/2 lg:w-2/5 mt-20 sm:mt-0'>
                <BackButton />
                {isFirst && <H2>About you</H2>}
                {isSecond && <H2>Applicant 2</H2>}
                <div className='relative mb-15'>
                    {isFirst && (
                        <ApplicantView
                            formRef={(ref: any) => {
                                firstViewRef.current = ref;
                            }}
                            saveRef={ref}
                            isPrimaryApplicant={true}
                            applicantData={props.applicantsData?.[0] || {}}
                            saveApplicants={props.saveApplicants}
                            applicantCount={applicantCount}
                            setApplicantCount={setApplicantCount}
                            setActiveApplicant={setActiveApplicant}
                        />
                    )}
                    {applicantCount === 2 && isSecond && (
                        <>
                            <ApplicantView
                                formRef={(ref: any) => {
                                    secondViewRef.current = ref;
                                }}
                                saveRef={ref}
                                isPrimaryApplicant={false}
                                applicantData={props.applicantsData?.[1] || {}}
                                saveApplicants={props.saveApplicants}
                                mobilePhoneNumber={
                                    props.applicantsData?.[0].mobilePhoneNumber
                                }
                                applicantCount={applicantCount}
                                setActiveApplicant={setActiveApplicant}
                            />
                        </>
                    )}
                </div>
            </div>
            <ProgressMenu />
        </div>
    );
});

export default ApplicantsView;
