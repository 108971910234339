import React, { cloneElement } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Avatar } from '@src/ui/components/Avatar';
import { Container } from '@src/ui/components/Container';
import { Dropdown, DropdownItem } from '@src/ui/components/Dropdown';
import Footer from '@src/ui/components/Footer/Footer';
import { NavigationHeader } from '@src/ui/components/Header';
import { IconBox, LogoWithText } from '@src/ui/components/Icons';
import { LoaderProgress } from '@src/ui/components/LoaderProgress';
import { Trustbox } from '@src/ui/components/Trustbox';

export interface GoodNewsLayoutProps {
    userEmail: string;
    loading?: boolean;
    children: any;
}

const GoodNewsLayout = (props: GoodNewsLayoutProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation('goodNews', { keyPrefix: 'header' });

    const ref = React.useRef(null);

    React.useEffect(() => {
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current);
        }
    }, [ref]);

    return (
        <div className={clsx('grid grid-cols-1 text-body relative')}>
            <NavigationHeader
                leftComponent={
                    <IconBox className='h-10'>
                        <LogoWithText />
                    </IconBox>
                }
                rightComponent={
                    <div className={'flex gap-5 items-center'}>
                        <Dropdown
                            alignment='right'
                            avatar={
                                <Avatar
                                    size={'small'}
                                    name={props.userEmail || ''}
                                />
                            }
                        >
                            <DropdownItem onClick={() => navigate('/logout')}>
                                Log out
                            </DropdownItem>
                        </Dropdown>
                    </div>
                }
                noBorder={true}
                className={'items-center'}
            />
            <Container className='bg-neutral-40 flex justify-center lg:gap-10 mx-auto p-2 text-md text-steel-60 w-full xl:gap-20'>
                <p className='hidden md:block'>
                    {t('description.line1') as String}
                </p>
                <Trustbox type='microStar' />
                <p className='hidden md:block'>
                    {t('description.line2') as String}
                </p>
            </Container>

            <div className='good-news-background z-20'></div>
            <div className='good-news-background-2 z-10'></div>

            <Container variant={'thin'} className='relative z-50 mb-24'>
                <div className='mx-6'>
                    {cloneElement(props.children, {
                        loading: props.loading,
                    })}
                </div>
                <LoaderProgress loading={props.loading} fullScreen />
            </Container>
            <Footer className='z-40' />
        </div>
    );
};

export default GoodNewsLayout;
