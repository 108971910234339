import * as Yup from 'yup';

import { IncomeType } from '@App/interfaces/IncomeType';

import { currency, yesNo } from '../shared';

export const employmentStatuses = {
    employed: 'Employed',
    selfEmployed: 'Self-employed',
    contractor: 'Contractor',
    notInPaid: 'Not in paid employment',
    retired: 'Retired',
};

const otherIncomeTypes = [
    IncomeType.BONUS,
    IncomeType.OVERTIME,
    IncomeType.COMMISSION,
    IncomeType.DIVIDENDS,
    IncomeType.NET_PROFIT,
    IncomeType.CAR_ALLOWANCE,
    IncomeType.CITY_ALLOWANCE,
    IncomeType.POLICE_AND_NHS_ALLOWANCE,
    IncomeType.SHIFT_ALLOWANCE,
    IncomeType.ARMED_FORCES_PENSION,
    IncomeType.PRIVATE_PENSION,
    IncomeType.STATE_PENSION,
    IncomeType.RENTAL_INCOME,
    IncomeType.MAINTENANCE_INCOME,
    IncomeType.DISABILITY_LIVING_ALLOWANCE,
    IncomeType.MOBILITY_ALLOWANCE,
    IncomeType.CARERS_ALLOWANCE,
    IncomeType.GUARDIANS_ALLOWANCE,
    IncomeType.BEREAVEMENT_ALLOWANCE,
    IncomeType.EMPLOYMENT_AND_SUPPORT_ALLOWANCE,
    IncomeType.INDUSTRY_INJURIES_DISABLEMENT_BENEFIT,
    IncomeType.CHILD_TAX_CREDIT_OR_UNIVERSAL_CREDIT_EQUIVALENT,
    IncomeType.WORKING_TAX_CREDIT_OR_UNIVERSAL_CREDIT_EQUIVALENT,
    IncomeType.GROSS_SALARY,
    IncomeType.DIRECTOR_GROSS_SALARY,
].sort();

export const otherIncomeTypesToLabelAndValue = otherIncomeTypes.map((type) => {
    return { label: type, value: type };
});
export const otherIncomeForEmployed = otherIncomeTypesToLabelAndValue.filter(
    (item: any) => item.value !== IncomeType.GROSS_SALARY,
);
export const otherIncomeForSelfEmployed =
    otherIncomeTypesToLabelAndValue.filter(
        (item: any) =>
            item.value !== IncomeType.DIRECTOR_GROSS_SALARY &&
            item.value !== IncomeType.NET_PROFIT,
    );
export const otherIncomeForSelfEmployedNoDivident = otherIncomeTypes.filter(
    (item: any) =>
        item !== IncomeType.DIRECTOR_GROSS_SALARY &&
        item !== IncomeType.NET_PROFIT &&
        item !== IncomeType.DIVIDENDS,
);
export const otherIncomeForRetired = otherIncomeTypesToLabelAndValue.filter(
    (item: any) =>
        item.label !== IncomeType.ARMED_FORCES_PENSION &&
        item.label !== IncomeType.PRIVATE_PENSION &&
        item.label !== IncomeType.STATE_PENSION,
);

// -------------------------------------------------------------------------
export const commonDecrease = {
    expectsFutureIncomeDecrease: {
        ...yesNo,
        validation: Yup.boolean().required('This is a required field'),
        transform: {
            target: 'income.expectsFutureIncomeDecrease',
        },
    },
    expectsFutureIncomeDecreaseReason: {
        label: 'Why do you think your income will decrease?',
        items: [
            { label: 'Redundancy', value: 'Redundancy' },
            {
                label: 'Maternity or paternity leave',
                value: 'Maternity or paternity leave',
            },
            {
                label: 'Change in employment',
                value: 'Change in employment',
            },
            {
                label: 'Moving to part-time work',
                value: 'Moving to part-time work',
            },
            { label: 'Economic conditions', value: 'Economic conditions' },
            { label: 'Other', value: 'Other' },
        ],
        validation: Yup.string()
            .nullable()
            .when('expectsFutureIncomeDecrease', {
                is: true,
                then: Yup.string()
                    .required('This is a required field')
                    .notOneOf(['Select'], 'This is a required field'),
            }),
        transform: {
            target: 'income.expectsFutureIncomeDecreaseReason',
        },
    },
};

export const commonRetirement = {
    estimatedRetirementAge: {
        // going to applicant
        label: 'At what age do you think you’ll retire?',
        additionalInfo: 'For most people this will be 66',
        mask: 'number',
        maskProps: {
            min: 18,
            max: 99,
        },
        validation: Yup.number()
            .required('Please enter a valid retirement age (18-99)')
            .typeError('Please enter a valid retirement age (18-99)')
            .max(99, 'Please enter a valid retirement age (18-99)')
            .min(18, 'Please enter a valid retirement age (18-99)'),
        transform: {
            target: 'estimatedRetirementAge',
        },
    },
};

type incomeType = {
    label: IncomeType;
    value: IncomeType;
};

export const otherIncome = (incomeTypes: incomeType[] = []) => ({
    otherIncomes: {
        nested: true,
        hasAny: {
            ...yesNo,
            validation: Yup.boolean().required('This is a required field'),
            transform: {
                target: 'otherIncomes',
                fromRequest: (_value: any, dependencies: any) => {
                    const types = incomeTypes.map((a) => a.label);
                    const incomes = (dependencies?.income?.income || []).filter(
                        (item: any) => {
                            return types.includes(item.type);
                        },
                    );
                    return incomes.length > 0;
                },
                toRequest: null,
            },
        },
        incomes: {
            validation: Yup.mixed().when('hasAny', {
                is: true,
                then: Yup.array()
                    .test(
                        'not_finished',
                        'You have not saved the above income',
                        (_value: any, context: any) => {
                            const parent = context.parent;
                            if (
                                parent.tmp &&
                                (parent.tmp?.type !== 'Select' ||
                                    (parent.tmp?.amount !== '' &&
                                        parent.tmp?.amount))
                            ) {
                                return false;
                            }
                            return true;
                        },
                    )
                    .min(1, 'You have not saved the above income'),
            }),
            transform: {
                target: 'income.income[]',

                fromRequest: (_value: any, dependencies: any) => {
                    const types = incomeTypes.map((a) => a.label);
                    return (dependencies?.income?.income || []).filter(
                        (item: any) => {
                            return types.includes(item.type);
                        },
                    );
                },
            },
        },
        tmp: {
            type: {
                label: 'Income type',
                items: incomeTypes,
            },
            amount: {
                label: 'Amount per year',
                ...currency,
            },
        },
        default: {
            incomes: [],
            tmp: {
                type: 'Select',
                amount: '',
            },
        },
        transform: {
            // it is needs if the nested node ('otherIncomes') shouldn't be applied (so keep the child income.income[] in the same level)
            target: '',
        },
    },
});
