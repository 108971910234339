import { FC } from 'react';

const ContactPhone: FC = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
        >
            <path
                d='M6.28125 8.30002C6.79576 9.36253 7.65492 10.2195 8.71875 10.7313C8.79722 10.7684 8.88402 10.7845 8.97059 10.7779C9.05717 10.7714 9.14054 10.7424 9.2125 10.6938L10.775 9.65002C10.844 9.6032 10.9238 9.57463 11.0069 9.56698C11.0899 9.55933 11.1736 9.57285 11.25 9.60627L14.175 10.8625C14.275 10.9041 14.3585 10.9774 14.4127 11.0712C14.4669 11.1649 14.4888 11.2739 14.475 11.3813C14.3823 12.1049 14.0291 12.7699 13.4815 13.2519C12.9339 13.7339 12.2295 13.9999 11.5 14C9.24566 14 7.08365 13.1045 5.48959 11.5104C3.89553 9.91637 3 7.75436 3 5.50002C3.00016 4.77052 3.26613 4.06607 3.74814 3.51849C4.23015 2.97091 4.89516 2.61773 5.61875 2.52502C5.72615 2.51122 5.83511 2.53312 5.92884 2.58733C6.02258 2.64153 6.09589 2.72505 6.1375 2.82502L7.39375 5.75627C7.42642 5.83149 7.44009 5.91359 7.43355 5.99533C7.42701 6.07707 7.40046 6.15595 7.35625 6.22502L6.3125 7.81252C6.26603 7.88432 6.23876 7.96684 6.23329 8.05219C6.22782 8.13755 6.24433 8.22287 6.28125 8.30002V8.30002Z'
                stroke='#3A4146'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ContactPhone;
