import * as Yup from 'yup';

import { IIncomeValue } from '@App/interfaces/IIncome';
import { IncomeType } from '@App/interfaces/IncomeType';
import {
    isValidDateFormat,
    months,
    toApiDate,
    toValidDate,
    years,
} from '@Shared/helpers/date';
import { normalizeNumber } from '@src/shared/helpers';

import { CustomFormProps, currency, yesNo } from '../shared';

import {
    commonDecrease,
    commonRetirement,
    otherIncome,
    otherIncomeForEmployed,
} from './incomings.shared';

// -------------------------------------------------------------------------
export const employed: CustomFormProps = {
    employment: {
        nested: true,
        default: {
            contractStartDate: '',
            registeredCompanyName: undefined,
            ownSharesInThisCompany: undefined,
            jobTitle: '',
            startMonth: '',
            startYear: '',
        },
        month: {
            items: months,
        },
        year: {
            items: years,
        },
        startMonth: {
            validation: Yup.string().required('Please select month'),
        },
        startYear: {
            validation: Yup.string().required('Please select year'),
        },
        registeredCompanyName: {
            transform: {
                target: 'employment.registeredCompanyName',
            },
            validation: Yup.string()
                .required('Please enter a valid company name')
                .min(2, 'Please enter a valid company name'),
        },
        contractStartDate: {
            label: 'What was your start date?',
            validation: Yup.string().test(
                'validDate',
                'It is not a valid date',
                (value) => {
                    return isValidDateFormat(value + '');
                },
            ),
            transform: {
                target: 'employment.contractStartDate',
                toRequest: (value: string) => toApiDate(value),
                fromRequest: (value: string) => toValidDate(value),
            },
        },
        ownSharesInThisCompany: {
            ...yesNo,
            transform: {
                target: 'employment.ownSharesInThisCompany',
            },
            validation: Yup.boolean().required('This is a required field'),
        },
        jobTitle: {
            label: 'What is your job title?',
            transform: {
                target: 'employment.jobTitle',
            },
            validation: Yup.string()
                .required('Please enter a valid job title')
                .min(2, 'Please enter a valid job title'),
        },

        transform: { target: '' },
    },
    incomeAmount: {
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            toRequest: (value: any) => ({
                type: IncomeType.GROSS_SALARY,
                amount: normalizeNumber(value),
            }),
            fromRequest: (_value: any, dependencies: any) => {
                const incomeValues = dependencies?.income?.income || [];
                const grossSalaryIncome = incomeValues.find(
                    (it: IIncomeValue) => it.type === IncomeType.GROSS_SALARY,
                );

                const grossAnnualIncome = incomeValues.find(
                    (it: IIncomeValue) =>
                        it.type === IncomeType.GROSS_ANNUAL_INCOME,
                );

                const salaryIncome = grossSalaryIncome
                    ? grossSalaryIncome.amount
                    : grossAnnualIncome?.amount;

                return salaryIncome || '';
            },
        },
    },
    ...otherIncome(otherIncomeForEmployed),
    ...commonRetirement,
    ...commonDecrease,
};
