const DoorIcon = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                opacity='0.2'
                d='M4.37543 17.5V3.125C4.37543 2.95924 4.44128 2.80027 4.55849 2.68306C4.6757 2.56585 4.83467 2.5 5.00043 2.5H15.0004C15.1662 2.5 15.3252 2.56585 15.4424 2.68306C15.5596 2.80027 15.6254 2.95924 15.6254 3.125V17.5H4.37543Z'
                fill='#3A4146'
            />
            <path
                d='M1.87518 17.5H18.1252'
                stroke='#3A4146'
                strokeWidth='1.1875'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M4.37543 17.5V3.125C4.37543 2.95924 4.44128 2.80027 4.55849 2.68306C4.6757 2.56585 4.83467 2.5 5.00043 2.5H15.0004C15.1662 2.5 15.3252 2.56585 15.4424 2.68306C15.5596 2.80027 15.6254 2.95924 15.6254 3.125V17.5'
                stroke='#3A4146'
                strokeWidth='1.1875'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M12.1874 10.9375C12.7052 10.9375 13.1249 10.5178 13.1249 10C13.1249 9.48223 12.7052 9.0625 12.1874 9.0625C11.6697 9.0625 11.2499 9.48223 11.2499 10C11.2499 10.5178 11.6697 10.9375 12.1874 10.9375Z'
                fill='#3A4146'
            />
        </svg>
    );
};

export default DoorIcon;
