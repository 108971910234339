import React, { FC, SVGProps, useState } from 'react';

import clsx from 'clsx';

interface ChevronIconProps extends SVGProps<SVGSVGElement> {
    isRotated?: boolean;
}

const ChevronIcon: FC<ChevronIconProps> = ({
    isRotated,
    onClick,
    ...props
}) => {
    const [internalRotate, setInternalRotate] = useState<boolean>(false);

    const handleClick = (
        event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        if (onClick) {
            onClick(event);
        }
        if (isRotated === undefined) {
            setInternalRotate((prevState) => !prevState);
        }
    };

    const rotationClass = clsx({
        'rotate-180': isRotated ?? internalRotate,
    });

    return (
        <svg
            className={clsx('cursor-pointer transform', rotationClass)}
            width='24'
            height='24'
            {...props}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={handleClick}
        >
            <path
                d='M21.6023 9.24513C22.1326 8.73153 22.1326 7.89881 21.6023 7.38521C21.0719 6.8716 20.2121 6.8716 19.6818 7.3852L12 14.8249L4.31819 7.3852C3.78787 6.8716 2.92806 6.8716 2.39774 7.3852C1.86742 7.89881 1.86742 8.73152 2.39774 9.24513L11.0398 17.6148C11.5701 18.1284 12.4299 18.1284 12.9602 17.6148'
                fill={props.color || '#B0B3B5'}
            />
        </svg>
    );
};

export default ChevronIcon;
