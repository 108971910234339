import { axiosInstance } from '../providers';

import { getRequestConfigWithUserProfile, httpErrorResponse } from './shared';

export const postStoredApplication = async (
    applicationId: string,
    tokens: { accessToken: string; idToken: string },
    data: any,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.patch(
            `${url}/application/${applicationId}`,
            data,
            getRequestConfigWithUserProfile(tokens.accessToken, tokens.idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
