export interface AddressValue {
    id?: string;
    addressType?: string | null;
    buildingNumber?: string;
    street?: string;
    apartmentFlatNumber?: string;
    buildingName?: string;
    postcode?: string;
    line1?: string;
    line2?: string;
    line3?: string;
    udprn?: string;
    poBox?: string;
    city?: string;
    county?: string;
    country?: string;
    subBuildingName?: string | null;
}

export interface AddressViewProps {
    variant?: 'compact' | 'extended';
    address: AddressValue;
}

const AddressView = ({ address, variant, ...other }: AddressViewProps) => {
    let buildingInformation = [address.buildingNumber, address.street]
        .filter(Boolean)
        .join(' ');

    buildingInformation = [
        address.apartmentFlatNumber,
        buildingInformation,
        address.subBuildingName,
        address.buildingName,
    ]
        .filter(Boolean)
        .join(', ');

    const addressLine1OrBuildingInformation =
        address.line1 || buildingInformation;

    const addressLine2OrCity = address.line2 || address.city;

    const Info = () => {
        return (
            <div {...other}>
                <p>{addressLine1OrBuildingInformation}</p>
                <p>{addressLine2OrCity}</p>
                {variant === 'extended' && (
                    <>
                        <p>{address.postcode}</p>
                        <p>{address.country}</p>
                    </>
                )}
            </div>
        );
    };

    return <Info />;
};

export default AddressView;
