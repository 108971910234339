import React, { FC } from 'react';

import clsx from 'clsx';

import { Container } from '@src/ui/components/Container';

const className = {
    wrapper: 'bg-white h-16 flex px-4',
    wrapper_border: 'border-b border-disabled_contrast',
    container: 'flex justify-between',
};

export interface NavigationHeaderProps {
    leftComponent: React.ReactNode;
    centerComponent?: React.ReactNode;
    rightComponent: React.ReactNode;
    className?: string;
    noBorder?: boolean;
}

const NavigationHeader: FC<NavigationHeaderProps> = (
    props: NavigationHeaderProps,
) => {
    const wrapperClassName = clsx(
        className.wrapper,
        props.noBorder ? '' : className.wrapper_border,
    );
    return (
        <Container className={clsx([props.className, wrapperClassName])}>
            <Container variant={'wide'} className={className.container}>
                {props.leftComponent}
                {props.centerComponent && (
                    <Container className='flex' variant={'thin'}>
                        {props.centerComponent}
                    </Container>
                )}
                {props.rightComponent}
            </Container>
        </Container>
    );
};

export default NavigationHeader;
