import { useContext, useEffect } from 'react';

import footer_en from '@Translations/en/pages/footer.json';
import good_news_en from '@Translations/en/pages/goodNews.json';
import axios from 'axios';
import cookie from 'cookie';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';

import ApplicationContextProvider from '@App/contexts/Application';
import AuthProvider, { AuthContext } from '@App/contexts/Auth';

import { KameleeonProvider } from '../utils/kameleoon';

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    lng: 'en',
    resources: {
        en: {
            footer: footer_en,
            goodNews: good_news_en,
        },
    },
});

const AuthFrame = (props: { children: any }) => {
    const {
        getAccessToken,
        isToFetchApplicationData,
        updateFetchApplicationDataFlag,
        getIdToken,
        logOut,
    } = useContext(AuthContext);

    useEffect(() => {
        const cookies = cookie.parse(document.cookie);
        const appInfo = cookies.application
            ? JSON.parse(cookies.application)
            : {};

        if (
            appInfo.applicationId !== localStorage.getItem('user.applicationId')
        ) {
            logOut();
        }

        updateFetchApplicationDataFlag().then();
    }, [updateFetchApplicationDataFlag, logOut]);

    return (
        <Router>
            <ApplicationContextProvider
                getAccessToken={getAccessToken}
                isToFetchApplication={isToFetchApplicationData}
                getIdToken={getIdToken}
            >
                <I18nextProvider i18n={i18next}>
                    {props.children}
                </I18nextProvider>
            </ApplicationContextProvider>
        </Router>
    );
};

const Providers = (props: { children: any }) => {
    return (
        <AuthProvider>
            <KameleeonProvider>
                <AuthFrame>
                    <AxiosInterceptor>{props.children}</AxiosInterceptor>
                </AuthFrame>
            </KameleeonProvider>
        </AuthProvider>
    );
};

const axiosInstance = axios.create();

const AxiosInterceptor = (props: any) => {
    const navigate = useNavigate();
    const { logOut } = useContext(AuthContext);

    useEffect(() => {
        const interceptor = axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (
                    error.response?.status === 401 ||
                    error.response?.status === 403
                ) {
                    logOut();
                    navigate('/login');
                }
                return Promise.reject(error);
            },
        );

        return () => axiosInstance.interceptors.response.eject(interceptor);
    });

    return <>{props.children}</>;
};

export { axiosInstance };
export default Providers;
