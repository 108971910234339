import { useCallback, useContext } from 'react';

import {
    useFeatureFlag,
    useInitialize,
    useVisitorCode,
} from '@kameleoon/react-sdk';

import { ApplicationContext } from '../contexts/Application';

export const useKameleoonFeature = (featureKey: string) => {
    const { initialize } = useInitialize();
    const { getFeatureFlagVariationKey } = useFeatureFlag();
    const { getVisitorCode } = useVisitorCode();
    const { applicationEmail } = useContext(ApplicationContext);

    const getVariationKey = useCallback(async (): Promise<string> => {
        await initialize();

        const visitorCode = getVisitorCode(applicationEmail);
        return getFeatureFlagVariationKey(visitorCode, featureKey);
    }, [
        initialize,
        getFeatureFlagVariationKey,
        getVisitorCode,
        applicationEmail,
        featureKey,
    ]);

    return getVariationKey;
};
