import { ChangeEvent, FC, useRef, useState } from 'react';

import { Button, TextField } from '@selina-finance/ui';
import cookie from 'cookie';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { formItems } from '@src/app/formModels/auth/signIn';
import { FormError } from '@src/ui/components/FormError';
import EyeIcon from '@src/ui/components/Icons/EyeIcon';
import ModalLayout from '@src/ui/layouts/ModalLayout';

import ResetPasswordModalView from '../modal/ResetPasswordView';

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

export interface SignInViewProps {
    className?: string;
    onSubmit?: Function;
    forgotPassword?: Function;
    email?: string;
    applicationId?: string;
}

const SignInView: FC<SignInViewProps> = (props) => {
    const [revealPassword, setRevealPassword] = useState(false);
    const [errors, setErrors] = useState<{}>({});
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);

    const navigate = useNavigate();
    const formikRef = useRef<any>(null);

    const cookies = cookie.parse(document.cookie);
    const appInfo = cookies.application ? JSON.parse(cookies.application) : {};

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{ ...defaultValues, email: props.email }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (props.onSubmit) {
                        props
                            .onSubmit(
                                values.email,
                                values.password,
                                props.applicationId || appInfo.applicationId,
                            )
                            .then((result: any) => {
                                if (!result.success) {
                                    setErrors({ login: result.message });
                                    return;
                                }
                                navigate('/');
                            });
                    }
                }}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { hasError, getError } = getFormHelpers(
                        formItems,
                        formikProps,
                    );
                    return (
                        <Form>
                            <TextField
                                id='email'
                                name='email'
                                labelSize='small'
                                label='Email address'
                                value={
                                    formikProps.values.email?.toString() || ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('email')}
                            />
                            <br />
                            <TextField
                                type={revealPassword ? 'text' : 'password'}
                                suffix={
                                    <div
                                        onClick={() =>
                                            setRevealPassword(!revealPassword)
                                        }
                                        className='flex items-center cursor-pointer w-20'
                                    >
                                        <EyeIcon />
                                        <label className='ml-2 cursor-pointer'>
                                            {revealPassword ? 'Hide' : 'Show'}
                                        </label>
                                    </div>
                                }
                                id='password'
                                name='password'
                                labelSize='small'
                                label='Password'
                                value={
                                    formikProps.values.password?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('password')}
                            />
                            <br />

                            <FormError
                                touched={{
                                    ...formikProps.touched,
                                    confirm_password: true,
                                }}
                                otherErrors={errors}
                            />

                            <Button
                                type='submit'
                                className='mb-6 w-full'
                                size='large'
                                color='secondary'
                                disabled={hasError()}
                            >
                                Log in
                            </Button>
                            <p className='text-md mb-4 text-center'>
                                Don’t have an account? &nbsp;
                                <a
                                    href={
                                        window?._env_?.NEWQUOTE_BASE_URL || ''
                                    }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='underline cursor-pointer text-md'
                                >
                                    Get a quote and sign up.
                                </a>
                            </p>
                            <div className='text-center'>
                                <div
                                    className='mb-4 text-md underline cursor-pointer'
                                    onClick={() => {
                                        setOpenResetPasswordModal(true);
                                    }}
                                >
                                    <p>Forgot your password?</p>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <ModalLayout
                contentClassName='max-w-xl mx-auto'
                open={openResetPasswordModal}
                onClose={() => setOpenResetPasswordModal(false)}
                title={'Reset your password'}
            >
                <ResetPasswordModalView
                    email={formikRef.current?.values.email || ''}
                    forgotPassword={(email: string) => {
                        if (props.forgotPassword) {
                            props.forgotPassword(email).then((result: any) => {
                                if (result.success) {
                                    navigate(
                                        `/reset-password?email=${encodeURIComponent(
                                            email || '',
                                        )}`,
                                    );
                                } else {
                                    setErrors({ code: result.message });
                                    setOpenResetPasswordModal(false);
                                    setTimeout(() => {
                                        setErrors({});
                                    }, 3000);
                                }
                            });
                        }
                    }}
                />
            </ModalLayout>
        </div>
    );
};

export default SignInView;
