import PhoneCallIcon from '../Icons/PhoneCallIcon';

const Help = ({ className }: { className?: string }) => {
    return (
        <div className={`${className || 'my-15'} flex justify-center`}>
            <div className='bg-white p-4 rounded-lg w-max'>
                <div className='flex items-center space-x-2 hidden lg:flex'>
                    <PhoneCallIcon />
                    <p className='text-md'>
                        <span className='font-bold'>Need help?</span> 020 4525
                        8044 (Mon - Fri, 9am - 5pm)
                    </p>
                </div>
                <div className='items-center space-x-2 block lg:hidden'>
                    <div className='flex'>
                        <PhoneCallIcon />
                        <p className='font-bold pl-2 text-md'>Need help?</p>
                    </div>
                    <p className='text-md pl-6'>
                        020 4525 8044 (Mon - Fri, 9am - 5pm)
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Help;
