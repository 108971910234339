import { axiosInstance } from '@App/providers';
import {
    getRequestConfigWithUserProfile,
    httpErrorResponse,
} from '@App/requests/shared';

export const updateClientId = async (
    accessToken: any,
    idToken: any,
    applicationId: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.put(
            `${url}/application/username/${applicationId}`,
            {},
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
