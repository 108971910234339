import { IconBox, Logo } from '@src/ui/components/Icons';
import { Slider } from '@src/ui/components/Slider';
import Testimonial from '@src/ui/components/Testimonial/Testimonial';

const WaitViewLoader = () => {
    return (
        <>
            <div className='md:pb-10 p-6 text-center'>
                <div className='m-auto relative w-fit'>
                    <IconBox className='absolute h-20 left-2 top-2 w-20'>
                        <Logo />
                    </IconBox>
                    <div className='animate-spin h-24 w-24 rounded-full border-l  border-base' />
                </div>
                <p className='text-5xl font-bold'>Calculating...</p>
                <p className='text-lg'>
                    Please wait - this may take up to 120 seconds.
                    <br />
                    This page will automatically refresh
                </p>
            </div>
            <Slider timer={7000} className='h-44'>
                <Testimonial
                    name='Amanda'
                    title='Upgraded her home'
                    description='“Having the option of drawing down funds in stages has been invaluable. We were not clocking up unnecessary interest and yet we could still get the money we needed.”'
                />
                <Testimonial
                    name='Richard'
                    title='Upgraded his home extension'
                    description='“It’s common to go over budget when doing home improvements so we were very pleased to have the flexibility to draw down more as many times as needed.”'
                />
                <Testimonial
                    name='Tanuja'
                    title='Upgraded everything in her home'
                    description='“We’re using the Selina in stages. We used the first drawdown to pay for major foundation work and will draw down a second time for furniture and final touches.”'
                />
                <Testimonial
                    name='Alistair'
                    title='Upgraded his living, dining and kitchen space'
                    description='“Thanks to the Selina, I was able to first pay initial deposits for the kitchen and then phase payments for builders and other suppliers as the project progressed.”'
                />
                <Testimonial
                    name='Blaise'
                    title='Upgraded his business'
                    description='“Very pleased with the flexibility of the product. It enabled us to upgrade a former shop into three flats generating a financial return as well as new quality homes for our tenants.”'
                />
                <Testimonial
                    name='Paul'
                    title='Upgraded his living, dining and kitchen space'
                    description='“Not having to draw down in one lump sum also meant I could control repayments over the life of the project which was fantastic for budget planning.”'
                />
            </Slider>
        </>
    );
};

export default WaitViewLoader;
