const CrossIcon = () => {
    return (
        <svg
            width='12'
            height='13'
            viewBox='0 0 12 13'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.7999 1.7002L1.19992 11.3002'
                stroke='#FF0000'
                strokeWidth='1.71429'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.7999 11.3002L1.19992 1.7002'
                stroke='#FF0000'
                strokeWidth='1.71429'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CrossIcon;
