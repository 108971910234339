import { chain } from 'lodash';

const calculateMonthsDifference = (firstDate: Date, secondDate: Date) => {
    const yearsDifference = secondDate.getFullYear() - firstDate.getFullYear();
    const monthsDifference = secondDate.getMonth() - firstDate.getMonth();

    return yearsDifference * 12 + monthsDifference;
};

const getMonthsBetweenDates = (
    fromDate: string | null,
    toDate: string | null,
) => {
    if (!fromDate || !toDate) {
        return null;
    }

    const fromDateObject = new Date(fromDate);
    const toDateObject = new Date(toDate);

    return calculateMonthsDifference(toDateObject, fromDateObject);
};

const isMoreThanThreeCommon = (date: string) => {
    const inputDate = new Date(date);

    const currentDate = new Date();

    const totalMonthsDifference = calculateMonthsDifference(
        inputDate,
        currentDate,
    );

    return totalMonthsDifference >= 36;
};
export const isMoreThanThreeYears = (addresses: any): boolean => {
    const date = chain(addresses)
        .map((it) => `${it.livedIn.fromYear}-${it.livedIn.fromMonth}-01`)
        .minBy((date) => date)
        .value();

    return isMoreThanThreeCommon(date);
};

export const isMoreThanThreeYearsFromApplication = (
    addresses: any,
): boolean | null => {
    const date = chain(addresses)
        .map((it) => it.address?.from)
        .minBy((date) => date)
        .value();

    if (!date) {
        return null;
    }

    return isMoreThanThreeCommon(date);
};

export const isLessThanSixMonth = (
    fromDate: string | null,
    toDate: string | null,
) => {
    const diff = getMonthsBetweenDates(fromDate, toDate);

    return diff ? diff > 6 : null;
};

export const isMoreThanTwoMonth = (
    fromDate: string | null,
    toDate: string | null,
) => {
    const diff = getMonthsBetweenDates(fromDate, toDate);

    return diff ? diff < -2 : null;
};

export const isToDateMore = (
    fromDate: string | null,
    toDate: string | null,
): boolean => {
    if (!fromDate || !toDate) {
        return false;
    }

    const fromDateObject = new Date(fromDate);
    const toDateObject = new Date(toDate);

    return toDateObject >= fromDateObject;
};

export const isDateMoreNow = (date: string | null): boolean => {
    if (!date) {
        return false;
    }

    const dateObject = new Date(date);

    const nowDateObject = new Date();

    const currentMonth = (nowDateObject.getMonth() + 1)
        .toString()
        .padStart(2, '0');

    const startOfMonth = new Date(
        `${nowDateObject.getFullYear()}-${currentMonth}-01`,
    );

    return dateObject > startOfMonth;
};
