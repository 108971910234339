import { useState } from 'react';

import { Button, H3 } from '@selina-finance/ui';
import { useNavigate } from 'react-router-dom';

import BackButton from '@src/ui/components/BackButton/BackButton';
import { Calendly } from '@src/ui/components/Calendly';
import Help from '@src/ui/components/Help/Help';
import TrustpilotReviews from '@src/ui/components/TrustpilotReviews/TrustpilotReviews';

const FinaliseView = () => {
    const [showCalendly, setShowCalendly] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleBookCallClick = () => {
        setShowCalendly(true);
    };

    const handleMobileBookCallClick = () => {
        navigate('/eligibility/offer/book-call');
    };

    return (
        <div className='text-steel-100 flex flex-col mx-auto max-w-400 w-full lg:max-w-none'>
            <div className='hidden lg:block mb-8'>
                <BackButton />
            </div>
            <div className='text-xl text-steel-100'>
                <div className='flex flex-col items-center'>
                    <H3 className='leading-100 font-bold mb-3 lg:mb-6 mt-5 sm:mt-0 w-72 lg:mt-0 lg:w-auto'>
                        Review your application with a qualified advisor
                    </H3>
                    <div>
                        <ul className='list-disc space-y-4 mb-6 ml-8 leading-120%'>
                            <li>Make sure this is right for you</li>
                            <li className='w-72 lg:w-80'>
                                Tell us about your debts, and learn how much you
                                can save
                            </li>
                            <li>Nothing to prepare</li>
                        </ul>
                        <div className='hidden lg:block'>
                            <Button
                                size='large'
                                color='secondary'
                                className='font-bold w-96 mt-4 mb-24'
                                onClick={handleBookCallClick}
                            >
                                Book a call
                            </Button>
                        </div>
                    </div>
                </div>

                <TrustpilotReviews />

                <div className='block lg:hidden'>
                    <Button
                        size='large'
                        color='secondary'
                        className='font-bold w-full lg:w-auto mt-8'
                        onClick={handleMobileBookCallClick}
                    >
                        Book a call
                    </Button>
                </div>

                <Help className='mt-4 lg:hidden' />

                {showCalendly && (
                    <Calendly onClose={() => setShowCalendly(false)} />
                )}
            </div>
        </div>
    );
};

export default FinaliseView;
