const RadioSelected = () => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <circle
                cx='12'
                cy='12'
                r='11.25'
                fill='white'
                stroke='#3A4146'
                strokeWidth='1.5'
            />
            <circle cx='12' cy='12' r='8' fill='#3A4146' />
            <path
                d='M8.66663 12.6668L10.6666 15.3335L15.3333 9.3335'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default RadioSelected;
