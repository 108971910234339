import { FC } from 'react';

import clsx from 'clsx';

import { IconBox, Logo } from '../Icons';

export interface LoaderProgressProps {
    loading?: boolean;
    fullScreen?: boolean;
}

const LoaderProgress: FC<LoaderProgressProps> = (props) => {
    return (
        <div
            className={clsx(
                props.loading ? 'block' : 'hidden',
                props.fullScreen ? 'fixed' : 'absolute',
                'left-0 right-0 bottom-0 top-0 backdrop-blur-xs backdrop-filter',
            )}
        >
            <div>
                <IconBox className='h-20 w-20 left-1/2 top-1/2 absolute -translate-x-1/2 transform -translate-y-1/2'>
                    <Logo />
                </IconBox>
                <div className='h-24 w-24 left-1/2 top-1/2 absolute -translate-x-1/2 transform -translate-y-1/2'>
                    <div className='animate-spin h-24 w-24 rounded-full border-l  border-base' />
                </div>
            </div>
        </div>
    );
};

export default LoaderProgress;
