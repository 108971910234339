import { axiosInstance } from '@App/providers';
import {
    getRequestConfigWithUserProfile,
    httpErrorResponse,
} from '@App/requests/shared';

export const getOffersData = async (
    accessToken: string,
    idToken: string,
    id: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.post(
            `${url}/decision/${id}`,
            undefined,
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const getRecommendedOffers = async (
    id: string,
    accessToken: string,
    idToken: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.get(
            `${url}/v2/recommendation/${id}`,
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
