import { useContext, useEffect, useRef, useState } from 'react';

import { ApplicationContext } from '@App/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import ApplicantsView from '../views/eligibility/ApplicantsView';

const Applicants = () => {
    const {
        applicants,
        loading,
        activeApplicant,
        updateStageToFactFind,
        setActiveApplicant,
        saveApplicants,
        applicationId,
    } = useContext(ApplicationContext);

    const [localApplicant, setLocalApplicant] = useState(null);

    useEffect(() => {
        async function update() {
            updateStageToFactFind(applicationId);
        }
        update();
        // eslint-disable-next-line
    }, [applicationId]);

    useEffect(() => {
        setLocalApplicant(activeApplicant || 0);
        setActiveApplicant(null);
    }, [activeApplicant, setActiveApplicant, setLocalApplicant]);

    const saveRef = useRef(null);

    return (
        <EligibilityLayout loading={loading} ref={saveRef}>
            <ApplicantsView
                applicantsData={applicants}
                activeApplicant={localApplicant}
                saveApplicants={saveApplicants}
                ref={saveRef}
            />
        </EligibilityLayout>
    );
};

export default Applicants;
