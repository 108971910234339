import { DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';

import { Size } from '@src/ui/components/shared';

import { Control, ControlProps } from '../Control';

import MaskedInput from './MaskedInput';

const className = {
    // base
    base: 'flex flex-col',
    wrapper:
        'flex flex-row justify-center align-middle content-center items-center rounded-lg border border-base_light',
    inner_wrapper:
        'flex justify-center align-middle content-center items-center flex-1 rounded-lg h-full',
    input: 'outline-none w-full h-full rounded-lg',

    // actions
    focus: 'focus-within:ring-4 focus-within:ring-gray-200 focus-within:border-success border',
    hover: 'hover:shadow-md',
    border_error: 'border-danger focus-within:border-danger',
    text_error: 'text-danger',

    wrapper_small: 'h-13',
    wrapper_medium: 'h-15',
    wrapper_large: 'h-16',

    // extras
    start_adornment: 'pl-4 pr-0.5 h-full flex items-center',
    end_adornment: 'pr-4 pl-0.5 h-full flex items-center',

    // input
    input_small: 'text-sm px-2',
    input_medium: 'text-lg px-3',
    input_large: 'text-lg px-4',

    // info
    info: 'pl-0 pt-1 pb-1',
    info_at_top: 'pl-0 pt-0 pb-1',
    info_small: 'text-xs px-2.5 py-2 ',
    info_medium: 'text-sm px-3.5 py-3 ',
    info_large: 'text-md px-4 py-3.5',

    // error
    error_text: 'pl-0 pb-0 text-danger',
    error_small: 'text-xs px-2.5 py-2 ',
    error_medium: 'text-sm px-3.5 py-3 ',
    error_large: 'text-md px-4 py-3.5',
};

export type InputVariant = 'outlined';
export type InputColor = 'base';
export type InputType =
    | 'email'
    | 'file'
    | 'hidden'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';
export interface TextFieldProps
    extends Omit<
            DetailedHTMLProps<
                InputHTMLAttributes<HTMLInputElement>,
                HTMLInputElement
            >,
            'size' | 'type' | 'className' | 'children'
        >,
        ControlProps {
    type?: InputType;
    label?: string;
    labelClassName?: string;
    wrapperClassName?: string;
    errorClassName?: string;
    innerWrapperClassName?: string;
    inputClassName?: string;
    infoClassName?: string;
    startElement?: string | ReactNode;
    endElement?: string | ReactNode;
    startAdornment?: string | ReactNode;
    endAdornment?: string | ReactNode;
    mask?: any;
    maskProps?: any;
    value: any;
    hide?: boolean;
}

const TextField: FC<TextFieldProps> = (props) => {
    const size: Size = props.size || 'medium';
    const type: InputType = props.type || 'text';

    if (props.hide) {
        return null;
    }

    const innerWrapperClassName = clsx([
        props.innerWrapperClassName,
        props.startElement ? 'rounded-l-none border-l-0' : '',
        props.endElement ? 'rounded-r-none border-r-0' : '',
        className.inner_wrapper,
    ]);

    const startAdornmentClassName = clsx([
        className[`input_${size}`],
        className.start_adornment,
    ]);

    const endAdornmentClassName = clsx([
        className[`input_${size}`],
        className.end_adornment,
    ]);

    const inputClassName = clsx([
        props.inputClassName,
        props.startAdornment ? 'pl-0 ml-0' : '',
        props.endAdornment ? 'pr-0 mr-0' : '',
        className.input,
        className[`input_${size}`],
    ]);

    const startElementClassName = clsx(['-ml-1', className[`wrapper_${size}`]]);
    const endElementClassName = clsx(['-mr-1', className[`wrapper_${size}`]]);

    const limitedProps = omit(props, [
        'style',
        'className',
        'labelClassName',
        'errorClassName',
        'outerWrapperClassName',
        'innerWrapperWrapperClassName',
        'inputClassName',
        'infoClassName',
        'type',
        'capture',
        'size',
        'startAdornment',
        'endAdornment',
        'startElement',
        'endElement',
        'infoAtTop',
        'maskProps',
        'hide',
    ]) as DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >;

    return (
        <Control {...omit(props, 'mask', 'children', 'maskProps')}>
            <>
                {props.startElement && (
                    <div className={startElementClassName}>
                        {props.startElement}
                    </div>
                )}
                <div className={innerWrapperClassName}>
                    {props.startAdornment && (
                        <div className={startAdornmentClassName}>
                            {props.startAdornment}
                        </div>
                    )}
                    {!props.mask && (
                        <input
                            type={type}
                            className={inputClassName}
                            {...limitedProps}
                            title={props.title}
                            aria-label={props.label}
                        />
                    )}
                    {props.mask && (
                        <MaskedInput
                            mask={props.mask}
                            maskProps={props.maskProps}
                            type={type}
                            className={inputClassName}
                            {...limitedProps}
                            title={props.title}
                            aria-label={props.label}
                        />
                    )}
                    {props.endAdornment && (
                        <div className={endAdornmentClassName}>
                            {props.endAdornment}
                        </div>
                    )}
                </div>
                {props.endElement && (
                    <div className={endElementClassName}>
                        {props.endElement}
                    </div>
                )}
            </>
        </Control>
    );
};

export default TextField;
