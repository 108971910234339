import { useContext, useEffect, useRef, useState } from 'react';

import { ApplicationContext } from '@App/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import IncomingsView from '../views/eligibility/IncomingsView';

const Incomings = () => {
    const {
        incomings,
        setIncomings,
        loading,
        activeApplicant,
        setActiveApplicant,
        saveIncomings,
    } = useContext(ApplicationContext);
    const [localApplicant, setLocalApplicant] = useState(null);

    useEffect(() => {
        setLocalApplicant(activeApplicant || 0);
        setActiveApplicant(null);
    }, [activeApplicant, setActiveApplicant, setLocalApplicant]);

    const setIncomingsData = (data: any, index: number) => {
        const newIncomings = [...incomings];
        newIncomings[index] = data;
        setIncomings(newIncomings);
    };

    const saveRef = useRef(null);

    return (
        <EligibilityLayout loading={loading} ref={saveRef}>
            <IncomingsView
                incomingsData={incomings}
                activeApplicant={localApplicant}
                saveIncomings={saveIncomings}
                setIncomingsData={setIncomingsData}
                ref={saveRef}
            />
        </EligibilityLayout>
    );
};

export default Incomings;
