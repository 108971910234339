import {
    JSXElementConstructor,
    ReactElement,
    cloneElement,
    forwardRef,
    useContext,
} from 'react';

import clsx from 'clsx';

import { AuthContext } from '@src/app/contexts/Auth';
import { Container } from '@src/ui/components/Container';
import { LoaderProgress } from '@src/ui/components/LoaderProgress';

import Header from '../components/Header/Header';
import Trustbar from '../components/Trustbar/Trustbar';

export interface EligibilityLayoutProps {
    contentClassName?: string;
    className?: string;
    loading?: boolean;
    data?: {};
    save?: Function;
    children: ReactElement<any, string | JSXElementConstructor<any>>;
    noBorder?: boolean;
    offerPage?: boolean;
}

const EligibilityLayout = forwardRef((props: EligibilityLayoutProps, ref) => {
    const { loading } = useContext(AuthContext);
    return (
        <div
            className={clsx(
                props.className,
                'text-body pb-15 bg-neutral-10 h-screen overflow-y-auto',
            )}
        >
            <Header ref={ref} />

            <Trustbar offerPage={props.offerPage} />

            <Container
                variant={'wider'}
                className={clsx('relative', props.contentClassName)}
            >
                <div className='mx-4 xl:mx-0'>
                    {cloneElement(props.children, {
                        loading: props.loading,
                        data: props.data,
                        save: props.save,
                    })}
                </div>
                <LoaderProgress loading={props.loading || loading} fullScreen />
            </Container>
        </div>
    );
});

export default EligibilityLayout;
