import { FC } from 'react';

const CheckCircleIcon: FC = () => {
    return (
        <svg
            width='41'
            height='40'
            viewBox='0 0 41 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.5 36.6667C29.7047 36.6667 37.1667 29.2047 37.1667 20C37.1667 10.7953 29.7047 3.33333 20.5 3.33333C11.2953 3.33333 3.83333 10.7953 3.83333 20C3.83333 29.2047 11.2953 36.6667 20.5 36.6667ZM20.5 40C31.5457 40 40.5 31.0457 40.5 20C40.5 8.9543 31.5457 0 20.5 0C9.4543 0 0.5 8.9543 0.5 20C0.5 31.0457 9.4543 40 20.5 40Z'
                fill='#3A4146'
            />
            <path
                d='M13.8334 21.5L17.8334 26.8334L27.1667 14.8334'
                stroke='#3A4146'
                strokeWidth='4'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default CheckCircleIcon;
