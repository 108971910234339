import { FC, useContext, useEffect, useState } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import { useKameleoonFeature } from '@src/app/hooks/useKameleoon';
import { KAMELEOON_FEATURE, sendFeatureToHeap } from '@src/app/utils/kameleoon';

import WaitLayout from '../layouts/WaitLayout';
import WaitView from '../views/wait/WaitView';

const Wait: FC = () => {
    const {
        setOffers,
        setApplicationStage,
        runDecisioning,
        fetchApplication,
        updateCreditCommitments,
        applicationId,
        applicationEmail,
    } = useContext(ApplicationContext);

    const [waitVariant, setWaitVariant] = useState<string>('');
    const getVariationKey = useKameleoonFeature(KAMELEOON_FEATURE.wait);

    useEffect(() => {
        const fetchVariant = async () => {
            if (applicationEmail) {
                const variationKey = await getVariationKey();
                setWaitVariant(variationKey);
            }
        };

        fetchVariant();
    }, [getVariationKey, applicationEmail]);

    useEffect(() => {
        sendFeatureToHeap(
            waitVariant,
            KAMELEOON_FEATURE.wait,
            applicationId,
            applicationEmail,
        );
    }, [waitVariant, applicationId, applicationEmail]);

    return (
        <WaitLayout>
            <WaitView
                fetchApplication={fetchApplication}
                setOffers={setOffers}
                setApplicationStage={setApplicationStage}
                runDecisioning={runDecisioning}
                updateCreditCommitments={updateCreditCommitments}
                applicationId={applicationId}
                waitLoaderFlowVariant={waitVariant}
            />
        </WaitLayout>
    );
};

export default Wait;
