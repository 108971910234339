import { useEffect, useRef } from 'react';

import clsx from 'clsx';

import { Container } from '@src/ui/components/Container';

import PhoneCallIcon from '../Icons/PhoneCallIcon';

const classes = {
    wrapper: 'p-3 bg-sand h-11 sm:mb-12',
    container: 'flex justify-center lg:justify-between text-md text-steel-40',
};

export interface TrustbarProps {
    className?: string;
    offerPage?: boolean;
}

const Trustbar = ({ className, offerPage }: TrustbarProps) => {
    const wrapperClassName = clsx(classes.wrapper);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current);
        }
    }, [ref]);

    return (
        <Container className={clsx([className, wrapperClassName])}>
            <Container variant={'wide'} className={classes.container}>
                {offerPage ? (
                    <div className='lg:flex lg:justify-between w-full'>
                        <div className='transform scale-80 mt-0.25 lg:-ml-9'>
                            <div
                                ref={ref}
                                className='tp-widget-trustscore mx-auto'
                                data-locale='en-GB'
                                data-template-id='5419b732fbfb950b10de65e5'
                                data-businessunit-id='60fae03f0687800001e2a1da'
                                data-style-height='20px'
                                data-style-width='100%'
                                data-text-color='#B0B3B5'
                            >
                                <a
                                    href='https://uk.trustpilot.com/review/selinaadvance.com'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Trustpilot
                                </a>
                            </div>
                        </div>
                        <div className='text-steel-100 items-center space-x-2 hidden lg:flex lg:order-2'>
                            <div className='flex'>
                                <PhoneCallIcon />
                                <p className='font-bold pl-2 text-md'>
                                    Need help?
                                </p>
                            </div>
                            <p className='text-md'>
                                020 4525 8044 (Mon - Fri, 9am - 5pm)
                            </p>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className='hidden lg:block'>
                            Get your funds 5 days faster with Selina
                        </p>
                        <div className='transform scale-80 mt-0.25'>
                            <div
                                ref={ref}
                                className='tp-widget-trustscore mx-auto'
                                data-locale='en-GB'
                                data-template-id='5419b732fbfb950b10de65e5'
                                data-businessunit-id='60fae03f0687800001e2a1da'
                                data-style-height='20px'
                                data-style-width='100%'
                                data-text-color='#B0B3B5'
                            >
                                <a
                                    href='https://uk.trustpilot.com/review/selinaadvance.com'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    Trustpilot
                                </a>
                            </div>
                        </div>
                        <p className='hidden lg:block'>
                            Authorised and regulated by the FCA
                        </p>
                    </>
                )}
            </Container>
        </Container>
    );
};

export default Trustbar;
