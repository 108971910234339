import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@App/contexts/Application';
import { ApplicationStage } from '@App/interfaces/application-stage.enum';
import { AuthContext } from '@src/app/contexts/Auth';
import { useKameleoonFeature } from '@src/app/hooks/useKameleoon';
import { ExperimentsConfig } from '@src/app/utils/experiments';
import { KAMELEOON_FEATURE } from '@src/app/utils/kameleoon';

import { LoaderProgress } from '../LoaderProgress';

const ProtectedEligibility = (props: { children: any }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fallback = '/eligibility';
    const [waiting, setWaiting] = useState(true);
    const { dashboardItemStatuses, applicationStage, applicationEmail } =
        useContext(ApplicationContext);
    const { loggedIn, isToFetchApplicationData } = useContext(AuthContext);

    const getVariationKey = useKameleoonFeature(KAMELEOON_FEATURE.loan_details);
    const [loanDetailsVariant, setLoanDetailsVariant] = useState<string>('');

    useEffect(() => {
        const fetchVariant = async () => {
            if (applicationEmail) {
                const variationKey = await getVariationKey();
                setLoanDetailsVariant(variationKey);
            }
        };

        fetchVariant();
    }, [getVariationKey, applicationEmail]);

    useEffect(() => {
        const isLocationAllowed = (path: string) => {
            if (!path.includes('eligibility')) return true;

            const stage = path.split('/').reverse()[0];
            const getCompletedStatus = (stage: string) =>
                dashboardItemStatuses.find((item: any) => item.name === stage)
                    ?.completed;

            const isAfterDecisioning =
                applicationStage === ApplicationStage.DECISIONING_DECLINE ||
                applicationStage === ApplicationStage.CLOSED ||
                (applicationStage === ApplicationStage.DECISIONING_ACCEPT &&
                    isToFetchApplicationData) ||
                applicationStage === ApplicationStage.DECISIONING_ERROR;

            if (
                stage === 'loan-details' &&
                loanDetailsVariant === ExperimentsConfig.Variants.A
            )
                return isAfterDecisioning;
            if (stage === 'loan-details') return !isAfterDecisioning;
            if (stage === 'applicants')
                return (
                    !isAfterDecisioning && getCompletedStatus('loan-details')
                );
            if (stage === 'property-details')
                return !isAfterDecisioning && getCompletedStatus('applicants');
            if (stage === 'income')
                return (
                    !isAfterDecisioning &&
                    getCompletedStatus('property-details')
                );
            // TODO: income is true on inital load but it should be false so check property-details aswell
            if (stage === 'outgoings')
                return (
                    !isAfterDecisioning &&
                    getCompletedStatus('income') &&
                    getCompletedStatus('property-details')
                );

            if (stage === 'wait') return getCompletedStatus('outgoings');
            if (
                stage === 'good-news' ||
                stage === 'explore' ||
                stage === 'finalise' ||
                stage === 'book-call' ||
                stage === 'confirmation'
            ) {
                return getCompletedStatus('quote');
            }

            return false;
        };

        const isAllowed = isLocationAllowed(location.pathname);
        if (isAllowed) setWaiting(false);
        if (!isAllowed) {
            if (!loggedIn) {
                const path = '/eligibility/applicants';
                navigate(path, { replace: true });
            } else {
                navigate(fallback, { replace: true });
            }
        }
    }, [
        applicationStage,
        dashboardItemStatuses,
        location.pathname,
        navigate,
        loggedIn,
        isToFetchApplicationData,
        loanDetailsVariant,
    ]);

    return waiting ? <LoaderProgress fullScreen /> : <>{props.children}</>;
};

export default ProtectedEligibility;
