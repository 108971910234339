import React, { ReactNode, useState } from 'react';

import { Button } from '@selina-finance/ui';
import { useNavigate } from 'react-router-dom';

import { Offer } from '@src/app/interfaces/offer';
import Help from '@src/ui/components/Help/Help';
import CalenderIcon from '@src/ui/components/Icons/CalenderIcon';
import CheckCircleIcon from '@src/ui/components/Icons/CheckCircle';
import ClockIcon from '@src/ui/components/Icons/ClockIcon';
import EllipseIcon from '@src/ui/components/Icons/EllipseIcon';
import OfferCard from '@src/ui/components/OfferCard/OfferCard';

interface ConfirmationViewProps {
    offer: Offer;
    callDate: string;
    callTime: string;
}

interface InfoCardProps {
    icon: React.ComponentType<{ colour: string }>;
    text: string;
    marginRight?: boolean;
    desktopView?: boolean;
}

interface HeroBulletProps {
    children: ReactNode;
    number: number;
}

interface CallInfoProps {
    callDate: string;
    callTime: string;
    desktopView?: boolean;
}

const HeroBullet = ({ number, children }: HeroBulletProps) => (
    <div className='flex items-center mb-7 text-xl'>
        <EllipseIcon className='mr-4 flex-none' number={number} />
        <p className='hero-regular leading-120%'>{children}</p>
    </div>
);

const CallInfo = ({ callDate, callTime, desktopView }: CallInfoProps) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        if (window.innerWidth >= 1024) {
            navigate('/eligibility/offer/finalise');
        } else {
            navigate('/eligibility/offer/book-call');
        }
    };

    return (
        <>
            <div
                className={`flex pt-6 sm:pt-0 items-center mb-4 ${
                    desktopView ? '' : 'self-start'
                }`}
            >
                <CheckCircleIcon />
                <h1 className='text-28 font-bold ml-2'>Your call is booked</h1>
            </div>
            <div className='w-full lg:max-w-400'>
                <div className='flex mb-2'>
                    <InfoCard
                        icon={CalenderIcon}
                        text={callDate}
                        marginRight
                        desktopView={desktopView}
                    />
                    <InfoCard
                        icon={ClockIcon}
                        text={callTime}
                        desktopView={desktopView}
                    />
                </div>
                <Button
                    className='w-full mb-2 lg:max-w-400 rounded-lg'
                    style={{ backgroundColor: '#E3DDD1', color: '#3A4146' }}
                    onClick={handleNavigate}
                    size='small'
                >
                    Reschedule
                </Button>
            </div>
        </>
    );
};

const InfoCard = ({
    icon: Icon,
    text,
    marginRight,
    desktopView,
}: InfoCardProps) => (
    <div
        className={`flex-1 py-4 px-6 flex flex-col items-center ${
            desktopView
                ? 'bg-steel-100 border-steel-80 text-neutral-10'
                : 'bg-neutral-40 border-neutral-10'
        }  border border-solid0 rounded-lg ${marginRight ? 'mr-2' : ''}`}
    >
        <Icon colour={desktopView ? '#FBFAF9' : '#B0B3B5'} />
        <span className='text-md'>{text}</span>
    </div>
);

const WhatHappensNext = ({ desktopView }: { desktopView?: boolean }) => (
    <div className='w-full max-w-400'>
        <p className={`${desktopView ? 'text-xl' : 'text-28'} font-bold mb-4`}>
            What happens next?
        </p>
        <div className='mb-6'>
            <HeroBullet number={1}>
                Review your offer with a qualified mortgage advisor
            </HeroBullet>
            <HeroBullet number={2}>Add supporting documents</HeroBullet>
            <HeroBullet number={3}>
                Get approved, and receive your funds
            </HeroBullet>
        </div>
    </div>
);

const ConfirmationView = ({
    offer,
    callDate,
    callTime,
}: ConfirmationViewProps) => {
    const [showMoreInfo, setShowMoreInfo] = useState(false);

    const toggleMoreInfo = () => {
        setShowMoreInfo((prev) => !prev);
    };

    return (
        <>
            <div className='text-steel-100 flex flex-col items-center mx-auto max-w-400 w-full lg:hidden'>
                <CallInfo callDate={callDate} callTime={callTime} />
                <p className='text-28 font-bold mb-4 self-start'>Your offer</p>
                <div className='w-full'>
                    <OfferCard
                        offer={offer}
                        selected={false}
                        selectable={false}
                        showMoreInfo={showMoreInfo}
                        toggle={toggleMoreInfo}
                    />
                </div>
                <p className='text-md leading-120% mb-6'>
                    Your home may be repossessed if you do not keep up
                    repayments on a mortgage or any other debt secured on it.
                </p>
                <WhatHappensNext />
                <Help className='my-0' />
            </div>

            {/* Desktop */}
            <div className='hidden text-steel-100 lg:block pt-10'>
                <div className='flex flex-col items-center'>
                    <CallInfo
                        callDate={callDate}
                        callTime={callTime}
                        desktopView
                    />
                    <p className='text-md leading-120% my-8 text-center max-w-400'>
                        Your home may be repossessed if you do not keep up
                        repayments on a mortgage or any other debt secured on
                        it.
                    </p>
                </div>
                <div className='flex justify-center items-start space-x-20 pt-13'>
                    <div className='w-full max-w-400'>
                        <p className='text-xl font-bold mb-4'>
                            Your selected offer
                        </p>
                        <div className='w-full max-w-400'>
                            <OfferCard
                                offer={offer}
                                selected={false}
                                selectable={false}
                                showMoreInfo={showMoreInfo}
                                toggle={toggleMoreInfo}
                            />
                        </div>
                    </div>
                    <WhatHappensNext desktopView />
                </div>
            </div>
        </>
    );
};

export default ConfirmationView;
