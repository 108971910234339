import { ChangeEvent, FC, useCallback, useRef } from 'react';

import { isEqualStr } from '@Helpers';
import { ChevronDownIcon } from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    handleSubFormValidation,
} from '@Shared/helpers/input';
import { selfEmployed } from '@src/app/formModels/eligibility/incomings.selfEmployed';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import SelectBox from '../../components/SelectBox/SelectBox';

import IncomingSelfEmployedViewPartnership from './IncomingSelfEmployedView.Partnership';
import IncomingSelfEmployedViewLimitedCompany from './IncomingSelfEmployedView.limitedComany';
import IncomingSelfEmployedViewSoleTrader from './IncomingSelfEmployedView.soleTrader';

export interface IncomingSelfEmployedViewProps {
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    setRef?: Function;
}

const defaultValues = getDefaultValues(selfEmployed);
const validationSchema = getValidationSchema(selfEmployed);

const IncomingSelfEmployedView: FC<IncomingSelfEmployedViewProps> = (props) => {
    const subFormikRef = useRef<any>(null);

    const setSubFormRef = useCallback((ref: any) => {
        subFormikRef.current = ref;
    }, []);

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={{
                    ...defaultValues,
                    ...pick(props.incomeData, 'selfEmployedType'),
                }}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                    if (!subFormikRef.current) return;

                    const isValid = await handleSubFormValidation(
                        subFormikRef.current,
                    );
                    if (!isValid) {
                        return; // there was some error in the forms
                    }
                    const subFormValues = await subFormikRef.current
                        .submitForm()
                        .then((data: any) => data);

                    if (!subFormValues) {
                        return; // there was some unhandled error in the subForms
                    }

                    return {
                        ...pick(values, 'selfEmployedType'),
                        ...subFormValues,
                    };
                }}
                innerRef={(ref) => {
                    if (props.setRef) props.setRef(ref);
                }}
            >
                {(formikProps) => {
                    const { getError } = getFormHelpers(
                        selfEmployed,
                        formikProps,
                    );
                    const employmentType = formikProps.values.selfEmployedType;

                    return (
                        <>
                            <SelectBox
                                items={selfEmployed.selfEmployedType.items}
                                suffix={<ChevronDownIcon />}
                                label='How are you self-employed?'
                                name='selfEmployedType'
                                validState={
                                    !!formikProps.values.selfEmployedType
                                }
                                error={getError('selfEmployedType')}
                                value={
                                    formikProps.values.selfEmployedType || ' '
                                }
                                onChange={(
                                    e: ChangeEvent<HTMLSelectElement>,
                                ) => {
                                    formikProps.setFieldValue(
                                        'selfEmployedType',
                                        e.currentTarget.value,
                                    );
                                }}
                                onBlur={() => {
                                    return formikProps.setTouched({
                                        ...formikProps.touched,
                                        selfEmployedType: true,
                                    });
                                }}
                            />
                            {isEqualStr(employmentType, 'Limited company') && (
                                <IncomingSelfEmployedViewLimitedCompany
                                    setRef={setSubFormRef}
                                    incomeData={props.incomeData}
                                />
                            )}
                            {isEqualStr(employmentType, 'Sole trader') && (
                                <IncomingSelfEmployedViewSoleTrader
                                    setRef={setSubFormRef}
                                    incomeData={props.incomeData}
                                />
                            )}
                            {isEqualStr(employmentType, 'Partnership') && (
                                <IncomingSelfEmployedViewPartnership
                                    setRef={setSubFormRef}
                                    incomeData={props.incomeData}
                                />
                            )}
                            <ErrorContainer formikProps={formikProps} />
                        </>
                    );
                }}
            </Formik>
        </>
    );
};

export default IncomingSelfEmployedView;
