import { ChangeEvent } from 'react';

import { ChevronDownIcon, Controller } from '@selina-finance/ui';

import SelectBox from '@Components/SelectBox/SelectBox';
import { getFormHelpers } from '@Shared/helpers/input';

export interface IMoveOutControllerProps {
    index: number;
    notTouched: boolean;
    isPrimaryApplicant: boolean;
    addressItemName: string;
    formikProps: any;
    formItems: any;
}

export const MoveOutController = ({
    index,
    notTouched,
    isPrimaryApplicant,
    addressItemName,
    formikProps,
    formItems,
}: IMoveOutControllerProps) => {
    const { getError } = getFormHelpers(formItems, formikProps);

    return (
        <Controller
            className='mt-6'
            label={`${
                isPrimaryApplicant
                    ? 'When did you move out?'
                    : 'When did they move out?'
            }`}
        >
            <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                <SelectBox
                    items={formItems.month.items}
                    suffix={<ChevronDownIcon />}
                    placeholder='Month'
                    name='month'
                    label='Month'
                    labelSize='small'
                    validState={
                        !!formikProps.values.addresses[index].livedIn?.toMonth
                    }
                    value={
                        formikProps.values.addresses[
                            index
                        ].livedIn?.toMonth?.toString() || ''
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formikProps.setTouched({
                            ...formikProps.touched,
                            [`${addressItemName}.livedIn.to`]: true,
                        });
                        formikProps.setFieldValue(
                            `${addressItemName}.livedIn.toMonth`,
                            `${e.currentTarget.value}`,
                        );
                        formikProps.setFieldValue(
                            `${addressItemName}.livedIn.to`,
                            `${e.currentTarget.value}/${formikProps.values.addresses[index].livedIn?.toYear}`,
                        );
                    }}
                    onBlur={() => {
                        return formikProps.setTouched({
                            ...formikProps.touched,
                            [`${addressItemName}.livedIn.to`]: true,
                        });
                    }}
                />

                <SelectBox
                    items={formItems.year.items}
                    suffix={<ChevronDownIcon />}
                    placeholder='Year'
                    name='year'
                    label='Year'
                    labelSize='small'
                    validState={
                        !!formikProps.values.addresses[index].livedIn?.toYear
                    }
                    value={
                        formikProps.values.addresses[
                            index
                        ].livedIn?.toYear?.toString() || ''
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        formikProps.setTouched({
                            ...formikProps.touched,
                            [`${addressItemName}.livedIn.to`]: true,
                        });
                        formikProps.setFieldValue(
                            `${addressItemName}.livedIn.toYear`,
                            `${e.currentTarget.value}`,
                        );
                        formikProps.setFieldValue(
                            `${addressItemName}.livedIn.to`,
                            `${formikProps.values.addresses[index].livedIn?.toMonth}/${e.currentTarget.value}`,
                        );
                    }}
                    onBlur={() => {
                        return formikProps.setTouched({
                            ...formikProps.touched,
                            [`${addressItemName}.livedIn.to`]: true,
                        });
                    }}
                />
            </div>
            <div className='mt-1.5 text-md text-red-500'>
                {getError(`${addressItemName}.livedIn.to`, notTouched)}
            </div>
        </Controller>
    );
};
