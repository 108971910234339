import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import { Button, TextField } from '@selina-finance/ui';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { formItems } from '@FormModels/auth/resetPassword';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { FormError } from '@src/ui/components/FormError';
import EyeIcon from '@src/ui/components/Icons/EyeIcon';
import { Info } from '@src/ui/components/Info';
import { LinkButton } from '@src/ui/components/LinkButton';

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

export interface ResetPasswordViewProps {
    className?: string;
    email?: string;
    resetPassword?: Function;
    resendCode?: Function;
}

const ResetPasswordView: FC<ResetPasswordViewProps> = (props) => {
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [failedResend, setFailedResend] = useState('');
    const [successResend, setSuccessResend] = useState('');
    const [revealPassword, setRevealPassword] = useState(false);

    const navigate = useNavigate();
    const formikRef = useRef<any>(null);

    useEffect(() => {
        formikRef.current.values = {
            ...defaultValues,
            email: props.email,
        };
    }, [props.email]);

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialErrors={{ verificationCode: 'required' }}
                initialValues={{ ...defaultValues, email: props.email }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (props.resetPassword) {
                        setErrors({});
                        props
                            .resetPassword(
                                values.email,
                                values.verificationCode,
                                values.password,
                            )
                            .then((result: any) => {
                                if (result.success) {
                                    setSuccess(result.message);
                                    setTimeout(() => navigate('/login'), 3000);
                                } else {
                                    setErrors({ code: result.message });
                                }
                            });
                    }
                }}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getError, hasError } = getFormHelpers(
                        formItems,
                        formikProps,
                    );
                    return (
                        <Form autoComplete='off'>
                            <input
                                name='email'
                                type={'hidden'}
                                value={formikProps.values.email}
                            />
                            <TextField
                                id='verificationCode'
                                name='verificationCode'
                                labelSize='small'
                                label='Verification code'
                                value={
                                    formikProps.values.verificationCode?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('verificationCode')}
                            />
                            <div className='font-light text-sm mb-4'>
                                Can't see it?{' '}
                                <LinkButton
                                    onClick={() => {
                                        if (props.resendCode) {
                                            props
                                                .resendCode(
                                                    formikProps.values.email,
                                                )
                                                .then((result: any) => {
                                                    if (result.success) {
                                                        setSuccessResend(
                                                            result.message,
                                                        );
                                                        setTimeout(() => {
                                                            setSuccessResend(
                                                                '',
                                                            );
                                                        }, 3000);
                                                    } else {
                                                        setFailedResend(
                                                            result.message,
                                                        );
                                                    }
                                                });
                                        }
                                    }}
                                >
                                    <p className='text-sm'>Resend code</p>
                                </LinkButton>
                            </div>

                            {successResend && (
                                <Info type={'success'} className={'mb-6'}>
                                    {successResend}
                                </Info>
                            )}
                            {failedResend && (
                                <Info type={'error'} className={'mb-6'}>
                                    {failedResend}
                                </Info>
                            )}
                            <TextField
                                type={revealPassword ? 'text' : 'password'}
                                suffix={
                                    <div
                                        onClick={() =>
                                            setRevealPassword(!revealPassword)
                                        }
                                        className='flex items-center cursor-pointer'
                                    >
                                        <EyeIcon />
                                        <label className='ml-2 cursor-pointer'>
                                            {revealPassword ? 'Hide' : 'Show'}
                                        </label>
                                    </div>
                                }
                                id='password'
                                name='password'
                                labelSize='small'
                                label='New password'
                                value={
                                    formikProps.values.password?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('password')}
                            />
                            <br />
                            <TextField
                                type={revealPassword ? 'text' : 'password'}
                                suffix={
                                    <div
                                        onClick={() =>
                                            setRevealPassword(!revealPassword)
                                        }
                                        className='flex items-center cursor-pointer'
                                    >
                                        <EyeIcon />
                                        <label className='ml-2 cursor-pointer'>
                                            {revealPassword ? 'Hide' : 'Show'}
                                        </label>
                                    </div>
                                }
                                id='confirmPassword'
                                name='confirmPassword'
                                labelSize='small'
                                label='Confirm new password'
                                value={
                                    formikProps.values.confirmPassword.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('confirmPassword')}
                            />

                            <FormError
                                touched={{
                                    ...formikProps.touched,
                                    confirmPassword: true,
                                }}
                                otherErrors={errors}
                            />
                            {success && (
                                <Info type={'success'} className={'mb-6'}>
                                    {success}
                                </Info>
                            )}

                            <Button
                                type={'submit'}
                                className={'mb-4 mt-6 w-full'}
                                size='large'
                                color='secondary'
                                disabled={hasError()}
                            >
                                Submit
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ResetPasswordView;
