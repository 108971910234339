import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { isEqualStr } from '@Helpers';
import { Button, RadioButtonGroup } from '@selina-finance/ui';
import { Form, Formik } from 'formik';
import { pick } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@App/contexts/Application';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    handleSubFormValidation,
} from '@Shared/helpers/input';
import { formItems as incomingsFormItems } from '@src/app/formModels/eligibility/incomings';
import { employmentStatusOptions } from '@src/app/formModels/eligibility/incomings';
import { HttpError } from '@src/ui/components/HttpError';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';

import IncomingContractorView from './IncomingContractorView';
import IncomingEmployedView from './IncomingEmployedView';
import IncomingNotPaidView from './IncomingNotPaid';
import IncomingRetiredView from './IncomingRetiredView';
import IncomingSelfEmployedView from './IncomingSelfEmployedView';

export interface IncomingViewProps {
    formRef: any;
    saveRef: any;
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    setIncomeData: Function;
    isPrimaryApplicant: boolean;
    setActiveApplicant: Function;
    applicantCount: number;
    saveIncomings?: (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => Promise<any>;
}

const defaultValues = getDefaultValues(incomingsFormItems);
const validationSchema = getValidationSchema(incomingsFormItems);

const IncomingView = forwardRef((props: IncomingViewProps) => {
    const formikRef = useRef<any>(null);
    const subFormikRef = useRef<any>(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setCurrentForm } = useContext(ApplicationContext);

    const setSubFormRef = useCallback((ref: any) => {
        subFormikRef.current = ref;
    }, []);

    useEffect(() => {
        setCurrentForm(undefined);
    }, [setCurrentForm]);

    useEffect(() => {
        if (formikRef.current) {
            setCurrentForm(formikRef.current);
        }
    }, [formikRef, setCurrentForm]);

    const validateAndSubmit = async () => {
        setError(null);

        const values = formikRef.current.values;
        const isValid = await handleSubFormValidation(formikRef.current);
        const isSubFormValid = await handleSubFormValidation(
            subFormikRef.current || null,
        );
        if (!isSubFormValid || !isValid) {
            return false; // there was some error in the forms
        }

        const subFormValues = await subFormikRef.current
            .submitForm()
            .then((data: any) => data);
        if (!subFormValues) {
            return false; // there was some unhandled error in the subForms
        }

        const finalValues = {
            ...pick(values, 'employmentStatus'),
            ...subFormValues,
        };

        props.saveIncomings &&
            (await props
                .saveIncomings(
                    finalValues,
                    props.isPrimaryApplicant,
                    props.applicantCount,
                )
                .then((data: any) => {
                    const redirectTo =
                        values.redirectTo || '/eligibility/outgoings';
                    if (data.success && !data.hasCoApplicant) {
                        navigate(redirectTo);
                        return true;
                    }
                    if (!data.success) {
                        setError(data.error);
                        return false;
                    }
                }));

        return false;
    };

    const saveForLater = async () => {
        const values = formikRef.current.values;
        props.saveIncomings &&
            (await props
                .saveIncomings(
                    values,
                    props.isPrimaryApplicant,
                    props.applicantCount,
                )
                .then((data: any) => {
                    if (data.success && !data.hasCoApplicant) {
                        return true;
                    }
                    if (!data.success) {
                        setError(data.error);
                        return false;
                    }
                }));
    };

    useImperativeHandle(props.formRef, () => {
        return { validateAndSubmit };
    });

    useImperativeHandle(props.saveRef, () => ({
        saveMethod: saveForLater,
    }));

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{
                    ...defaultValues,
                    ...pick(props.incomeData, Object.keys(incomingsFormItems)),
                }}
                validationSchema={validationSchema}
                onSubmit={validateAndSubmit}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getError } = getFormHelpers(
                        incomingsFormItems,
                        formikProps,
                    );

                    const employmentStatus =
                        formikProps.values.employmentStatus;
                    return (
                        <Form>
                            <div className='grid grid-cols-1 mt-6 mb-6 gap-10'>
                                <RadioButtonGroup
                                    name='employmentStatus'
                                    id='employmentStatus'
                                    value={
                                        formikProps.values.employmentStatus?.toString() ||
                                        ''
                                    }
                                    className='flex-col gap-2'
                                    error={getError('employmentStatus')}
                                    onChange={(e) => {
                                        if (
                                            e.currentTarget.value !==
                                            formikProps?.values
                                                ?.employmentStatus
                                        ) {
                                            props.setIncomeData({
                                                employmentStatus:
                                                    e.currentTarget.value,
                                            });
                                            formikProps.setFieldValue(
                                                'employmentStatus',
                                                e.currentTarget.value,
                                            );
                                        }
                                    }}
                                    options={employmentStatusOptions}
                                />
                                {isEqualStr(employmentStatus, 'employed') && (
                                    <IncomingEmployedView
                                        incomeData={props.incomeData}
                                        secondary={props.secondary}
                                        setRef={setSubFormRef}
                                        isPrimaryApplicant={
                                            props.isPrimaryApplicant
                                        }
                                    />
                                )}
                                {isEqualStr(
                                    employmentStatus,
                                    'self-employed',
                                ) && (
                                    <IncomingSelfEmployedView
                                        incomeData={props.incomeData}
                                        secondary={props.secondary}
                                        setRef={setSubFormRef}
                                    />
                                )}
                                {isEqualStr(employmentStatus, 'contractor') && (
                                    <IncomingContractorView
                                        incomeData={props.incomeData}
                                        secondary={props.secondary}
                                        setRef={setSubFormRef}
                                    />
                                )}
                                {isEqualStr(employmentStatus, 'retired') && (
                                    <IncomingRetiredView
                                        incomeData={props.incomeData}
                                        secondary={props.secondary}
                                        setRef={setSubFormRef}
                                    />
                                )}
                                {isEqualStr(
                                    employmentStatus,
                                    'Not in paid employment',
                                ) && (
                                    <IncomingNotPaidView
                                        incomeData={props.incomeData}
                                        secondary={props.secondary}
                                        setRef={setSubFormRef}
                                    />
                                )}
                            </div>
                            {error && <HttpError error={error} />}
                            <ErrorContainer formikProps={formikProps} />

                            <div className='flex gap-5 justify-end mt-12'>
                                <Button
                                    type='submit'
                                    size='large'
                                    color='secondary'
                                    className='w-full'
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

export default IncomingView;
