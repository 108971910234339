import { useContext, useRef } from 'react';

import { ApplicationContext } from '@App/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import YourPropertyView from '../views/eligibility/YourPropertyView';

const YourProperty = () => {
    const { loading, propertyDetails, savePropertyDetails } =
        useContext(ApplicationContext);

    const saveRef = useRef(null);

    return (
        <EligibilityLayout loading={loading} ref={saveRef}>
            <YourPropertyView
                ref={saveRef}
                propertyData={propertyDetails}
                savePropertyDetails={savePropertyDetails}
            />
        </EligibilityLayout>
    );
};

export default YourProperty;
