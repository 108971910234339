import { createContext, useCallback, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { useSentryLogger } from '@App/hooks/log';
import { intercomShutdown } from '@App/intercom/intercom';
import { preSignup } from '@App/requests/preSignup';
import { containsAnySubStr } from '@src/shared/helpers/string';

import { updateClientId } from '../requests/updateClientId';

type AuthContextProps = {
    loading: boolean;
    user: any;
    loggedIn: boolean;
    isToFetchApplicationData: boolean | null;
    logIn: Function;
    logOut: Function;
    register: Function;
    confirmRegistration: Function;
    forgotPassword: Function;
    resetPassword: Function;
    resendRegistrationCode: Function;
    isLoggedIn: Function;
    updateCurrentUser: Function;
    getAccessToken: Function;
    updateFetchApplicationDataFlag: Function;
    getIdToken: Function;
};

const INCORRECT_PASSWORD: string = 'incorrect username or password.';
const INCORRECT_EMAIL: string = "preauthentication failed with error 'email'.";
const USER_NOT_FOUND = 'usernotfoundexception';

export const AuthContext = createContext<AuthContextProps>({
    loading: true,
    user: null,
    loggedIn: false,
    isToFetchApplicationData: null,
    logIn: () => {},
    logOut: () => {},
    updateCurrentUser: () => {},
    register: () => {},
    confirmRegistration: () => {},
    forgotPassword: () => {},
    resetPassword: () => {},
    resendRegistrationCode: () => {},
    isLoggedIn: () => {},
    getAccessToken: () => {},
    updateFetchApplicationDataFlag: () => {},
    getIdToken: () => {},
});

const authError = (
    error: any,
    customMessage?: string,
    applicationId?: string,
) => ({
    success: false,
    code: error?.code,
    message: customMessage || error?.message,
    applicationId: applicationId,
});

const authSuccess = (customMessage?: string, applicationId?: string) => ({
    success: true,
    code: '',
    message: customMessage || '',
    applicationId: applicationId,
});

const AuthProvider = (props: { children: any }) => {
    const [user, setUser] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [isToFetchApplicationData, setIsToFetchApplicationData] = useState<
        boolean | null
    >(null);

    const { sentryError, flushLogs } = useSentryLogger(
        'Authentication',
        'Auth context',
    );

    const handleError = useCallback(
        (error: any, section?: string, applicationId?: string) => {
            setLoading(false);

            const message = error?.message?.toLowerCase() || '';

            const isIncorrectCredentials = containsAnySubStr(message, [
                INCORRECT_PASSWORD,
                INCORRECT_EMAIL,
                USER_NOT_FOUND,
            ]);

            if (isIncorrectCredentials) {
                return authError(
                    error,
                    "Sorry, we didn't recognise you. Please check your login details and try again.",
                    applicationId,
                );
            }

            if (error?.code === 'CodeMismatchException') {
                return authError(error, error.message, applicationId);
            }

            sentryError(`error on ${section}`, error);
            flushLogs();

            return authError(
                error,
                "That's embarrassing. Looks like we've had a technical issue.\nPlease try again.",
                applicationId,
            );
        },
        [flushLogs, sentryError],
    );

    const logIn = useCallback(
        (email: string, password: string, applicationId: string) => {
            setLoading(true);
            email = email.toLowerCase();
            return Auth.signIn(email, password)
                .then(async (user) => {
                    localStorage.setItem('user.email', email);
                    localStorage.setItem('user.applicationId', applicationId);
                    setUser(user.attributes);
                    if (applicationId) {
                        await updatesClientId(applicationId);
                    }

                    setLoading(false);
                    setIsToFetchApplicationData(true);
                    return authSuccess('Successful login');
                })
                .catch((error) => handleError(error, 'Login'));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleError],
    );

    const logOut = useCallback(async () => {
        setLoading(true);
        try {
            localStorage.removeItem('user.email');
            setIsToFetchApplicationData(false);
            intercomShutdown();
            await Auth.signOut({ global: true });
        } catch (error) {
            setLoading(false);
            return authError(error);
        } finally {
            setLoading(false);
            setUser(null);
        }
    }, []);

    const updatesClientId = async (id: string) => {
        try {
            await updateClientId(
                await getAccessToken(),
                await getIdToken(),
                id,
            );
        } catch (e) {
            return handleError(e, 'update client ID');
        }
    };

    const register = useCallback(
        async (email: string, password: string, applicationId: string) => {
            setLoading(true);
            let response;
            email = email.toLowerCase();
            try {
                response = await preSignup(applicationId, email);
                await Auth.signUp({
                    username: email,
                    password: password,
                    attributes: {
                        email: email,
                        'custom:isD2CUser': 'true',
                    },
                });

                localStorage.setItem('user.email', email);
                localStorage.setItem('user.applicationId', applicationId);
                return authSuccess(
                    'Registration was successful.',
                    response.applicationId,
                );
            } catch (e) {
                return handleError(e, 'Registration', response?.applicationId);
            } finally {
                setLoading(false);
            }
        },
        [handleError],
    );

    const confirmRegistration = useCallback(
        (email: string, code: string) => {
            setLoading(true);
            return Auth.confirmSignUp(email.toLowerCase(), code)
                .then(() => {
                    setLoading(false);
                    return authSuccess('Thank you for validation your email.');
                })
                .catch((error) => handleError(error, 'Verify email'));
        },
        [handleError],
    );

    const forgotPassword = useCallback(
        (email: string) => {
            setLoading(true);
            return Auth.forgotPassword(email.toLowerCase())
                .then(() => {
                    setLoading(false);
                    return authSuccess(
                        'We have sent you an email with the verification code for resetting your email.',
                    );
                })
                .catch((error) => handleError(error, 'Forgot password'));
        },
        [handleError],
    );

    const resetPassword = useCallback(
        (email: string, code: string, password: string) => {
            setLoading(true);
            return Auth.forgotPasswordSubmit(
                email.toLowerCase(),
                code,
                password,
            )
                .then(() => {
                    setLoading(false);
                    return authSuccess(
                        'Your new password was successfully set.',
                    );
                })
                .catch((error) => handleError(error, 'Reset password'));
        },
        [handleError],
    );

    const resendRegistrationCode = useCallback(
        (email: string) => {
            setLoading(true);
            return Auth.resendSignUp(email.toLowerCase())
                .then(() => {
                    setLoading(false);
                    return authSuccess(
                        'Verification code was sent to your email.',
                    );
                })
                .catch((error) =>
                    handleError(error, 'Resend registration code'),
                );
        },
        [handleError],
    );

    const isLoggedIn = useCallback(() => {
        setLoading(true);
        const hasEmail = !!localStorage.getItem('user.email');
        return Auth.currentSession()
            .then((session: CognitoUserSession) => {
                setLoading(false);
                return session.isValid() && hasEmail;
            })
            .catch((_: any) => {
                setLoading(false);
                return false;
            });
    }, []);

    const updateCurrentUser = useCallback(() => {
        return Auth.currentAuthenticatedUser()
            .then((currentUser: any) => {
                setUser(currentUser.attributes);
                return authSuccess();
            })
            .catch((error) => {
                setUser(null);
                return authError(error);
            });
    }, []);

    const getAccessToken = useCallback(async () => {
        try {
            let session: CognitoUserSession = await Auth.currentSession();

            if (!session.isValid() || !session.getAccessToken().getJwtToken()) {
                session = await refreshSession();
            }

            const accessToken = session.getAccessToken().getJwtToken();
            if (accessToken) {
                return accessToken;
            }

            setLoading(false);
            return authError('Invalid session or accessToken token is null.');
        } catch (error) {
            setLoading(false);
            return authError(error);
        }
    }, []);

    const getIdToken = useCallback(async () => {
        try {
            let session: CognitoUserSession = await Auth.currentSession();

            if (!session.isValid() || !session.getIdToken().getJwtToken()) {
                session = await refreshSession();
            }

            const idToken = session.getIdToken().getJwtToken();
            if (idToken) {
                return idToken;
            }

            setLoading(false);
            return authError('Invalid session or ID token is null.');
        } catch (error) {
            setLoading(false);
            return authError(error);
        }
    }, []);

    const refreshSession = async (): Promise<CognitoUserSession> => {
        const currentUser = await Auth.currentAuthenticatedUser();
        return new Promise((resolve, reject) => {
            currentUser.refreshSession(
                currentUser.getSignInUserSession().getRefreshToken(),
                (err: any, session: CognitoUserSession) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                },
            );
        });
    };

    const updateFetchApplicationDataFlag = useCallback(async () => {
        const response = await isLoggedIn();
        setIsToFetchApplicationData(!!response);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthContext.Provider
            value={{
                loading,
                user,
                loggedIn: !!user,
                updateCurrentUser,
                logIn,
                logOut,
                register,
                confirmRegistration,
                forgotPassword,
                resetPassword,
                resendRegistrationCode,
                isLoggedIn,
                getAccessToken,
                isToFetchApplicationData,
                updateFetchApplicationDataFlag,
                getIdToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
