import * as Yup from 'yup';

import {
    CustomFormProps,
    passwordErrorMessage,
    passwordRegex,
} from '@FormModels/shared';

export const formItems: CustomFormProps = {
    email: {
        label: 'Email address',
        type: 'email',
        validation: Yup.string()
            .required('The email field is required.')
            .email('The email is not valid.'),
    },
    verificationCode: {
        label: 'Verification code',
        validation: Yup.string().required('Verification code required.'),
    },
    password: {
        label: 'New password',
        validation: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(passwordRegex, passwordErrorMessage),
    },
    confirmPassword: {
        label: 'Confirm new password',
        validation: Yup.string()
            .required('Please enter matching passwords')
            .oneOf(
                [Yup.ref('password'), null],
                'Please enter matching passwords',
            ),
    },
};
