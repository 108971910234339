import { ChangeEvent } from 'react';

import { Controller, RadioButtonGroup, TextField } from '@selina-finance/ui';

import { radioButtonOptions } from '@src/shared/helpers/input';

interface IPropertyType {
    formikProps: any;
    getError: (fieldName: string) => any;
}

export enum BasePropertyType {
    HOUSE = 'house',
    FLAT = 'flat',
    BUNGALOW = 'bungalow',
}

export enum FlatType {
    PURPOSE_BUILT = 'Purpose-built flat or maisonette',
    CONVERTED = 'Flat or maisonette in converted house',
}

const PropertyType = (props: IPropertyType) => {
    const getOptions = (baseType: BasePropertyType) => {
        const flatTypeOptions = [
            {
                label: 'Purpose-built flat',
                value: FlatType.PURPOSE_BUILT,
            },
            {
                label: 'Flat in a converted house',
                value: FlatType.CONVERTED,
            },
        ];

        const otherTypesOptions = [
            {
                label: 'Detached',
                value: `Detached ${baseType}`,
            },
            {
                label: 'Semi-detached',
                value: `Semi-detached ${baseType}`,
            },
            {
                label: 'Terraced',
                value: `Terraced ${baseType}`,
            },
        ];
        return baseType === BasePropertyType.FLAT
            ? flatTypeOptions
            : otherTypesOptions;
    };

    return (
        <>
            <Controller label='What type of property is it?'>
                <RadioButtonGroup
                    className='flex-col gap-2'
                    name='propertyTypeBase'
                    id='propertyTypeBase'
                    value={props.formikProps.values.propertyTypeBase}
                    error={{
                        hasError: false,
                        errorMessage: props.getError('propertyTypeBase'),
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        props.formikProps.setTouched({
                            ...props.formikProps.touched,
                            propertyTypeBase: true,
                        });
                        props.formikProps.setFieldValue(
                            'propertyTypeBase',
                            e.currentTarget.value,
                        );
                    }}
                    options={[
                        {
                            label: 'House',
                            value: BasePropertyType.HOUSE,
                        },
                        {
                            label: 'Flat',
                            value: BasePropertyType.FLAT,
                        },
                        {
                            label: 'Bungalow',
                            value: BasePropertyType.BUNGALOW,
                        },
                    ]}
                />
            </Controller>
            {props.formikProps.values.propertyTypeBase && (
                <Controller
                    label={`What type of ${props.formikProps.values.propertyTypeBase} is it?`}
                >
                    <RadioButtonGroup
                        className='flex-col gap-2'
                        name='propertyType'
                        id='propertyType'
                        value={props.formikProps.values.propertyType?.toString()}
                        error={{
                            hasError: false,
                            errorMessage: props.getError('propertyType'),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.formikProps.setTouched({
                                ...props.formikProps.touched,
                                propertyType: true,
                            });
                            props.formikProps.handleChange(e);
                        }}
                        options={getOptions(
                            props.formikProps.values.propertyTypeBase,
                        )}
                    />
                </Controller>
            )}

            {props.formikProps.values.propertyTypeBase ===
                BasePropertyType.FLAT && (
                <Controller label='What floor is it on?'>
                    <RadioButtonGroup
                        className='flex-col gap-2'
                        name='floorOfFlat'
                        id='floorOfFlat'
                        value={props.formikProps.values.floorOfFlat?.toString()}
                        error={{
                            hasError: false,
                            errorMessage: props.getError('floorOfFlat'),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.formikProps.setTouched({
                                ...props.formikProps.touched,
                                floorOfFlat: true,
                            });
                            props.formikProps.handleChange(e);
                        }}
                        options={[
                            {
                                label: '3rd floor or lower',
                                value: '3',
                            },
                            {
                                label: '4th floor or higher',
                                value: '4',
                            },
                        ]}
                    />
                </Controller>
            )}
            {props.formikProps.values.floorOfFlat === '4' && (
                <Controller label='Is it serviced by a lift?'>
                    <RadioButtonGroup
                        name='is4thFloorOrHigherWithoutLift'
                        id='is4thFloorOrHigherWithoutLift'
                        value={props.formikProps.values.is4thFloorOrHigherWithoutLift?.toString()}
                        error={{
                            hasError: false,
                            errorMessage: props.getError(
                                'is4thFloorOrHigherWithoutLift',
                            ),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            props.formikProps.setTouched({
                                ...props.formikProps.touched,
                                is4thFloorOrHigherWithoutLift: true,
                            });
                            props.formikProps.handleChange(e);
                        }}
                        options={radioButtonOptions}
                    />
                </Controller>
            )}
            <TextField
                name='numberOfBedrooms'
                mask='number'
                label='How many bedrooms does it have?'
                maxLength={3}
                value={props.formikProps.values.numberOfBedrooms?.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    props.formikProps.handleChange(e);
                }}
                onBlur={props.formikProps.handleBlur}
                error={props.getError('numberOfBedrooms')}
            />
        </>
    );
};

export default PropertyType;
