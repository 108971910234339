import { useContext, useRef } from 'react';

import { ApplicationContext } from '@App/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import OutgoingsView from '../views/eligibility/OutgoingsView';

const Outgoings = () => {
    const { applicants, loading, outgoings, saveOutgoings } =
        useContext(ApplicationContext);

    const saveRef = useRef(null);

    return (
        <EligibilityLayout loading={loading} ref={saveRef}>
            <OutgoingsView
                ref={saveRef}
                outgoingsData={outgoings}
                coApplicantName={applicants?.[1]?.firstName}
                saveOutgoings={saveOutgoings}
            />
        </EligibilityLayout>
    );
};

export default Outgoings;
