import * as Yup from 'yup';

import { CustomFormProps } from '@FormModels/shared';

export const formItems: CustomFormProps = {
    email: {
        label: 'Email:',
        type: 'email',
        validation: Yup.string()
            .required('The email field is required')
            .email('The email is not valid'),
    },
};
