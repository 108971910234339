import { MouseEventHandler, useEffect } from 'react';

import clsx from 'clsx';

import { containsAnySubStr } from '@Shared/helpers/string';

const className = {
    modalOverlay:
        'bg-opacity-40 bg-black fixed left-0 right-0 top-0 bottom-0 w-screen h-screen z-10 backdrop-blur-md backdrop-filter',
    modalScroll:
        'overflow-auto absolute left-0 top-0 right-0 bottom-0 h-full w-full',
    modal: 'w-full overflow-visible absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden sm:block',
    mobileModal:
        'w-full overflow-visible fixed bottom-0 left-0 right-0 h-[75vh] max-h-screen md:max-w-60 sm:w-full md:min-w-600 sm:p-4 rounded-t-3xl bg-white block sm:hidden',
    content: 'w-full relative shadow-xl bg-white rounded-2xl',
    mobileContent: 'rounded-t-3xl',
    subContent: 'p-6 xs:px-24',
    mobileSubContent: 'px-6 pb-48 pt-3',
    rectangle: 'w-12 h-1.5 bg-mono-30 rounded-full mx-auto mt-2',
};

export interface ModalLayoutProps {
    contentClassName?: string;
    open: boolean;
    onClose: () => void;
    children?: any;
    title?: string;
}

const ModalLayout = (props: ModalLayoutProps) => {
    const modalOverlayClassName = clsx(
        className.modalOverlay,
        props.open ? 'block animate-fadeIn' : 'hidden animate-fadeOut',
    );

    const mobileModalClassName = clsx(
        className.mobileModal,
        props.open ? 'animate-slideInFromBottom' : 'animate-slideOutToBottom',
    );

    useEffect(() => {
        const keyDownListener = (event: KeyboardEvent) => {
            if (containsAnySubStr(event.key, ['Escape', 'Esc'])) {
                props.onClose();
            }
        };
        document.addEventListener('keydown', keyDownListener);
        return () => document.removeEventListener('keydown', keyDownListener);
    }, [props]);

    useEffect(() => {
        document.body.style.overflow = props.open ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [props.open]);

    const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
        const modal = document.getElementById('modal');
        if (e.target === modal) {
            return props.onClose();
        }
    };

    return (
        <div className={modalOverlayClassName}>
            <div
                className={className.modalScroll}
                id='modal'
                role='dialog'
                aria-modal='true'
                onClick={onClick}
            >
                <div className={className.modal}>
                    <div
                        className={clsx(
                            props.contentClassName,
                            className.content,
                        )}
                    >
                        {props.title && (
                            <h1 className='text-steel-80 text-3xl bg-neutral-40 rounded-t-2xl font-extrabold mx-auto text-center py-2 border-b-1 border-neutral-100'>
                                {props.title}
                            </h1>
                        )}
                        <div className={clsx(className.subContent)}>
                            {props.children}
                        </div>
                    </div>
                </div>
                <div className={mobileModalClassName}>
                    <div
                        className={clsx(
                            props.contentClassName,
                            className.mobileContent,
                        )}
                    >
                        <div className={className.rectangle}></div>
                        {props.title && (
                            <h1 className='text-steel-80 text-3xl rounded-t-xl font-extrabold mx-auto pt-6 pl-6'>
                                {props.title}
                            </h1>
                        )}
                        <div className={clsx(className.mobileSubContent)}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLayout;
