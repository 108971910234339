export const isValidPostCode = (postcode: string) => {
    const finalPostcode = postcode.replace(/\s/g, '').replace(/-/g, '');
    const regex = /^[A-Z]{1,2}[0-9|A-Z]{1,2} ?[0-9][A-Z]{2}$/i;
    return regex.test(finalPostcode);
};

export const normalizePostCode = (value: any) => {
    let finalValue = (value + '')
        .replace(/\s/g, '')
        .replace(/,/g, '')
        .replace(/-/g, '');
    if (finalValue.length > 3) {
        finalValue = [
            finalValue.slice(0, finalValue.length - 3),
            ' ',
            finalValue.slice(-3),
        ].join('');
    }
    return finalValue;
};
