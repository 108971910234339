import { MaskedRange } from 'imask';
import { IMaskInput } from 'react-imask';

const getMaskProps = (name: string) => {
    if (name === 'number') {
        return {
            mask: Number,
            scale: 4, // digits after point, 0 for integers
            signed: false, // disallow negative
            thousandsSeparator: ',', // any single char
            padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
            normalizeZeros: true, // appends or removes zeros at ends
            radix: '.', // fractional delimiter
            mapToRadix: ['.'], // symbols to process as radix
            // additional number interval options (e.g.)
            min: 0,
            max: 999999999,
        };
    }

    if (name === 'percentage') {
        return {
            mask: 'num %',
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    scale: 2,
                    min: 0,
                    max: 100,
                    radix: '.',
                    mapToRadix: [','],
                },
            },
        };
    }

    if (name === 'date') {
        return {
            mask: 'd/m/Y',
            lazy: true,
            overwrite: true,
            autofix: true,
            blocks: {
                d: {
                    mask: MaskedRange,
                    placeholderChar: 'd',
                    from: 1,
                    to: 31,
                    maxLength: 2,
                },
                m: {
                    mask: MaskedRange,
                    placeholderChar: 'm',
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
                Y: {
                    mask: MaskedRange,
                    placeholderChar: 'y',
                    from: 1900,
                    to: 2999,
                    maxLength: 4,
                },
            },
        };
    }

    if (name === 'month-year') {
        return {
            mask: 'm/Y',
            lazy: true,
            overwrite: true,
            autofix: true,
            blocks: {
                m: {
                    mask: MaskedRange,
                    placeholderChar: 'm',
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
                Y: {
                    mask: MaskedRange,
                    placeholderChar: 'y',
                    from: 1900,
                    to: 2999,
                    maxLength: 4,
                },
            },
        };
    }

    if (name === 'year') {
        return {
            mask: Number,
            scale: 0,
            min: 1900,
            max: 2999,
        };
    }

    if (name === 'phone') {
        return {
            mask: '0000 000 000',
        };
    }
};

const MaskedInput = (props: any) => {
    const { onChange, maskProps, ...other } = props;

    const mergedMaskProps = { ...getMaskProps(props?.mask), ...maskProps };

    let placeholder = '';
    if (props?.mask === 'date') {
        placeholder = 'DD/MM/YYYY';
    }

    if (props?.mask === 'year') {
        placeholder = 'YYYY';
    }

    if (props?.mask === 'month-year') {
        placeholder = 'MM/YYYY';
    }

    return (
        <IMaskInput
            {...other}
            {...mergedMaskProps}
            onAccept={(value: any) =>
                onChange
                    ? onChange({ currentTarget: { name: props.name, value } })
                    : null
            }
            overwrite
            placeholder={props.placeholder || placeholder}
        />
    );
};

export default MaskedInput;
