import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';

import { Size } from '@src/ui/components/shared';

const className = {
    // base
    paper: 'w-full p-6 rounded-lg',
    color_sand: 'bg-sand',
    color_primary: '',
    color_ghost: 'bg-ghost border-gray-500 border',
    color_gray: 'bg-gray-100',
    raised: 'shadow-lg',
    bordered: 'border border-gray-600',
    dashed: 'border border-gray-600 border-dashed',

    small: 'text-md p-5 ',
    medium: 'text-lg p-6 ',
    large: 'text-xl p-7',

    button: 'active:shadow-none cursor-pointer',
    disabled: 'opacity-40',
};

export type PaperColor = 'sand' | 'primary' | 'ghost' | 'gray';

export interface PaperProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    color?: PaperColor;
    size?: Size;
    raised?: boolean;
    disabled?: boolean;
    bordered?: boolean;
    dashed?: boolean;
}

const Paper: FC<PaperProps> = (props) => {
    const size: Size = props.size || 'medium';
    const color: PaperColor = props.color || 'primary';
    const isButton = Boolean(props.onClick);
    const raised =
        isButton && props.raised === false ? false : isButton || props.raised;
    const bordered =
        isButton && props.bordered === false
            ? false
            : isButton || props.bordered;

    const classNames = clsx([
        props.className,
        className.paper,
        className[size],
        className[`color_${color}`],
        raised && !props.disabled && className.raised,
        props.onClick && !props.disabled && className.button,
        props.disabled && className.disabled,
        bordered && className.bordered,
        props.dashed && className.dashed,
    ]);

    return (
        <div
            className={classNames}
            {...omit(props, [
                'className',
                'size',
                'color',
                'raised',
                'dashed',
                'bordered',
            ])}
            onClick={(e) =>
                !props.disabled && props.onClick && props.onClick(e)
            }
        >
            {props.children}
        </div>
    );
};

export default Paper;
