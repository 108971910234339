import { ReactNode } from 'react';

import clsx from 'clsx';

import { Label } from '@src/ui/components/Label';

import { Size } from '../shared';

const className = {
    // base
    base: 'flex flex-col border-base_light',

    wrapper:
        'flex flex-row justify-center align-middle content-center items-center rounded-lg border border-base_light p-0.5',
    wrapper_noBorder: 'grid grid-cols-1 gap-2 rounded-lg',

    inner_wrapper:
        'flex justify-center align-middle content-center items-center flex-1 rounded-lg',
    input: 'outline-none w-full rounded-lg',

    // actions
    focus: 'focus-within:ring-4 focus-within:ring-gray-200 focus-within:border-success border',
    hover: 'hover:shadow-md',
    border_error: 'border-danger focus-within:border-danger',
    text_error: 'text-danger',

    wrapper_small: 'h-13',
    wrapper_medium: 'h-15',
    wrapper_large: 'h-16',

    // extras
    start_adornment: 'pl-4 pr-0.5',
    end_adornment: 'pr-4 pl-0.5',

    // input
    input_small: 'text-sm px-2.5 py-2',
    input_medium: 'text-lg px-3.5 py-3 ',
    input_large: 'text-lg px-4 py-3.5 ',

    // info
    info: 'pl-0 pt-1 pb-1',
    info_at_top: 'pl-0 pt-0 pb-1',
    info_small: 'text-xs px-2.5 py-2 ',
    info_medium: 'text-sm px-3.5 py-3 ',
    info_large: 'text-md px-4 py-3.5',

    // error
    error_text: 'pl-0 pb-0 text-danger',
    error_small: 'text-xs px-2.5 py-2 ',
    error_medium: 'text-sm px-3.5 py-3 ',
    error_large: 'text-md px-4 py-3.5',
};

export type InputVariant = 'outlined';
export type InputColor = 'base';
export type InputType =
    | 'email'
    | 'file'
    | 'hidden'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'url';

export interface ControlProps {
    size?: Size;
    label?: string;
    info?: string;
    error?: string;
    infoAtTop?: boolean;

    className?: string;
    labelClassName?: string;
    wrapperClassName?: string;
    errorClassName?: string;
    innerWrapperClassName?: string;
    inputClassName?: string;
    infoClassName?: string;

    startElement?: string | ReactNode;
    endElement?: string | ReactNode;
    startAdornment?: string | ReactNode;
    endAdornment?: string | ReactNode;

    noBorder?: boolean;
    children?: any;
}

const Control = (props: ControlProps) => {
    const size: Size = props.size || 'medium';
    const infoAtTop: boolean = props.infoAtTop || true;

    const baseClassName = clsx([
        props.className,
        props.noBorder ? '' : className.base,
        props.error ? 'invalid' : '',
    ]);

    const labelClassName = clsx([
        props.labelClassName,
        infoAtTop ? 'pb-3' : 'pb-3',
    ]);

    const infoClassName = clsx([
        props.infoClassName,
        className[`info_${size}`],
        className[props.infoAtTop ? 'info_at_top' : 'info'],
    ]);

    const wrapperClassName = clsx([
        props.wrapperClassName,
        props.noBorder ? '' : className.focus,
        props.noBorder ? '' : className.hover,
        props.noBorder ? className.wrapper_noBorder : className.wrapper,
        props.noBorder ? '' : className[`wrapper_${size}`],
    ]);

    const errorClassName = clsx([
        props.errorClassName,
        className['error_text'],
        className[`error_${size}`],
        props.infoAtTop ? 'pt-1' : 'pt-0 -mt-1',
        props.info ? '' : 'pt-1',
    ]);

    return (
        <div className={baseClassName}>
            <Label
                className={labelClassName}
                size={props.size}
                color={props.error ? 'error' : 'base'}
                dangerouslySetInnerHTML={{ __html: props.label || '' }}
            />
            {props.infoAtTop && props.info && (
                <div className={infoClassName}>{props.info}</div>
            )}

            <div className={wrapperClassName}>{props.children}</div>

            {!props.infoAtTop && props.info && (
                <div className={infoClassName}>{props.info}</div>
            )}
            {props.error ? (
                <div className={errorClassName}>{props.error}</div>
            ) : (
                <div className={errorClassName}>&nbsp;</div>
            )}
        </div>
    );
};

export default Control;
