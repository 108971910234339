import { SVGProps } from 'react';

const Logos = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='122'
            height='123'
            viewBox='0 0 122 123'
            {...props}
            fill='none'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M96.1576 28.9929L39.7975 0C34.5903 1.10895 29.5944 3.04529 25 5.73536L97.7382 33.0122C97.2766 31.6574 96.7497 30.3176 96.1576 28.9929ZM75.3192 8C76.7493 8.10537 78.1342 8.2258 79.6396 8.4516C86.6222 9.54259 93.2937 12.1034 99.2129 15.9648C105.132 19.8262 110.164 24.9001 113.976 30.8511L75.3192 8ZM5.86015 40.9369L102.759 53.1603C102.783 53.3911 102.809 53.6248 102.836 53.8601L102.836 53.8603L102.836 53.8605L102.836 53.8606L102.836 53.8612V53.8612V53.8613C102.895 54.3777 102.954 54.9015 102.985 55.4183L3 54.5302C3.10537 53.1603 3.21075 51.7905 3.4215 50.4055C3.93152 47.179 4.74811 44.0084 5.86015 40.9369ZM25.7633 77.5016C23.966 73.2119 22.7753 68.6925 22.2257 64.074L122 59.0913C121.925 59.8741 121.925 60.6117 121.925 61.3794L25.7633 77.5016ZM63.8934 107.714L118.432 76.794C118.868 75.7033 119.256 74.6029 119.649 73.4848C119.745 73.2141 119.84 72.9423 119.937 72.6693L48.8249 102.686C53.5455 105.159 58.6334 106.857 63.8934 107.714ZM91.6066 75.7247L10.6643 104.522C6.95338 101.44 3.6994 97.8466 1 93.849L92.0583 73V73.5419C91.9767 74.0518 91.8675 74.5409 91.7587 75.028L91.7587 75.0282C91.7069 75.2601 91.6552 75.4916 91.6066 75.7247ZM120.705 46.5971L38.152 21.0062C34.6237 24.2453 31.5816 27.977 29.1199 32.0856L121.232 49.2766C121.196 49.0813 121.16 48.8853 121.124 48.6889L121.124 48.6876C120.997 47.9911 120.869 47.2895 120.705 46.5971ZM60 122.95L96.9412 99C93.0123 105.54 87.6423 111.097 81.2406 115.247C74.8388 119.398 67.5741 122.032 60 122.95Z'
                fill='#6A0006'
            />
        </svg>
    );
};

export default Logos;
