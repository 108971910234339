import { axiosInstance } from '@App/providers';
import { httpErrorResponse } from '@App/requests/shared';

export const patchStageToFactFind = async (id: string) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        await axiosInstance.patch(`${url}/public/application/stage/${id}`);
        return { success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
