export const poll = (
    fn: Function,
    validate: Function,
    interval: number,
    maxTimeout: number,
) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    const start = new Date();

    const executePoll = async (resolve: any, reject: any) => {
        const duration = Date.now() - start.getTime();
        if (duration >= maxTimeout) {
            clearTimeout(timeoutId);
            return reject(new Error('Exceeded time!'));
        }

        const result = await fn();

        if (!result.success) {
            return reject(result.error);
        }
        if (validate(result)) {
            return resolve(result);
        }
        timeoutId = setTimeout(executePoll, interval, resolve, reject);
    };

    return new Promise(executePoll);
};
