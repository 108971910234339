import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { InfoIcon } from '../Icons';

const InfoModal: FC<{ onClose: Function }> = (props) => {
    const { t } = useTranslation('goodNews', { keyPrefix: 'infoModal' });
    useEffect(() => {
        document.body.style.overflow = 'hidden';
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleClose);
        return () => window.removeEventListener('keydown', handleClose);
    });

    const handleClose = () => {
        props.onClose();
        document.body.style.overflow = 'initial';
    };

    return (
        <div
            className='backdrop-blur-xs backdrop-filter bottom-0 fixed h-screen left-0 right-0 top-0 w-screen'
            onClick={handleClose}
        >
            <div className='-translate-x-1/2 -translate-y-1/2 bg-white fixed flex flex-col gap-2 left-1/2 max-h-screen max-w-600 min-w-300 overflow-y-auto p-6 rounded-2xl shadow-infoModal top-1/2 transform'>
                <h4 className='flex font-bold gap-1 items-center text-xl'>
                    {t('product.title') as String}
                    <InfoIcon />
                </h4>
                <p>{t('product.description') as String}</p>
                <h5 className='font-bold my-6'>{t('heloc.title') as String}</h5>
                <p>{t('heloc.description') as String}</p>
                <h5 className='font-bold my-6'>
                    {t('homeownerLoan.title') as String}
                </h5>
                <p>{t('homeownerLoan.description') as String}</p>
            </div>
        </div>
    );
};

export default InfoModal;
