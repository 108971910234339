import PencilIcon from '@Components/Icons/PencilIcon';
import { LinkButton } from '@Components/LinkButton';

interface IEditButtonProps {
    onEditButtonClick(): void;
}

export const EditButton = ({ onEditButtonClick }: IEditButtonProps) => (
    <div
        className='absolute cursor-pointer items-center flex top-4 right-4 font-bold'
        onClick={onEditButtonClick}
    >
        <PencilIcon />
        <LinkButton title='remove'>Edit</LinkButton>
    </div>
);
