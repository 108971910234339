import { SVGProps } from 'react';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            height='100%'
            viewBox='0 0 64 64'
            {...props}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M34.1243 0C35.0396 0.0674394 35.9259 0.144513 36.8893 0.289026C41.3582 0.98726 45.628 2.6262 49.4163 5.09748C53.2046 7.56877 56.4251 10.8161 58.865 14.6247L34.1243 0ZM1.8305 21.0796L63.8459 28.9026C63.8609 29.0503 63.8779 29.1998 63.8949 29.3503L63.895 29.3512C63.9325 29.6817 63.9705 30.017 63.9904 30.3477L0 29.7793C0.0674394 28.9026 0.134879 28.0259 0.269758 27.1395C0.596175 25.0745 1.11879 23.0454 1.8305 21.0796ZM59.4238 48.6719L35.7814 63.9999C40.6289 63.4126 45.2783 61.7266 49.3754 59.0703C53.4725 56.4141 56.9093 52.8576 59.4238 48.6719ZM25.2514 0.645519L61.3219 19.201C61.7008 20.0488 62.038 20.9062 62.3334 21.7733L15.781 4.31615C18.7214 2.59451 21.9188 1.35525 25.2514 0.645519ZM0.144469 35.8873C0.496202 38.8432 1.25821 41.7356 2.40851 44.4811L63.9518 34.1628C63.9518 33.6715 63.9518 33.1994 64 32.6984L0.144469 35.8873ZM61.7165 44.0282L26.8118 63.8168C23.4454 63.2684 20.1891 62.1819 17.1679 60.599L62.6799 41.3884C62.6179 41.563 62.5567 41.7369 62.4957 41.9101C62.2437 42.6257 61.9957 43.3301 61.7165 44.0282ZM11.5613 56.8417L63.3644 38.4115C63.3955 38.2623 63.4286 38.1142 63.4617 37.9657L63.4618 37.9656C63.5314 37.6539 63.6013 37.3408 63.6535 37.0145V36.6677L5.37618 50.0111C7.10379 52.5696 9.18634 54.8694 11.5613 56.8417ZM10.3373 8.324L63.1712 24.7021C63.2764 25.1453 63.3581 25.5943 63.4393 26.0401C63.4622 26.1661 63.4851 26.2918 63.5084 26.417L4.55675 15.4148C6.13223 12.7853 8.07916 10.397 10.3373 8.324Z'
                fill='currentColor'
            />
        </svg>
    );
};

export default Logo;
