import { ChangeEvent, FC, useEffect, useRef } from 'react';

import { Button, TextField } from '@selina-finance/ui';
import { Form, Formik } from 'formik';

import { formItems } from '@FormModels/auth/resetPasswordModal';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

export interface ResetPasswordModalViewProps {
    className?: string;
    email?: string;
    forgotPassword?: Function;
}

const ResetPasswordModalView: FC<ResetPasswordModalViewProps> = (props) => {
    const formikRef = useRef<any>(null);

    useEffect(() => {
        formikRef.current.values = { email: props.email };
    }, [props.email]);

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{ email: props.email || defaultValues.email }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    if (props.forgotPassword) {
                        props.forgotPassword(values.email);
                    }
                }}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getError, hasError } = getFormHelpers(
                        formItems,
                        formikProps,
                    );
                    return (
                        <Form autoComplete='off'>
                            <TextField
                                id='email'
                                name='email'
                                labelSize='small'
                                label='Email address'
                                value={
                                    formikProps.values.email?.toString() || ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('email')}
                            />
                            <br />
                            <Button
                                type='submit'
                                className='mb-6 w-full'
                                size='large'
                                color='secondary'
                                disabled={hasError()}
                            >
                                Submit
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ResetPasswordModalView;
