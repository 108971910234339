import { isEqualStr } from '@Helpers';
import { addYears, format } from 'date-fns';
import { cloneDeep, get, set } from 'lodash';

import { IntercomDataModel } from '@App/intercom/intercom-data.model';
import {
    AddressResponse,
    ApplicantResponse,
} from '@App/interfaces/applicant.response';
import { ConsentResponse } from '@App/interfaces/consent.response';
import { LoanDetailsResponse } from '@App/interfaces/loan-details.response';
import { Expenditure } from '@App/interfaces/outgoings.response';
import { PropertyDetailsResponse } from '@App/interfaces/property-details.response';
import { toValidDate } from '@Shared/helpers/date';
import { containsAnySubStr } from '@Shared/helpers/string';
import { formItems as applicantDetailsDefsV2 } from '@src/app/formModels/eligibility/applicantDetails';
import { employmentStatuses } from '@src/app/formModels/eligibility/incomings.shared';
import { formItems as loanDetailsDefs } from '@src/app/formModels/eligibility/loanDetails';
import { formItems as outgoingsDefsV2 } from '@src/app/formModels/eligibility/outgoings';
import { formItems as yourPropertyDefsV2 } from '@src/app/formModels/eligibility/yourProperty';

import { contractor as contractorFormModelV2 } from '../formModels/eligibility/incomings.contractor';
import { employed as employedFormModelV2 } from '../formModels/eligibility/incomings.employed';
import { notInPaid as notInPaidFormModelV2 } from '../formModels/eligibility/incomings.notInPaid';
import { retired as retiredFormModelV2 } from '../formModels/eligibility/incomings.retired';
import { selfEmployedLimitedCompany as selfEmployedLimitedCompanyFormModelV2 } from '../formModels/eligibility/incomings.selfEmployed';
import { selfEmployedSoleTrader as selfEmployedSoleTraderFormModel } from '../formModels/eligibility/incomings.selfEmployed';
import { selfEmployedPartnership as selfEmployedPartnershipFormModel } from '../formModels/eligibility/incomings.selfEmployed';

const parseDataFromRequest = (
    data: any,
    sourceItems: any,
    dependencies?: any,
) => {
    const result = {};
    Object.keys(sourceItems).forEach((key) => {
        if (
            !containsAnySubStr(key, [
                'transform',
                'default',
                'nested',
                'validation',
            ])
        ) {
            const isNested = sourceItems[key]?.nested;
            const sourceKey = sourceItems[key]?.transform?.target || key;
            const fromRequest = sourceItems[key]?.transform?.fromRequest;

            let value = get(data, sourceKey.replace('[]', ''));
            if (isNested) {
                value = parseDataFromRequest(
                    data,
                    sourceItems[key],
                    dependencies,
                );
            }
            if (!isNested && fromRequest) {
                value = fromRequest(value, dependencies);
            }
            set(result, key, value);
        }
    });
    return result;
};

const orderByCurrentFlag = (addressA: any, addressB: any) => {
    if (addressA.addressType === addressB.addressType) return 0;
    if (isEqualStr(addressA.addressType, 'current')) return -1;
    return 0;
};

const orderByPrimaryApplicantFlag = (applicantA: any, applicantB: any) => {
    if (applicantA.primaryApplicant === applicantB.primaryApplicant) return 0;
    if (applicantA.primaryApplicant) return -1;
    return 0;
};

export const transformLoanDetailsFromRequest = (
    loanDetails: LoanDetailsResponse,
    applicants: ApplicantResponse[],
) => {
    if (!loanDetails) return null;
    return parseDataFromRequest(loanDetails, loanDetailsDefs, {
        applicants,
        loanDetails,
    });
};

export const transformApplicantsFromRequest = (data: ApplicantResponse[]) => {
    const orderedApplicantsByPrimaryFlag = data.sort(
        orderByPrimaryApplicantFlag,
    );
    const applicants: any[] = [];

    orderedApplicantsByPrimaryFlag.forEach(
        (applicant: ApplicantResponse, index: number) => {
            const isCoApplicant = index === 1;
            let currentAddressMovedInDate = applicant.currentAddressMovedInDate;
            let orderedAddresses =
                applicant?.addresses?.sort(orderByCurrentFlag);

            if (isCoApplicant && !orderedAddresses?.length) {
                orderedAddresses = cloneDeep(
                    orderedApplicantsByPrimaryFlag[0].addresses,
                );
                currentAddressMovedInDate =
                    orderedApplicantsByPrimaryFlag[0].currentAddressMovedInDate;
            }

            if (isCoApplicant) {
                orderedApplicantsByPrimaryFlag[1].mobilePhoneNumber =
                    orderedApplicantsByPrimaryFlag[0].mobilePhoneNumber;
            }

            const addresses = orderedAddresses?.map(
                (address: AddressResponse, index: number) => {
                    const from = toValidDate(
                        address.from ||
                            (index === 0
                                ? currentAddressMovedInDate
                                : address.from),
                    );

                    return {
                        address: {
                            line1: address.addressLine1,
                            line2: address.addressLine2,
                            addressType: address.addressType,
                            buildingName: address.buildingName,
                            buildingNumber: address.buildingNumber,
                            city: address.city,
                            country: address.country,
                            county: address.county,
                            from: address.from,
                            poBox: address.poBox,
                            postcode: address.postcode,
                            subBuildingName: address.subBuildingName,
                            to: address.to,
                            udprn: address.udprn,
                            street: address.street,
                        },
                        livedIn: {
                            from,
                            to:
                                index === 0
                                    ? format(
                                          addYears(new Date(), 2),
                                          'dd/MM/yyyy',
                                      )
                                    : toValidDate(address.to),
                        },
                    };
                },
            );

            applicants.push(
                parseDataFromRequest(applicant, applicantDetailsDefsV2, {
                    addresses,
                    applicant,
                }),
            );
        },
    );

    return applicants;
};

export const transformPropertyDetailsFromRequest = (
    data: PropertyDetailsResponse,
) => {
    if (!data) return null;
    return parseDataFromRequest(data, yourPropertyDefsV2, data);
};

export const transformOutgoingsFromRequest = (
    data: Expenditure[],
    applicants: ApplicantResponse[],
    consent: ConsentResponse,
) => {
    if (!data) return null;
    return parseDataFromRequest(data, outgoingsDefsV2, {
        applicants,
        expenditure: data,
        consent,
    });
};

export const transformAddressFromRequest = (data: any) => {
    if (!data) return [];
    return data.map((item: any) => {
        return {
            id: item.id,
            line1:
                item.line_2?.length && item.line_3?.length
                    ? `${item.line_1}, ${item.line_2}`
                    : item.line_1,
            line2:
                item.line_2?.length && item.line_3?.length
                    ? item.line_3
                    : item.line_2,
            postcode: item.postcode,
            city: item.post_town,
            subBuildingName: item.sub_building_name,
            buildingName: item.building_name,
            buildingNumber: item.building_number,
            udprn: item.udprn ? +item.udprn : undefined,
            poBox: item.po_box,
            county: item.county,
            country: item.country,
            street: item.thoroughfare,
            apartmentFlatNumber: item.sub_building_name,
        };
    });
};

const transformIncomingsFromRequest = (data: any) => {
    if (!data) return [];
    return data.map((applicant: any) => {
        const sourceEmploymentStatus = applicant.employment?.employmentStatus;
        const businessStructureStatus = applicant.employment?.businessStructure;
        let employment: any = {};

        // retired
        if (sourceEmploymentStatus === employmentStatuses.retired) {
            employment = parseDataFromRequest(
                applicant,
                retiredFormModelV2,
                applicant,
            );
            employment.employmentStatus = 'Retired';
            employment.selfEmployedType = '';
        }

        if (isEqualStr(sourceEmploymentStatus, 'Employed')) {
            employment = parseDataFromRequest(
                applicant,
                employedFormModelV2,
                applicant,
            );
            employment.employmentStatus = 'Employed';
            employment.selfEmployedType = '';
        }

        if (
            isEqualStr(
                sourceEmploymentStatus,
                'Self-employed (limited company)',
            )
        ) {
            employment = parseDataFromRequest(
                applicant,
                selfEmployedLimitedCompanyFormModelV2,
                applicant,
            );
            employment.employmentStatus = 'Self-employed';
            employment.selfEmployedType = 'Limited company';
        }

        // V2 Partnership
        if (
            isEqualStr(businessStructureStatus, 'Partnership') &&
            isEqualStr(
                sourceEmploymentStatus,
                'Self-employed (sole trader / partnership)',
            )
        ) {
            employment = parseDataFromRequest(
                applicant,
                selfEmployedPartnershipFormModel,
                applicant,
            );
            employment.employmentStatus = 'Self-employed';
            employment.selfEmployedType = 'Partnership';
        }

        // V2 Sole trader
        if (
            isEqualStr(businessStructureStatus, 'Sole Trader') &&
            isEqualStr(
                sourceEmploymentStatus,
                'Self-employed (sole trader / partnership)',
            )
        ) {
            employment = parseDataFromRequest(
                applicant,
                selfEmployedSoleTraderFormModel,
                applicant,
            );
            employment.employmentStatus = 'Self-employed';
            employment.selfEmployedType = 'Sole trader';
        }

        if (isEqualStr(sourceEmploymentStatus, 'Self-employed (contractor)')) {
            employment = parseDataFromRequest(
                applicant,
                contractorFormModelV2,
                applicant,
            );
            employment.employmentStatus = 'Contractor';
            employment.selfEmployedType = '';
        }

        if (isEqualStr(sourceEmploymentStatus, 'Not in paid employment')) {
            employment = parseDataFromRequest(
                applicant,
                notInPaidFormModelV2,
                applicant,
            );
            employment.employmentStatus = 'Not in paid employment';
            employment.selfEmployedType = '';
        }

        return employment;
    });
};

export const transformFromRequest = (data: any) => {
    const loanDetails = transformLoanDetailsFromRequest(
        data.loanInformation,
        data.applicants,
    );
    const applicants = transformApplicantsFromRequest(data.applicants);
    const propertyDetails: any = transformPropertyDetailsFromRequest(
        data.propertyDetails,
    );
    const outgoings = transformOutgoingsFromRequest(
        data.expenditure,
        data.applicants,
        data,
    );
    const stage = data.applicationStage;
    const id = data.id;
    const emailAddress = data.applicants[0].emailAddress;

    const externalApplicationId = data.externalApplicationId;
    const incomings = transformIncomingsFromRequest(data.applicants);
    const offers = cloneDeep(data.offers);

    return {
        loanDetails,
        applicants,
        propertyDetails,
        outgoings,
        incomings,
        stage,
        id,
        emailAddress,
        offers,
        externalApplicationId,
    };
};

export const transformIntercomDataFromRequest = (
    applicant: any,
): IntercomDataModel => {
    return {
        name: transformIntercomName(applicant),
        email: applicant.emailAddress,
        phone: applicant.mobilePhoneNumber,
    };
};

export const transformIntercomName = (applicant: any): string => {
    return `${applicant.firstName} ${applicant.lastName}`;
};
