import {
    FC,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { isEqualStr } from '@Helpers';
import cookie from 'cookie';
import { cloneDeep, get, merge } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { BaseSchema } from 'yup';

import { useSentryLogger } from '@App/hooks/log';
import { IntercomDataModel } from '@App/intercom/intercom-data.model';
import { ApplicationStage } from '@App/interfaces/application-stage.enum';
import { Offer, RecommendedOffers } from '@App/interfaces/offer';
import {
    getApplicationById,
    getPublicApplicationData,
    getUserApplication,
} from '@App/requests/getApplicationData';
import {
    getOffersData,
    getRecommendedOffers,
} from '@App/requests/getOfferData';
import { patchStageToFactFind } from '@App/requests/patchStageToFactFind';
import {
    saveApplicantsData,
    saveEventBooking,
    saveIncomingsData,
    saveLoanDetailsData,
    saveOffer,
    saveOutgoingsData,
    savePropertyDetailsData,
} from '@App/requests/saveApplicationData';
import { httpErrorResponse } from '@App/requests/shared';
import {
    transformApplicantToRequest,
    transformApplicants,
    transformIncomingsToRequest,
    transformLoanDetailsToRequest,
    transformOutgoingsToRequest,
    transformPropertyDetailsToRequest,
} from '@App/requests/transformToRequest';
import { updateCreditCommitmentsData } from '@App/requests/updateCreditCommitments';
import { getValidationSchema } from '@Shared/helpers/input';
import { formItems as applicantFormItems } from '@src/app/formModels/eligibility/applicantDetails';
import { formItems as incomingsFM } from '@src/app/formModels/eligibility/incomings';
import { contractor as contractorFM } from '@src/app/formModels/eligibility/incomings.contractor';
import { employed as employedFM } from '@src/app/formModels/eligibility/incomings.employed';
import { notInPaid as notInPaidFM } from '@src/app/formModels/eligibility/incomings.notInPaid';
import { retired as retiredFM } from '@src/app/formModels/eligibility/incomings.retired';
import {
    selfEmployed as selfEmployedFM,
    selfEmployedLimitedCompany as selfEmployedLimitedCompanyFM,
    selfEmployedPartnership as selfEmployedPartnershipFM,
    selfEmployedSoleTrader as selfEmployedSoleTraderFM,
} from '@src/app/formModels/eligibility/incomings.selfEmployed';
import { formItems as loanFormItems } from '@src/app/formModels/eligibility/loanDetails';
import { formItems as outgoingsFormItems } from '@src/app/formModels/eligibility/outgoings';
import { formItems as propertyFormItems } from '@src/app/formModels/eligibility/yourProperty';
import { savePhoneNumber } from '@src/app/requests/saveApplicationData';
import { containsAnySubStr } from '@src/shared/helpers/string';
import { BasePropertyType } from '@src/ui/components/PropertyType/PropertyType';
import useStorage from '@src/ui/hooks/useStorage';

import { EventBooking } from '../interfaces/eventBooking';
import { postStoredApplication } from '../requests/saveLSData';
import {
    transformFromRequest,
    transformIntercomDataFromRequest,
    transformIntercomName,
} from '../requests/transformFromRequest';

import { AuthContext } from './Auth';

export const ApplicationContext = createContext<any>({ loading: true });

export const validateIncomings = (data: any) => {
    try {
        const employmentStatus = get(data, 'employmentStatus');

        if (isEqualStr(employmentStatus, 'employed')) {
            const employedSchema = getValidationSchema(employedFM);
            removeValidationEmployed(employedSchema);
            return !!employedSchema.validateSync(data);
        }

        if (isEqualStr(employmentStatus, 'self-employed')) {
            const selfEmployedSchema = getValidationSchema(selfEmployedFM);
            const isSelfEmployedValid = selfEmployedSchema.validateSync(data);
            if (!isSelfEmployedValid) {
                return false;
            }
            const selfEmployedType = get(data, 'selfEmployedType');
            if (isEqualStr(selfEmployedType, 'Limited company')) {
                const selfEmployedLimitedCompanySchema = getValidationSchema(
                    selfEmployedLimitedCompanyFM,
                );
                removeValidationSelfEmployed(selfEmployedLimitedCompanySchema);
                return !!selfEmployedLimitedCompanySchema.validateSync(data);
            }
            if (isEqualStr(selfEmployedType, 'Sole trader')) {
                const selfEmployedSoleTraderSchema = getValidationSchema(
                    selfEmployedSoleTraderFM,
                );
                return !!selfEmployedSoleTraderSchema.validateSync(data);
            }
            if (isEqualStr(selfEmployedType, 'Partnership')) {
                const selfEmployedPartnershipSchema = getValidationSchema(
                    selfEmployedPartnershipFM,
                );
                removeValidationSelfEmployed(selfEmployedPartnershipSchema);
                return !!selfEmployedPartnershipSchema.validateSync(data);
            }

            return false;
        }
        if (isEqualStr(employmentStatus, 'retired')) {
            const retiredSchema = getValidationSchema(retiredFM);
            return !!retiredSchema.validateSync(data);
        }
        if (isEqualStr(employmentStatus, 'Not in paid employment')) {
            const notInPaidSchema = getValidationSchema(notInPaidFM);
            return !!notInPaidSchema.validateSync(data);
        }
        if (isEqualStr(employmentStatus, 'contractor')) {
            const contractorSchema = getValidationSchema(contractorFM);
            removeValidationContractor(contractorSchema);
            return !!contractorSchema.validateSync(data);
        }
        return false;
    } catch (_error) {
        return false;
    }
};

// These fields aren't returned from the BE and would cause validation to fail
const removeValidationEmployed = (schema: any) => {
    delete schema.fields.employment.fields.startMonth;
    delete schema.fields.employment.fields.startYear;
};

const removeValidationContractor = (schema: any) => {
    delete schema.fields.endMonth;
    delete schema.fields.endYear;
    delete schema.fields.startMonth;
    delete schema.fields.startYear;
    delete schema.fields.incorp_month;
    delete schema.fields.incorp_year;
};

const removeValidationSelfEmployed = (schema: any) => {
    delete schema.fields.month;
    delete schema.fields.year;
};

interface ApplicationContextProviderProps {
    children?: ReactNode;
    getAccessToken: Function;
    isToFetchApplication: boolean | null;
    getIdToken: Function;
}

type FormStorage = Record<
    string,
    {
        factFind: {
            loanDetails: any;
            applicants: any;
            incomings: any;
            propertyDetails: any;
            outgoings: any;
        };
        factFindForm: {
            loanDetails: any;
            applicants: any;
            incomings: any;
            propertyDetails: any;
            outgoings: any;
        };
    }
>;

const ApplicationContextProvider: FC<ApplicationContextProviderProps> = (
    props,
) => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(AuthContext);
    const [loanDetails, setLoanDetails] = useState<any>({});
    const [applicants, setApplicants] = useState<any>([{}]);
    const [propertyDetails, setPropertyDetails] = useState<any>({});
    const [outgoings, setOutgoings] = useState<any>({});
    const [incomings, setIncomings] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [activeApplicant, setActiveApplicant] = useState<number | null>(null);
    const [applicationId, setApplicationId] = useState('');
    const [applicationEmail, setApplicationEmail] = useState('');
    const [offers, setOffers] = useState<Offer[]>([]);
    const [recommendedOffersData, setRecommendedOffersData] =
        useState<RecommendedOffers>();
    const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
    const [callDateTime, setCallDateTime] = useState<EventBooking | null>(null);
    const [applicationStage, setApplicationStage] = useState<
        ApplicationStage | ''
    >('');
    const [hasDashboardError, setHasDashboardError] = useState<boolean>(false);
    const [currentForm, setCurrentForm] = useState<any>(null);
    const [intercomData, setIntercomData] = useState<IntercomDataModel>({
        email: '',
        name: '',
        phone: '',
    });

    const [appLs, setAppLs] = useStorage<FormStorage>('local', 'form');
    const [externalApplicationId, setExternalApplicationId] = useState('');

    var readyForQuote = false;

    const { sentryError, sentryLog, flushLogs } = useSentryLogger(
        applicationId,
        'Application context',
    );

    const handleError = useCallback(
        (error: any, step: string) => {
            sentryError(`error on ${step}`, error);
            flushLogs();
            return httpErrorResponse(error);
        },
        [flushLogs, sentryError],
    );

    const dashboardItemStatuses = useMemo(() => {
        const dataMap: any = [
            {
                name: 'loan-details',
                itemSpecs: loanFormItems,
                data: [loanDetails],
            },
            {
                name: 'applicants',
                itemSpecs: applicantFormItems,
                data: applicants,
            },
            {
                name: 'property-details',
                itemSpecs: propertyFormItems,
                data: [propertyDetails],
            },
            {
                name: 'income',
                itemSpecs: incomingsFM,
                data: incomings,
            },
            {
                name: 'outgoings',
                itemSpecs: outgoingsFormItems,
                data: [outgoings],
            },
            {
                name: 'quote',
                itemSpecs: {},
                data: { applicationStage, offers },
            },
        ];

        let prevIsDisabled = false;
        const hasOffer =
            containsAnySubStr(applicationStage || '', [
                ApplicationStage.DECISIONING_ACCEPT,
                ApplicationStage.DECISIONING_DECLINE,
                ApplicationStage.CLOSED,
            ]) &&
            offers &&
            props.isToFetchApplication;

        return dataMap.map((dataItem: any) => {
            if (dataItem.name === 'loan-details') {
                return {
                    name: dataItem.name,
                    completed: true,
                    edited: false,
                    disabled: false,
                };
            }

            if (hasOffer) {
                if (dataItem.name === 'quote') {
                    return {
                        name: dataItem.name,
                        completed: true,
                        disabled: false,
                        viewed: true,
                    };
                }
                return {
                    name: dataItem.name,
                    completed: true,
                    edited: false,
                    disabled: true,
                };
            }

            let status;

            try {
                //remove password validation check
                if (dataItem.name === 'outgoings' && loggedIn) {
                    delete dataItem.itemSpecs.password;
                }

                const schema = getValidationSchema(dataItem.itemSpecs);

                const isApplicantsWithData =
                    dataItem.name === 'applicants' && dataItem.data;
                const isIncomeWithData =
                    dataItem.name === 'income' && dataItem.data;

                const applicantsLength = applicants.length;
                const differentItemCount =
                    dataItem.data.length === 1 && applicantsLength === 2;

                if (isApplicantsWithData && differentItemCount) {
                    throw new Error('Income data is not valid');
                }
                if (isIncomeWithData && differentItemCount) {
                    throw new Error('Applicants data is not valid');
                }

                dataItem.data.forEach((data: BaseSchema) => {
                    // validateIncomings returns false if incomings is not valid as it's used other places
                    if (
                        dataItem.name === 'income' &&
                        !validateIncomings(data)
                    ) {
                        throw new Error('Incoming validation failed');
                    }

                    // validateSync will throw error if not valid
                    return schema.validateSync(data);
                });
                status = {
                    name: dataItem.name,
                    completed: true,
                    edited: true,
                    disabled: prevIsDisabled,
                };
            } catch (e: any) {
                status = {
                    name: dataItem.name,
                    completed: false,
                    edited: false,
                    disabled: prevIsDisabled,
                };
                prevIsDisabled = true;
            }
            return status;
        });
    }, [
        loanDetails,
        applicants,
        propertyDetails,
        incomings,
        outgoings,
        applicationStage,
        offers,
        props.isToFetchApplication,
        loggedIn,
    ]);

    useEffect(() => {
        sentryLog('props.isToFetchApplication ' + props.isToFetchApplication);
        if (props.isToFetchApplication === true) {
            setLoading(true);
            setHasDashboardError(false);
            fetchApplicationData().then((response) => {
                if (!response.success) {
                    console.error(
                        '[Dashboard] Error fetching the application: ',
                        response.error,
                    );
                    setHasDashboardError(true);
                }
            });
        } else if (props.isToFetchApplication === false) {
            fetchPublicApplicationData().then((appId: string) => {
                appLs?.[appId]?.factFind.loanDetails &&
                    setLoanDetails(appLs[appId].factFind.loanDetails);
                appLs?.[appId]?.factFind.applicants &&
                    setApplicants(appLs[appId].factFind.applicants);
                appLs?.[appId]?.factFind.incomings &&
                    setIncomings(appLs[appId].factFind.incomings);
                appLs?.[appId]?.factFind.propertyDetails &&
                    setPropertyDetails(appLs[appId].factFind.propertyDetails);
                appLs?.[appId]?.factFind.outgoings &&
                    setOutgoings(appLs[appId].factFind.outgoings);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isToFetchApplication]);

    const fetchApplicationData = async () => {
        try {
            setLoading(true);
            sentryLog('fetching application data');
            const response = await getUserApplication(
                await props.getAccessToken(),
                await props.getIdToken(),
            );
            if (response.success) {
                sentryLog('fetching application data successfull');

                const {
                    applicants,
                    propertyDetails,
                    loanDetails,
                    outgoings,
                    stage,
                    id,
                    emailAddress,
                    incomings,
                    offers,
                    externalApplicationId,
                } = transformFromRequest(response);

                if (window.heap) {
                    window.heap.identify(id);
                }

                sentryLog('stage ' + stage);
                sentryLog(
                    'outgoings complete ' + dashboardItemStatuses[4].completed,
                );
                sentryLog(
                    'quote complete ' + dashboardItemStatuses[5].completed,
                );

                const handleApplicationState = () => {
                    updateState(applicants, propertyDetails);

                    setApplicationId(id);
                    setApplicationEmail(emailAddress);
                    setExternalApplicationId(externalApplicationId);
                    setApplicants(applicants);
                    setPropertyDetails(propertyDetails);
                    setLoanDetails(loanDetails);
                    setOutgoings(outgoings);
                    setApplicationStage(stage);
                    setIncomings(incomings);
                    setOffers(offers);
                    setIntercomData(
                        transformIntercomDataFromRequest(
                            response.applicants[0],
                        ),
                    );
                    const callDateTime =
                        response?.adviceCall?.eventMemberships?.[0]
                            ?.bufferedStartTime;
                    setCallDateTime(callDateTime);
                };

                if (!readyForQuote) {
                    const formSaveResult = await saveLSFormData(id);
                    if (!formSaveResult) {
                        handleApplicationState();
                    }
                } else {
                    handleApplicationState();
                }

                if (
                    dashboardItemStatuses[4].completed &&
                    !dashboardItemStatuses[5].completed
                ) {
                    sentryLog('navigate to wait');
                    navigate('/eligibility/wait');
                }
            }
            return response;
        } catch (e) {
            return handleError(e, 'fetch application data');
        } finally {
            setLoading(false);
        }
    };

    const fetchPublicApplicationData = async () => {
        try {
            setLoading(true);

            const cookies = cookie.parse(document.cookie);

            if (!cookies.application) {
                return;
            }
            const appInfo = JSON.parse(cookies.application);

            sentryLog('fetching public application data');
            const response = await getPublicApplicationData(
                appInfo.applicationId,
                appInfo.emailAddress,
            );
            if (response.success) {
                sentryLog('fetching public application data successfull');
                const {
                    applicants,
                    propertyDetails,
                    loanDetails,
                    outgoings,
                    stage,
                    incomings,
                } = transformFromRequest(response);

                setApplicationId(appInfo.applicationId);
                setApplicationEmail(appInfo.emailAddress);
                setApplicants(applicants);
                setPropertyDetails(propertyDetails);
                setLoanDetails(loanDetails);
                setOutgoings(outgoings);
                setApplicationStage(stage);
                setIncomings(incomings);
                setIntercomData(
                    transformIntercomDataFromRequest(response.applicants[0]),
                );

                if (window.heap) {
                    window.heap.identify(appInfo.applicationId);
                }
            }
            return appInfo.applicationId;
        } catch (e) {
            return handleError(e, 'fetch public application data');
        } finally {
            setLoading(false);
        }
    };

    const saveLoanDetails = async (data: any) => {
        const updateState = () => {
            const term = isEqualStr(data.term, 'other')
                ? data.otherTerm
                : data.term;
            const purpose = isEqualStr(data.purpose, 'Something else')
                ? data.otherPurpose
                : data.purpose;

            const tmpLoanDetails = {
                ...data,
                otherTerm: term,
                term: term,
                purpose: purpose,
            };

            const tmpApplicants = [...applicants];
            const tmpIncomings = [...incomings];
            const numberOfApplicants = data.applicants;

            if (numberOfApplicants === 2 && tmpApplicants.length === 1) {
                tmpApplicants.push({
                    addresses: [tmpApplicants[0].addresses[0]],
                });
            }
            if (numberOfApplicants === 1 && tmpApplicants.length === 2) {
                tmpApplicants.pop();
                tmpIncomings.pop();
            }

            setLoanDetails(tmpLoanDetails);
            setApplicants(tmpApplicants);
            setIncomings(tmpIncomings);

            return {
                loanDetails: tmpLoanDetails,
                applicants: tmpApplicants,
                incomings: tmpIncomings,
            };
        };
        try {
            setLoading(true);

            if (loggedIn) {
                const response = await saveLoanDetailsData(
                    {
                        accessToken: await props.getAccessToken(),
                        idToken: await props.getIdToken(),
                    },
                    transformLoanDetailsToRequest(cloneDeep(data), applicants),
                    applicationId,
                );
                updateState();
                return response;
            } else {
                const res = updateState();

                if (applicationId) {
                    setAppLs((prevData) => {
                        return {
                            ...prevData,
                            [applicationId]: {
                                ...(prevData?.[applicationId]
                                    ? prevData[applicationId]
                                    : {}),
                                factFind: {
                                    ...(prevData?.[applicationId]
                                        ? prevData[applicationId].factFind
                                        : {}),
                                    ...res,
                                },
                                factFindForm: {
                                    ...(prevData?.[applicationId]
                                        ? prevData[applicationId].factFindForm
                                        : {}),
                                    loanDetails: transformLoanDetailsToRequest(
                                        cloneDeep(data),
                                        applicants,
                                    ),
                                },
                            },
                        };
                    });
                }

                return {
                    success: true,
                };
            }
        } catch (e) {
            return handleError(e, 'save loan details');
        } finally {
            setLoading(false);
        }
    };

    const saveApplicants = async (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => {
        const numberOfApplicants = noOfApplicants || loanDetails.applicants;
        const hasCoApplicant = numberOfApplicants === 2 && isPrimaryApplicant;

        const updateState = () => {
            const localApplicants: any[] = [data];

            if (
                numberOfApplicants === 2 &&
                isPrimaryApplicant &&
                !applicants[1]?.addresses
            ) {
                localApplicants.push({
                    addresses: [localApplicants[0].addresses[0]],
                });
            }

            if (
                numberOfApplicants === 2 &&
                isPrimaryApplicant &&
                applicants[1]
            ) {
                localApplicants.push(applicants[1]);
            }

            if (numberOfApplicants === 2 && !isPrimaryApplicant) {
                localApplicants.unshift(applicants[0]);
            }

            setApplicants(localApplicants);

            if (isPrimaryApplicant) {
                setIntercomData((previousData: IntercomDataModel) => ({
                    ...previousData,
                    name: transformIntercomName(localApplicants[0]),
                }));
            }

            return { applicants: localApplicants };
        };
        try {
            setLoading(true);

            if (loggedIn) {
                const requestData = transformApplicants(
                    numberOfApplicants,
                    cloneDeep(applicants),
                    cloneDeep(data),
                    isPrimaryApplicant,
                );
                const response = await saveApplicantsData(
                    {
                        accessToken: await props.getAccessToken(),
                        idToken: await props.getIdToken(),
                    },
                    requestData,
                    applicationId,
                );

                updateState();

                // Set active applicant only if the request was executed with success
                if (response.success && hasCoApplicant) {
                    setActiveApplicant(1);
                }

                return { ...response, hasCoApplicant };
            } else {
                const res = updateState();

                setAppLs((prevData) => ({
                    ...prevData,
                    [applicationId]: {
                        ...(prevData[applicationId]
                            ? prevData[applicationId]
                            : {}),
                        factFind: {
                            ...prevData[applicationId].factFind,
                            ...res,
                        },
                        factFindForm: {
                            ...prevData[applicationId].factFindForm,
                            applicants: transformApplicants(
                                numberOfApplicants,
                                cloneDeep(applicants),
                                cloneDeep(data),
                                isPrimaryApplicant,
                            ),
                        },
                    },
                }));

                // Set active applicant only if the request was executed with success
                if (hasCoApplicant) {
                    setActiveApplicant(1);
                }

                return {
                    success: true,
                    hasCoApplicant,
                };
            }
        } catch (e) {
            return handleError(e, 'save applicants');
        } finally {
            setLoading(false);
        }
    };

    const saveIncomings = async (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => {
        const numberOfApplicants = noOfApplicants || loanDetails.applicants;
        const hasCoApplicant = numberOfApplicants === 2 && isPrimaryApplicant;

        const updateState = () => {
            const localIncomings: any[] = [data];

            if (
                numberOfApplicants === 2 &&
                isPrimaryApplicant &&
                incomings[1]
            ) {
                localIncomings.push(incomings[1]);
            }

            if (numberOfApplicants === 2 && !isPrimaryApplicant) {
                localIncomings.unshift(incomings[0]);
            }

            setIncomings(localIncomings);

            if (hasCoApplicant) {
                setActiveApplicant(1);
            }

            return { incomings: localIncomings };
        };

        try {
            setLoading(true);

            if (loggedIn) {
                const requestData = transformIncomingsToRequest(
                    cloneDeep(data),
                );
                const response = await saveIncomingsData(
                    {
                        accessToken: await props.getAccessToken(),
                        idToken: await props.getIdToken(),
                    },
                    requestData,
                    applicationId,
                    isPrimaryApplicant ? 1 : 2,
                );

                updateState();

                return { ...response, hasCoApplicant };
            } else {
                const res = updateState();

                if (isPrimaryApplicant) {
                    setAppLs((prevData) => ({
                        ...prevData,
                        [applicationId]: {
                            ...(prevData[applicationId]
                                ? prevData[applicationId]
                                : {}),
                            factFind: {
                                ...prevData[applicationId].factFind,
                                ...res,
                            },
                            factFindForm: {
                                ...prevData[applicationId].factFindForm,
                                incomings: [
                                    transformIncomingsToRequest(
                                        cloneDeep(data),
                                    ),
                                    ...(prevData[applicationId].factFindForm
                                        .incomings &&
                                    prevData[applicationId].factFindForm
                                        .incomings.length > 1 &&
                                    Array.isArray(
                                        prevData[applicationId].factFindForm
                                            .incomings[1],
                                    )
                                        ? prevData[applicationId].factFindForm
                                              .incomings[1]
                                        : []),
                                ],
                            },
                        },
                    }));
                } else {
                    setAppLs((prevData) => ({
                        ...prevData,
                        [applicationId]: {
                            factFind: {
                                ...prevData[applicationId].factFind,
                                ...res,
                            },
                            factFindForm: {
                                ...prevData[applicationId].factFindForm,
                                incomings: [
                                    prevData[applicationId].factFindForm
                                        .incomings[0],
                                    transformIncomingsToRequest(
                                        cloneDeep(data),
                                    ),
                                ],
                            },
                        },
                    }));
                }

                return {
                    success: true,
                    hasCoApplicant,
                };
            }
        } catch (e) {
            return handleError(e, 'save incomings');
        } finally {
            setLoading(false);
        }
    };

    const savePropertyDetails = async (data: any) => {
        try {
            setLoading(true);

            if (loggedIn) {
                const response = await savePropertyDetailsData(
                    {
                        accessToken: await props.getAccessToken(),
                        idToken: await props.getIdToken(),
                    },
                    transformPropertyDetailsToRequest(data),
                    applicationId,
                );
                setPropertyDetails(data);
                return response;
            } else {
                setAppLs((prevData) => ({
                    ...prevData,
                    [applicationId]: {
                        ...(prevData[applicationId]
                            ? prevData[applicationId]
                            : {}),
                        factFind: {
                            ...prevData[applicationId].factFind,
                            propertyDetails: data,
                        },
                        factFindForm: {
                            ...prevData[applicationId].factFindForm,
                            propertyDetails:
                                transformPropertyDetailsToRequest(data),
                        },
                    },
                }));

                setPropertyDetails(data);

                return {
                    success: true,
                };
            }
        } catch (e) {
            return handleError(e, 'save property details');
        } finally {
            setLoading(false);
        }
    };

    const saveOutgoings = async (data: any) => {
        const transformedOutgoingsData = transformOutgoingsToRequest(data);
        const transformedApplicants = applicants.map(
            (applicant: any, index: number) =>
                transformApplicantToRequest(
                    applicant,
                    index === 0,
                    loanDetails,
                ),
        );
        transformedApplicants[0].numberOfAdultDependants =
            transformedOutgoingsData.numberOfAdultDependants;
        transformedApplicants[0].numberOfChildDependants =
            transformedOutgoingsData.numberOfChildDependants;
        const transformedData = {
            expenditure: transformedOutgoingsData.expenditure,
            applicants: transformedApplicants,
            ...transformedOutgoingsData.consent,
        };

        const updateState = () => {
            setOutgoings(data);

            const tmpApplicants = [...applicants];
            tmpApplicants[0].numberOfAdultDependants =
                transformedData.applicants[0].numberOfAdultDependants;
            tmpApplicants[0].numberOfChildDependants =
                transformedData.applicants[0].numberOfChildDependants;
            setApplicants(tmpApplicants);

            return { outgoings: data, applicants: tmpApplicants };
        };

        try {
            setLoading(true);

            if (loggedIn) {
                const response = await saveOutgoingsData(
                    {
                        accessToken: await props.getAccessToken(),
                        idToken: await props.getIdToken(),
                    },
                    transformedData,
                    applicationId,
                );

                updateState();

                return response;
            } else {
                const res = updateState();

                setAppLs((prevData) => ({
                    ...prevData,
                    [applicationId]: {
                        ...(prevData[applicationId]
                            ? prevData[applicationId]
                            : {}),
                        factFind: {
                            ...prevData[applicationId].factFind,
                            ...res,
                        },

                        factFindForm: {
                            ...prevData[applicationId].factFindForm,
                            outgoings: transformedData,
                        },
                    },
                }));

                return {
                    success: true,
                };
            }
        } catch (e) {
            return handleError(e, 'save outgoings');
        } finally {
            setLoading(false);
        }
    };

    const fetchApplication = async () => {
        try {
            return await getApplicationById(
                applicationId,
                await props.getAccessToken(),
                await props.getIdToken(),
            );
        } catch (e) {
            return handleError(e, 'fetch application');
        }
    };

    const runDecisioning = async () => {
        try {
            return await getOffersData(
                await props.getAccessToken(),
                await props.getIdToken(),
                applicationId,
            );
        } catch (e) {
            return handleError(e, 'run decisioning');
        }
    };

    const updateCreditCommitments = async () =>
        updateCreditCommitmentsData(
            await props.getAccessToken(),
            await props.getIdToken(),
            applicationId,
        );

    const saveLSFormData = async (appId: string) => {
        if (!appLs || !appLs[appId]) {
            return;
        }

        setLoading(true);

        // remove 2nd applicant data
        if (
            appLs[appId].factFindForm.loanDetails.applicants.length === 2 &&
            appLs[appId].factFindForm.applicants?.length === 1
        ) {
            appLs[appId].factFindForm.loanDetails.applicants.pop();
        }

        //merge each transformed state into  single object
        const requestData = merge(
            appLs[appId].factFindForm.loanDetails,
            { applicants: appLs[appId].factFindForm.applicants },
            { propertyDetails: appLs[appId].factFindForm.propertyDetails },
            { applicants: appLs[appId].factFindForm.incomings },
            appLs[appId].factFindForm.outgoings,
        );

        const applicationData = await postStoredApplication(
            appId,
            {
                accessToken: await props.getAccessToken(),
                idToken: await props.getIdToken(),
            },
            requestData,
        );

        if (applicationData.success) {
            //on success delete ls
            setAppLs((prevData) => ({
                ...prevData,
                [appId]: undefined,
            }));
        }

        readyForQuote = true;

        // get latest data
        await fetchApplicationData();
        return true;
    };

    const updateStageToFactFind = async (
        applicationId: string,
        currentStage: ApplicationStage,
    ) => {
        const stage = currentStage || applicationStage;

        if (
            stage === ApplicationStage.FACT_FIND ||
            stage === ApplicationStage.DECISIONING_ACCEPT ||
            stage === ApplicationStage.DECISIONING_DECLINE ||
            stage === ApplicationStage.CLOSED
        ) {
            return;
        }

        if (applicationId) {
            const result = await patchStageToFactFind(applicationId);
            if (result.success) {
                setApplicationStage(ApplicationStage.FACT_FIND);
            }
        }
    };

    const updatePhoneNumber = async (
        mobilePhoneNumber: string,
        getExternalApplicationId: string,
        applicationId: string,
    ) => {
        try {
            setLoading(true);
            return await savePhoneNumber(
                mobilePhoneNumber,
                getExternalApplicationId,
                applicationId,
            );
        } catch (e) {
            return handleError(e, 'save phone number');
        } finally {
            setLoading(false);
        }
    };

    const updateState = (applicants: any, propertyDetails: any) => {
        // TODO move to from request
        // update hasSecondApplicant as its not returned from the BE
        if (!applicants[0].hasSecondApplicant) {
            applicants[0].hasSecondApplicant =
                applicants.length === 2 ? 'true' : 'false';

            if (applicants[1]) {
                applicants[1].hasSecondApplicant = false;
            }
        }

        // update propertyTypeBase as its not returned from the BE
        if (propertyDetails.propertyType?.includes(BasePropertyType.HOUSE)) {
            propertyDetails.propertyTypeBase = BasePropertyType.HOUSE;
        } else if (
            propertyDetails.propertyType?.includes(BasePropertyType.BUNGALOW)
        ) {
            propertyDetails.propertyTypeBase = BasePropertyType.BUNGALOW;
        } else if (
            propertyDetails.propertyType?.includes(BasePropertyType.FLAT)
        ) {
            propertyDetails.propertyTypeBase = BasePropertyType.FLAT;
        }
    };

    const fetchRecommendedOffers = useCallback(async () => {
        try {
            setLoading(true);
            const result = await getRecommendedOffers(
                applicationId,
                await props.getAccessToken(),
                await props.getIdToken(),
            );

            setRecommendedOffersData(result);

            const customerSelectedOffer =
                offers.find(
                    (offer) => offer.productCode === result.selectedProductCode,
                ) || null;

            setSelectedOffer(customerSelectedOffer);
        } catch (e) {
            return handleError(e, 'fetching recommended offers');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicationId]);

    const saveSelectedOffer = async () => {
        try {
            setLoading(true);
            const response = await saveOffer(
                applicationId,
                await props.getAccessToken(),
                await props.getIdToken(),
                selectedOffer,
            );
            return response;
        } catch (e) {
            return handleError(e, 'save selected offer');
        } finally {
            setLoading(false);
        }
    };

    const saveScheduledEventBooking = async (eventBooking: EventBooking) => {
        try {
            setLoading(true);

            const response = await saveEventBooking(
                await props.getAccessToken(),
                applicationId,
                eventBooking,
                await props.getIdToken(),
            );
            return response;
        } catch (e) {
            return handleError(e, 'save call date and time');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ApplicationContext.Provider
            value={{
                loading,
                applicants,
                loanDetails,
                propertyDetails,
                outgoings,
                incomings,
                setIncomings,
                saveApplicants,
                saveLoanDetails,
                savePropertyDetails,
                saveOutgoings,
                saveIncomings,
                activeApplicant,
                setActiveApplicant,
                dashboardItemStatuses,
                fetchApplication,
                offers,
                setOffers,
                applicationStage,
                setApplicationStage,
                hasDashboardError,
                currentForm,
                setCurrentForm,
                updateCreditCommitments,
                runDecisioning,
                applicationId,
                intercomData,
                saveLSFormData,
                updateStageToFactFind,
                externalApplicationId,
                setExternalApplicationId,
                updatePhoneNumber,
                applicationEmail,
                callDateTime,
                setCallDateTime,
                selectedOffer,
                setSelectedOffer,
                fetchRecommendedOffers,
                saveSelectedOffer,
                saveScheduledEventBooking,
                recommendedOffersData,
            }}
        >
            {props.children}
        </ApplicationContext.Provider>
    );
};

export default ApplicationContextProvider;
