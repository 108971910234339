import { format } from 'date-fns';
import startOfToday from 'date-fns/startOfToday/index';
import { FieldArray } from 'formik';

import { Paper } from '@Components/Paper';
import { AddressRepeaterViewContent } from '@Views/eligibility/addressRepeaterView/views/AddressRepeaterViewContent';

interface AddressRepeaterViewProps {
    addressFieldName: string;
    formikProps: any;
    formItems: any;
    isPrimaryApplicant: boolean;
}

const AddressRepeaterView = ({
    addressFieldName,
    formikProps,
    formItems,
    isPrimaryApplicant,
}: AddressRepeaterViewProps) => (
    <FieldArray
        name={addressFieldName}
        render={(arrayHelpers) => {
            return (
                <div className='grid gap-2'>
                    {formikProps.values.addresses.map(
                        (_item: any, index: number) => {
                            if (index === 0) {
                                formikProps.values.addresses[index].livedIn.to =
                                    format(startOfToday(), 'MM/yyyy');
                            }

                            if (
                                !formikProps.values.addresses[index].livedIn
                                    .fromMonth &&
                                !formikProps.values.addresses[index].livedIn
                                    .fromYear
                            ) {
                                formikProps.values.addresses[
                                    index
                                ].livedIn.fromMonth = '';
                                formikProps.values.addresses[
                                    index
                                ].livedIn.fromYear = '';
                            }

                            if (
                                !formikProps.values.addresses[index].livedIn
                                    .toMonth &&
                                !formikProps.values.addresses[index].livedIn
                                    .toYear
                            ) {
                                formikProps.values.addresses[
                                    index
                                ].livedIn.toMonth = '';
                                formikProps.values.addresses[
                                    index
                                ].livedIn.toYear = '';
                            }

                            //assign vales from props to month/year dropdowns
                            if (_item.livedIn?.from) {
                                formikProps.values.addresses[
                                    index
                                ].livedIn.fromMonth =
                                    formikProps.values.addresses[
                                        index
                                    ].livedIn?.from?.split('/')[0];

                                formikProps.values.addresses[
                                    index
                                ].livedIn.fromYear =
                                    formikProps.values.addresses[
                                        index
                                    ].livedIn?.from?.split('/')[1];
                            }

                            if (_item.livedIn?.to) {
                                formikProps.values.addresses[
                                    index
                                ].livedIn.toMonth =
                                    formikProps.values.addresses[
                                        index
                                    ].livedIn?.to?.split('/')[0];

                                formikProps.values.addresses[
                                    index
                                ].livedIn.toYear =
                                    formikProps.values.addresses[
                                        index
                                    ].livedIn?.to?.split('/')[1];
                            }

                            return (
                                <Paper
                                    key={`address_${index}`}
                                    className='relative mb-3 bg-neutral-40'
                                >
                                    <AddressRepeaterViewContent
                                        index={index}
                                        addressFieldName={addressFieldName}
                                        formItems={formItems}
                                        formikProps={formikProps}
                                        isPrimaryApplicant={isPrimaryApplicant}
                                        arrayHelpers={arrayHelpers}
                                    />
                                </Paper>
                            );
                        },
                    )}
                </div>
            );
        }}
    />
);

export default AddressRepeaterView;
