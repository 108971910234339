import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';

export interface SliderProps {
    className?: string;
    timer?: number;
    animationDelay?: number;
    showNavigator?: boolean;
    center?: boolean;
    nonRepeatable?: boolean;
    isStopped?: boolean;
    children: any;
}

const nextAnimation = (
    indexes: any,
    itemsRef: any,
    delay: number,
    nonRepeatable?: boolean,
    timerCurrent?: any,
) => {
    const prevIndexes = indexes.current;

    if (itemsRef.current) {
        itemsRef.current[
            prevIndexes.current
        ].style.transition = `left ${delay}ms ease-in-out`;
        itemsRef.current[prevIndexes.current].style.left = '0px';

        if (prevIndexes.prev != null) {
            itemsRef.current[
                prevIndexes.prev
            ].style.transition = `left ${delay}ms ease-in-out`;
            itemsRef.current[prevIndexes.prev].style.left = '-100%';
        }

        itemsRef.current[prevIndexes.next].style.transition = 'none';
        itemsRef.current[prevIndexes.next].style.left = '100%';
    }

    if (prevIndexes.next === 0 && nonRepeatable) {
        clearInterval(timerCurrent);
        return;
    }

    indexes.current = {
        all: prevIndexes.all,
        prev: prevIndexes.current,
        current: prevIndexes.next,
        next: prevIndexes.all > prevIndexes.next + 1 ? prevIndexes.next + 1 : 0,
    };
};

const Slider = (props: SliderProps) => {
    const indexes = useRef<any>({
        all: React.Children.count(props.children),
    });

    const containerRef = useRef<any>([]);
    const itemsRef = useRef<any[]>([]);
    const timer = useRef<any>(null);
    const timeInterval = useRef<any>(props.timer || 3000);

    useEffect(() => {
        indexes.current = {
            ...indexes.current,
            prev: null,
            current: 0,
            next: 1,
        };

        nextAnimation(
            indexes,
            itemsRef,
            props.animationDelay || 700,
            props.nonRepeatable,
            timer.current,
        );

        if (!props.isStopped) {
            timer.current = setInterval(() => {
                nextAnimation(
                    indexes,
                    itemsRef,
                    props.animationDelay || 700,
                    props.nonRepeatable,
                    timer.current,
                );
            }, timeInterval.current);

            return () => {
                if (timer.current) {
                    clearInterval(timer.current);
                }
            };
        }
    }, [props.isStopped, props.animationDelay, props.nonRepeatable]);

    return (
        <>
            <div
                ref={(ref) => (containerRef.current = ref)}
                className={clsx(
                    props.className,
                    'relative w-full overflow-hidden',
                )}
            >
                {React.Children.map(props.children, (child: any, index) => {
                    return (
                        <div
                            ref={(ref) => itemsRef.current.push(ref)}
                            className={clsx(
                                'absolute w-full',
                                props.center &&
                                    'transform -translate-y-1/2 top-1/2',
                            )}
                            key={`slider-item_${index}`}
                            style={{ left: '100%' }}
                        >
                            {child}
                        </div>
                    );
                })}

                {props.showNavigator && (
                    <div className='flex flex-row justify-center absolute bottom-1.5 left-1/2 transform -translate-x-1/2'>
                        {React.Children.map(
                            props.children,
                            (_child: any, index) => {
                                return (
                                    <div
                                        className='p-0.5 h-1.5 cursor-pointer bg-gray-500 ml-2 mr-2 w-10 rounded-md'
                                        onClick={() => {
                                            if (timer.current) {
                                                clearInterval(timer.current);
                                            }
                                            while (
                                                indexes.current.prev !== index
                                            ) {
                                                nextAnimation(
                                                    indexes,
                                                    itemsRef,
                                                    props.animationDelay || 700,
                                                );
                                            }
                                            timer.current = setInterval(() => {
                                                nextAnimation(
                                                    indexes,
                                                    itemsRef,
                                                    props.animationDelay || 700,
                                                );
                                            }, timeInterval.current);
                                        }}
                                    />
                                );
                            },
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default Slider;
