import { FC, useState } from 'react';

import {
    Button,
    ChevronDownIcon,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import clsx from 'clsx';
import { FieldArray } from 'formik';
import { get } from 'lodash';
import { IoTrash } from 'react-icons/io5';

import { toCurrency } from '@Shared/helpers';
import { radioButtonOptions } from '@src/shared/helpers/input';
import { Label } from '@src/ui/components/Label';
import { LinkButton } from '@src/ui/components/LinkButton';
import { Paper } from '@src/ui/components/Paper';

import SelectBox from '../../components/SelectBox/SelectBox';

const IncomesRepeater: FC<any> = ({
    arrayHelpers,
    values,
    prefix,
    setVisible,
}) => {
    const incomes = get(values, `${prefix}.incomes`) || [];

    return (
        <div className='grid gap-1.5 mt-2.5 '>
            {incomes.map((_item: any, index: number) => {
                const itemName = `${prefix}.incomes[${index}]`;

                return (
                    <Paper
                        key={`${prefix}.other_income_${index}`}
                        className={
                            'py-4 flex hover:shadow-md justify-between flex-1 bg-neutral-40 flex-col'
                        }
                    >
                        <div className={'flex justify-between flex-1'}>
                            <Label className='font-semibold text-steel-60 text-xl'>
                                {get(values, `${itemName}.type`)}
                            </Label>
                            <LinkButton
                                title={'Remove'}
                                onClick={() => {
                                    if (incomes.length > 0) {
                                        setVisible(true);
                                    }
                                    arrayHelpers.remove(index);
                                }}
                                className=' mb-0.5'
                            >
                                <IoTrash />
                                <p className='text-sm font-bold'>Delete</p>
                            </LinkButton>
                        </div>
                        <Label className='font-bold text-sm_title'>
                            £{toCurrency(get(values, `${itemName}.amount`))}
                        </Label>
                    </Paper>
                );
            })}
        </div>
    );
};

interface IncomingOtherIncomesSubViewProps {
    formikProps: any;
    getPropsWithError: Function;
    getError: Function;
    addButtonTitle: string;
    title: string;
    alwaysShowTitle?: boolean;
    className?: string;
    fieldPrefix?: string;
}

const IncomingOtherIncomesSubView: FC<IncomingOtherIncomesSubViewProps> = ({
    formikProps,
    getPropsWithError,
    getError,
    addButtonTitle,
    className,
    fieldPrefix,
}) => {
    const [visible, setVisible] = useState(false);
    const prefix = `${fieldPrefix ? `${fieldPrefix}.` : ''}otherIncomes`;

    const values = formikProps.values;
    const hasAny = get(values, `${prefix}.hasAny`, false);
    const valuesAreValid =
        get(values, `${prefix}.tmp.type`, 'Select') !== 'Select' &&
        get(values, `${prefix}.tmp.amount`, 0) > 0;
    const resetTmpFields = () => {
        try {
            // if the fields are not exists yet touched cannot be set
            formikProps.setFieldValue(`${prefix}.tmp.type`, 'Select');
            formikProps.setFieldValue(`${prefix}.tmp.amount`, '');
            delete formikProps.touched.otherIncomes;
        } catch {}
        setVisible(false);
    };

    const getIncomingsError = () => {
        const incomingsError = get(
            formikProps.errors,
            'otherIncomes.incomes',
            null,
        );
        const isTypeTouched = formikProps.getFieldMeta(
            `${prefix}.tmp.type`,
        ).touched;
        const isAmountTouched = formikProps.getFieldMeta(
            `${prefix}.tmp.amount`,
        ).touched;
        const isFormTouched = formikProps.getFieldMeta(
            'otherIncomes.incomes',
        ).touched;

        if ((isTypeTouched && isAmountTouched) || isFormTouched) {
            return incomingsError;
        }

        return null;
    };

    const incomingsError = getIncomingsError();
    const hasAnyValue =
        get(values, `${prefix}.hasAny`) === undefined ? '' : hasAny;

    return (
        <div className={className}>
            <RadioButtonGroup
                name={`${prefix}.hasAny`}
                value={hasAnyValue.toString()}
                onChange={(e: any) => {
                    const value = e.currentTarget.value === 'true';
                    if (hasAny !== value) {
                        resetTmpFields();
                        formikProps.setFieldValue(`${prefix}.incomes`, []);
                    }
                    if (!hasAny && value) {
                        setVisible(true);
                    }
                    formikProps.setFieldValue(`${prefix}.hasAny`, value);
                }}
                error={{
                    hasError: false,
                    errorMessage: getError(`${prefix}.hasAny`),
                }}
                options={radioButtonOptions}
            />

            {hasAny && (
                <FieldArray
                    name={`${prefix}.incomes`}
                    render={(arrayHelpers) => {
                        const incomes = get(values, `${prefix}.incomes`) || [];

                        return (
                            <div className='mt-6'>
                                <IncomesRepeater
                                    arrayHelpers={arrayHelpers}
                                    values={values}
                                    setVisible={setVisible}
                                    prefix={prefix}
                                />
                                {visible && (
                                    <Paper
                                        className={clsx(
                                            incomes.length === 0
                                                ? 'mt-0'
                                                : 'mt-4',
                                            incomingsError ? 'invalid' : '',
                                            'bg-neutral-40',
                                        )}
                                    >
                                        <SelectBox
                                            {...getPropsWithError(
                                                `${prefix}.tmp.type`,
                                            )}
                                            suffix={<ChevronDownIcon />}
                                        />
                                        <br />
                                        {get(values, `${prefix}.tmp.type`) !==
                                            'Select' && (
                                            <>
                                                <TextField
                                                    prefix='£'
                                                    {...getPropsWithError(
                                                        `${prefix}.tmp.amount`,
                                                    )}
                                                />
                                                <Button
                                                    className={
                                                        'w-60 ml-auto w-full mt-7'
                                                    }
                                                    size='large'
                                                    color='secondary'
                                                    disabled={!valuesAreValid}
                                                    onClick={() => {
                                                        arrayHelpers.push({
                                                            type: get(
                                                                values,
                                                                `${prefix}.tmp.type`,
                                                            ),
                                                            amount: get(
                                                                values,
                                                                `${prefix}.tmp.amount`,
                                                            ),
                                                        });
                                                        resetTmpFields();
                                                    }}
                                                >
                                                    Save income
                                                </Button>
                                            </>
                                        )}
                                        {incomingsError && (
                                            <div className='mt-4'>
                                                <div className='mr-2 text-danger text-sm_body text-right ml-4 '>
                                                    {incomingsError}
                                                </div>
                                            </div>
                                        )}
                                    </Paper>
                                )}
                                {!visible && (
                                    <Button
                                        size='large'
                                        color='outline-dark'
                                        className='w-full mt-4 mb-4 text-center'
                                        onClick={() => setVisible(true)}
                                    >
                                        {addButtonTitle}
                                    </Button>
                                )}
                            </div>
                        );
                    }}
                />
            )}
        </div>
    );
};

export default IncomingOtherIncomesSubView;
