import { FormEventHandler } from 'react';

import clsx from 'clsx';

const className = {
    item: 'block hover:bg-gray-100 px-4 py-2 w-full text-left text-lg',
    item_first: 'first:rounded-t-lg',
    item_last: 'last:rounded-b-lg',
};

export interface DropdownItemProps {
    onClick?: FormEventHandler<HTMLButtonElement>;
    children: any;
}

const DropdownItem = (props: DropdownItemProps) => {
    const classNames = clsx(
        className.item,
        className.item_first,
        className.item_last,
    );
    return (
        <button className={classNames} type={'button'} onClick={props.onClick}>
            {props.children}
        </button>
    );
};

export default DropdownItem;
