import { FC, useContext } from 'react';

import { ApplicationContext } from '@App/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import RejectView from '../views/eligibility/RejectView';

const Reject: FC = () => {
    const { loading } = useContext(ApplicationContext);

    return (
        <EligibilityLayout loading={loading}>
            <RejectView />
        </EligibilityLayout>
    );
};

export default Reject;
