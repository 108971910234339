import {
    ForwardedRef,
    ReactNode,
    Ref,
    forwardRef,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Listbox } from '@headlessui/react';
import { Controller, ErrorIcon, RoundedSuccessIcon } from '@selina-finance/ui';
import { LabelSizeType } from '@selina-finance/ui/build/components/Controller';
import { SelectBoxItem } from '@selina-finance/ui/build/components/SelectBox';
import { isEmpty, omit } from 'lodash';
import styled from 'styled-components';

interface SelectBoxProps {
    label?: string;
    labelSize?: LabelSizeType;
    hideErrorText?: boolean;
    name?: string;
    id?: string;
    children?: ReactNode;
    className?: string;
    suffix?: ReactNode | string;
    prefix?: ReactNode | string;
    items?: SelectBoxItem[];
    error?: string;
    validState?: boolean;
    hideValidatorIcons?: boolean;
    assistiveText?: string;
    placeholder?: string;
    value: string;
    open?: boolean;
    onChange?: Function;
    onBlur?: Function;
    selectBoxButtonRef?: Ref<HTMLButtonElement>;
    topposition?: string;
    small?: string;
    hide?: boolean;
}

const ListBoxStylesWrapper = styled.div<SelectBoxProps>`
    font-family: ${({ theme }) => theme.fonts.body};
    width: 100%;
    cursor: pointer;

    .listbox {
        width: 100%;
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.colors.mono[0]};
        font-family: ${({ theme }) => theme.fonts.default};
        position: relative;
    }

    .listbox-button {
        border-radius: ${({ theme }) => theme.borderRadius.average};
        border: 1px solid ${({ theme }) => theme.colors.steel[100]};
        ${(props) => props.open && 'border-bottom-left-radius: 0px'};
        ${(props) => props.open && 'border-bottom-right-radius: 0px'};
        width: 100%;
        min-height: 48px;
        background-color: ${({ theme }) => theme.colors.mono[0]};
        display: flex;
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;

        & span {
            text-align: left;
            padding: 12px 0 12px 10px;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            flex-grow: 1;

            &.unselected {
                color: ${({ theme }) => theme.colors.steel[40]};
            }
        }

        & span.selected {
            color: ${({ theme }) => theme.colors.steel[100]};
        }

        &:hover {
            background-color: ${({ theme }) => theme.colors.mono[20]};
        }

        &:focus {
            box-shadow: 0 0 0 3 ${({ theme }) => theme.colors.highlight[100]};
        }

        ${(props) =>
            props.validState &&
            `
			box-shadow: 0px 0px 0px 1.5px ${props.theme.colors.success[100]};
			border-bottom-left-radius: ${
                props.open ? '1px' : props.theme.borderRadius.average
            };
			border-bottom-right-radius: ${
                props.open ? '1px' : props.theme.borderRadius.average
            };
		`}

        ${(props) =>
            !!props.error &&
            `
			box-shadow: 0px 0px 0px 3px ${props.theme.colors.error[100]};
			border-bottom-left-radius: ${
                props.open ? '1px' : props.theme.borderRadius.average
            };
			border-bottom-right-radius: ${
                props.open ? '1px' : props.theme.borderRadius.average
            };
		`}
    }

    .prefix,
    .suffix {
        font-family: ${({ theme }) => theme.fonts.body};
        background: ${({ theme }) => theme.colors.neutral[40]};
        display: flex;
        padding: 0 16px;
        align-items: center;
        align-self: stretch;
        justify-content: center;
        min-height: 48px;
        font-size: 20px;
    }

    .prefix {
        border-radius: ${({ theme }) => theme.borderRadius.average} 0 0
            ${({ theme }) => theme.borderRadius.average};
        border-right: 1px solid ${({ theme }) => theme.colors.steel[100]};
    }

    .suffix {
        border-radius: 0 ${({ theme }) => theme.borderRadius.average}
            ${({ theme }) => theme.borderRadius.average} 0;
        border-left: 1px solid ${({ theme }) => theme.colors.steel[100]};
    }

    .listbox-options {
        max-height: 360px; //not working, untested?
        margin: 0;
        padding: 0;
        overflow-y: auto;
        position: absolute;
        ${(props) => (props.topposition ? 'bottom: 100%' : 'top: 100%')};
        right: 0;
        left: 0;
        background-color: white;
        z-index: 10;
        border: 1px solid ${({ theme }) => theme.colors.steel[100]};
        outline: none;
        border-top: none;
        border-radius: 0 0 ${({ theme }) => theme.borderRadius.average}
            ${({ theme }) => theme.borderRadius.average};

        & li:first-child {
            border-top: none !important;
        }

        & li:last-child {
            border-bottom: none !important;
        }
    }

    .listbox-option {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 48px;
        list-style: none;
        color: ${({ theme }) => theme.colors.steel[100]};
        font-size: 16px;
        padding-left: 16px;
        font-weight: 400;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:hover {
            border-top: 1px solid ${({ theme }) => theme.colors.steel[100]};
            border-bottom: 1px solid ${({ theme }) => theme.colors.steel[100]};
            background: ${({ theme }) => theme.colors.neutral[40]};
        }
    }

    .state-svg {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        padding: 12px 10px 12px 0;
    }
`;

interface IfProps {
    condition?: boolean;
    children: any;
}
const If = ({ condition, children }: IfProps) => {
    return condition ? <>{children}</> : null;
};

const generateTarget = (value: any, name?: string) => {
    return {
        name: name,
        target: { value: value },
        currentTarget: { value: value },
    };
};

const SelectBox = forwardRef(
    (props: SelectBoxProps, ref: ForwardedRef<HTMLSelectElement>) => {
        const [selected, setSelected] = useState<string>(props.value);

        useEffect(() => {
            if (props.value !== selected) {
                setSelected(props.value);
            }
        }, [props.value, selected]);

        const [isOpen, setIsOpen] = useState(false);

        const errorState = useMemo(
            () => !!props.error && !isOpen,
            [props.error, isOpen],
        );
        const validState = useMemo(
            () => !!props.validState,
            [props.validState],
        );

        const idPrefix = useMemo(() => {
            return props.id || props.name;
        }, [props.id, props.name]);

        if (!props.items || props.hide) {
            return null;
        }

        return (
            <Controller
                {...omit(
                    props,
                    'suffix',
                    'prefix',
                    'validState',
                    'name',
                    'hideValidatorIcons',
                    'selectBoxButtonRef',
                    'error',
                )}
                valid={validState}
                id={props.id || props.name}
                hideErrorText={props.hideErrorText}
                error={!isOpen ? props.error : ''}
                className={`${props.small && 'w-48'}`}
            >
                <ListBoxStylesWrapper
                    {...omit(
                        props,
                        'suffix',
                        'prefix',
                        'onChange',
                        'onBlur',
                        'onFocus',
                        'selectBoxButtonRef',
                    )}
                    open={isOpen ? true : false}
                    error={!isOpen ? props.error : ''}
                    topposition={props.topposition}
                >
                    <Listbox
                        as='div'
                        value={props.value}
                        className={'listbox'}
                        ref={ref as any}
                        onChange={(e: any) => {
                            setSelected(e.value);
                            if (props.onChange) {
                                props.onChange(
                                    generateTarget(e.value, idPrefix),
                                );
                            }
                        }}
                        onBlur={(e: any) => {
                            const isSameTarget =
                                e?.target?.attributes?.['data-refid']?.value !==
                                e?.relatedTarget?.attributes?.['data-refid']
                                    ?.value;
                            if (props.onBlur && isSameTarget) {
                                props.onBlur(e);
                            }
                        }}
                    >
                        {({ open }) => {
                            setTimeout(() => setIsOpen(open), 0);
                            return (
                                <>
                                    <Listbox.Button
                                        ref={props.selectBoxButtonRef}
                                        className={'listbox-button'}
                                        data-refid={`${idPrefix}_listbox`}
                                        {...omit(
                                            props,
                                            'items',
                                            'onChange',
                                            'onBlur',
                                            'onFocus',
                                            'suffix',
                                            'prefix',
                                            'children',
                                            'id',
                                            'name',
                                            'hideErrorText',
                                            'placeholder',
                                            'validState',
                                            'hideValidatorIcons',
                                            'assistiveText',
                                            'labelSize',
                                            'selectBoxButtonRef',
                                        )}
                                    >
                                        {/* PREFIX */}
                                        <If condition={!!props.prefix}>
                                            <div className='prefix'>
                                                {props.prefix}
                                            </div>
                                        </If>
                                        {!isEmpty(props.value) ? (
                                            <span className='selected'>
                                                {selected}
                                            </span>
                                        ) : (
                                            <span className='unselected'>
                                                {' '}
                                                {props.placeholder ||
                                                    'Please select'}{' '}
                                            </span>
                                        )}

                                        {/* STATE SVG */}
                                        <If
                                            condition={
                                                validState &&
                                                !props.hideValidatorIcons
                                            }
                                        >
                                            <div className='state-svg'>
                                                <RoundedSuccessIcon />
                                            </div>
                                        </If>
                                        <If
                                            condition={
                                                errorState &&
                                                !open &&
                                                !props.hideValidatorIcons
                                            }
                                        >
                                            <div className='state-svg'>
                                                <ErrorIcon />
                                            </div>
                                        </If>

                                        {/* SUFFIX */}
                                        <If condition={!!props.suffix}>
                                            <div className='suffix'>
                                                {props.suffix}
                                            </div>
                                        </If>
                                    </Listbox.Button>

                                    <Listbox.Options
                                        className={`listbox-options && ${
                                            open ? 'open' : 'close'
                                        }`}
                                        onBlur={() => setIsOpen(false)}
                                        data-refid={`${idPrefix}_listbox`}
                                    >
                                        {props.items?.map((item: any) => (
                                            <Listbox.Option
                                                className={'listbox-option'}
                                                key={item.value}
                                                value={item}
                                                data-refid={`${idPrefix}_listbox`}
                                            >
                                                {item.label}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </>
                            );
                        }}
                    </Listbox>
                </ListBoxStylesWrapper>
            </Controller>
        );
    },
);

export default SelectBox;
