import { CustomFormProps } from '../shared';

import {
    commonDecrease,
    otherIncome,
    otherIncomeForEmployed,
} from './incomings.shared';

export const notInPaid: CustomFormProps = {
    ...otherIncome(otherIncomeForEmployed),
    ...commonDecrease,
};
