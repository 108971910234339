import { useContext, useRef } from 'react';

import { ApplicationContext } from '@src/app/contexts/Application';

import EligibilityLayout from '../layouts/EligibilityLayout';
import LoanDetailsView from '../views/eligibility/LoanDetailsView';

const LoanDetails = () => {
    const { loading, loanDetails, saveLoanDetails } =
        useContext(ApplicationContext);
    const saveRef = useRef(null);
    return (
        <EligibilityLayout loading={loading} ref={saveRef}>
            <LoanDetailsView
                ref={saveRef}
                loanDetailsData={loanDetails}
                saveLoanDetails={saveLoanDetails}
            />
        </EligibilityLayout>
    );
};

export default LoanDetails;
