export interface PercentageCompleteIconProps {
    className?: string;
}

const PercentageCompleteIcon = (props: PercentageCompleteIconProps) => {
    return (
        <svg
            {...props}
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.9543 40.5 0 31.5457 0 20.5C0 9.4543 8.9543 0.5 20 0.5C31.0457 0.5 40 9.4543 40 20.5ZM4.946 20.5C4.946 28.8141 11.6859 35.554 20 35.554C28.3141 35.554 35.054 28.8141 35.054 20.5C35.054 12.1859 28.3141 5.446 20 5.446C11.6859 5.446 4.946 12.1859 4.946 20.5Z'
                fill='#E3DDD1'
            />
            <path
                d='M40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.9543 40.5 0 31.5457 0 20.5C0 9.4543 8.9543 0.5 20 0.5C31.0457 0.5 40 9.4543 40 20.5ZM4.946 20.5C4.946 28.8141 11.6859 35.554 20 35.554C28.3141 35.554 35.054 28.8141 35.054 20.5C35.054 12.1859 28.3141 5.446 20 5.446C11.6859 5.446 4.946 12.1859 4.946 20.5Z'
                fill='#A6666A'
            />
            <path
                d='M12.4144 20.2853C11.6334 21.0663 11.6334 22.3327 12.4144 23.1137L16.1301 26.8294C16.529 27.2283 17.0545 27.4235 17.5773 27.4149C18.0782 27.4067 18.5765 27.2115 18.9586 26.8294L27.3299 18.4582C28.1109 17.6771 28.1109 16.4108 27.3299 15.6297C26.5488 14.8487 25.2825 14.8487 24.5014 15.6297L17.5444 22.5868L15.2428 20.2853C14.4618 19.5042 13.1954 19.5042 12.4144 20.2853Z'
                fill='#A6666A'
            />
        </svg>
    );
};

export default PercentageCompleteIcon;
