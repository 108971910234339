import { ChangeEvent, FC } from 'react';

import {
    ChevronDownIcon,
    Controller,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    radioButtonOptions,
} from '@Shared/helpers/input';
import { employed } from '@src/app/formModels/eligibility/incomings.employed';
import IncomingOtherIncomesSubView from '@src/ui/views/eligibility/IncomingOtherIncomesSubView';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import LightbulbIcon from '../../components/Icons/LightbulbIcon';
import SelectBox from '../../components/SelectBox/SelectBox';

import ExpectedDecrease from './IncomingExpectedDecreaseField';

export interface IncomingEmployedViewProps {
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    isPrimaryApplicant: boolean;
    setRef?: Function;
}

const defaultValues = getDefaultValues(employed);
const validationSchema = getValidationSchema(employed);

const IncomingEmployedView: FC<IncomingEmployedViewProps> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(props.incomeData, Object.keys(employed)),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => values}
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getPropsWithError, getError } = getFormHelpers(
                    employed,
                    formikProps,
                );

                if (
                    !formikProps.values.employment.startMonth &&
                    !formikProps.values.employment.startYear
                ) {
                    formikProps.values.employment.startMonth = '';
                    formikProps.values.employment.startYear = '';
                }

                if (formikProps.values.employment.contractStartDate) {
                    formikProps.values.employment.startMonth =
                        formikProps.values.employment.contractStartDate.split(
                            '/',
                        )[1];
                    formikProps.values.employment.startYear =
                        formikProps.values.employment.contractStartDate.split(
                            '/',
                        )[2];
                }

                return (
                    <div className='grid grid-cols-1 gap-10 m-0'>
                        <TextField
                            name='employment.registeredCompanyName'
                            label={`What ${
                                props.isPrimaryApplicant
                                    ? 'is your employer’s registered company name?'
                                    : 'company do they work for?'
                            }`}
                            maxLength={255}
                            value={
                                formikProps.values.employment.registeredCompanyName?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('employment.registeredCompanyName')}
                        />
                        <Controller
                            label={`What was ${
                                props.isPrimaryApplicant ? 'your' : 'their'
                            } start date?`}
                        >
                            <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                                <SelectBox
                                    items={employed.employment.month.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Month'
                                    labelSize='small'
                                    name='startMonth'
                                    placeholder='Select'
                                    validState={
                                        !!formikProps.values.employment
                                            .startMonth
                                    }
                                    value={
                                        formikProps.values.employment.startMonth?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractStartDate = `01/${e.currentTarget.value}/${formikProps.values.employment.startYear}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.employment.startMonth}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'employment.contractStartDate',
                                            contractStartDate,
                                        );
                                        formikProps.setFieldValue(
                                            'employment.startMonth',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('employment.startMonth')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.employment.startMonth}`]:
                                                true,
                                        });
                                    }}
                                />
                                <SelectBox
                                    items={employed.employment.year.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Year'
                                    labelSize='small'
                                    name='startYear'
                                    placeholder='Select'
                                    validState={
                                        !!formikProps.values.employment
                                            .startYear
                                    }
                                    value={
                                        formikProps.values.employment.startYear?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractStartDate = `01/${formikProps.values.employment.startMonth}/${e.currentTarget.value}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.employment.startYear}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'employment.contractStartDate',
                                            contractStartDate,
                                        );
                                        formikProps.setFieldValue(
                                            'employment.startYear',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('employment.startYear')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.employment.startYear}`]:
                                                true,
                                        });
                                    }}
                                />
                            </div>
                        </Controller>
                        <Controller
                            label={`Do ${
                                props.isPrimaryApplicant
                                    ? 'you own more than 25% of the company in shares?'
                                    : 'they own more than 25% of the company?'
                            }`}
                        >
                            <RadioButtonGroup
                                name='employment.ownSharesInThisCompany'
                                id='employment.ownSharesInThisCompany'
                                value={
                                    formikProps.values.employment.ownSharesInThisCompany?.toString() ||
                                    ''
                                }
                                error={{
                                    hasError: false,
                                    errorMessage: getError(
                                        'employment.ownSharesInThisCompany',
                                    ),
                                }}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    formikProps.setTouched({
                                        ...formikProps.touched,
                                        [`${formikProps.values.employment.ownSharesInThisCompany}}`]:
                                            true,
                                    });
                                    formikProps.handleChange(e);
                                }}
                                options={radioButtonOptions}
                            />
                        </Controller>
                        <TextField
                            name='employment.jobTitle'
                            label={`What is ${
                                props.isPrimaryApplicant ? 'your' : 'their'
                            } job title?`}
                            maxLength={255}
                            value={
                                formikProps.values.employment.jobTitle?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('employment.jobTitle')}
                        />
                        <Controller
                            label={`What is ${
                                props.isPrimaryApplicant ? 'your' : 'their'
                            } base salary?`}
                            additionalInfo='This should be the annual, pre-tax amount'
                        >
                            <div className='bg-neutral-40 flex p-3 rounded-lg mb-2'>
                                <div className='mr-2'>
                                    <LightbulbIcon />
                                </div>
                                <div>
                                    <p className='text-steel-100 font-semibold'>
                                        We may ask for proof of income later.
                                    </p>
                                </div>
                            </div>
                            <TextField
                                name='incomeAmount'
                                mask={'currency' as any}
                                prefix='£'
                                maxLength={255}
                                value={
                                    formikProps.values.incomeAmount?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('incomeAmount')}
                            />
                        </Controller>
                        <Controller
                            label={`Do ${
                                props.isPrimaryApplicant ? 'you' : 'they'
                            } earn any other income?`}
                            additionalInfo='Such as bonus, dividends, pension, second job.'
                        >
                            <IncomingOtherIncomesSubView
                                getError={getError}
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                title={'Other incomes'}
                                addButtonTitle={'Add another income source'}
                            />
                        </Controller>
                        <Controller
                            label={`At what age do you think ${
                                props.isPrimaryApplicant ? "you'll" : "they'll"
                            } retire?`}
                            additionalInfo='For most people this will be 66'
                        >
                            <TextField
                                name='estimatedRetirementAge'
                                mask='number'
                                maxLength={2}
                                value={
                                    formikProps.values.estimatedRetirementAge?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('estimatedRetirementAge')}
                            />
                        </Controller>
                        <Controller
                            label={`Do you expect ${
                                props.isPrimaryApplicant ? 'your' : 'their'
                            } income to decrease in the near future?`}
                        >
                            <ExpectedDecrease
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <ErrorContainer formikProps={formikProps} />
                    </div>
                );
            }}
        </Formik>
    );
};

export default IncomingEmployedView;
