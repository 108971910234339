import { DetailedHTMLProps, HTMLAttributes } from 'react';

export interface RectangleIconProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const RectangleIcon = (props: RectangleIconProps) => {
    return (
        <div>
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='160'
                height='20'
                viewBox='0 0 160 20'
                fill='none'
            >
                {props.children}
                <path
                    opacity='0.5'
                    d='M7.5 0H150L142.5 17H0L7.5 0Z'
                    fill='#C3999B'
                />
            </svg>
        </div>
    );
};

export default RectangleIcon;
