import { useEffect, useRef, useState } from 'react';

import { Button, Controller } from '@selina-finance/ui';
import { chain, get, isEmpty } from 'lodash';

import {
    isMoreThanThreeYears,
    isMoreThanThreeYearsFromApplication,
} from '@Shared/helpers/dateDifference';
import { AddressSection } from '@Views/eligibility/addressRepeaterView/views/AddressSection/AdressSection';
import { InputSection } from '@Views/eligibility/addressRepeaterView/views/InputSection/InputSection';

interface IAddressViewProps {
    index: number;
    addressFieldName: string;
    formItems: any;
    formikProps: any;
    isPrimaryApplicant: boolean;
    arrayHelpers: any;
}

export const AddressRepeaterViewContent = ({
    index,
    addressFieldName,
    formItems,
    formikProps,
    arrayHelpers,
    isPrimaryApplicant,
}: IAddressViewProps) => {
    const [isAddressSectionEdit, setIsAddressSectionEdit] = useState(false);
    const [isInputSectionEdit, setIsInputSectionEdit] = useState(false);
    const [notTouched, setNotTouched] = useState(false);
    const fromDate = formikProps.values.addresses[index].livedIn.from;

    const hasItemAddedRef = useRef(false);

    const isMoreThanThree = isMoreThanThreeYears(formikProps.values.addresses);
    const isMoreThanThreeFromApplication = isMoreThanThreeYearsFromApplication(
        formikProps.values.addresses,
    );

    useEffect(() => {
        if (index === 0 && fromDate === '') {
            setIsInputSectionEdit(true);
            setIsAddressSectionEdit(false);
        }
    }, [fromDate, index]);

    useEffect(() => {
        if (
            index !== 0 &&
            isEmpty(formikProps.values.addresses[index].address)
        ) {
            setIsAddressSectionEdit(true);
            setIsInputSectionEdit(true);
        }

        if (
            index === 0 &&
            isMoreThanThreeFromApplication === false &&
            isMoreThanThree === false &&
            !hasItemAddedRef.current
        ) {
            arrayHelpers.push({
                address: {},
                livedIn: {},
            });

            hasItemAddedRef.current = true;
        }
    }, [
        fromDate,
        index,
        formikProps.values.addresses,
        isMoreThanThreeFromApplication,
        arrayHelpers,
        isMoreThanThree,
    ]);

    const onSaveValidateFields = [
        `${addressFieldName}[${index}].livedIn.from`,
        `${addressFieldName}[${index}].livedIn.to`,
        `${addressFieldName}[${index}].address`,
    ];

    const onSave = async () => {
        await onSaveValidateFields.forEach((it) => {
            formikProps.setFieldTouched(it);
            formikProps.validateField(it);
        });

        const error = chain(onSaveValidateFields)
            .map((field) => get(formikProps.errors, field))
            .compact()
            .value();

        setNotTouched(false);

        if (error.length === 0 && fromDate) {
            setNotTouched(true);
            setIsAddressSectionEdit(false);
            setIsInputSectionEdit(false);

            !isMoreThanThree &&
                arrayHelpers.push({
                    address: {},
                    livedIn: {},
                });

            removeExtraAddresses();
        }
    };

    const removeExtraAddresses = () => {
        const allElementsBeforeCurrent = formikProps.values.addresses.filter(
            (_: any, currentIndex: number) => {
                return currentIndex <= index;
            },
        );

        if (isMoreThanThreeYears(allElementsBeforeCurrent)) {
            for (let i = formikProps.values.addresses.length - 1; i >= 0; i--) {
                if (i > index) {
                    arrayHelpers.remove(i);
                }
            }
        }
    };

    return (
        <Controller
            label={index === 0 ? 'Current address' : 'Previous address'}
        >
            <AddressSection
                index={index}
                notTouched={notTouched}
                isEdit={isAddressSectionEdit}
                addressFieldName={addressFieldName}
                formItems={formItems}
                formikProps={formikProps}
                setIsInputSectionEdit={setIsInputSectionEdit}
                setIsAddressSectionEdit={setIsAddressSectionEdit}
            />
            <InputSection
                index={index}
                isEdit={isInputSectionEdit}
                notTouched={notTouched}
                addressFieldName={addressFieldName}
                formItems={formItems}
                formikProps={formikProps}
                isPrimaryApplicant={isPrimaryApplicant}
            />

            {isInputSectionEdit && (
                <div className='pt-6'>
                    <Button
                        type='button'
                        size='large'
                        color='secondary'
                        className='w-full'
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </div>
            )}
        </Controller>
    );
};
