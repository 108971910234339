const LightbulbIcon = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M6.875 18.125H13.125'
                stroke='#3A4146'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10 15.625V11.25'
                stroke='#3A4146'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.5 8.75L10 11.25L12.5 8.75'
                stroke='#3A4146'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.1485 13.0469C5.40444 12.4683 4.8018 11.728 4.38626 10.882C3.97071 10.036 3.75315 9.10659 3.75007 8.16406C3.73444 4.77344 6.461 1.95313 9.85163 1.875C11.1639 1.84389 12.4526 2.22672 13.535 2.96919C14.6175 3.71166 15.4387 4.77609 15.8822 6.0115C16.3257 7.24691 16.369 8.5906 16.0059 9.85199C15.6428 11.1134 14.8918 12.2285 13.8594 13.0391C13.6314 13.2153 13.4466 13.4414 13.3194 13.7001C13.1921 13.9587 13.1256 14.243 13.1251 14.5313V15C13.1251 15.1658 13.0592 15.3247 12.942 15.4419C12.8248 15.5592 12.6658 15.625 12.5001 15.625H7.50007C7.33431 15.625 7.17534 15.5592 7.05813 15.4419C6.94091 15.3247 6.87507 15.1658 6.87507 15V14.5313C6.87311 14.2452 6.80679 13.9632 6.68101 13.7062C6.55524 13.4493 6.37324 13.2239 6.1485 13.0469V13.0469Z'
                stroke='#3A4146'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default LightbulbIcon;
