import { IntercomDataModel } from '@App/intercom/intercom-data.model';

export const intercomBoot = () => {
    if (window?.Intercom) {
        window.Intercom('boot', {
            app_id: window._env_.INTERCOM_ID,
            'Type of user': 'Consumer',
        });
    }
};

export const intercomShutdown = () => {
    if (window?.Intercom) {
        window.Intercom('shutdown');
    }
};

export const intercomTrackAcceptPageEvent = (data: IntercomDataModel) => {
    trackEvent('Quick quote accept', data);
};

export const intercomTrackRejectPageEvent = (data: IntercomDataModel) => {
    trackEvent('Quick quote reject', data);
};

const trackEvent = (
    event: 'Quick quote accept' | 'Quick quote reject',
    data: IntercomDataModel,
) => {
    if (window?.Intercom) {
        window.intercomSettings = {
            email: data.email,
            name: data.name,
            phone: data.phone,
        };
        window.Intercom('trackEvent', event);
    }
};
