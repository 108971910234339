import { useContext, useEffect } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import { Offer, RecommendedOffer } from '@src/app/interfaces/offer';
import EligibilityLayout from '@src/ui/layouts/EligibilityLayout';
import ExlporeView from '@src/ui/views/offer/ExploreView';

const Explore = () => {
    const {
        loading,
        fetchRecommendedOffers,
        offers,
        selectedOffer,
        setSelectedOffer,
        recommendedOffersData,
    } = useContext(ApplicationContext);

    useEffect(() => {
        const getOffers = async () => {
            if (!recommendedOffersData) {
                await fetchRecommendedOffers();
            }
        };

        getOffers();
    }, [fetchRecommendedOffers, recommendedOffersData]);

    const ids = recommendedOffersData?.offers.map(
        (offer: RecommendedOffer) => offer.id,
    );

    const recommendedOffers: Offer[] = offers.filter((offer: Offer) =>
        ids?.includes(offer.id),
    );

    return (
        <EligibilityLayout loading={loading} offerPage>
            <ExlporeView
                offers={recommendedOffers}
                selectedOffer={selectedOffer}
                onSelect={setSelectedOffer}
            />
        </EligibilityLayout>
    );
};

export default Explore;
