import { ChangeEvent, FC } from 'react';

import {
    ChevronDownIcon,
    Controller,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    radioButtonOptions,
} from '@Shared/helpers/input';
import { contractor } from '@src/app/formModels/eligibility/incomings.contractor';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import SelectBox from '../../components/SelectBox/SelectBox';

import ExpectedDecrease from './IncomingExpectedDecreaseField';
import IncomingOtherIncomesSubView from './IncomingOtherIncomesSubView';

export interface IncomingSelfEmployedViewProps {
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    setRef?: Function;
}

const defaultValues = getDefaultValues(contractor);
const validationSchema = getValidationSchema(contractor);

const IncomingContractorView: FC<IncomingSelfEmployedViewProps> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(props.incomeData, Object.keys(contractor)),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => values}
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getError, getPropsWithError } = getFormHelpers(
                    contractor,
                    formikProps,
                );

                if (formikProps.values.contractStartDate) {
                    formikProps.values.startMonth =
                        formikProps.values.contractStartDate.split('/')[1];
                    formikProps.values.startYear =
                        formikProps.values.contractStartDate.split('/')[2];
                }

                if (formikProps.values.contractEndDate) {
                    formikProps.values.endMonth =
                        formikProps.values.contractEndDate.split('/')[1];
                    formikProps.values.endYear =
                        formikProps.values.contractEndDate.split('/')[2];
                }

                if (formikProps.values.whenWasCompanyIncorporated) {
                    formikProps.values.incorp_month =
                        formikProps.values.whenWasCompanyIncorporated.split(
                            '/',
                        )[1];
                    formikProps.values.incorp_year =
                        formikProps.values.whenWasCompanyIncorporated.split(
                            '/',
                        )[2];
                }

                return (
                    <div className='grid grid-cols-1 gap-10 m-0'>
                        <Controller label='Are you working within IR35?'>
                            <RadioButtonGroup
                                name='isSoleTrader'
                                id='isSoleTrader'
                                value={
                                    formikProps.values.isSoleTrader?.toString() ||
                                    ''
                                }
                                error={{
                                    hasError: false,
                                    errorMessage: getError('isSoleTrader'),
                                }}
                                onChange={(e: any) => {
                                    const isSoletrader =
                                        e.currentTarget.value === 'true';
                                    if (!isSoletrader) {
                                        formikProps.setFieldValue(
                                            'registeredCompanyName',
                                            undefined,
                                        );
                                        formikProps.setFieldValue(
                                            'whenWasCompanyIncorporated',
                                            undefined,
                                        );
                                    }
                                    if (isSoletrader) {
                                        formikProps.setFieldTouched(
                                            'registeredCompanyName',
                                            false,
                                        );
                                        formikProps.setFieldTouched(
                                            'whenWasCompanyIncorporated',
                                            false,
                                        );
                                    }
                                    formikProps.setFieldValue(
                                        'isSoleTrader',
                                        isSoletrader,
                                    );
                                }}
                                options={radioButtonOptions}
                            />
                        </Controller>
                        {formikProps.values.isSoleTrader === false && (
                            <>
                                <TextField
                                    label='What is the company’s registered name?'
                                    name='registeredCompanyName'
                                    maxLength={255}
                                    value={
                                        formikProps.values
                                            ?.registeredCompanyName || ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => formikProps.handleChange(e)}
                                    onBlur={formikProps.handleBlur}
                                    error={getError('registeredCompanyName')}
                                />
                                <Controller label='When was your company incorporated?'>
                                    <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                                        <SelectBox
                                            items={contractor.month.items}
                                            suffix={<ChevronDownIcon />}
                                            label='Month'
                                            labelSize='small'
                                            placeholder='Select'
                                            name='incorp_month'
                                            validState={
                                                !!formikProps.values
                                                    ?.incorp_month
                                            }
                                            value={
                                                formikProps.values?.incorp_month?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                const whenWasCompanyIncorporated = `01/${e.currentTarget.value}/${formikProps.values.year}`;

                                                formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    [`${formikProps.values.incorp_month}}`]:
                                                        true,
                                                });
                                                formikProps.setFieldValue(
                                                    'whenWasCompanyIncorporated',
                                                    whenWasCompanyIncorporated,
                                                );
                                                formikProps.setFieldValue(
                                                    'incorp_month',
                                                    `${e.currentTarget.value}`,
                                                );
                                            }}
                                            error={getError('incorp_month')}
                                            onBlur={() => {
                                                return formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    incorp_month: true,
                                                });
                                            }}
                                        />
                                        <SelectBox
                                            items={contractor.year.items}
                                            suffix={<ChevronDownIcon />}
                                            label='Year'
                                            labelSize='small'
                                            placeholder='Select'
                                            name='incorp_year'
                                            validState={
                                                !!formikProps.values
                                                    ?.incorp_year
                                            }
                                            value={
                                                formikProps.values?.incorp_year?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                const whenWasCompanyIncorporated = `01/${formikProps.values.incorp_month}/${e.currentTarget.value}`;

                                                formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    [`${formikProps.values.incorp_year}}`]:
                                                        true,
                                                });
                                                formikProps.setFieldValue(
                                                    'whenWasCompanyIncorporated',
                                                    whenWasCompanyIncorporated,
                                                );
                                                formikProps.setFieldValue(
                                                    'incorp_year',
                                                    `${e.currentTarget.value}`,
                                                );
                                            }}
                                            error={getError('incorp_year')}
                                            onBlur={() => {
                                                return formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    incorp_year: true,
                                                });
                                            }}
                                        />
                                    </div>
                                </Controller>
                            </>
                        )}
                        <Controller label='Are you a first time contractor?'>
                            <RadioButtonGroup
                                name='firstTimeContractor'
                                id='firstTimeContractor'
                                value={
                                    formikProps.values.firstTimeContractor?.toString() ||
                                    ''
                                }
                                error={{
                                    hasError: false,
                                    errorMessage: getError(
                                        'firstTimeContractor',
                                    ),
                                }}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    formikProps.setTouched({
                                        ...formikProps.touched,
                                        firstTimeContractor: true,
                                    });
                                    formikProps.handleChange(e);
                                }}
                                options={radioButtonOptions}
                            />
                        </Controller>
                        <Controller label='When did your contract start?'>
                            <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                                <SelectBox
                                    items={contractor.month.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Month'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='startMonth'
                                    validState={
                                        !!formikProps.values?.startMonth
                                    }
                                    value={
                                        formikProps.values?.startMonth?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractStartDate = `01/${e.currentTarget.value}/${formikProps.values.startYear}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.startMonth}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'contractStartDate',
                                            contractStartDate,
                                        );
                                        formikProps.setFieldValue(
                                            'startMonth',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('startMonth')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            startMonth: true,
                                        });
                                    }}
                                />
                                <SelectBox
                                    items={contractor.year.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Year'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='startYear'
                                    validState={!!formikProps.values?.startYear}
                                    value={
                                        formikProps.values?.startYear?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractStartDate = `01/${formikProps.values.startMonth}/${e.currentTarget.value}`;
                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.startYear}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'contractStartDate',
                                            contractStartDate,
                                        );
                                        formikProps.setFieldValue(
                                            'startYear',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('startYear')}
                                    onBlur={() => {
                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            startYear: true,
                                        });

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            contractStartDate: true,
                                        });
                                    }}
                                />
                            </div>
                            {formikProps.values.startMonth &&
                                formikProps.values.startYear &&
                                formikProps.errors.contractStartDate && (
                                    <div className='text-error text-md -mt-5 inline'>
                                        {getError('contractStartDate')}
                                    </div>
                                )}
                        </Controller>
                        <Controller label='When does your contract end?'>
                            <div className='grid grid-cols-1 gap-4 items-end grid-cols-2'>
                                <SelectBox
                                    items={contractor.month.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Month'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='endMonth'
                                    validState={!!formikProps.values?.endMonth}
                                    value={
                                        formikProps.values?.endMonth?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractEndDate = `01/${e.currentTarget.value}/${formikProps.values.endYear}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.endMonth}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'contractEndDate',
                                            contractEndDate,
                                        );
                                        formikProps.setFieldValue(
                                            'endMonth',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('endMonth')}
                                    onBlur={() => {
                                        return formikProps.setTouched({
                                            ...formikProps.touched,
                                            endMonth: true,
                                        });
                                    }}
                                />
                                <SelectBox
                                    items={contractor.year.items}
                                    suffix={<ChevronDownIcon />}
                                    label='Year'
                                    labelSize='small'
                                    placeholder='Select'
                                    name='endYear'
                                    validState={!!formikProps.values?.endYear}
                                    value={
                                        formikProps.values?.endYear?.toString() ||
                                        ''
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const contractEndDate = `01/${formikProps.values.endMonth}/${e.currentTarget.value}`;

                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            [`${formikProps.values.endYear}}`]:
                                                true,
                                        });
                                        formikProps.setFieldValue(
                                            'contractEndDate',
                                            contractEndDate,
                                        );
                                        formikProps.setFieldValue(
                                            'endYear',
                                            `${e.currentTarget.value}`,
                                        );
                                    }}
                                    error={getError('endYear')}
                                    onBlur={() => {
                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            endYear: true,
                                        });
                                        formikProps.setTouched({
                                            ...formikProps.touched,
                                            contractEndDate: true,
                                        });
                                    }}
                                />
                            </div>
                            {formikProps.values.endMonth &&
                                formikProps.values.endYear &&
                                formikProps.errors.contractEndDate && (
                                    <div className='text-error text-md -mt-5 inline'>
                                        {getError('contractEndDate')}
                                    </div>
                                )}
                        </Controller>
                        <TextField
                            label='What is your contract day rate?'
                            name='contractDayRateReported'
                            mask={'currency' as any}
                            prefix='£'
                            maxLength={10}
                            value={
                                formikProps.values.contractDayRateReported?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('contractDayRateReported')}
                        />
                        <TextField
                            label='How many days per week do you work?'
                            name='contractDaysWorkedWeeklyReported'
                            mask='number'
                            maxLength={1}
                            value={
                                formikProps.values?.contractDaysWorkedWeeklyReported?.toString() ||
                                ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('contractDaysWorkedWeeklyReported')}
                        />
                        <TextField
                            label='What is your job title?'
                            name='jobTitle'
                            maxLength={255}
                            value={
                                formikProps.values?.jobTitle?.toString() || ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                formikProps.handleChange(e)
                            }
                            onBlur={formikProps.handleBlur}
                            error={getError('jobTitle')}
                        />
                        <Controller
                            label='Do you earn any other income?'
                            additionalInfo='Such as bonus, dividends, pension, second job.'
                        >
                            <IncomingOtherIncomesSubView
                                title='Other incomes:'
                                addButtonTitle='Add an income source'
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <Controller
                            label="At what age do you think you'll retire?"
                            additionalInfo='For most people this will be 66'
                        >
                            <TextField
                                name='estimatedRetirementAge'
                                mask='number'
                                maxLength={2}
                                value={
                                    formikProps.values?.estimatedRetirementAge?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('estimatedRetirementAge')}
                            />
                        </Controller>
                        <Controller label='Do you expect your income to decrease in the near future?'>
                            <ExpectedDecrease
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <ErrorContainer formikProps={formikProps} />
                    </div>
                );
            }}
        </Formik>
    );
};

export default IncomingContractorView;
