export const getRequestConfig = (token: string | null) => ({
    headers: { Authorization: `Bearer ${token}` },
});

export const getRequestConfigWithUserProfile = (
    accessToken: string | null,
    idToken: string | null,
) => ({
    headers: {
        Authorization: `Bearer ${accessToken}`,
        'x-user-profile': `Bearer ${idToken}`,
    },
});

export const httpErrorResponse = (error: any) => ({
    success: false,
    error,
});
