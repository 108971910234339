import * as Yup from 'yup';

import { yesNo } from '@FormModels/shared';
import { containsAnySubStr, isEqualStr } from '@Shared/helpers/string';
import { normalizeNumber } from '@src/shared/helpers';
import { parseApiDate, toApiDate, years } from '@src/shared/helpers/date';

import { requiredErrorMessage } from './applicantDetails';

export const numberOfBedroomsErrorMessage =
    'This a required field. For a studio enter 0';

export const minimumPropertyWorth = 100000;

const isFlat = (value: string) =>
    containsAnySubStr(value, ['flat', 'maisonette']);
const wasConstructedAfter2010 = (value: string) =>
    isEqualStr(value, 'After 2010');

export const constructionYearOptions = [
    { label: 'Before 1914', value: 'Before 1914' },
    { label: '1914 - 2000', value: '1914 - 2000' },
    { label: '2000 - 2010', value: '2000 - 2010' },
    { label: 'After 2010', value: 'After 2010' },
];

export const warrantyCertificateOptions = [
    { label: 'ABC+', value: 'ABC+' },
    { label: 'Advantage', value: 'Advantage' },
    { label: 'AEDIS Warranties Ltd', value: 'AEDIS Warranties Ltd' },
    { label: 'ARK', value: 'ARK' },
    {
        label: 'Build Assure (New Homes Structural Defects Insurance)',
        value: 'Build Assure (New Homes Structural Defects Insurance)',
    },
    { label: 'Building Life Plan', value: 'Building Life Plan' },
    {
        label: 'Build-Zone Structural Warranty',
        value: 'Build-Zone Structural Warranty',
    },
    {
        label: 'Checkmate Castle 10 New Home',
        value: 'Checkmate Castle 10 New Home',
    },
    {
        label: 'Global Home Warranties 10 Year Structural Defects Insurance',
        value: 'Global Home Warranties 10 Year Structural Defects Insurance',
    },
    { label: 'HCI and Protek', value: 'HCI and Protek' },
    {
        label: 'ICW (International Construction Warranties)',
        value: 'ICW (International Construction Warranties)',
    },
    { label: 'LABC New Home Warranty', value: 'LABC New Home Warranty' },
    { label: 'NHBC', value: 'NHBC' },
    { label: 'None', value: 'None' },
    { label: 'One Guarantee', value: 'One Guarantee' },
    { label: 'Other', value: 'Other' },
    { label: 'Premier Guarantee', value: 'Premier Guarantee' },
    { label: 'Q Policy', value: 'Q Policy' },
];

export const formItems = {
    whenHasLastPurchased: {
        items: years,
        validation: Yup.string().required(requiredErrorMessage),
        transform: {
            target: 'whenHasLastPurchased',
            toRequest: (value: string | number) => {
                if ((typeof value === 'number' && isNaN(value)) || !value) {
                    return '';
                }
                return toApiDate(`01/01/${value}`);
            },
            fromRequest: (value: string) => {
                try {
                    return value
                        ? parseApiDate(value).getFullYear()
                        : undefined;
                } catch {
                    // date was invalid
                    return undefined;
                }
            },
        },
    },
    hasSharedOwnership: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'hasSharedOwnership' },
    },
    estimatedValue: {
        validation: Yup.string()
            .required(requiredErrorMessage)
            .test(
                'minimumAmount',
                'Your property must be worth at least £100,000',
                (value) => {
                    if (!value) return true;
                    return normalizeNumber(value) >= minimumPropertyWorth;
                },
            ),
        transform: {
            target: 'estimatedValue',
            toRequest: (value: string) => {
                return normalizeNumber(value);
            },
        },
    },
    purchaseValue: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: {
            target: 'purchaseValue',
            toRequest: (value: string) => {
                return normalizeNumber(value);
            },
        },
    },
    propertyTypeBase: {
        validation: Yup.string().required(requiredErrorMessage),
    },
    propertyType: {
        validation: Yup.string().required(requiredErrorMessage),
        transform: {
            target: 'propertyType',
            toRequest: (value: string) =>
                value === 'End-of-terrace house' ? 'Terraced house' : value,
        },
    },
    constructionYear: {
        items: constructionYearOptions,
        default: constructionYearOptions,
        validation: Yup.string()
            .typeError(requiredErrorMessage)
            .required(requiredErrorMessage),
        transform: { target: 'constructionYear' },
    },
    hasBeenBuiltInThePast2Years: {
        ...yesNo,
        validation: Yup.boolean()
            .when('constructionYear', {
                is: (value: string) => wasConstructedAfter2010(value),
                then: Yup.boolean()
                    .typeError(requiredErrorMessage)
                    .required(requiredErrorMessage),
            })
            .nullable(),
        transform: {
            target: 'hasBeenBuiltInThePast2Years',
            toRequest: (value: any, propertyDetails: any) =>
                wasConstructedAfter2010(propertyDetails.constructionYear)
                    ? value
                    : undefined,
        },
    },
    warrantyCertificate: {
        items: warrantyCertificateOptions,
        default: warrantyCertificateOptions,
        validation: Yup.string()
            .nullable()
            .when('hasBeenBuiltInThePast2Years', {
                is: true,
                then: Yup.string()
                    .typeError(requiredErrorMessage)
                    .required(requiredErrorMessage),
            }),
        transform: {
            target: 'buildingWarrantyCertificate',
            fromRequest: (value: string | null) => value || '',
            toRequest: (value: any, propertyDetails: any) =>
                wasConstructedAfter2010(propertyDetails.constructionYear)
                    ? value
                    : undefined,
        },
    },
    tenure: {
        validation: Yup.string().required(requiredErrorMessage),
        transform: {
            target: 'tenure',
            toRequest: (value: string) =>
                value === 'true' ? 'Leasehold' : null,
            fromRequest: (value: string) => {
                if (value === 'Leasehold') {
                    return 'true';
                } else if (value === null) {
                    return false;
                }
            },
        },
    },
    yearsRemainingOnLease: {
        validation: Yup.string()
            .nullable()
            .when('tenure', {
                is: 'true',
                then: Yup.string().required(requiredErrorMessage),
            }),
        transform: {
            target: 'yearsRemainingOnLease',
            fromRequest: (value: string | null) =>
                value ? parseInt(value) : undefined,
            toRequest: (value: any, propertyDetails: any) =>
                propertyDetails.tenure === 'true' && value ? value : null,
        },
    },
    isThePropertyAnExLocalAuthority: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'isThePropertyAnExLocalAuthority' },
    },
    isListedAsGradeIOrGradeIiOrA: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'isListedAsGradeIOrGradeIiOrA' },
    },
    notOfStandardConstruction: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'notOfStandardConstruction' },
    },
    notResidentialUse: {
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'notResidentialUse' },
    },
    floorOfFlat: {
        validation: Yup.string()
            .nullable()
            .when('propertyType', {
                is: (value: string) => isFlat(value),
                then: Yup.string().nullable().required(requiredErrorMessage),
            }),
        transform: {
            target: 'floorOfFlat',
            toRequest: (value: any, propertyDetails: any) =>
                isFlat(propertyDetails.propertyType) ? value : undefined,
        },
    },
    is4thFloorOrHigherWithoutLift: {
        validation: Yup.string()
            .nullable()
            .when('floorOfFlat', {
                is: (value: string) => value === '4',
                then: Yup.string().nullable().required(requiredErrorMessage),
            }),
        transform: {
            target: 'is4thFloorOrHigherWithoutLift',
            toRequest: (value: any) => (value ? value : undefined),
        },
    },
    numberOfBedrooms: {
        validation: Yup.number()
            .nullable()
            .required(numberOfBedroomsErrorMessage),
        transform: {
            target: 'numberOfBedrooms',
            toRequest: (value: string) => {
                return normalizeNumber(value);
            },
        },
    },
};
