import * as Yup from 'yup';

import { CustomFormProps } from '@FormModels/shared';

export const formItems: CustomFormProps = {
    email: {
        label: 'Email address',
        type: 'email',
        validation: Yup.string()
            .required('The email field is required')
            .email('The email is not valid'),
    },
    password: {
        label: 'Password',
        validation: Yup.string().required('No password provided'),
    },
};
