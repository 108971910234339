import { FC, useEffect, useState } from 'react';

import TrustpilotReviews from '@Components/TrustpilotReviews/TrustpilotReviews';
import { Slider } from '@src/ui/components/Slider';

interface IWaitViewLoaderV2Props {
    loadingStopped?: boolean;
}
const sliderValues = [
    'Getting to know you…',
    'Scanning your credit report to serve you better…',
    'Finding your best offer…',
    'Sorting through your personalised results…',
];

export const WaitViewLoaderV2: FC<IWaitViewLoaderV2Props> = ({
    loadingStopped,
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!loadingStopped) {
            const updateProgressBar = () => {
                setProgress((prevProgress) => {
                    if (prevProgress < 100) {
                        return prevProgress + 10;
                    } else {
                        clearInterval(progressInterval);
                        return prevProgress;
                    }
                });
            };

            const progressInterval = setInterval(updateProgressBar, 1000);

            return () => clearInterval(progressInterval);
        }
    }, [loadingStopped]);

    return (
        <div className='mt-32px md:mt-36 text-center'>
            <div className='flex sm:flex-col flex-col-reverse'>
                <div className='mt-6 max-w-2xl w-full self-center'>
                    <Slider
                        center
                        nonRepeatable
                        timer={3000}
                        className='h-10'
                        isStopped={loadingStopped}
                    >
                        {sliderValues.map((it, index) => (
                            <div key={index} className='text-steel-80'>
                                {it}
                            </div>
                        ))}
                    </Slider>
                    <div className='h-2 bg-neutral-40 mt-2 text-center rounded-full'>
                        <div
                            className='h-full bg-primary opacity-70 rounded-full'
                            style={{
                                width: `${progress}%`,
                                transition: 'width 1s linear',
                            }}
                        ></div>
                    </div>
                </div>
                <p className='md:text-5xl text-28 font-bold leading-120% sm:pt-6'>
                    Calculating your personalised quote
                </p>
            </div>
            <p className='text-xl pt-13 sm:pt-28 leading-120%'>
                Thanks for your patience, please do not close this page.
                <br />
                <p className='font-bold'>
                    This won't impact your credit score.
                </p>
            </p>
            {!loadingStopped && (
                <div className='mt-18 sm:pb-24'>
                    <TrustpilotReviews />
                </div>
            )}
        </div>
    );
};
