import {
    ChangeEvent,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { Button, ChevronDownIcon, H2, TextField } from '@selina-finance/ui';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';
import { formItems } from '@src/app/formModels/eligibility/loanDetails';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@src/shared/helpers/input';
import BackButton from '@src/ui/components/BackButton/BackButton';
import ErrorContainer from '@src/ui/components/ErrorContainer/ErrorContainer';
import { HttpError } from '@src/ui/components/HttpError';
import ProgressMenu from '@src/ui/components/Progress/ProgressMenu';
import SelectBox from '@src/ui/components/SelectBox/SelectBox';

interface LoanDetailsViewProps {
    loanDetailsData?: any;
    saveLoanDetails?: (data: any) => Promise<any>;
}
const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

const LoanDetailsView = forwardRef((props: LoanDetailsViewProps, ref) => {
    const formikRef = useRef<any>(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { setCurrentForm } = useContext(ApplicationContext);

    useEffect(() => {
        formikRef.current.values = {
            ...defaultValues,
            ...props.loanDetailsData,
        };
    });

    useEffect(() => {
        setCurrentForm(undefined);
    }, [setCurrentForm]);

    useEffect(() => {
        if (formikRef.current) {
            setCurrentForm(formikRef.current);
        }
    }, [formikRef, setCurrentForm]);

    const saveForLater = () => {
        const values = formikRef.current.values;

        props.saveLoanDetails &&
            props.saveLoanDetails(values).then((data: any) => {
                if (!data.success) {
                    setError(data.error);
                }
            });
    };

    useImperativeHandle(ref, () => ({
        saveMethod: saveForLater,
    }));

    return (
        <div className='flex flex-row justify-between'>
            <div className='w-full sm:w-1/2 lg:w-2/5 mt-20 sm:mt-0'>
                <BackButton />
                <H2>Your Loan</H2>
                <div className='relative mb-15'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            ...defaultValues,
                            ...props.loanDetailsData,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            setError(null);
                            props.saveLoanDetails &&
                                props
                                    .saveLoanDetails(values)
                                    .then((data: any) => {
                                        const redirectTo =
                                            values.redirectTo ||
                                            '/eligibility/applicants';
                                        if (data.success) {
                                            navigate(redirectTo);
                                        }
                                        if (!data.success) {
                                            setError(data.error);
                                        }
                                    });
                        }}
                        innerRef={(ref) => (formikRef.current = ref)}
                    >
                        {(formikProps) => {
                            const { getError } = getFormHelpers(
                                formItems,
                                formikProps,
                            );

                            const amountAssistiveText = 'From £10k to £500k';
                            const termAssistiveText = '5 - 30 years';

                            return (
                                <Form>
                                    <div className='grid grid-col-1 mt-6 mb-6 gap-10'>
                                        <TextField
                                            type='tel'
                                            label='I want to borrow'
                                            prefix='£'
                                            maxLength={7}
                                            mask={'currency' as any}
                                            name='amount'
                                            assistiveText={
                                                !getError('amount')
                                                    ? amountAssistiveText
                                                    : ''
                                            }
                                            value={
                                                formikProps.values.amount?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                const numericValue = parseFloat(
                                                    e.currentTarget.value.replace(
                                                        /,/g,
                                                        '',
                                                    ),
                                                );

                                                formikProps.setFieldValue(
                                                    'amount',
                                                    numericValue,
                                                );
                                            }}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('amount')}
                                        />
                                        <TextField
                                            label='And pay it back over'
                                            suffix='Years'
                                            className='w-48'
                                            maxLength={2}
                                            mask='number'
                                            name='term'
                                            assistiveText={
                                                !getError('term')
                                                    ? termAssistiveText
                                                    : ''
                                            }
                                            value={
                                                formikProps.values.term?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('term')}
                                        />
                                        <SelectBox
                                            items={formItems.purpose.items}
                                            suffix={<ChevronDownIcon />}
                                            label='Primary purpose'
                                            name='purpose'
                                            validState={
                                                !!formikProps.values.purpose
                                            }
                                            error={getError('purpose')}
                                            value={
                                                formikProps.values.purpose ||
                                                ' '
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                formikProps.setFieldValue(
                                                    'purpose',
                                                    e.currentTarget.value,
                                                );
                                            }}
                                            onBlur={() => {
                                                return formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    purpose: true,
                                                });
                                            }}
                                        />
                                    </div>
                                    {error && <HttpError error={error} />}
                                    <ErrorContainer formikProps={formikProps} />
                                    <div className='flex gap-5 justify-end mt-12'>
                                        <Button
                                            color='secondary'
                                            type='submit'
                                            size={'large'}
                                            className='w-full'
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <ProgressMenu />
        </div>
    );
});
export default LoanDetailsView;
