import { FC } from 'react';

const ContactEmail: FC = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'
        >
            <path
                d='M14 4L8 9.5L2 4'
                stroke='#3A4146'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2 4H14V12.5C14 12.6326 13.9473 12.7598 13.8536 12.8536C13.7598 12.9473 13.6326 13 13.5 13H2.5C2.36739 13 2.24021 12.9473 2.14645 12.8536C2.05268 12.7598 2 12.6326 2 12.5V4Z'
                stroke='#3A4146'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.90625 8.5L2.15625 12.8562'
                stroke='#3A4146'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.8438 12.8562L9.09375 8.5'
                stroke='#3A4146'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ContactEmail;
