const ArrowCircleLeftIcon = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z'
                stroke='#898D90'
                strokeWidth='1.25'
                strokeMiterlimit='10'
            />
            <path
                d='M9.52344 12.6484L6.875 10L9.52344 7.35156'
                stroke='#898D90'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.875 10H13.125'
                stroke='#898D90'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default ArrowCircleLeftIcon;
