import { SVGProps } from 'react';

interface EllipseProps extends SVGProps<SVGSVGElement> {
    number: number;
}

const EllipseIcon = ({ number, ...props }: EllipseProps) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='32'
        height='32'
        viewBox='0 0 32 32'
        {...props}
    >
        <circle cx='16' cy='16' r='16' fill='#F4F1ED' />
        <text
            x='50%'
            y='50%'
            dominantBaseline='middle'
            textAnchor='middle'
            fill='#61676B'
            fontWeight='bold'
        >
            {number}
        </text>
    </svg>
);

export default EllipseIcon;
