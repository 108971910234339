const PencilIcon = () => {
    return (
        <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.5 3.59536L8.42344 1.51411C8.28361 1.37074 8.09389 1.28679 7.89375 1.27974C7.79218 1.2774 7.69119 1.29572 7.59691 1.33359C7.50264 1.37147 7.41704 1.4281 7.34531 1.50005L6.10781 2.73286L1.72031 7.12505C1.65042 7.19441 1.59497 7.27693 1.55715 7.36785C1.51934 7.45877 1.49991 7.55627 1.5 7.65474V9.75005C1.5 9.94896 1.57902 10.1397 1.71967 10.2804C1.86032 10.421 2.05109 10.5001 2.25 10.5001H4.34531C4.54399 10.4992 4.73432 10.42 4.875 10.2797L10.5 4.65474C10.6391 4.51354 10.7171 4.32328 10.7171 4.12505C10.7171 3.92683 10.6391 3.73656 10.5 3.59536ZM9 5.09536L6.90469 3.00005L7.875 2.02974L9.97031 4.12505L9 5.09536Z'
                fill='#883338'
            />
        </svg>
    );
};

export default PencilIcon;
