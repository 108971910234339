import { FC, useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';
import VerifyEmailView from '@src/ui/views/auth/VerifyEmailView';

import AuthLayout from '../layouts/AuthLayout';

const VerifyEmail: FC = () => {
    const {
        isLoggedIn,
        logOut,
        confirmRegistration,
        resendRegistrationCode,
        loading,
    } = useContext(AuthContext);
    const [waiting, setWaiting] = useState(true);

    const location = useLocation();
    const search = location.search;
    const email = new URLSearchParams(search).get('email') || '';

    const navigate = useNavigate();

    useEffect(() => {
        logOut();
    }, [logOut]);

    useEffect(() => {
        setWaiting(true);
        isLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                navigate('/');
            } else {
                setWaiting(false);
            }
        });
    }, [setWaiting, navigate, isLoggedIn]);

    return (
        <AuthLayout
            title={'We’ve sent you an email'}
            subTitle={
                <>
                    We sent a verification code to{' '}
                    <strong className='whitespace-nowrap'>
                        {email ? email : 'your e-mail'}
                    </strong>
                    . Input the code below to verify your email. You may need to
                    check your spam folder.
                </>
            }
            loading={waiting || loading}
        >
            <VerifyEmailView
                onSubmit={confirmRegistration}
                resendCode={resendRegistrationCode}
                email={email}
            />
        </AuthLayout>
    );
};

export default VerifyEmail;
