import { FC, useContext, useEffect, useState } from 'react';

import { Button, H2, H3 } from '@selina-finance/ui';
import { useNavigate } from 'react-router-dom';

import { ExperimentsConfig } from '@App/utils/experiments';
import { WaitViewLoaderV2 } from '@Views/wait/WaitViewLoaderV2';
import { ApplicationContext } from '@src/app/contexts/Application';
import { AuthContext } from '@src/app/contexts/Auth';
import { ApplicationStage } from '@src/app/interfaces/application-stage.enum';
import { containsAnySubStr } from '@src/shared/helpers/string';
import ChatsCircleIcon from '@src/ui/components/Icons/ChatsCircle';
import HandshakeIcon from '@src/ui/components/Icons/Handshake';
import RectangleIcon from '@src/ui/components/Icons/Rectangle';
import TimerIcon from '@src/ui/components/Icons/Timer';
import { Paper } from '@src/ui/components/Paper';
import WaitViewLoader from '@src/ui/views/wait/WaitViewLoader';

import ProgressMenu from '../../components/Progress/ProgressMenu';

const DashboardView: FC<{ waitLoaderFlowVariant: string }> = (props) => {
    const [showWaitScreen, setShowWaitScreen] = useState<boolean>(false);
    const [isRejected, setIsRejected] = useState<boolean>(false);
    const navigate = useNavigate();

    const { dashboardItemStatuses, applicationStage } =
        useContext(ApplicationContext);

    const { loggedIn } = useContext(AuthContext);

    useEffect(
        () =>
            setIsRejected(
                containsAnySubStr(applicationStage || '', [
                    ApplicationStage.DECISIONING_DECLINE,
                    ApplicationStage.CLOSED,
                ]),
            ),
        [applicationStage],
    );

    useEffect(() => {
        if (
            loggedIn &&
            dashboardItemStatuses[4].completed &&
            !dashboardItemStatuses[5].completed
        ) {
            setShowWaitScreen(true);
        }
    }, [dashboardItemStatuses, loggedIn]);

    if (showWaitScreen) {
        return props.waitLoaderFlowVariant === ExperimentsConfig.Variants.A ? (
            <WaitViewLoader />
        ) : (
            <WaitViewLoaderV2 loadingStopped />
        );
    }

    const handleNavigate = () => {
        const latestStep = dashboardItemStatuses.find(
            (item: { completed: boolean }) => !item.completed,
        );
        if (latestStep) {
            latestStep.name === 'quote'
                ? navigate('/eligibility/create-account')
                : navigate(`/eligibility/${latestStep.name}`);
        } else {
            const url = new URL(window.location.href);
            const inEligibility = url.pathname.includes('eligibility');
            isRejected
                ? navigate(inEligibility ? './reject' : './eligibility/reject')
                : navigate(
                      inEligibility ? './good-news' : './eligibility/good-news',
                  );
        }
    };

    return (
        <div className='grid gap-7'>
            <div className='flex justify-between flex-col sm:flex-row'>
                <div className='lg:w-7/12 md:w-1/2 sm:pr-4 md:pr-4 lg:pr-0'>
                    <H2 className='mb-3 hidden lg:flex'>
                        Complete your application
                    </H2>
                    <H3 className='mb-3 block lg:hidden mt-20 sm:mt-0'>
                        Full application
                    </H3>
                    <p className='mb-6 text-xl lg:w-2/3 leading-6'>
                        You're almost there - to tailor your quote, we need some
                        more details from you.
                    </p>
                    <div className='flex justify-between flex-col lg:flex-row leading-6'>
                        <Paper
                            bordered={true}
                            color='sand'
                            className='border-neutral-100 mr-2 mx-auto flex justify-center flex-col mb-4 mb-4'
                        >
                            <div className='flex justify-center'>
                                <TimerIcon />
                            </div>
                            <p className='text-center'>
                                Complete your online application{' '}
                                <span className='font-bold'>
                                    in under 5 minutes
                                </span>
                            </p>
                        </Paper>
                        <Paper
                            bordered={true}
                            color='sand'
                            className='border-neutral-100 mr-2 mx-auto flex justify-center flex-col mb-4'
                        >
                            <div className='flex justify-center'>
                                <ChatsCircleIcon />
                            </div>
                            <p className='text-center'>
                                Speak to a friendly qualified mortgage advisor
                            </p>
                        </Paper>
                        <Paper
                            bordered={true}
                            color='sand'
                            className='border-neutral-100 mr-2 mx-auto flex justify-center flex-col mb-4 lg:p-6 p-4'
                        >
                            <div className='flex justify-center lg:-mt-8'>
                                <HandshakeIcon />
                            </div>
                            <div className='text-center'>
                                Get your funds in
                                <br />
                                <div className='flex justify-center'>
                                    <div className='flex'>
                                        <span className='absolute font-bold -mt-1 ml-3'>
                                            as little as 2 days
                                        </span>
                                    </div>
                                    <RectangleIcon />
                                </div>
                            </div>
                        </Paper>
                    </div>
                    <hr className='my-5 bg-neutral-100 mr-2 hidden lg:block'></hr>
                    <div className='hidden lg:flex lg:flex-row lg:justify-end mr-2'>
                        <Button
                            className={'mt-2'}
                            size='large'
                            color='secondary'
                            onClick={() => handleNavigate()}
                        >
                            {dashboardItemStatuses[5].completed
                                ? 'View my quote'
                                : 'Continue application'}
                        </Button>
                    </div>
                    <div className='block lg:hidden flex justify-center mt-10'>
                        <Button
                            className={'w-full'}
                            size='large'
                            color='secondary'
                            onClick={() => handleNavigate()}
                        >
                            {dashboardItemStatuses[5].completed
                                ? 'View my quote'
                                : 'Continue'}
                        </Button>
                    </div>
                </div>
                <ProgressMenu />
            </div>
        </div>
    );
};

export default DashboardView;
