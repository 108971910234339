import { FC, useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { ApplicationContext } from '@App/contexts/Application';
import { intercomTrackAcceptPageEvent } from '@App/intercom/intercom';
import { Offer } from '@App/interfaces/offer';
import { getOffer } from '@Shared/helpers/offer';
import OfferExpansionPanel from '@src/ui/components/OfferExpansionPanel/OfferExpansionPanel';

import { BookACall } from '../../components/BookACall';
import CheckCircleIcon from '../../components/Icons/CheckCircle';
import { InfoModal } from '../../components/InfoModal';

export interface GoodNewsViewProps {
    className?: string;
    offers?: Offer[];
}

const GoodNewsDisclaimer: FC<{
    applicantName: string;
    t: TFunction;
}> = ({ applicantName, t }) => (
    <div className='mb-6 md:mt-14 mt-6 mx-auto'>
        <div className='flex font-black gap-2 items-center justify-center leading-110% md:text-42 text-34 text-steel-100'>
            <CheckCircleIcon></CheckCircleIcon>
            {applicantName + t('mainSection.ready')}
        </div>
        <div className='leading-120% md:text-34 md:text-center mt-2 text-28 text-steel-80'>
            {t('mainSection.speak.text') as String}
        </div>
        <span className='block md:max-w-60 md:my-32px md:text-center mx-auto my-6 text-md text-steel-100 leading-5'>
            {t('subSection') as String}
        </span>
    </div>
);

const GoodNewsView: FC<GoodNewsViewProps> = (props) => {
    const [offer, setOffer] = useState<Offer | undefined>(undefined);
    const { loanDetails, intercomData, applicants } =
        useContext(ApplicationContext);
    const { t } = useTranslation('goodNews');
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    useEffect(() => {
        if (props.offers && props.offers.length > 0) {
            setOffer(
                getOffer(
                    props.offers,
                    loanDetails?.purpose === 'Home improvements' || false,
                ),
            );
        }
    }, [loanDetails?.purpose, props.offers]);

    useEffect(() => intercomTrackAcceptPageEvent(intercomData), [intercomData]);

    return offer ? (
        <div className={clsx('flex flex-col gap-6 md:gap-10', props.className)}>
            <div>
                <GoodNewsDisclaimer
                    applicantName={applicants[0]?.firstName}
                    t={t}
                />
                <OfferExpansionPanel
                    offer={offer}
                    loanDetails={loanDetails}
                    setShowInfoModal={setShowInfoModal}
                />
            </div>
            <div className='flex flex-col md:gap-4 md:flex-row'>
                <BookACall className='flex-1' />
            </div>

            {showInfoModal && (
                <InfoModal onClose={() => setShowInfoModal(false)} />
            )}
        </div>
    ) : null;
};

export default GoodNewsView;
