import { useContext } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import EligibilityLayout from '@src/ui/layouts/EligibilityLayout';
import FinaliseView from '@src/ui/views/offer/FinaliseView';

const Finalise = () => {
    const { loading } = useContext(ApplicationContext);

    return (
        <EligibilityLayout loading={loading} offerPage>
            <FinaliseView />
        </EligibilityLayout>
    );
};

export default Finalise;
