import { isEqualStr, normalizeNumber } from '@Helpers';
import * as Yup from 'yup';

import {
    CustomFormProps,
    passwordErrorMessage,
    passwordRegex,
} from '@FormModels/shared';

const currencyFormItem = (type: string) => {
    return {
        transform: {
            target: 'expenditure',
            fromRequest: (_: any, dependencies: any) =>
                dependencies.expenditure?.find((item: any) =>
                    isEqualStr(item.expenditureType, type),
                )?.amountDeclared,
            toRequest: (value: any, dependencies: any) => {
                if (value && normalizeNumber(value) > 0) {
                    dependencies.expenditure.push({
                        expenditureType: type,
                        amountDeclared: normalizeNumber(value),
                        frequency: 'monthly',
                    });
                }
                return dependencies.expenditure;
            },
        },
    };
};

export const formItems: CustomFormProps = {
    haveDependants: {
        validation: Yup.string()
            .nullable()
            .required('Please specify if you have dependants'),
        transform: {
            fromRequest: (_: any, dependencies: any) => {
                if (
                    dependencies.applicants[0].numberOfAdultDependants > 0 ||
                    dependencies.applicants[0].numberOfChildDependants > 0
                ) {
                    return true;
                } else if (
                    dependencies.applicants[0].numberOfAdultDependants === 0 &&
                    dependencies.applicants[0].numberOfChildDependants === 0
                ) {
                    return false;
                } else {
                    return undefined;
                }
            },
        },
    },
    adultDependants: {
        validation: Yup.string()
            .nullable()
            .when('haveDependants', {
                is: (value: any) => value === 'true',
                then: Yup.string()
                    .nullable()
                    .required('Please specify the number of adult dependants.'),
            }),
        transform: {
            target: 'numberOfAdultDependants',
            fromRequest: (_: any, dependencies: any) =>
                dependencies.applicants &&
                dependencies.applicants[0].numberOfAdultDependants,
            toRequest: (value: number, dependencies: any) => {
                if (dependencies.data.haveDependants) {
                    return value;
                } else if (dependencies.data.haveDependants === false) {
                    return 0;
                } else {
                    return undefined;
                }
            },
        },
    },
    childDependants: {
        validation: Yup.string()
            .nullable()
            .when('haveDependants', {
                is: (value: any) => value === 'true',
                then: Yup.string()
                    .nullable()
                    .required('Please specify the number of child dependants.'),
            }),
        transform: {
            target: 'numberOfChildDependants',
            fromRequest: (_: any, dependencies: any) =>
                dependencies.applicants &&
                dependencies.applicants[0].numberOfChildDependants,
            toRequest: (value: number, dependencies: any) => {
                if (dependencies.data.haveDependants) {
                    return value;
                } else if (dependencies.data.haveDependants === false) {
                    return 0;
                } else {
                    return undefined;
                }
            },
        },
    },
    food: currencyFormItem('Food, Drink, Housekeeping'),
    clothing: currencyFormItem('Clothing or footwear'),
    tvPhoneInternet: currencyFormItem('TV phone and internet'),
    utilities: currencyFormItem('Utilities'),
    insurance: currencyFormItem('Insurance'),
    councilTax: currencyFormItem('Council tax'),
    transport: currencyFormItem('Transport'),
    recreation: currencyFormItem('Recreation'),
    rent: currencyFormItem('Ground rent'),
    otherExpenditures: currencyFormItem('Other'),
    password: {
        label: 'Password',
        validation: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .matches(passwordRegex, passwordErrorMessage),
    },
};
