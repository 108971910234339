import { useEffect, useRef } from 'react';

import * as Sentry from '@sentry/react';

import { intercomBoot, intercomShutdown } from '@App/intercom/intercom';
import Providers from '@App/providers';
import ScrollToTop from '@App/utils/ScrollToTop';

import Routing from './ui/Routing';
import './ui/styles/output.css';

import '@selina-finance/ui/build/fonts.css';

function App() {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            try {
                intercomBoot();
            } catch (e) {
                console.error('unsupported browser');
            }
            mounted.current = true;
        }
        return () => intercomShutdown();
    }, []);

    return (
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
            <Providers>
                <ScrollToTop>
                    <Routing />
                </ScrollToTop>
            </Providers>
        </Sentry.ErrorBoundary>
    );
}

export default App;
