import { CSSProperties, FC } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';

import { Size } from '@src/ui/components/shared';

type AvatarColor =
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'ghost'
    | 'gray';

export interface AvatarProps {
    style?: CSSProperties;
    className?: string;
    image?: string;
    size?: Size;
    color?: AvatarColor;
    name?: string;
}

const className = {
    avatar: 'inline-flex rounded-full border justify-center bg-center bg-contain',
    small: 'h-8 leading-8 w-8 text-sm',
    medium: 'h-12 leading-16 w-12 text-2xl',
    large: 'h-16 leading-16 w-16 text-4xl',

    color_primary: 'text-primary_contrast bg-primary border-primary_contrast',
    color_secondary:
        'text-secondary_contrast bg-secondary border-secondary_contrast',
    color_success: 'text-success_contrast bg-primary border-success_contrast',
    color_danger: 'text-danger_contrast bg-danger border-danger_contrast',
    color_ghost: 'text-ghost_contrast bg-ghost border-ghost_contrast',
    color_gray: 'text-black bg-gray-100 border-primary_contrast',
};

const getLetters = (name?: string) => {
    if (!name) return null;

    const nameParts = name.split(' ');
    return nameParts.map((p) => p[0].toUpperCase()).join('');
};

const Avatar: FC<AvatarProps> = (props) => {
    const style: CSSProperties = {
        backgroundImage: props.image ? `url(${props.image})` : '',
    };

    const size: Size = props.size || 'medium';
    const color: AvatarColor = props.color || 'primary';

    const classNames = clsx([
        props.className,
        className.avatar,
        className[size],
        className[`color_${color}`],
    ]);

    const children = props.image ? null : getLetters(props.name);

    return (
        <div
            className={classNames}
            title={props.name}
            {...omit(
                { ...props, style: { ...style, ...props.style } },
                'className',
                'title',
            )}
        >
            {children}
        </div>
    );
};

export default Avatar;
