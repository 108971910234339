import { DefaultTheme, UIThemeProvider } from '@selina-finance/ui';
import { Route, Routes } from 'react-router-dom';

import { Protected, ProtectedEligibility } from '@src/ui/components/Protected';
import CreatePassword from '@src/ui/pages/CreatePassword';
import GoodNews from '@src/ui/pages/GoodNews';
import Login from '@src/ui/pages/Login';
import Logout from '@src/ui/pages/Logout';
import ResetPassword from '@src/ui/pages/ResetPassword';
import VerifyEmail from '@src/ui/pages/VerifyEmail';

import Applicants from './pages/Applicants';
import Dashboard from './pages/Dashbord';
import Incomings from './pages/Incomings';
import LoanDetails from './pages/LoanDetails';
import Outgoings from './pages/Outgoings';
import Reject from './pages/Reject';
import Wait from './pages/Wait';
import YourProperty from './pages/YourProperty';
import BookCall from './pages/offer/BookCall';
import Confirmation from './pages/offer/Confirmation';
import Explore from './pages/offer/Explore';
import Finalise from './pages/offer/Finalise';

const Routing = () => {
    return (
        <UIThemeProvider theme={DefaultTheme}>
            <Routes>
                <Route
                    index
                    element={
                        <Protected>
                            <Dashboard />
                        </Protected>
                    }
                />
                <Route path='eligibility'>
                    <Route
                        path=''
                        element={
                            <Protected>
                                <Dashboard />
                            </Protected>
                        }
                    />
                    <Route
                        path='loan-details'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <LoanDetails />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route
                        path='applicants'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <Applicants />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route
                        path='property-details'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <YourProperty />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route
                        path='income'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <Incomings />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route
                        path='outgoings'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <Outgoings />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />

                    <Route
                        path='create-account'
                        element={
                            <Protected>
                                <CreatePassword />
                            </Protected>
                        }
                    />
                    <Route
                        path='wait'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <Wait />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route
                        path='good-news'
                        element={
                            <Protected>
                                <ProtectedEligibility>
                                    <GoodNews />
                                </ProtectedEligibility>
                            </Protected>
                        }
                    />
                    <Route path='offer'>
                        <Route
                            path='explore'
                            element={
                                <Protected>
                                    <ProtectedEligibility>
                                        <Explore />
                                    </ProtectedEligibility>
                                </Protected>
                            }
                        />
                        <Route
                            path='finalise'
                            element={
                                <Protected>
                                    <ProtectedEligibility>
                                        <Finalise />
                                    </ProtectedEligibility>
                                </Protected>
                            }
                        />
                        <Route
                            path='book-call'
                            element={
                                <Protected>
                                    <ProtectedEligibility>
                                        <BookCall />
                                    </ProtectedEligibility>
                                </Protected>
                            }
                        />
                        <Route
                            path='confirmation'
                            element={
                                <Protected>
                                    <ProtectedEligibility>
                                        <Confirmation />
                                    </ProtectedEligibility>
                                </Protected>
                            }
                        />
                    </Route>
                    <Route
                        path='reject'
                        element={
                            <Protected>
                                <Reject />
                            </Protected>
                        }
                    />
                </Route>
                <Route path='login' element={<Login />} />
                <Route path='logout' element={<Logout />} />
                <Route path='create-password' element={<CreatePassword />} />
                <Route path='reset-password' element={<ResetPassword />} />
                <Route path='verify-email' element={<VerifyEmail />} />
            </Routes>
        </UIThemeProvider>
    );
};

export default Routing;
