export const toFlatStr = (str1: any) => {
    return (str1 + '').trim().toLowerCase();
};

export const isEqualStr = (str1: any, str2: any) => {
    return toFlatStr(str1 + '') === toFlatStr(str2 + '');
};

export const containsAnySubStr = (searchStr: any, strArray: any[]) => {
    const flatSearchStr = toFlatStr(searchStr);
    let result = false;
    strArray.forEach((str) => {
        const compare = flatSearchStr.includes(toFlatStr(str));
        if (compare) {
            result = true;
        }
    });

    return result;
};
