import { FC, useEffect, useRef, useState } from 'react';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { formItems } from '@FormModels/auth/verifyEmail';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { Button } from '@src/ui/components/Button';
import { FormError } from '@src/ui/components/FormError';
import { Info } from '@src/ui/components/Info';
import { LinkButton } from '@src/ui/components/LinkButton';
import { TextField } from '@src/ui/components/TextField';

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

export interface VerifyEmailViewProps {
    className?: string;
    onSubmit?: Function;
    resendCode?: Function;
    email?: string;
}

const VerifyEmailView: FC<VerifyEmailViewProps> = (props) => {
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState<any>('');
    const [successResend, setSuccessResend] = useState('');
    const [failedResend, setFailedResend] = useState('');

    const navigate = useNavigate();
    const formikRef = useRef<any>(null);

    useEffect(() => {
        formikRef.current.values = { ...defaultValues, email: props.email };
    }, [props.email]);

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{ ...defaultValues, email: props.email }}
                validationSchema={validationSchema}
                initialErrors={{ verificationCode: 'required verification' }}
                onSubmit={(values) => {
                    if (props.onSubmit) {
                        props
                            .onSubmit(values.email, values.verificationCode)
                            .then((result: any) => {
                                if (result.success) {
                                    setSuccess(
                                        <>
                                            Verification was successful, this
                                            page will be redirected to the{' '}
                                            <LinkButton
                                                onClick={() =>
                                                    navigate('/login', {
                                                        replace: true,
                                                    })
                                                }
                                            >
                                                login
                                            </LinkButton>{' '}
                                            page.
                                        </>,
                                    );
                                    setTimeout(() => {
                                        navigate('/login', { replace: true });
                                    }, 3000);
                                } else {
                                    setErrors({ code: result.message });
                                }
                            });
                    }
                }}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getPropsWithError, hasError } = getFormHelpers(
                        formItems,
                        formikProps,
                    );
                    return (
                        <div className='mt-4'>
                            <Form autoComplete='off'>
                                <input
                                    name='email'
                                    type={'hidden'}
                                    value={formikProps.values.email}
                                />
                                <TextField
                                    {...getPropsWithError('verificationCode')}
                                    autoFocus={
                                        !!formikProps.initialValues.email
                                    }
                                    onChange={(e) => {
                                        formikProps.handleChange(e);
                                        setErrors({});
                                    }}
                                />

                                <FormError
                                    touched={{ ...formikProps.touched }}
                                    otherErrors={errors}
                                />

                                {success && (
                                    <Info type={'success'} className='mb-6'>
                                        {success}
                                    </Info>
                                )}

                                <Button
                                    type={'submit'}
                                    className={'mb-4 w-full mt-4'}
                                    disabled={hasError()}
                                >
                                    Submit
                                </Button>

                                <div className={'text-center'}>
                                    Can't see it?{' '}
                                    <LinkButton
                                        onClick={() => {
                                            if (props.resendCode) {
                                                props
                                                    .resendCode(
                                                        formikProps.values
                                                            .email,
                                                    )
                                                    .then((result: any) => {
                                                        if (result.success) {
                                                            setSuccessResend(
                                                                result.message,
                                                            );
                                                            setTimeout(() => {
                                                                setSuccessResend(
                                                                    '',
                                                                );
                                                            }, 3000);
                                                        } else {
                                                            setFailedResend(
                                                                result.message,
                                                            );
                                                        }
                                                    });
                                            }
                                        }}
                                    >
                                        Resend code
                                    </LinkButton>
                                    {successResend && (
                                        <Info
                                            type={'success'}
                                            className={'mb-6'}
                                        >
                                            {successResend}
                                        </Info>
                                    )}
                                    {failedResend && (
                                        <Info type={'error'} className={'mb-6'}>
                                            {failedResend}
                                        </Info>
                                    )}
                                </div>
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
};

export default VerifyEmailView;
