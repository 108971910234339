import { Offer } from '@App/interfaces/offer';

export const getOffer = (
    offers: Offer[],
    isHomeImprovements: boolean,
): Offer | undefined => {
    let localOffers;

    localOffers = getOffersByType(offers, isHomeImprovements);

    if (localOffers?.length === 0)
        localOffers = getOffersByType(offers, !isHomeImprovements);

    const biggestBalance = sortByBiggestBalance(localOffers);

    return sortByLowestInitialRate(biggestBalance);
};

const getOffersByType = (
    offers: Offer[],
    isHomeImprovements: boolean,
): Offer[] => {
    const acceptedOffers = offers.filter(
        (offer) => offer.decision === 'Accept' || offer.decision === 'Refer',
    );

    return isHomeImprovements
        ? getHomeImprovementsOffers(acceptedOffers)
        : getNotHomeImprovementsOffers(acceptedOffers);
};

const getHomeImprovementsOffers = (acceptedOffers: Offer[]): Offer[] => {
    return acceptedOffers.filter((offer) => offer.family === 'HELOC');
};

const getNotHomeImprovementsOffers = (acceptedOffers: Offer[]): Offer[] => {
    return acceptedOffers.filter(
        (offer) => offer.family === 'Homeowner Loan' && offer.maxErc !== null,
    );
};

const sortByBiggestBalance = (offers: Offer[]): Offer[] => {
    const sortByBiggestBalance = offers.sort(
        (a, b) => b.offerBalance - a.offerBalance,
    );

    return sortByBiggestBalance?.filter(
        (offer) => offer.offerBalance === sortByBiggestBalance[0].offerBalance,
    );
};

const sortByLowestInitialRate = (offers: Offer[]): Offer | undefined => {
    const localOffers =
        offers.sort((a, b) => a.initialRate - b.initialRate) || [];
    return localOffers[0] || undefined;
};
