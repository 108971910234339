import { useNavigate } from 'react-router-dom';

import { LinkButton } from '@src/ui/components/LinkButton';

import ArrowCircleLeftIcon from '../Icons/ArrowCircleLeftIcon';

const BackButton = () => {
    const navigate = useNavigate();
    const handleNavigate = () => {
        const isEligibilityPath = window.location.pathname.includes(
            'eligibility/applicants',
        );

        if (isEligibilityPath) {
            navigate('/eligibility');
        } else {
            navigate(-1);
        }
    };
    return (
        <div
            className='flex items-center cursor-pointer'
            onClick={() => handleNavigate()}
        >
            <ArrowCircleLeftIcon />
            <LinkButton
                noHoverChange={true}
                className='pr-4 w-0 lg:w-48 text-steel-60 ml-0.5'
            >
                Back
            </LinkButton>
        </div>
    );
};

export default BackButton;
