import { FC, SVGProps } from 'react';

const PhoneIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            {...props}
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                opacity='0.2'
                d='M14.4531 19.5C15.7394 22.1563 17.8873 24.2987 20.5469 25.5781C20.7431 25.671 20.96 25.7112 21.1765 25.6948C21.3929 25.6784 21.6013 25.6059 21.7812 25.4844L25.6875 22.875C25.8601 22.758 26.0595 22.6865 26.2672 22.6674C26.4748 22.6483 26.6839 22.6821 26.875 22.7656L34.1875 25.9063C34.4374 26.0103 34.6462 26.1936 34.7817 26.4279C34.9173 26.6623 34.972 26.9346 34.9375 27.2031C34.7057 29.0121 33.8228 30.6746 32.4538 31.8797C31.0849 33.0847 29.3238 33.7496 27.5 33.75C21.8641 33.75 16.4591 31.5112 12.474 27.526C8.48883 23.5409 6.25 18.1359 6.25 12.5C6.25041 10.6763 6.91534 8.91515 8.12035 7.5462C9.32537 6.17726 10.9879 5.29429 12.7969 5.06252C13.0654 5.02803 13.3378 5.08276 13.5721 5.21828C13.8064 5.3538 13.9897 5.5626 14.0937 5.81252L17.2344 13.1406C17.316 13.3287 17.3502 13.5339 17.3339 13.7383C17.3175 13.9427 17.2512 14.1399 17.1406 14.3125L14.5312 18.2813C14.4151 18.4608 14.3469 18.6671 14.3332 18.8805C14.3195 19.0938 14.3608 19.3072 14.4531 19.5Z'
                fill='#898D90'
            />
            <path
                d='M14.4531 19.5C15.7394 22.1563 17.8873 24.2987 20.5469 25.5781C20.7431 25.671 20.96 25.7112 21.1765 25.6948C21.3929 25.6784 21.6013 25.6059 21.7812 25.4844L25.6875 22.875C25.8601 22.758 26.0595 22.6865 26.2672 22.6674C26.4748 22.6483 26.6839 22.6821 26.875 22.7656L34.1875 25.9063C34.4374 26.0103 34.6462 26.1936 34.7817 26.4279C34.9173 26.6623 34.972 26.9346 34.9375 27.2031C34.7057 29.0121 33.8228 30.6746 32.4538 31.8797C31.0849 33.0847 29.3238 33.7496 27.5 33.75C21.8641 33.75 16.4591 31.5112 12.474 27.526C8.48883 23.5409 6.25 18.1359 6.25 12.5C6.25041 10.6763 6.91534 8.91515 8.12035 7.5462C9.32537 6.17726 10.9879 5.29429 12.7969 5.06252C13.0654 5.02803 13.3378 5.08276 13.5721 5.21828C13.8064 5.3538 13.9897 5.5626 14.0937 5.81252L17.2344 13.1406C17.316 13.3287 17.3502 13.5339 17.3339 13.7383C17.3175 13.9427 17.2512 14.1399 17.1406 14.3125L14.5312 18.2813C14.4151 18.4608 14.3469 18.6671 14.3332 18.8805C14.3195 19.0938 14.3608 19.3072 14.4531 19.5V19.5Z'
                stroke='#898D90'
                strokeWidth='2.625'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PhoneIcon;
