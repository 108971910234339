import {
    ChangeEvent,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {
    Button,
    ChevronDownIcon,
    Controller,
    H2,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';
import {
    constructionYearOptions,
    formItems,
    warrantyCertificateOptions,
} from '@src/app/formModels/eligibility/yourProperty';
import { isEqualStr } from '@src/shared/helpers';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    radioButtonOptions,
    radioButtonOptionsInvert,
} from '@src/shared/helpers/input';
import { HttpError } from '@src/ui/components/HttpError';

import BackButton from '../../components/BackButton/BackButton';
import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import DoorIcon from '../../components/Icons/DoorIcon';
import ProgressMenu from '../../components/Progress/ProgressMenu';
import PropertyType from '../../components/PropertyType/PropertyType';
import SelectBox from '../../components/SelectBox/SelectBox';
import SubHeading from '../../components/SubHeading/SubHeading';

interface YourPropertyViewProps {
    className?: string;
    propertyData?: any;
    savePropertyDetails?: (data: any) => Promise<any>;
}

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

const YourPropertyView = forwardRef((props: YourPropertyViewProps, ref) => {
    const formikRef = useRef<any>(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { applicants, setCurrentForm, applicationId } =
        useContext(ApplicationContext);

    useEffect(() => {
        formikRef.current.values = {
            ...defaultValues,
            ...props.propertyData,
        };
    }, [props.propertyData]);

    useEffect(() => {
        setCurrentForm(undefined);
    }, [setCurrentForm]);

    useEffect(() => {
        if (formikRef.current) {
            setCurrentForm(formikRef.current);
        }
    }, [formikRef, setCurrentForm]);

    const saveForLater = () => {
        const values = formikRef.current.values;

        props.savePropertyDetails &&
            props.savePropertyDetails(values).then((data: any) => {
                if (!data.success) {
                    setError(data.error);
                }
            });
    };

    useImperativeHandle(ref, () => ({
        saveMethod: saveForLater,
    }));

    return (
        <div className='flex flex-row justify-between'>
            <div className='w-full sm:w-1/2 lg:w-2/5 mt-20 sm:mt-0'>
                <BackButton />
                <H2>Your property</H2>
                <SubHeading
                    text='We use this information to find the best rate we can
                        provide you'
                />
                <div className='bg-neutral-40 h-14 rounded flex items-center text-xl pl-4'>
                    <DoorIcon />
                    <label className='pl-3'>{`${applicants?.[0].addresses?.[0].address?.line1}`}</label>
                </div>
                <div className='relative mb-15'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            ...defaultValues,
                            ...props.propertyData,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            setError(null);
                            props.savePropertyDetails &&
                                props
                                    .savePropertyDetails(values)
                                    .then((data: any) => {
                                        const redirectTo =
                                            values.redirectTo ||
                                            '/eligibility/income';
                                        if (data.success) {
                                            navigate(redirectTo);
                                        }
                                        if (!data.success) {
                                            setError(data.error);
                                        }
                                    });
                        }}
                        innerRef={(ref) => (formikRef.current = ref)}
                    >
                        {(formikProps) => {
                            const { getError } = getFormHelpers(
                                formItems,
                                formikProps,
                            );
                            const wasConstructedAfter2010 = isEqualStr(
                                formikProps.values.constructionYear,
                                'After 2010',
                            );
                            const hasBeenBuiltInThePast2Years =
                                formikProps.values.hasBeenBuiltInThePast2Years;

                            return (
                                <Form>
                                    <div className='grid grid-cols-1 mt-6 mb-6 gap-10'>
                                        <SelectBox
                                            items={
                                                formItems.whenHasLastPurchased
                                                    .items
                                            }
                                            suffix={<ChevronDownIcon />}
                                            label='When did you buy your property?'
                                            name='whenHasLastPurchased'
                                            validState={
                                                !!formikProps.values
                                                    .whenHasLastPurchased
                                            }
                                            error={getError(
                                                'whenHasLastPurchased',
                                            )}
                                            value={
                                                formikProps.values.whenHasLastPurchased?.toString() ||
                                                ' '
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLSelectElement>,
                                            ) => {
                                                formikProps.setFieldValue(
                                                    'whenHasLastPurchased',
                                                    e.currentTarget.value,
                                                );
                                            }}
                                            onBlur={() => {
                                                return formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    whenHasLastPurchased: true,
                                                });
                                            }}
                                        />

                                        <Controller label='Did you buy it using a scheme such as shared ownership or help-to-buy?'>
                                            <RadioButtonGroup
                                                name='hasSharedOwnership'
                                                id='hasSharedOwnership'
                                                value={formikProps.values.hasSharedOwnership?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage:
                                                        getError(
                                                            'hasSharedOwnership',
                                                        ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        hasSharedOwnership:
                                                            true,
                                                    });
                                                    formikProps.handleChange(e);
                                                }}
                                                options={radioButtonOptions}
                                            />
                                        </Controller>

                                        <TextField
                                            label='How much did you buy it for?'
                                            prefix='£'
                                            maxLength={10}
                                            mask={'currency' as any}
                                            name='purchaseValue'
                                            value={
                                                formikProps.values.purchaseValue?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('purchaseValue')}
                                        />

                                        {!props.propertyData.estimatedValue && (
                                            <Controller
                                                label='How much is your property currently worth?'
                                                additionalInfo='The approximate value of the property today'
                                            >
                                                <TextField
                                                    prefix='£'
                                                    maxLength={10}
                                                    mask={'currency' as any}
                                                    name='estimatedValue'
                                                    value={
                                                        formikProps.values.estimatedValue?.toString() ||
                                                        ''
                                                    }
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        formikProps.handleChange(
                                                            e,
                                                        )
                                                    }
                                                    onBlur={
                                                        formikProps.handleBlur
                                                    }
                                                    error={getError(
                                                        'estimatedValue',
                                                    )}
                                                />
                                            </Controller>
                                        )}

                                        <PropertyType
                                            formikProps={formikProps}
                                            getError={getError}
                                        />
                                        <Controller label='Roughly when was it built?'>
                                            <RadioButtonGroup
                                                className='flex-col gap-2'
                                                name='constructionYear'
                                                id='constructionYear'
                                                value={
                                                    formikProps.values.constructionYear?.toString() ||
                                                    ''
                                                }
                                                error={{
                                                    hasError: false,
                                                    errorMessage:
                                                        getError(
                                                            'constructionYear',
                                                        ),
                                                }}
                                                onChange={(e) => {
                                                    const value =
                                                        e.currentTarget.value;
                                                    const wasConstructedAfter2010 =
                                                        isEqualStr(
                                                            value,
                                                            'After 2010',
                                                        );
                                                    if (
                                                        !wasConstructedAfter2010
                                                    ) {
                                                        formikProps.setFieldValue(
                                                            'hasBeenBuiltInThePast2Years',
                                                            null,
                                                        );
                                                        formikProps.setFieldValue(
                                                            'warrantyCertificate',
                                                            null,
                                                        );
                                                    }
                                                    formikProps.setFieldValue(
                                                        'constructionYear',
                                                        value,
                                                    );
                                                }}
                                                options={
                                                    constructionYearOptions
                                                }
                                            />
                                        </Controller>
                                        {wasConstructedAfter2010 && (
                                            <Controller label='Was it built within the last 2 years?'>
                                                <RadioButtonGroup
                                                    className='flex-col gap-2'
                                                    name='hasBeenBuiltInThePast2Years'
                                                    id='hasBeenBuiltInThePast2Years'
                                                    value={
                                                        formikProps.values.hasBeenBuiltInThePast2Years?.toString() ||
                                                        ''
                                                    }
                                                    error={{
                                                        hasError: false,
                                                        errorMessage: getError(
                                                            'hasBeenBuiltInThePast2Years',
                                                        ),
                                                    }}
                                                    onChange={(e) => {
                                                        const value =
                                                            e.currentTarget
                                                                .value;
                                                        if (
                                                            value !== null &&
                                                            value !==
                                                                undefined &&
                                                            value.toString() !==
                                                                'true'
                                                        ) {
                                                            formikProps.setFieldValue(
                                                                'warrantyCertificate',
                                                                null,
                                                            );
                                                        }
                                                        formikProps.setFieldValue(
                                                            'hasBeenBuiltInThePast2Years',
                                                            value === 'true',
                                                        );
                                                    }}
                                                    options={radioButtonOptions}
                                                />
                                            </Controller>
                                        )}
                                        {wasConstructedAfter2010 &&
                                            hasBeenBuiltInThePast2Years && (
                                                <Controller label='What type of building warranty certificate do you have?'>
                                                    <RadioButtonGroup
                                                        className='flex-col gap-2'
                                                        name='warrantyCertificate'
                                                        id='warrantyCertificate'
                                                        value={
                                                            formikProps.values
                                                                .warrantyCertificate
                                                        }
                                                        error={{
                                                            hasError: false,
                                                            errorMessage:
                                                                getError(
                                                                    'warrantyCertificate',
                                                                ),
                                                        }}
                                                        onChange={(
                                                            e: ChangeEvent<HTMLInputElement>,
                                                        ) => {
                                                            formikProps.setTouched(
                                                                {
                                                                    ...formikProps.touched,
                                                                    warrantyCertificate:
                                                                        true,
                                                                },
                                                            );
                                                            formikProps.handleChange(
                                                                e,
                                                            );
                                                        }}
                                                        options={
                                                            warrantyCertificateOptions
                                                        }
                                                    />
                                                </Controller>
                                            )}
                                        <Controller label='Is it a leasehold property?'>
                                            <RadioButtonGroup
                                                name='tenure'
                                                id='tenure'
                                                value={
                                                    localStorage.getItem(
                                                        `tenureSelected${applicationId}`,
                                                    ) === 'true'
                                                        ? formikProps.values.tenure?.toString()
                                                        : undefined
                                                }
                                                error={{
                                                    hasError: false,
                                                    errorMessage:
                                                        getError('tenure'),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        tenure: true,
                                                    });
                                                    formikProps.handleChange(e);

                                                    localStorage.setItem(
                                                        `tenureSelected${applicationId}`,
                                                        'true',
                                                    );
                                                }}
                                                options={radioButtonOptions}
                                            />
                                        </Controller>

                                        {formikProps.values.tenure ===
                                            'true' && (
                                            <TextField
                                                name='yearsRemainingOnLease'
                                                mask='number'
                                                label='How many years do you have left on the property lease?'
                                                maxLength={3}
                                                value={
                                                    formikProps.values.yearsRemainingOnLease?.toString() ||
                                                    ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError(
                                                    'yearsRemainingOnLease',
                                                )}
                                            />
                                        )}

                                        <Controller label='Is it ex-local authority?'>
                                            <RadioButtonGroup
                                                name='isThePropertyAnExLocalAuthority'
                                                id='isThePropertyAnExLocalAuthority'
                                                value={formikProps.values.isThePropertyAnExLocalAuthority?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage: getError(
                                                        'isThePropertyAnExLocalAuthority',
                                                    ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        isThePropertyAnExLocalAuthority:
                                                            true,
                                                    });
                                                    formikProps.handleChange(e);
                                                }}
                                                options={radioButtonOptions}
                                            />
                                        </Controller>

                                        <Controller label='Is it Grade I, II or A listed?'>
                                            <RadioButtonGroup
                                                name='isListedAsGradeIOrGradeIiOrA'
                                                id='isListedAsGradeIOrGradeIiOrA'
                                                value={formikProps.values.isListedAsGradeIOrGradeIiOrA?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage: getError(
                                                        'isListedAsGradeIOrGradeIiOrA',
                                                    ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        isListedAsGradeIOrGradeIiOrA:
                                                            true,
                                                    });
                                                    formikProps.handleChange(e);
                                                }}
                                                options={radioButtonOptions}
                                            />
                                        </Controller>

                                        <Controller
                                            label='Is it of standard construction?'
                                            additionalInfo='Built from bricks and mortar, or stone with roof made of tile or slate.'
                                        >
                                            <RadioButtonGroup
                                                name='notOfStandardConstruction'
                                                id='notOfStandardConstruction'
                                                value={formikProps.values.notOfStandardConstruction?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage: getError(
                                                        'notOfStandardConstruction',
                                                    ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        notOfStandardConstruction:
                                                            true,
                                                    });
                                                    formikProps.handleChange(e);
                                                }}
                                                options={
                                                    radioButtonOptionsInvert
                                                }
                                            />
                                        </Controller>

                                        <Controller label='Is the property only used for residential purposes?'>
                                            <RadioButtonGroup
                                                name='notResidentialUse'
                                                id='notResidentialUse'
                                                value={formikProps.values.notResidentialUse?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage:
                                                        getError(
                                                            'notResidentialUse',
                                                        ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        notResidentialUse: true,
                                                    });
                                                    formikProps.handleChange(e);
                                                }}
                                                options={
                                                    radioButtonOptionsInvert
                                                }
                                            />
                                        </Controller>
                                    </div>
                                    {error && <HttpError error={error} />}
                                    <ErrorContainer formikProps={formikProps} />
                                    <div className='flex gap-5 justify-end mt-12'>
                                        <Button
                                            color='secondary'
                                            type='submit'
                                            size={'large'}
                                            className='w-full'
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <ProgressMenu />
        </div>
    );
});

export default YourPropertyView;
