import { FC } from 'react';

import { Controller } from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { notInPaid } from '@src/app/formModels/eligibility/incomings.notInPaid';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';

import ExpectedDecrease from './IncomingExpectedDecreaseField';
import IncomingOtherIncomesSubView from './IncomingOtherIncomesSubView';

export interface IncomingNotPaidViewProps {
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    setRef?: Function;
}

const defaultValues = getDefaultValues(notInPaid);
const validationSchema = getValidationSchema(notInPaid);

const IncomingNotPaidView: FC<IncomingNotPaidViewProps> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(props.incomeData, Object.keys(notInPaid)),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => values}
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getPropsWithError, getError } = getFormHelpers(
                    notInPaid,
                    formikProps,
                );
                return (
                    <>
                        <div className='grid grid-cols-1 gap-10 m-0'>
                            <Controller
                                label='Do you earn any other income?'
                                additionalInfo='Such as BTL income, dividends, pension'
                            >
                                <IncomingOtherIncomesSubView
                                    getError={getError}
                                    title='Other incomes:'
                                    addButtonTitle='Add an income source'
                                    formikProps={formikProps}
                                    getPropsWithError={getPropsWithError}
                                />
                            </Controller>
                            <Controller label='Do you expect your income to decrease in the near future?'>
                                <ExpectedDecrease
                                    formikProps={formikProps}
                                    getPropsWithError={getPropsWithError}
                                    getError={getError}
                                />
                            </Controller>
                            <ErrorContainer formikProps={formikProps} />
                        </div>
                    </>
                );
            }}
        </Formik>
    );
};

export default IncomingNotPaidView;
