import { ChangeEvent, FC, useContext, useRef, useState } from 'react';

import { Button, TextField } from '@selina-finance/ui';
import cookie from 'cookie';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

import { formItems } from '@FormModels/auth/createPassword';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { ApplicationContext } from '@src/app/contexts/Application';
import { FormError } from '@src/ui/components/FormError';
import EyeIcon from '@src/ui/components/Icons/EyeIcon';
import FileLockIcon from '@src/ui/components/Icons/FileLock';
import { Info } from '@src/ui/components/Info';

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

export interface CreatePasswordViewProps {
    className?: string;
    email?: string;
    register?: Function;
    logIn?: Function;
    buttonText?: string;
    flow: string;
    onClose?: () => void;
}

const CreatePasswordView: FC<CreatePasswordViewProps> = (props) => {
    const [revealPassword, setRevealPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showForm, setShowForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const formikRef = useRef<any>(null);
    const location = useLocation();

    const cookies = cookie.parse(document.cookie);
    const appInfo = cookies.application ? JSON.parse(cookies.application) : {};

    const { dashboardItemStatuses } = useContext(ApplicationContext);

    const handleLogin = (values: any, applicationId: string) => {
        setTimeout(() => {
            if (props.logIn) {
                props
                    .logIn(
                        values.email,
                        values.password,
                        applicationId || appInfo.applicationId,
                    )
                    .then((loginResult: any) => {
                        if (!loginResult.success) {
                            setLoading(false);
                            setErrors({
                                login: "Sorry, we didn't recognise you. Please check your login details and try again.",
                            });
                            return;
                        }
                        setShowForm(false);
                    });
            }
        }, 3000);
    };

    const navigate = useNavigate();

    const handleNavigate = () => {
        const latestStep = dashboardItemStatuses.find(
            (item: { completed: boolean }) => !item.completed,
        );

        const stage = location?.pathname?.split('/').reverse()[0];

        if (latestStep.name === stage) {
            props.onClose?.();
        } else {
            navigate(`/eligibility/${latestStep.name}`);
        }
    };

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{ ...defaultValues, email: props.email }}
                validationSchema={validationSchema}
                initialErrors={{ password: 'required' }}
                onSubmit={(values) => {
                    if (props.register) {
                        setLoading(true);
                        setErrors({});
                        props
                            .register(
                                values.email,
                                values.password,
                                appInfo.applicationId,
                            )
                            .then((result: any) => {
                                setLoading(false);
                                if (result.success) {
                                    setSuccess(true);
                                    setSuccessMessage(
                                        `${result.message} Please wait, the page will redirect to the next step.`,
                                    );
                                    handleLogin(values, result.applicationId);
                                } else {
                                    if (
                                        result.code ===
                                        'InvalidParameterException'
                                    ) {
                                        return setErrors({
                                            error: 'Email is not valid',
                                        });
                                    }
                                    if (
                                        result.code ===
                                        'UsernameExistsException'
                                    ) {
                                        const redirectTo = `../login?email=${encodeURIComponent(
                                            values.email || '',
                                        )}&applicationId=${encodeURIComponent(
                                            result.applicationId || '',
                                        )}`;
                                        const anchor =
                                            '<a style="text-decoration: underline" href="' +
                                            redirectTo +
                                            '" target="_self" rel="noopener noreferrer">log in here</a>';
                                        return setErrors({
                                            error: `An account with this email already exists. Please ${anchor}`,
                                        });
                                    }

                                    setErrors({ code: result.message });
                                }
                            });
                    }
                }}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getError, hasError } = getFormHelpers(
                        formItems,
                        formikProps,
                    );

                    return (
                        <>
                            {showForm && (
                                <>
                                    <Form autoComplete='off'>
                                        <input
                                            title='email'
                                            name='email'
                                            type='hidden'
                                            value={formikProps.values.email}
                                        />
                                        <p className='text-xl leading-6 mb-5'>
                                            Create an account to complete your
                                            application later.
                                        </p>
                                        <TextField
                                            type={
                                                revealPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            suffix={
                                                <div
                                                    onClick={() =>
                                                        setRevealPassword(
                                                            !revealPassword,
                                                        )
                                                    }
                                                    className='flex items-center cursor-pointer w-20'
                                                >
                                                    <EyeIcon />
                                                    <label className='ml-2 cursor-pointer'>
                                                        {revealPassword
                                                            ? 'Hide'
                                                            : 'Show'}
                                                    </label>
                                                </div>
                                            }
                                            id='password'
                                            name='password'
                                            placeholder='Set a password'
                                            value={
                                                formikProps.values.password?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                formikProps.handleChange(e);
                                            }}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('password')}
                                        />
                                        <FormError
                                            touched={{
                                                ...formikProps.touched,
                                                confirmPassword: true,
                                            }}
                                            otherErrors={errors}
                                        />
                                        {success && (
                                            <Info
                                                type='success'
                                                className='mb-6'
                                            >
                                                {successMessage}
                                            </Info>
                                        )}
                                        <Button
                                            type='submit'
                                            className='mt-6 w-full'
                                            color='secondary'
                                            size='large'
                                            disabled={
                                                loading || hasError() || success
                                            }
                                        >
                                            {loading ? (
                                                <div className='flex items-center justify-center'>
                                                    <div className='animate-spin h-4 w-4 mr-3 border-l border-t-transparent border-white rounded-full' />
                                                    Creating account...
                                                </div>
                                            ) : props.buttonText ? (
                                                props.buttonText
                                            ) : (
                                                'Create account'
                                            )}
                                        </Button>
                                    </Form>
                                    <div className='text-base_light text-left text-base leading-tight'>
                                        <br />
                                        By signing up you verify that you have
                                        read and agree with Selina Finance's{' '}
                                        <a
                                            target='_blank'
                                            href='https://www.selinafinance.co.uk/terms-of-use'
                                            rel='noreferrer'
                                            className='underline'
                                        >
                                            terms and conditions
                                        </a>{' '}
                                        and{' '}
                                        <a
                                            target='_blank'
                                            href='https://www.selinafinance.co.uk/terms-of-use'
                                            rel='noreferrer'
                                            className='underline'
                                        >
                                            privacy policy
                                        </a>
                                        <br />
                                        <br />
                                    </div>
                                </>
                            )}
                            {!showForm && success && (
                                <Form className='my-2'>
                                    <div className='flex justify-between'>
                                        <FileLockIcon />
                                        <p className='ml-3 text-2xl font-semibold leading-8'>
                                            Thanks, your loan application has
                                            been saved.
                                        </p>
                                    </div>
                                    <p className='text-xl leading-6 mt-2'>
                                        We’ve created your account, you can log
                                        in at anytime using the details you
                                        provided.
                                    </p>
                                    <div className='mb-6 mt-10'>
                                        <Button
                                            type='button'
                                            className='mt-6 w-full'
                                            color='secondary'
                                            size='large'
                                            onClick={async () =>
                                                handleNavigate()
                                            }
                                        >
                                            {props.buttonText
                                                ? props.buttonText
                                                : 'Continue my application now'}
                                        </Button>
                                        <Button
                                            type='button'
                                            className='mt-2 w-full'
                                            color='outline-dark'
                                            size='large'
                                            onClick={() => navigate('/logout')}
                                        >
                                            {props.buttonText
                                                ? props.buttonText
                                                : 'Log out and exit'}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CreatePasswordView;
