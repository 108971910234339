// NOTE:
// The applicationStage is different from the application status!
// The applicationStage is only stored in mongoDB not in Salesforce.
export enum ApplicationStage {
    DECISIONING_ACCEPT = 'Decisioning Accept',
    DECISIONING_DECLINE = 'Decisioning Decline',
    DECISIONING_ERROR = 'Decisioning Error',
    FACT_FIND = 'Fact Find',
    CLOSED = 'Closed',
}
