import clsx from 'clsx';

import { Container } from '@src/ui/components/Container';

import Header from '../components/Header/Header';
import Trustbar from '../components/Trustbar/Trustbar';

export interface WaitLayoutProps {
    className?: string;
    children: any;
}

const WaitLayout = (props: WaitLayoutProps) => {
    return (
        <div
            className={clsx(
                props.className,
                'text-body pb-15 h-screen bg-neutral-10',
            )}
        >
            <Header />

            <Trustbar />

            <Container variant={'wide'} className={props.className}>
                <div className='mx-4'>{props.children}</div>
            </Container>
        </div>
    );
};

export default WaitLayout;
