import { differenceInCalendarMonths, parse } from 'date-fns';
import { get, isArray, isEqual, omit, replace, set } from 'lodash';

import { toApiDate } from '@Shared/helpers/date';
import { formItems as applicantFormItemsV2 } from '@src/app/formModels/eligibility/applicantDetails';
import { notInPaid as incomingsNotInPaidFormItemsV2 } from '@src/app/formModels/eligibility/incomings';
import { retired as incomingsRetiredFormItemsV2 } from '@src/app/formModels/eligibility/incomings';
import { contractor as incomingsContractorFormItemsV2 } from '@src/app/formModels/eligibility/incomings';
import { employed as incomingsEmployedFormItemsV2 } from '@src/app/formModels/eligibility/incomings';
import { formItems as incomingsFormItemsV2 } from '@src/app/formModels/eligibility/incomings';
import {
    selfEmployed as incomingsSelfEmployedFormItemsV2,
    selfEmployedLimitedCompany as incomingsSelfEmployedLimitedCompanyFormItemsV2,
    selfEmployedPartnership as incomingsSelfEmployedPartnershipFormItems,
    selfEmployedSoleTrader as incomingsSelfEmployedSoleTraderFormItems,
} from '@src/app/formModels/eligibility/incomings.selfEmployed';
import { formItems as loanDetailsFormItems } from '@src/app/formModels/eligibility/loanDetails';
import { formItems as outgoingsFormItemsV2 } from '@src/app/formModels/eligibility/outgoings';
import { formItems as yourPropertyFormItemsV2 } from '@src/app/formModels/eligibility/yourProperty';

import { validateIncomings } from '../contexts/Application';
import { employmentStatuses } from '../formModels/eligibility/incomings.shared';

export const parseDataToRequest = (
    data: any,
    sourceItems: any,
    dependencies?: any,
    parentResult: any = {},
    parentKey?: string,
) => {
    let result: any = parentResult;

    Object.keys(sourceItems).forEach((key) => {
        const isNested = sourceItems[key]?.nested;

        if (isNested) {
            const filteredKey = omit(
                sourceItems[key],
                'nested',
                'default',
                'transform',
            );
            const tmpData = parseDataToRequest(
                data,
                filteredKey,
                dependencies,
                result,
                key,
            );
            result = { ...result, ...tmpData };
            return;
        }

        if (!isNested) {
            const transform = sourceItems[key]?.transform;
            if (!transform || transform?.toRequest === null) return;
            const target = sourceItems[key]?.transform.target;
            if (!target) return;
            let value = get(data, parentKey ? `${parentKey}.${key}` : key); // field value

            if (transform.toRequest) {
                value = transform.toRequest(value, dependencies); // field transform has a function
            }

            if (target.endsWith('[]')) {
                const prevValue = get(result, replace(target, '[]', ''));

                if (prevValue) {
                    const newValue = isArray(value) ? value : [value];
                    value = [...prevValue, ...newValue]; // merge with the previous elements
                }

                value = isArray(value) ? value : [value];
            }
            set(result, replace(target, '[]', ''), value);
        }
    });
    return result;
};

export const transformApplicantToRequest = (
    data: any,
    isPrimaryApplicant: boolean,
    loanDetails?: any,
) => {
    let livedInCurrentAddressFor3Years = false;
    const addresses =
        data.addresses?.map((address: any, index: number) => {
            Object.keys(address.address).forEach((key) => {
                if (!address.address[key]) delete address.address[key];
            });

            const currentAddress = omit(
                {
                    ...address.address,
                    addressLine1: address.address.line1,
                    addressLine2: address.address.line2,
                    addressType: index === 0 ? 'current' : 'previous',
                },
                ['line1', 'line2'],
            );

            if (address.livedIn.from !== '') {
                currentAddress.from = toApiDate('01/' + address.livedIn.from);
                currentAddress.to =
                    index === 0
                        ? null
                        : toApiDate(
                              address.livedIn?.to
                                  ? '01/' + address.livedIn.to
                                  : '',
                          );
            }

            if (index === 0) {
                const from = parse(
                    currentAddress.from,
                    'yyyy-MM-dd',
                    new Date(),
                );
                const to = new Date();
                const difference = differenceInCalendarMonths(to, from);
                livedInCurrentAddressFor3Years = difference >= 3 * 12;
            }

            return currentAddress;
        }) || [];

    const formItems = applicantFormItemsV2;

    return {
        primaryApplicant: isPrimaryApplicant,
        addresses,
        ...parseDataToRequest(data, formItems, {
            isPrimaryApplicant,
            addresses,
            loanDetails,
        }),
        livedInCurrentAddressFor3Years,
    };
};

export const transformLoanDetailsToRequest = (
    loanDetails: any,
    applicantsData: any[],
) => {
    const parsedLoanInformation = parseDataToRequest(
        loanDetails,
        loanDetailsFormItems,
        loanDetails,
    );
    const applicants: any[] = applicantsData.map((applicant, index) =>
        transformApplicantToRequest(applicant, index === 0, loanDetails),
    );
    if (
        parsedLoanInformation.numberOfApplicants === 1 &&
        applicants.length === 2
    ) {
        applicants.pop();
    }

    return {
        loanInformation: omit(parsedLoanInformation),
        applicants,
    };
};

export const transformPropertyDetailsToRequest = (data: any) => {
    const formItems = yourPropertyFormItemsV2;
    return parseDataToRequest(data, formItems, data);
};

export const transformOutgoingsToRequest = (data: any) => {
    const formItems = outgoingsFormItemsV2;

    const parsedData = parseDataToRequest(data, formItems, {
        data,
        expenditure: [],
    });
    return {
        expenditure: parsedData.expenditure,
        numberOfAdultDependants: parsedData.numberOfAdultDependants,
        numberOfChildDependants: parsedData.numberOfChildDependants,
        consent: {
            hasCertifiedCurrentFinancialCircumstances:
                parsedData.hasCertifiedCurrentFinancialCircumstances,
            hasGivenConsentForHardCreditCheck:
                parsedData.hasGivenConsentForHardCreditCheck,
            hasGivenConsentForIdVerification:
                parsedData.hasGivenConsentForIdVerification,
        },
    };
};

export const transformIncomingToRequest = (data: any) => {
    let sourceItems: any = { ...incomingsFormItemsV2 };

    const addToSourceItems = (extra: any) => {
        sourceItems = { ...sourceItems, ...extra };
    };

    const isEmployed = data.employmentStatus === employmentStatuses.employed;
    const isSelfEmployed =
        data.employmentStatus === employmentStatuses.selfEmployed;
    const isContractor =
        data.employmentStatus === employmentStatuses.contractor;
    const isRetired = data.employmentStatus === employmentStatuses.retired;
    const isNotInPaid = data.employmentStatus === employmentStatuses.notInPaid;

    if (isEmployed) {
        addToSourceItems(incomingsEmployedFormItemsV2);
    }
    if (isContractor) {
        data.employmentStatus = 'Self-employed (contractor)';
        addToSourceItems(incomingsContractorFormItemsV2);
    }
    if (isRetired) {
        addToSourceItems(incomingsRetiredFormItemsV2);
    }
    if (isNotInPaid) {
        addToSourceItems(incomingsNotInPaidFormItemsV2);
    }

    const isLimitedCompany = data.selfEmployedType === 'Limited company';
    const isSoleTrader = data.selfEmployedType === 'Sole trader';
    const isPartnership = data.selfEmployedType === 'Partnership';

    if (isSelfEmployed) {
        addToSourceItems(incomingsSelfEmployedFormItemsV2);
        if (isLimitedCompany) {
            data.employmentStatus = 'Self-employed (limited company)';
            addToSourceItems(incomingsSelfEmployedLimitedCompanyFormItemsV2);
        }
        if (isSoleTrader) {
            data.employmentStatus = 'Self-employed (sole trader / partnership)';
            addToSourceItems(incomingsSelfEmployedSoleTraderFormItems);
        }
        if (isPartnership) {
            data.employmentStatus = 'Self-employed (sole trader / partnership)';
            addToSourceItems(incomingsSelfEmployedPartnershipFormItems);
        }
    }

    const parsedIncomings = parseDataToRequest(data, sourceItems, data);

    if (isContractor && data.isSoleTrader) {
        delete parsedIncomings.employment.whenWasCompanyIncorporated;
        delete parsedIncomings.employment.registeredCompanyName;
    }

    if (isSelfEmployed && isSoleTrader) {
        parsedIncomings.employment.businessStructure = 'Sole Trader';
    }
    if (isSelfEmployed && isPartnership) {
        parsedIncomings.employment.businessStructure = 'Partnership';
    }

    if (isSelfEmployed && parsedIncomings.employment.lengthSelfEmployed < 1) {
        parsedIncomings.employment.lengthSelfEmployed = 'Less than a year';
    }
    if (
        isSelfEmployed &&
        parsedIncomings.employment.lengthSelfEmployed >= 1 &&
        parsedIncomings.employment.lengthSelfEmployed <= 2
    ) {
        parsedIncomings.employment.lengthSelfEmployed = 'Between 1 and 2 years';
    }
    if (isSelfEmployed && parsedIncomings.employment.lengthSelfEmployed > 2) {
        parsedIncomings.employment.lengthSelfEmployed = 'More than 2 years';
    }

    // if a field left null then it shouldn't be sent to
    const incomes = parsedIncomings?.income?.income.filter((item: any) => {
        return item != null;
    });

    set(parsedIncomings, 'income.income', incomes);
    set(
        parsedIncomings,
        'income.doesNotHaveAnyIncome',
        incomes ? incomes.length === 0 : false,
    );

    return parsedIncomings;
};

export const transformApplicants = (
    numberOfApplicants: number,
    prevApplicantsData: any[],
    newApplicantData: any,
    isPrimaryApplicant: boolean,
) => {
    let transformedApplicants = [
        transformApplicantToRequest(newApplicantData, true),
    ];

    // D2C-590: we only should send the CO-Applicant data if its completely filled
    const isCoApplicantDataCompletelyFilled = get(
        prevApplicantsData,
        '1.firstName',
        undefined,
    );

    if (
        numberOfApplicants === 2 &&
        isPrimaryApplicant &&
        isCoApplicantDataCompletelyFilled
    ) {
        transformedApplicants.push(
            transformApplicantToRequest(prevApplicantsData[1], false),
        );
    }

    if (numberOfApplicants === 2 && !isPrimaryApplicant) {
        const mainApplicantCurrentAddress = omit(
            prevApplicantsData[0].addresses[0].address,
            'from',
            'to',
        );
        const coApplicantCurrentAddress = omit(
            newApplicantData.addresses[0].address,
            'from',
            'to',
        );
        const applicant2LivesWithApplicant1 = isEqual(
            mainApplicantCurrentAddress,
            coApplicantCurrentAddress,
        );

        transformedApplicants = [
            transformApplicantToRequest(prevApplicantsData[0], true),
            {
                ...transformApplicantToRequest(newApplicantData, false),
                applicant2LivesWithApplicant1,
            },
        ];
    }

    return transformedApplicants;
};

const mergeIncomeWithEmptyIncome = (incomeData: any) => {
    const emptyIncome = {
        employment: {
            employmentStatus: incomeData.employmentStatus,
            registeredCompanyName: '',
            shareHolding: null,
        },
        income: {
            income: [],
            doesNotHaveAnyIncome: true,
            expectsFutureIncomeDecrease: false,
            expectsFutureIncomeDecreaseReason: null,
        },
        estimatedRetirementAge: null,
    };

    const isValid = validateIncomings(incomeData);
    const transfered = isValid
        ? transformIncomingToRequest(incomeData)
        : emptyIncome;

    if (isValid) {
        return {
            ...emptyIncome,
            ...transfered,
            employment: { ...emptyIncome.employment, ...transfered.employment },
            income: { ...emptyIncome.income, ...transfered.income },
        };
    }
    return emptyIncome;
};

export const transformIncomingsToRequest = (newIncomingData: any) => {
    return mergeIncomeWithEmptyIncome(newIncomingData);
};
