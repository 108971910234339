import { without } from 'lodash';
import * as Yup from 'yup';

import { IncomeType } from '@App/interfaces/IncomeType';
import {
    isValidDateFormat,
    months,
    toApiDate,
    toValidDate,
    years,
} from '@Shared/helpers/date';
import { normalizeNumber } from '@src/shared/helpers';

import { CustomFormPropsV2, currency, yesNo } from '../shared';

import {
    commonDecrease,
    commonRetirement,
    otherIncome,
    otherIncomeForSelfEmployed, // secondJob,
} from './incomings.shared';

export const selfEmployed: CustomFormPropsV2 = {
    selfEmployedType: {
        label: 'How are you self-employed?',
        items: [
            { label: 'Limited company', value: 'Limited company' },
            {
                label: 'Sole trader',
                value: 'Sole trader',
            },
            {
                label: 'Partnership',
                value: 'Partnership',
            },
        ],
        validation: Yup.string().required('This is a required field'),
    },
};

export const selfEmployedLimitedCompany: CustomFormPropsV2 = {
    month: {
        items: months,
        validation: Yup.string().required('Please select month'),
    },
    year: {
        items: years,
        validation: Yup.string().required('Please select year'),
    },
    registeredCompanyName: {
        validation: Yup.string()
            .required('Please enter a valid company name')
            .min(2, 'Please enter a valid company name'),
        transform: {
            target: 'employment.registeredCompanyName',
        },
    },
    whenWasCompanyIncorporated: {
        mask: 'date',
        validation: Yup.string().test(
            'validDate',
            'It is not a valid date',
            (value) => isValidDateFormat(value + ''),
        ),
        transform: {
            target: 'employment.whenWasCompanyIncorporated',
            toRequest: (value: string) => toApiDate(value),
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    incomeAmountSalary: {
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            toRequest: (value: any) => {
                return {
                    type: IncomeType.DIRECTOR_GROSS_SALARY,
                    amount: normalizeNumber(value),
                };
            },
            fromRequest: (_: any, dependencies: any) => {
                const found = (dependencies?.income?.income || []).find(
                    (income: any) =>
                        income.type === IncomeType.DIRECTOR_GROSS_SALARY,
                );
                return found?.amount;
            },
        },
    },
    ...otherIncome([
        ...without(otherIncomeForSelfEmployed, {
            label: IncomeType.DIVIDENDS,
            value: IncomeType.DIVIDENDS,
        }),
    ]),
    ...commonRetirement,
    ...commonDecrease,
};

export const selfEmployedSoleTrader: CustomFormPropsV2 = {
    workUnderCompanyName: {
        label: 'Do you work under a company name ?',
        ...yesNo,
        validation: Yup.boolean()
            .nullable()
            .required('This is a required field'),
        default: null,
        transform: {
            target: 'employment.registeredCompanyName',
            fromRequest: (value: any) => {
                return !!value;
            },
            toRequest: null,
        },
    },
    registeredCompanyName: {
        label: "What is the company's registered name?",
        validation: Yup.string()
            .nullable()
            .when('workUnderCompanyName', {
                is: true,
                then: Yup.string()
                    .required('Please enter a valid company name')
                    .min(2, 'Please enter a valid company name'),
            }),
        transform: {
            target: 'employment.registeredCompanyName',
        },
    },
    lengthSelfEmployed: {
        label: 'How long have you been self-employed?',
        validation: Yup.number().required('This is a required field'),
        transform: {
            target: 'employment.lengthSelfEmployed',
            fromRequest: (value: any) => {
                if (value === 'Less than a year') {
                    return 0;
                } else if (value === 'Between 1 and 2 years') {
                    return 2;
                } else {
                    return 3;
                }
            },
        },
    },
    incomeAmountNetProfit: {
        label: 'What was your net profit?',
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            toRequest: (value: any) => {
                return {
                    type: IncomeType.NET_PROFIT,
                    amount: normalizeNumber(value),
                };
            },
            fromRequest: (_: any, dependencies: any) => {
                const found = (dependencies?.income?.income || []).find(
                    (income: any) => income.type === IncomeType.NET_PROFIT,
                );
                return found?.amount;
            },
        },
    },
    ...otherIncome(otherIncomeForSelfEmployed),
    ...commonRetirement,
    ...commonDecrease,
};

export const selfEmployedPartnership: CustomFormPropsV2 = {
    month: {
        items: months,
        validation: Yup.string().required('Please select month'),
    },
    year: {
        items: years,
        validation: Yup.string().required('Please select year'),
    },
    shareHolding: {
        label: 'What is your ownership share of the company? (optional)',
        mask: 'percentage',
        validation: Yup.string()
            .nullable()
            .test(
                'percentage',
                'Please enter a valid percentage',
                (value?: string | null) => {
                    if (!value) return true;
                    const numberValue = parseFloat(value || '');
                    return !(
                        isNaN(numberValue) ||
                        numberValue < 0 ||
                        numberValue > 100
                    );
                },
            ),
        transform: {
            target: 'employment.shareHolding',
            toRequest: (value: any) => {
                return value ? parseFloat(value) : undefined;
            },
        },
    },
    workUnderCompanyName: {
        label: 'Do you work under a company name ?',
        ...yesNo,
        validation: Yup.boolean()
            .nullable()
            .required('This is a required field'),
        default: null,
        transform: {
            target: 'employment.registeredCompanyName',
            fromRequest: (value: any) => {
                return !!value;
            },
            toRequest: null,
        },
    },
    registeredCompanyName: {
        label: "What is the company's registered name?",
        validation: Yup.string()
            .nullable()
            .when('workUnderCompanyName', {
                is: true,
                then: Yup.string()
                    .required('Please enter a valid company name')
                    .min(2, 'Please enter a valid company name'),
            }),
        transform: {
            target: 'employment.registeredCompanyName',
        },
    },
    lengthSelfEmployed: {
        label: 'How long have you been self-employed?',
        validation: Yup.string().required('This is a required field'),
        transform: {
            target: 'employment.lengthSelfEmployed',
            fromRequest: (value: any) => {
                if (value === 'Less than a year') {
                    return 0;
                } else if (value === 'Between 1 and 2 years') {
                    return 2;
                } else {
                    return 3;
                }
            },
        },
    },
    whenWasCompanyIncorporated: {
        label: 'When was your company incorporated? (optional)',
        mask: 'date',
        validation: Yup.string().test(
            'validDate',
            'It is not a valid date',
            (value) => {
                if (!value) return true;
                return isValidDateFormat(value + '');
            },
        ),
        transform: {
            target: 'employment.whenWasCompanyIncorporated',
            toRequest: (value: string) => {
                return value ? toApiDate(value) : undefined;
            },
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    incomeAmountNetProfit: {
        label: 'What was your net profit?',
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            toRequest: (value: any) => {
                return {
                    type: IncomeType.NET_PROFIT,
                    amount: normalizeNumber(value),
                };
            },
            fromRequest: (_: any, dependencies: any) => {
                const found = (dependencies?.income?.income || []).find(
                    (income: any) => income.type === IncomeType.NET_PROFIT,
                );
                return found?.amount;
            },
        },
    },
    ...otherIncome(otherIncomeForSelfEmployed),
    ...commonRetirement,
    ...commonDecrease,
};
