import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { H3 } from '@selina-finance/ui';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useSentryLogger } from '@App/hooks/log';
import { ApplicationStage } from '@App/interfaces/application-stage.enum';
import { ExperimentsConfig } from '@App/utils/experiments';
import { poll } from '@Shared/helpers/poll';
import { WaitViewLoaderV2 } from '@Views/wait/WaitViewLoaderV2';
import { Button } from '@src/ui/components/Button';
import Logos from '@src/ui/components/Icons/Logos';
import WaitViewLoader from '@src/ui/views/wait/WaitViewLoader';

import Help from '../../components/Help/Help';

export interface WaitViewProps {
    className?: string;
    setOffers?: Function;
    setApplicationStage?: Function;
    applicationId?: string;
    waitLoaderFlowVariant: string;
    fetchApplication?: () => Promise<any>;
    runDecisioning?: () => Promise<any>;
    updateCreditCommitments?: () => Promise<any>;
}

enum DecisionStages {
    START = '1',
    COMMITMENT = '2',
    DECISION = '3',
    POLL = '4',
    DONE = '5',
}

const validatePollResult = (data: any) => {
    if (data.success) {
        const status = data.status;
        return (
            status.includes(ApplicationStage.DECISIONING_ERROR) ||
            status.includes(ApplicationStage.DECISIONING_DECLINE) ||
            status.includes(ApplicationStage.DECISIONING_ACCEPT) ||
            status.includes(ApplicationStage.CLOSED)
        );
    }
    return false;
};

const WaitView: FC<WaitViewProps> = (props) => {
    const navigate = useNavigate();

    const storeId = useMemo(
        () => `appState - ${props.applicationId}`,
        [props.applicationId],
    );
    const prevDecisionStage = useRef(
        localStorage?.getItem(storeId) || DecisionStages.COMMITMENT,
    );
    const [currentDecisionStage, setCurrentDecisionStage] = useState(
        localStorage?.getItem(storeId) || DecisionStages.COMMITMENT,
    );

    const componentRef = useRef(false); // to avoid double fetch (Component was mounted)
    const [error, setError] = useState<boolean>(false);
    const { sentryLog, sentryError, flushLogs } = useSentryLogger(
        props.applicationId || '',
        'wait view',
    );

    const setLocalStorage = useCallback(
        (status: DecisionStages) => {
            localStorage?.setItem(storeId, status + '');
        },
        [storeId],
    );

    const setCurrentAppState = useCallback(
        (status: DecisionStages, callBack?: Function) => {
            setCurrentDecisionStage(() => {
                setLocalStorage(status);
                if (callBack) callBack();
                return status;
            });
        },
        [setLocalStorage],
    );

    const onCommitmentSuccess = useCallback(
        (response: any) => {
            if (!response.success) {
                setCurrentAppState(DecisionStages.COMMITMENT, () =>
                    sentryError(
                        'error on commitment',
                        response?.error?.response || response?.error,
                        () => {
                            setError(true);
                            flushLogs();
                        },
                    ),
                );
                return;
            }

            setCurrentAppState(DecisionStages.DECISION, () =>
                sentryLog('creditCommitment was successful'),
            );
        },
        [flushLogs, sentryError, sentryLog, setCurrentAppState],
    );

    const onDecisionSuccess = useCallback(
        (response: any) => {
            if (!response.success) {
                setCurrentAppState(DecisionStages.DECISION, () =>
                    sentryError(
                        'error on decision',
                        response?.error?.response || response.error,
                        () => {
                            setError(true);
                            flushLogs();
                        },
                    ),
                );
                return;
            }
            setCurrentAppState(DecisionStages.POLL, () =>
                sentryLog('decision was requested'),
            );
        },
        [flushLogs, sentryError, sentryLog, setCurrentAppState],
    );

    const onPollSuccess = useCallback(
        (response: any) => {
            // TODO: get variant
            const newFlow = false;
            const goToGoodNews = () =>
                navigate(
                    `/eligibility/${newFlow ? 'offer/explore' : 'good-news'} `,
                    { replace: true },
                );
            const goToBadNews = () =>
                navigate('/eligibility/reject', { replace: true });

            if (!response.success) {
                setCurrentAppState(DecisionStages.POLL, () =>
                    sentryError(
                        'error on poll',
                        response?.error?.response || response.error,
                        () => {
                            flushLogs();
                            setError(true);
                        },
                    ),
                );
                return;
            }

            const decisioningAccept = response.status.includes(
                ApplicationStage.DECISIONING_ACCEPT,
            );
            if (!props.setOffers || !props.setApplicationStage) return;
            window.onbeforeunload = null;
            if (decisioningAccept) {
                props.setOffers(response.offers);
                props.setApplicationStage(ApplicationStage.DECISIONING_ACCEPT);
                sentryLog('accepted decision', goToGoodNews);
                flushLogs();
            }
            if (!decisioningAccept) {
                props.setApplicationStage(ApplicationStage.DECISIONING_DECLINE);
                sentryLog('rejected decision', goToBadNews);
                flushLogs();
            }
        },
        [
            flushLogs,
            navigate,
            props,
            sentryError,
            sentryLog,
            setCurrentAppState,
        ],
    );

    /**
     * Poll application data
     */
    const startPollOffers = useCallback(() => {
        if (!props.fetchApplication) return;
        sentryLog('poll started');
        poll(props.fetchApplication, validatePollResult, 5000, 120 * 1000).then(
            onPollSuccess,
        );
    }, [onPollSuccess, props.fetchApplication, sentryLog]);

    /**
     * Request decision from ms-d2c
     */
    const startDecisioning = useCallback(() => {
        if (!props.runDecisioning) return;
        sentryLog('decision started');
        props.runDecisioning().then(onDecisionSuccess);
    }, [props, sentryLog, onDecisionSuccess]);

    /**
     * CreditCommitment
     */
    const startUpdateCreditCommitment = useCallback(() => {
        if (!props.updateCreditCommitments) return;
        sentryLog('creditCommitment started');
        props.updateCreditCommitments().then(onCommitmentSuccess);
    }, [onCommitmentSuccess, props, sentryLog]);

    /**
     * Call fetch depends of app stage
     **/
    const startProcessDependsOnStatus = useCallback(() => {
        if (error) {
            flushLogs(); // empty logs if there is any left
            setError(false);
        }
        const prevState =
            currentDecisionStage ||
            localStorage?.getItem(storeId) ||
            DecisionStages.START;
        if (prevState === DecisionStages.COMMITMENT)
            startUpdateCreditCommitment();
        if (prevState === DecisionStages.DECISION) startDecisioning();
        if (
            prevState === DecisionStages.POLL ||
            prevState === DecisionStages.DONE
        )
            startPollOffers();
    }, [
        currentDecisionStage,
        error,
        flushLogs,
        startDecisioning,
        startPollOffers,
        startUpdateCreditCommitment,
        storeId,
    ]);

    // ON MOUNT -------------------------------------------------------------------------------------------
    useEffect(() => {
        if (!error && !componentRef.current && props.applicationId) {
            componentRef.current = true;
            startProcessDependsOnStatus();

            window.onbeforeunload = (event) => {
                const e = event || window.event;
                sentryLog('page leave requested');
                flushLogs();
                e.preventDefault();
                // Cancel the event
                if (e) {
                    e.returnValue = ''; // Legacy method for cross browser support
                }
                return ''; // Legacy method for cross browser support
            };
        }
    }, [
        error,
        sentryLog,
        startProcessDependsOnStatus,
        currentDecisionStage,
        props.applicationId,
        flushLogs,
    ]);

    // ON UPDATED APP STAGE
    useEffect(() => {
        if (
            !error &&
            componentRef.current &&
            prevDecisionStage.current !== currentDecisionStage
        ) {
            prevDecisionStage.current = currentDecisionStage;
            startProcessDependsOnStatus();
        }
    }, [currentDecisionStage, error, startProcessDependsOnStatus]);

    const waitLoader =
        props.waitLoaderFlowVariant === ExperimentsConfig.Variants.A ? (
            <WaitViewLoader />
        ) : (
            <WaitViewLoaderV2 />
        );

    return (
        <>
            {!error && waitLoader}
            <div className={clsx(props.className, 'max-w-2xl mx-auto')}>
                {error && (
                    <div className='md:pb-10 pt-12 sm:pt-20 lg:pt-32 '>
                        <div className='sm:flex sm:justify-between md:flex md:justify-between lg:flex lg:justify-between xl:flex xl:justify-between'>
                            <div className='flex justify-center'>
                                <Logos />
                            </div>
                            <div className='flex justify-center text-center sm:text-left'>
                                <div className='text-lg pl-0 md:pl-16 lg:pl-16'>
                                    <p className='tracking-widest text-steel-40 font-bold text-base mt-10 sm:mt-0 lg:mt-0'>
                                        500 ERROR
                                    </p>
                                    <p className='text-4xl font-bold pb-2 hidden lg:flex'>
                                        Sorry, something’s gone wrong
                                    </p>
                                    <H3 className='mb-3 block lg:hidden mt-2 sm:mt-0'>
                                        Sorry, something’s gone wrong
                                    </H3>
                                    <p className='text-xl leading-8'>
                                        Something has gone wrong on our end,
                                        please try to reload the page.
                                    </p>
                                    <div className='flex justify-center sm:justify-start'>
                                        <Button
                                            size='medium'
                                            className='font-bold my-5'
                                            onClick={
                                                startProcessDependsOnStatus
                                            }
                                        >
                                            Try again
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Help />
                    </div>
                )}
            </div>
        </>
    );
};

export default WaitView;
