export enum IncomeType {
    ARMED_FORCES_PENSION = 'Armed forces pension',
    BEREAVEMENT_ALLOWANCE = 'Bereavement allowance',
    BONUS = 'Bonus',
    CAR_ALLOWANCE = 'Car allowance',
    CARERS_ALLOWANCE = 'Carers allowance',
    CITY_ALLOWANCE = 'City allowance',
    COMMISSION = 'Commission',
    CONTRACT_RATE = 'Contract rate',
    DISABILITY_LIVING_ALLOWANCE = 'Disability living allowance - DLA',
    DIRECTOR_GROSS_SALARY = 'Director gross salary',
    DIVIDENDS = 'Dividends',
    DRAWINGS = 'Drawings',
    EMPLOYMENT_AND_SUPPORT_ALLOWANCE = 'Employment and support allowance',
    GUARDIANS_ALLOWANCE = 'Guardians allowance',
    INDUSTRY_INJURIES_DISABLEMENT_BENEFIT = 'Industrial injuries disablement benefit',
    INVESTMENT_INCOME = 'Investment income',
    NET_PROFIT = 'Net profit',
    MAINTENANCE_INCOME = 'Maintenance income',
    MOBILITY_ALLOWANCE = 'Mobility allowance',
    OVERTIME = 'Overtime',
    POLICE_AND_NHS_ALLOWANCE = 'Police and NHS allowance',
    PRIVATE_PENSION = 'Private pension',
    RENTAL_INCOME = 'Rental income',
    GROSS_SALARY = 'Gross salary',
    SECOND_JOB_INCOME = 'Second job income',
    SHIFT_ALLOWANCE = 'Shift allowance',
    STATE_PENSION = 'State pension',
    WORKING_TAX_CREDIT_OR_UNIVERSAL_CREDIT_EQUIVALENT = 'Working tax credit or Universal credit equivalent',
    CHILD_TAX_CREDIT_OR_UNIVERSAL_CREDIT_EQUIVALENT = 'Child tax credit or Universal credit equivalent',
    OTHER = 'Other',
    HOUSEHOLD_GROSS_INCOME = 'Household Gross Income',
    GROSS_ANNUAL_INCOME = 'Gross annual income',
}
