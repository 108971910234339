import { useCallback, useEffect } from 'react';

import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface PersistantStoreState {
    [key: string]: any;
    setValue: (key: string, value: any) => void;
    clearValue: (key: string) => void;
}

export const useSessionStore = create<PersistantStoreState>()(
    persist(
        (set, get) => ({
            setValue: (key, value) => set(() => ({ [key]: value })),
            clearValue: (key) => {
                const state = get();

                delete state[key];

                set({ ...state });
            },
        }),
        {
            name: 'session-persist',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export const useLocalStore = create<PersistantStoreState>()(
    persist(
        (set, get) => ({
            setValue: (key, value) => {
                set(() => ({ [key]: value }));
            },
            clearValue: (key) => {
                const state = get();

                delete state[key];

                set(state);
            },
        }),
        {
            name: 'local-persist',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export const useStorage = <StoredData>(
    type: 'local' | 'session',
    key: string,
    initialData?: StoredData,
): [
    StoredData | null,
    (value: StoredData | ((value: StoredData) => void)) => void,
    () => void,
] => {
    const useStore = type === 'local' ? useLocalStore : useSessionStore;

    const { storedValue, setStoredValue, clearStoredValue } = useStore(
        (state) => ({
            storedValue: state[key],
            setStoredValue: state.setValue,
            clearStoredValue: state.clearValue,
        }),
    );

    const value = storedValue ?? null;

    useEffect(() => {
        if (initialData && !storedValue) {
            setStoredValue(key, initialData);
        }
        //eslint-disable-next-line
    }, [setStoredValue, key]);

    const setValue = useCallback(
        (value: ((value: StoredData) => void) | any) => {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;

            setStoredValue(key, valueToStore);
        },
        [setStoredValue, key, storedValue],
    );

    const clear = useCallback(() => {
        clearStoredValue(key);
    }, [clearStoredValue, key]);

    return [value, setValue, clear];
};

export default useStorage;
