import * as Yup from 'yup';

import { currency } from '@FormModels/shared';

const amountMinError = 'Your loan must be at least £10k';
const amountMin = 10000;
const amountMax = 500000;
const amountMaxError = 'You can borrow up to £500,000';
const termMessage = 'Please select an option for term.';
const termRangeMessage = 'Please enter a valid term range (5-30)';

const purposeArray = [
    { label: 'Debt consolidation', value: 'Debt consolidation' },
    { label: 'Home improvements', value: 'Home improvements' },
    { label: 'Holiday', value: 'Holiday' },
    { label: 'Medical bills', value: 'Medical bills' },
    {
        label: 'Property - Second home purchase',
        value: 'Property - Second home purchase',
    },
    {
        label: 'Property - Buy-to-let purchase',
        value: 'Property - Buy-to-let purchase',
    },
    {
        label: 'School fees / further education',
        value: 'School fees / further education',
    },
    { label: 'Tax bills', value: 'Tax bills' },
    { label: 'Vehicle purchase', value: 'Vehicle purchase' },
    { label: 'Wedding', value: 'Wedding' },
    { label: 'Business purposes', value: 'Business purposes' },
    { label: 'Contingency', value: 'Contingency' },
    { label: 'Other', value: 'Other' },
].sort((a, b) => a.value.localeCompare(b.value));

export const formItems = {
    amount: {
        default: amountMin,
        label: 'How much do you want to borrow?',
        ...currency,
        maskProps: {
            min: amountMin,
            max: amountMax,
        },
        custom: {
            min: amountMin,
            max: amountMax,
        },
        validation: Yup.number()
            .typeError(amountMinError)
            .min(amountMin, amountMinError)
            .max(amountMax, amountMaxError)
            .required(amountMinError),
        transform: {
            target: 'requestedLoanAmount',
        },
    },
    term: {
        mask: 'number',
        validation: Yup.number()
            .required(termMessage)
            .typeError(termMessage)
            .min(5, termRangeMessage)
            .max(30, termRangeMessage),
        transform: {
            target: 'requestedLoanTerm',
            toRequest: (value: number) => +value,
        },
    },
    purpose: {
        items: purposeArray,
        validation: Yup.string().required(
            'Please select an option for purpose.',
        ),
        transform: {
            target: 'loanPurpose',
            toRequest: (value: string) => value,
        },
    },
    facilities: {
        transform: {
            target: 'facilities',
            toRequest: (_: any, dependencies: any) => [
                {
                    allocationAmount: dependencies.amount,
                    allocationPurpose: dependencies.purpose,
                },
            ],
        },
    },
};
