import React, { FC, useState } from 'react';

const TrustpilotLink: FC = () => {
    return (
        <a
            href='https://uk.trustpilot.com/review/selinaadvance.com'
            target='_blank'
            rel='noopener noreferrer'
        >
            Trustpilot
        </a>
    );
};

const Trustbox: FC<{ type?: 'mini' | 'microStar' }> = (props) => {
    const ref = React.useRef(null);
    const [type] = useState(props.type || 'mini');

    React.useEffect(() => {
        if (window.Trustpilot && ref.current) {
            window.Trustpilot.loadFromElement(ref.current);
        }
    }, [ref]);

    return (
        <>
            {type === 'mini' && (
                <div
                    ref={ref}
                    className='trustpilot-widget h-32 mt-18'
                    data-locale='en-GB'
                    data-template-id='53aa8807dec7e10d38f59f32'
                    data-businessunit-id='60fae03f0687800001e2a1da'
                    data-style-height='50%'
                    data-style-width='50%'
                    data-theme='light'
                >
                    <TrustpilotLink />
                </div>
            )}
            {type === 'microStar' && (
                <div className='transform scale-80 mt-0.25'>
                    <div
                        ref={ref}
                        className='trustpilot-micro-star'
                        data-locale='en-GB'
                        data-template-id='5419b732fbfb950b10de65e5'
                        data-businessunit-id='60fae03f0687800001e2a1da'
                        data-style-height='24px'
                        data-style-width='100%'
                        data-theme='light'
                        data-text-color='#898D90'
                        data-text-size='16px'
                    >
                        <TrustpilotLink />
                    </div>
                </div>
            )}
        </>
    );
};

export default Trustbox;
