const ClockIcon = ({ colour }: { colour: string }) => {
    return (
        <svg
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M8.25 1.5C6.96442 1.5 5.70772 1.88122 4.6388 2.59545C3.56988 3.30968 2.73676 4.32484 2.24479 5.51256C1.75282 6.70028 1.62409 8.00721 1.8749 9.26809C2.1257 10.529 2.74477 11.6872 3.65381 12.5962C4.56285 13.5052 5.72104 14.1243 6.98192 14.3751C8.24279 14.6259 9.54973 14.4972 10.7374 14.0052C11.9252 13.5132 12.9403 12.6801 13.6546 11.6112C14.3688 10.5423 14.75 9.28558 14.75 8C14.7467 6.27711 14.0608 4.62573 12.8425 3.40746C11.6243 2.18918 9.97289 1.5033 8.25 1.5ZM11.75 8.5H8.25C8.11739 8.5 7.99022 8.44732 7.89645 8.35355C7.80268 8.25979 7.75 8.13261 7.75 8V4.5C7.75 4.36739 7.80268 4.24021 7.89645 4.14645C7.99022 4.05268 8.11739 4 8.25 4C8.38261 4 8.50979 4.05268 8.60356 4.14645C8.69732 4.24021 8.75 4.36739 8.75 4.5V7.5H11.75C11.8826 7.5 12.0098 7.55268 12.1036 7.64645C12.1973 7.74021 12.25 7.86739 12.25 8C12.25 8.13261 12.1973 8.25979 12.1036 8.35355C12.0098 8.44732 11.8826 8.5 11.75 8.5Z'
                fill={colour}
            />
        </svg>
    );
};

export default ClockIcon;
