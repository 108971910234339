import clsx from 'clsx';

import { Container } from '@src/ui/components/Container';

import Header from '../components/Header/Header';
import { LoaderProgress } from '../components/LoaderProgress';
import Trustbar from '../components/Trustbar/Trustbar';

export interface AuthLayoutProps {
    title?: string;
    subTitle?: any;
    className?: string;
    loading?: boolean;
    children: any;
}

const AuthLayout = (props: AuthLayoutProps) => {
    return (
        <>
            <Header />

            <Trustbar />

            <Container
                variant={'thin'}
                className={clsx(
                    props.className,
                    'grid grid-cols-1 text-body mb-12 mt-10 sm:mt-32',
                )}
            >
                {props.title && (
                    <div
                        className={
                            'font-bold text-md_title text-left mx-4 sm:mx-auto lg:mx-auto mb-6 max-w-lg'
                        }
                    >
                        {props.title}
                    </div>
                )}

                {props.subTitle && (
                    <div
                        className={
                            'font-light text-body text-center mx-auto mb-4 max-w-lg'
                        }
                    >
                        {props.subTitle}
                    </div>
                )}

                <Container variant={'extraThin'}>
                    <div className='mx-4 sm:mx-24 md:mx-24 lg:mx-24 xl:mx-24'>
                        {props.children}
                    </div>
                </Container>

                <LoaderProgress fullScreen loading={props.loading} />
            </Container>
        </>
    );
};

export default AuthLayout;
