import { FC, useContext, useEffect } from 'react';

import { H3 } from '@selina-finance/ui';
import { Button } from '@selina-finance/ui';
import clsx from 'clsx';

import { ApplicationContext } from '@App/contexts/Application';
import { intercomTrackRejectPageEvent } from '@App/intercom/intercom';

import Help from '../../components/Help/Help';

const RejectView: FC<{ className?: string }> = (props) => {
    const { intercomData } = useContext(ApplicationContext);

    useEffect(() => intercomTrackRejectPageEvent(intercomData), [intercomData]);

    return (
        <>
            <div
                className={clsx(
                    props.className,
                    'flex items-center md:max-w-lg',
                )}
            >
                <div className='text-xl text-steel-100'>
                    <p className='text-5xl leading-16 font-bold mb-2 hidden lg:flex'>
                        Unfortunately, we can’t offer you a loan right now
                    </p>
                    <H3 className='mb-3 block lg:hidden mt-10 sm:mt-0'>
                        Unfortunately, we can’t offer you a loan right now
                    </H3>
                    <div className='grid gap-2'>
                        <p>
                            After an assessment of the details you have
                            provided, we cannot offer you a loan at the moment.
                        </p>
                        <p>This can be due to a variety of reasons, such as:</p>
                        <ul className={'list-disc ml-8'}>
                            <li>Affordability</li>
                            <li>Items on your credit file</li>
                            <li>Characteristics of your property</li>
                        </ul>
                        <p>
                            Should your circumstances change, we would be more
                            than happy to review a new application at a later
                            date.
                        </p>
                    </div>

                    <Button
                        size='medium'
                        color='tertiary'
                        className='font-bold w-full sm:w-auto mt-8'
                        onClick={() =>
                            (window.location.href =
                                window?._env_?.NEWQUOTE_BASE_URL || '')
                        }
                    >
                        Start a new application
                    </Button>
                </div>
            </div>
            <Help />
        </>
    );
};

export default RejectView;
