import { FC, FormEvent } from 'react';

import { ChevronDownIcon, RadioButtonGroup } from '@selina-finance/ui';

import { radioButtonOptions } from '@src/shared/helpers/input';

import SelectBox from '../../components/SelectBox/SelectBox';

const ExpectedDecrease: FC<{
    getPropsWithError: Function;
    getError: Function;
    formikProps: any;
}> = ({ getError, getPropsWithError, formikProps }) => {
    return (
        <>
            <RadioButtonGroup
                name='expectsFutureIncomeDecrease'
                value={
                    formikProps.values.expectsFutureIncomeDecrease?.toString() ||
                    ''
                }
                onChange={(e: FormEvent<HTMLInputElement>) => {
                    const hasDecrease = e.currentTarget.value === 'true';
                    if (!hasDecrease) {
                        formikProps.setFieldValue(
                            'expectsFutureIncomeDecreaseReason',
                            undefined,
                        );
                    }
                    if (hasDecrease) {
                        formikProps.setFieldTouched(
                            'expectsFutureIncomeDecreaseReason',
                            false,
                        );
                    }
                    formikProps.setFieldValue(
                        'expectsFutureIncomeDecrease',
                        hasDecrease,
                    );
                }}
                error={{
                    hasError: false,
                    errorMessage: getError('expectsFutureIncomeDecrease'),
                }}
                options={radioButtonOptions}
            />
            <div className='mt-6'>
                <SelectBox
                    {...getPropsWithError('expectsFutureIncomeDecreaseReason')}
                    hide={
                        !formikProps.values.expectsFutureIncomeDecrease
                            ? 'false'
                            : undefined
                    }
                    validState={
                        !!formikProps.values.expectsFutureIncomeDecreaseReason
                    }
                    suffix={<ChevronDownIcon />}
                />
            </div>
        </>
    );
};

export default ExpectedDecrease;
