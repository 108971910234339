import clsx from 'clsx';

export type ContainerVariant =
    | 'wide'
    | 'thin'
    | 'wider'
    | 'extraThin'
    | 'default';

export interface ContainerProps {
    className?: string;
    variant?: ContainerVariant;
    children: any;
}

const className = {
    base: 'mx-auto w-full',
    variant_wide: 'md:max-w-7xl',
    variant_wider: 'md:max-w-7xl mx-4',
    variant_thin: 'md:max-w-3xl mx-4',
    variant_extraThin: 'md:max-w-xl mx-4',
    variant_default: '',
};

const Container = (props: ContainerProps) => {
    const variant = props.variant || 'default';
    const classNames = clsx([
        props.className,
        className.base,
        className[`variant_${variant}`],
    ]);

    return <div className={classNames}>{props.children}</div>;
};

export default Container;
