import { FC, PropsWithChildren } from 'react';

import clsx from 'clsx';

const className = {
    base: 'rounded border-l-8 p-4 mt-6',
    color_success: 'bg-green border-success',
    color_error: 'bg-red-100 border-red-700',
};

export type InfoType = 'success' | 'error';

export interface InfoProps {
    type?: InfoType;
    className?: string;
}

const Info: FC<PropsWithChildren<InfoProps>> = (
    props: PropsWithChildren<InfoProps>,
) => {
    const type: InfoType = props.type || 'success';
    const classNames = clsx([
        className.base,
        props.className,
        className[`color_${type}`],
    ]);

    return <div className={classNames}>{props.children}</div>;
};

export default Info;
