import React, { FC, FormEventHandler } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';
import { GoChevronRight } from 'react-icons/go';
import { HiArrowLeft } from 'react-icons/hi';

import { Size } from '@src/ui/components/shared';

import { ButtonState } from '../Button/Button';

const className = {
    // base
    btn: 'inline-flex items-center outline-none no-underline',

    focus: 'focus:underline',
    hover: 'focus:underline',
    active: 'active:no-underline',
    disabled: 'cursor-not-allowed',

    // size
    small: 'text-md',
    medium: 'text-lg',
    large: 'text-lg',

    // outlined ----------------------------------
    color_primary: {
        default: 'text-primary',
        active: 'active:text-loading_contrast',
    },

    color_secondary: {
        default: 'text-secondary',
        active: 'active:text-secondary_hover',
    },

    state_disabled: {
        default: 'bg-disabled text-disabled_contrast',
    },

    state_loading: {
        default: 'text-loading_contrast',
    },
};

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonColor = 'primary' | 'secondary';
export type ArrowType = 'front' | 'back';

export interface LinkButtonProps
    extends Omit<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        'size' | 'type' | 'className' | 'onBlur' | 'onFocus'
    > {
    color?: ButtonColor;
    size?: Size;
    type?: ButtonType;
    className?: string;
    noHoverChange?: boolean;
    arrow?: ArrowType;
    loading?: boolean;
    href?: string;
    target?: string;
    onBlur?: FormEventHandler<HTMLButtonElement>;
    onFocus?: FormEventHandler<HTMLButtonElement>;
}

const LinkButton: FC<LinkButtonProps> = (props) => {
    // get the default values
    const size: Size = props.size || 'medium';
    const color: ButtonColor = props.color || 'primary';
    const type: ButtonType = props.type || 'button';
    const arrow: ArrowType | undefined = props.arrow || undefined;

    const classNames = clsx([
        props.className,
        (props.disabled || props.loading) && className.disabled,
        className.btn,
        !props.noHoverChange && className.active,
        !props.noHoverChange && className.focus,
        !props.noHoverChange && className.hover,
        props.disabled !== true &&
            props.loading !== true && [
                (className[`color_${color}`] as ButtonState)?.default,
                (className[`color_${color}`] as ButtonState)?.active,
                className[size],
            ],
        props.loading && className['state_loading'].default,
        props.disabled && !props.loading && className['state_disabled'].default,
    ]);

    if (props.href) {
        return (
            <a href={props.href} className={classNames} target={props.target}>
                {arrow === 'back' && <HiArrowLeft className='mr-2 text-2xl' />}
                {props.children}
                {arrow === 'front' && (
                    <GoChevronRight className='ml-2 text-xl' />
                )}
            </a>
        );
    }

    return (
        <button
            type={type}
            className={classNames}
            disabled={Boolean(props.disabled || props.loading)}
            {...omit(props, [
                'noHoverChange',
                'className',
                'size',
                'type',
                'disabled',
                'loading',
                'isAdornment',
            ])}
        >
            {arrow === 'back' && <HiArrowLeft className='mr-2 text-2xl' />}
            {props.children}
            {arrow === 'front' && <GoChevronRight className='ml-2 text-xl' />}
        </button>
    );
};

export default LinkButton;
