import { ChangeEvent, FC } from 'react';

import { CheckboxGroup, Controller, TextField } from '@selina-finance/ui';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
} from '@Shared/helpers/input';
import { retired } from '@src/app/formModels/eligibility/incomings.retired';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';

import ExpectedDecrease from './IncomingExpectedDecreaseField';
import IncomingOtherIncomesSubView from './IncomingOtherIncomesSubView';

export interface IncomingRetiredViewProps {
    className?: string;
    secondary?: boolean;
    incomeData?: any;
    setRef?: Function;
}

const defaultValues = getDefaultValues(retired);
const validationSchema = getValidationSchema(retired);

const IncomingRetiredView: FC<IncomingRetiredViewProps> = (props) => {
    return (
        <Formik
            enableReinitialize
            initialValues={{
                ...defaultValues,
                ...pick(props.incomeData, Object.keys(retired)),
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => values}
            innerRef={(ref) => {
                if (props.setRef) props.setRef(ref);
            }}
        >
            {(formikProps) => {
                const { getError, getPropsWithError } = getFormHelpers(
                    retired,
                    formikProps,
                );
                return (
                    <div className='grid grid-cols-1 gap-10 m-0'>
                        <Controller label='What type of pension(s) do you have?'>
                            <CheckboxGroup
                                name='futurePensionType'
                                error={{
                                    hasError: false,
                                    errorMessage: getError('futurePensionType'),
                                }}
                                onValuesChange={(e: any) => {
                                    formikProps.setFieldValue(
                                        'futurePensionType',
                                        e,
                                    );
                                    const checkTrue = e.find(
                                        (item: any) => item.checked === true,
                                    );
                                    if (!checkTrue) {
                                        formikProps.setFieldValue(
                                            'futurePensionType',
                                            '',
                                        );
                                    }
                                }}
                                options={formikProps.values.futurePensionType}
                            />
                        </Controller>
                        <Controller
                            label='How much do you get from this annually?'
                            additionalInfo='This should be the total annual, pre-tax amount'
                        >
                            <TextField
                                type='tel'
                                prefix='£'
                                maxLength={10}
                                mask={'currency' as any}
                                name='futurePensionIncome'
                                value={
                                    formikProps.values.futurePensionIncome?.toString() ||
                                    ''
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    formikProps.handleChange(e)
                                }
                                onBlur={formikProps.handleBlur}
                                error={getError('futurePensionIncome')}
                            />
                        </Controller>
                        <Controller
                            label='Do you earn any other income?'
                            additionalInfo='Such as bonus, dividends, pension, second job.'
                        >
                            <IncomingOtherIncomesSubView
                                getError={getError}
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                title={'Other incomes'}
                                addButtonTitle={'Add another income source'}
                            />
                        </Controller>
                        <Controller label='Do you expect your income to decrease in the near future?'>
                            <ExpectedDecrease
                                formikProps={formikProps}
                                getPropsWithError={getPropsWithError}
                                getError={getError}
                            />
                        </Controller>
                        <ErrorContainer formikProps={formikProps} />
                    </div>
                );
            }}
        </Formik>
    );
};

export default IncomingRetiredView;
