import { axiosInstance } from '@App/providers';
import {
    getRequestConfigWithUserProfile,
    httpErrorResponse,
} from '@App/requests/shared';

import { EventBooking } from '../interfaces/eventBooking';
import { Offer } from '../interfaces/offer';

enum pages {
    loandDetails = 'loan-details',
    applicantDetails = 'applicant-details',
    propertyDetails = 'property-details',
    expenditureDetails = 'expenditure-details',
    incomeDetails = 'income-details',
}

interface Tokens {
    accessToken: string;
    idToken: string;
}

export const saveApplicantPage = async (
    page: pages.incomeDetails,
    tokens: Tokens,
    body: unknown,
    id: string,
    applicant: number,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.patch(
            `${url}/application/${id}/${page}/${applicant}`,
            body,
            getRequestConfigWithUserProfile(tokens.accessToken, tokens.idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const savePage = async (
    page: pages,
    tokens: Tokens,
    body: unknown,
    id: string,
    v2?: boolean,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.patch(
            `${url}/${v2 === true ? 'v2/' : ''}application/${id}/${page}`,
            body,
            getRequestConfigWithUserProfile(tokens.accessToken, tokens.idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const savePropertyDetailsData = async (
    tokens: { accessToken: string; idToken: string },
    data: any,
    id: string,
) => {
    const body = { propertyDetails: data };
    return savePage(pages.propertyDetails, tokens, body, id, true);
};

export const saveOutgoingsData = async (
    tokens: Tokens,
    data: any,
    id: string,
) => {
    const body = { ...data };
    return savePage(pages.expenditureDetails, tokens, body, id);
};

export const saveApplicantsData = async (
    tokens: Tokens,
    applicantData: any[],
    id: string,
) => {
    const body = { applicants: applicantData };
    return savePage(pages.applicantDetails, tokens, body, id, true);
};

export const saveIncomingsData = async (
    tokens: Tokens,
    incomingsData: any[],
    id: string,
    applicant: number,
) => {
    const body = { applicants: incomingsData };
    return saveApplicantPage(pages.incomeDetails, tokens, body, id, applicant);
};

export const saveLoanDetailsData = async (
    tokens: Tokens,
    data: { loanInformation: any; applicants: any[] },
    id: string,
) => {
    const body = { ...data };
    return savePage(pages.loandDetails, tokens, body, id);
};

export const savePhoneNumber = async (
    phoneNumber: string,
    externalApplicationId: string,
    applicationId: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const body = {
            phoneNumber,
            externalApplicationId,
        };
        const result = await axiosInstance.post(
            `${url}/public/application/phone-number/${applicationId}`,
            body,
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const saveOffer = async (
    applicationId: string,
    accessToken: string,
    idToken: string,
    offer: Offer | null,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.post(
            `${url}/v2/application/${applicationId}/product?product_code=${offer?.productCode}`,
            undefined,
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};

export const saveEventBooking = async (
    accessToken: string,
    applicationId: string,
    eventBooking: EventBooking | null,
    idToken: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        const result = await axiosInstance.post(
            `${url}/v2/application/${applicationId}/advice-call`,
            eventBooking,
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return await { ...result.data, success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
