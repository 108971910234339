import React from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';

import App from './App';

Amplify.configure({
    Auth: {
        mandatorySignIn: window._env_.COGNITO_MANDATORY_SIGN_IN,
        region: window._env_.COGNITO_REGION,
        userPoolId: window._env_.COGNITO_USER_POOL_ID,
        userPoolWebClientId: window._env_.COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
});

declare global {
    interface Window {
        _env_: any;
        Trustpilot: any;
        tr: any;
        environment: any;
        Intercom: any;
        intercomSettings: any;
        heap: any;
        Calendly: any;
    }
}

window.environment = window._env_.ENV;

Sentry.init({
    dsn: window._env_.SENTRY,
    integrations: [new BrowserTracing()],
    environment: window._env_.ENV,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
