import { InfoBox } from '@selina-finance/ui';

const ErrorContainer = (props: {
    formikProps: any;
    additionalErrors?: string[];
}) => {
    const getNumberOfErrors = (formikProps: any) => {
        let errorCounter = 0;
        for (const [key] of Object.entries(formikProps.values)) {
            const isTouchedAndInvalid =
                formikProps.touched[key] && formikProps.errors[key];
            if (isTouchedAndInvalid) {
                errorCounter++;
            }
        }
        return errorCounter;
    };

    const errorLength = getNumberOfErrors(props.formikProps);

    return errorLength > 0 ? (
        <div className='mb-3'>
            <InfoBox type='error'>
                There{' '}
                {errorLength > 1
                    ? 'are ' + errorLength + ' errors'
                    : 'is 1 error'}{' '}
                on this page, &nbsp;please resolve to continue
                <ul className='ml-6 list-disc'>
                    {props.additionalErrors &&
                        props.additionalErrors.map((it, index) =>
                            it ? <li key={index}>{it}</li> : null,
                        )}
                </ul>
            </InfoBox>
        </div>
    ) : (
        <></>
    );
};

export default ErrorContainer;
