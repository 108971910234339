const PopularIcon = () => {
    return (
        <svg
            width='91'
            height='18'
            viewBox='0 0 91 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='91' height='18' rx='4' fill='#66CC66' />
            <path
                d='M9 4L10.3284 7.17162L13.7553 7.45492L11.1494 9.69838L11.9389 13.0451L9 11.26L6.06107 13.0451L6.85061 9.69838L4.24472 7.45492L7.67161 7.17162L9 4Z'
                fill='white'
            />
            <path
                d='M18.4102 5.18359H22.1895C24.0879 5.18359 25.207 6.15039 25.207 7.80273C25.207 9.45508 24.0879 10.3926 22.1895 10.3926H20.4023V13H18.4102V5.18359ZM20.4023 8.57617H22.1719C22.8398 8.57617 23.1562 8.31836 23.1562 7.80273C23.1562 7.28711 22.8398 7.0293 22.1719 7.0293H20.4023V8.57617ZM32.2535 13.1172C29.7574 13.1172 28.0758 11.4238 28.0758 9.0918C28.0758 6.75391 29.7516 5.06641 32.2535 5.06641C34.7555 5.06641 36.4312 6.75391 36.4312 9.0918C36.4312 11.4238 34.7496 13.1172 32.2535 13.1172ZM32.2535 11.2422C33.5543 11.2422 34.3512 10.3164 34.3512 9.0918C34.3512 7.86133 33.5543 6.94141 32.2535 6.94141C30.9527 6.94141 30.1559 7.86133 30.1559 9.0918C30.1559 10.3164 30.9527 11.2422 32.2535 11.2422ZM39.4758 5.18359H43.2551C45.1535 5.18359 46.2727 6.15039 46.2727 7.80273C46.2727 9.45508 45.1535 10.3926 43.2551 10.3926H41.468V13H39.4758V5.18359ZM41.468 8.57617L43.2375 8.57617C43.9055 8.57617 44.2219 8.31836 44.2219 7.80273C44.2219 7.28711 43.9055 7.0293 43.2375 7.0293L41.468 7.0293V8.57617ZM52.7625 13.1172C50.5535 13.1172 49.3172 11.998 49.3172 9.79492V5.18359H51.3094V9.67773C51.3094 10.7676 51.7254 11.2422 52.7625 11.2422C53.7996 11.2422 54.2156 10.7676 54.2156 9.67773V5.18359H56.2078V9.79492C56.2078 11.998 54.9715 13.1172 52.7625 13.1172ZM65.1117 13H59.4281V5.18359H61.4203V11.1543H65.1117V13ZM76.1602 13H74.0449L73.5762 11.7227H70.3711L69.9082 13H67.8281L70.8281 5.18359H73.1484L76.1602 13ZM71.9707 7.32812L71.0215 9.93555H72.9258L71.9707 7.32812ZM83.8219 10.041L86.0895 13H83.7516L81.6949 10.1875H80.9508V13H78.9703V5.18359H82.6324C84.5074 5.18359 85.65 6.13867 85.65 7.71484C85.65 8.91016 84.9879 9.73047 83.8219 10.041ZM82.6148 7H80.9508V8.42969H82.6148C83.2652 8.42969 83.5992 8.17773 83.5992 7.71484C83.5992 7.24609 83.2652 7 82.6148 7Z'
                fill='white'
            />
        </svg>
    );
};

export default PopularIcon;
