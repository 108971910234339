import { useContext } from 'react';

import { ApplicationContext } from '@App/contexts/Application';
import EligibilityLayout from '@src/ui/layouts/EligibilityLayout';
import BookCallView from '@src/ui/views/offer/BookCallView';

const BookCall = () => {
    const { loading } = useContext(ApplicationContext);

    return (
        <EligibilityLayout loading={loading} offerPage>
            <BookCallView />
        </EligibilityLayout>
    );
};

export default BookCall;
