import { DetailedHTMLProps, FC, LabelHTMLAttributes } from 'react';

import clsx from 'clsx';
import { omit } from 'lodash';

import { Size } from '@src/ui/components/shared';

const className = {
    // base
    label: 'flex flex-row text-thin font-light',

    // label
    small: 'text-sm ',
    medium: 'text-lg',
    large: 'text-xl ',

    neutral: 'text-neutral',
    error: 'text-error',
    success: 'text-success',
    secondary: 'text-success',
    primary: 'text-primary',
    base: 'text-base',
};

export type LabelColor =
    | 'base'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'success'
    | 'neutral';

interface LabelProps
    extends Omit<
        DetailedHTMLProps<
            LabelHTMLAttributes<HTMLLabelElement>,
            HTMLLabelElement
        >,
        'size' | 'color'
    > {
    size?: Size;
    color?: LabelColor;
}

const Label: FC<LabelProps> = (props) => {
    const size: Size = props.size || 'medium';
    const color: LabelColor = props.color || 'base';

    const baseClassName = clsx([
        props.className,
        className.label,
        className[`${size}`],
        className[`${color}`],
    ]);

    const limitedProps = omit(props, [
        'className',
        'size',
    ]) as DetailedHTMLProps<
        LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
    >;

    return (
        <label className={baseClassName} {...limitedProps}>
            {props.children}
        </label>
    );
};

export default Label;
