import {
    ChangeEvent,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {
    Button,
    Controller,
    H2,
    H3,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import cookie from 'cookie';
import { Form, Formik } from 'formik';
import { keys } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@src/app/contexts/Application';
import { AuthContext } from '@src/app/contexts/Auth';
import { formItems } from '@src/app/formModels/eligibility/outgoings';
import { normalizeNumber, toCurrency } from '@src/shared/helpers';
import {
    getFormHelpers,
    getValidationSchema,
    radioButtonOptions,
} from '@src/shared/helpers/input';
import { HttpError } from '@src/ui/components/HttpError';
import { Info } from '@src/ui/components/Info';

import BackButton from '../../components/BackButton/BackButton';
import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import CheckCircleIcon from '../../components/Icons/CheckCircleIcon';
import EyeIcon from '../../components/Icons/EyeIcon';
import ProgressMenu from '../../components/Progress/ProgressMenu';
import SubHeading from '../../components/SubHeading/SubHeading';

export const getExpenditureValue = (value: any): number => {
    return Number(isNaN(value) ? 0 : value);
};

export interface OutgoingsViewProps {
    className?: string;
    coApplicantName?: string;
    outgoingsData?: any;
    saveOutgoings?: (data: any) => Promise<any>;
}

const validationSchema = getValidationSchema(formItems);

const OutgoingsView = forwardRef((props: OutgoingsViewProps, ref) => {
    const formikRef = useRef<any>(null);

    const [httpError, setHttpError] = useState(null);
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { setCurrentForm, applicationEmail } = useContext(ApplicationContext);
    const { loggedIn, register, logIn } = useContext(AuthContext);

    useEffect(() => {
        formikRef.current.values = {
            ...props.outgoingsData,
        };
    }, [props.outgoingsData]);

    useEffect(() => {
        setCurrentForm(undefined);
    }, [setCurrentForm]);

    useEffect(() => {
        if (formikRef.current) {
            setCurrentForm(formikRef.current);
        }
    }, [formikRef, setCurrentForm]);

    function getExpendituresTotal(values: any) {
        return (
            getExpenditureValue(normalizeNumber(values.food)) +
            getExpenditureValue(normalizeNumber(values.clothing)) +
            getExpenditureValue(normalizeNumber(values.tvPhoneInternet)) +
            getExpenditureValue(normalizeNumber(values.utilities)) +
            getExpenditureValue(normalizeNumber(values.insurance)) +
            getExpenditureValue(normalizeNumber(values.councilTax)) +
            getExpenditureValue(normalizeNumber(values.transport)) +
            getExpenditureValue(normalizeNumber(values.recreation)) +
            getExpenditureValue(normalizeNumber(values.rent)) +
            getExpenditureValue(normalizeNumber(values.otherExpenditures))
        );
    }

    const cookies = cookie.parse(document.cookie);
    const appInfo = cookies.application ? JSON.parse(cookies.application) : {};

    const handleLogin = (values: any, applicationId: string) => {
        setTimeout(() => {
            logIn(
                applicationEmail,
                values.password,
                applicationId || appInfo.applicationId,
            ).then((loginResult: any) => {
                if (!loginResult.success) {
                    setErrors({
                        login: "Sorry, we didn't recognise you. Please check your login details and try again.",
                    });
                    return;
                }
                navigate('/eligibility');
            });
        }, 3000);
    };

    const saveForLater = () => {
        const values = formikRef.current.values;

        props.saveOutgoings &&
            props.saveOutgoings(values).then((data: any) => {
                if (!data.success) {
                    setHttpError(data.error);
                }
            });
    };

    useImperativeHandle(ref, () => ({
        saveMethod: saveForLater,
    }));

    return (
        <div className='flex flex-row justify-between'>
            <div className='w-full sm:w-1/2 lg:w-2/5 mt-20 sm:mt-0'>
                <BackButton />
                <H2>Your expenses</H2>
                {props.coApplicantName && (
                    <SubHeading
                        text={`Please provide combined expenditure for you and ${props.coApplicantName}`}
                    />
                )}
                <div className='relative mb-15'>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            ...props.outgoingsData,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            setErrors({});
                            setHttpError(null);
                            props.saveOutgoings &&
                                props
                                    .saveOutgoings(values)
                                    .then((data: any) => {
                                        if (data.success) {
                                            if (loggedIn) {
                                                const redirectTo =
                                                    values.redirectTo ||
                                                    '/eligibility/wait';
                                                navigate(redirectTo);
                                            } else {
                                                register(
                                                    applicationEmail,
                                                    values.password,
                                                    appInfo.applicationId,
                                                ).then((result: any) => {
                                                    if (result.success) {
                                                        setSuccess(
                                                            `${result.message} Please wait, the page will redirect to the next step.`,
                                                        );
                                                        handleLogin(
                                                            values,
                                                            result.applicationId,
                                                        );
                                                    } else {
                                                        if (
                                                            result.code ===
                                                            'InvalidParameterException'
                                                        ) {
                                                            return setErrors({
                                                                error: 'Email is not valid',
                                                            });
                                                        }
                                                        if (
                                                            result.code ===
                                                            'UsernameExistsException'
                                                        ) {
                                                            const redirectTo = `../login?email=${encodeURIComponent(
                                                                applicationEmail ||
                                                                    '',
                                                            )}&applicationId=${encodeURIComponent(
                                                                result.applicationId ||
                                                                    '',
                                                            )}`;
                                                            const anchor =
                                                                '<a style="text-decoration: underline" href="' +
                                                                redirectTo +
                                                                '" target="_self" rel="noopener noreferrer">log in here</a>';
                                                            return setErrors({
                                                                error: `An account with this email already exists. Please ${anchor}`,
                                                            });
                                                        }

                                                        setErrors({
                                                            code: result.message,
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                        if (!data.success) {
                                            setHttpError(data.error);
                                        }
                                    });
                        }}
                        innerRef={(ref) => (formikRef.current = ref)}
                    >
                        {(formikProps) => {
                            const { getError } = getFormHelpers(
                                formItems,
                                formikProps,
                            );

                            if (loggedIn) {
                                delete formikProps.errors.password;
                            }

                            return (
                                <Form>
                                    <div className='grid grid-cols-1 mt-6 mb-6 gap-10'>
                                        <Controller
                                            label={`Do you ${
                                                props.coApplicantName
                                                    ? "or the person you're applying with"
                                                    : ''
                                            } have any dependants?`}
                                            additionalInfo='Anyone who relies on you financially. This can be an adult or child.'
                                        >
                                            <RadioButtonGroup
                                                name='haveDependants'
                                                id='haveDependants'
                                                value={formikProps.values.haveDependants?.toString()}
                                                error={{
                                                    hasError: false,
                                                    errorMessage:
                                                        getError(
                                                            'haveDependants',
                                                        ),
                                                }}
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    formikProps.setTouched({
                                                        ...formikProps.touched,
                                                        haveDependants: true,
                                                    });
                                                    const value =
                                                        e.target.value ===
                                                        'true';
                                                    if (
                                                        value !==
                                                        formikProps.values
                                                            .haveDependants
                                                    ) {
                                                        formikProps.setFieldValue(
                                                            'adultDependants',
                                                            null,
                                                        );
                                                        formikProps.setFieldValue(
                                                            'childDependants',
                                                            null,
                                                        );
                                                    }
                                                    formikProps.setFieldValue(
                                                        'haveDependants',
                                                        value,
                                                    );
                                                }}
                                                options={radioButtonOptions}
                                            />
                                        </Controller>
                                        {formikProps.values.haveDependants && (
                                            <div className='bg-neutral-40 flex p-4 rounded'>
                                                <TextField
                                                    mask={'number' as any}
                                                    className='mr-4'
                                                    labelSize='small'
                                                    label='How many under 18?'
                                                    name='childDependants'
                                                    maxLength={1}
                                                    value={
                                                        formikProps.values.childDependants?.toString() ||
                                                        ''
                                                    }
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        formikProps.handleChange(
                                                            e,
                                                        )
                                                    }
                                                    onBlur={
                                                        formikProps.handleBlur
                                                    }
                                                    error={getError(
                                                        'childDependants',
                                                    )}
                                                />
                                                <TextField
                                                    labelSize='small'
                                                    mask={'number' as any}
                                                    label='How many over 18?'
                                                    name='adultDependants'
                                                    maxLength={1}
                                                    value={
                                                        formikProps.values.adultDependants?.toString() ||
                                                        ''
                                                    }
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        formikProps.handleChange(
                                                            e,
                                                        )
                                                    }
                                                    onBlur={
                                                        formikProps.handleBlur
                                                    }
                                                    error={getError(
                                                        'adultDependants',
                                                    )}
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <H3 className='mb-4'>
                                                Monthly expenditure
                                            </H3>

                                            <TextField
                                                prefix='£'
                                                label='Food, drink, and other housekeeping costs'
                                                maxLength={7}
                                                mask={'number' as any}
                                                name='food'
                                                value={
                                                    formikProps.values.food?.toString() ||
                                                    ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError('food')}
                                            />
                                        </div>

                                        <TextField
                                            prefix='£'
                                            label='Clothing and footwear'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='clothing'
                                            value={
                                                formikProps.values.clothing?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('clothing')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='TV, phone and internet'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='tvPhoneInternet'
                                            value={
                                                formikProps.values.tvPhoneInternet?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('tvPhoneInternet')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Utilities (electricity, gas, water, etc)'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='utilities'
                                            value={
                                                formikProps.values.utilities?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('utilities')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Insurance'
                                            name='insurance'
                                            maxLength={7}
                                            mask={'number' as any}
                                            value={
                                                formikProps.values.insurance?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('insurance')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Council tax'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='councilTax'
                                            value={
                                                formikProps.values.councilTax?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('councilTax')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Transport'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='transport'
                                            value={
                                                formikProps.values.transport?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('transport')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Recreation, including eating out and holidays'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='recreation'
                                            value={
                                                formikProps.values.recreation?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('recreation')}
                                        />

                                        <TextField
                                            prefix='£'
                                            label='Ground rent and service charge'
                                            maxLength={7}
                                            mask={'number' as any}
                                            name='rent'
                                            value={
                                                formikProps.values.rent?.toString() ||
                                                ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError('rent')}
                                        />

                                        <Controller
                                            label='Anything else?'
                                            additionalInfo='Please exclude mortgage, credit card, and loan payments from this section.'
                                        >
                                            <TextField
                                                prefix='£'
                                                maxLength={7}
                                                mask={'number' as any}
                                                name='otherExpenditures'
                                                value={
                                                    formikProps.values.otherExpenditures?.toString() ||
                                                    ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError(
                                                    'otherExpenditures',
                                                )}
                                            />
                                        </Controller>

                                        <div className='bg-neutral-40 rounded p-6 flex flex-col'>
                                            <label>
                                                Total monthly expenditure
                                            </label>
                                            <label className='text-3xl font-extrabold'>{`£${toCurrency(
                                                getExpendituresTotal(
                                                    formikProps.values,
                                                ),
                                            )}`}</label>
                                        </div>

                                        {!loggedIn && (
                                            <div className='flex flex-col'>
                                                <label className='text-xl'>
                                                    Create a password
                                                </label>
                                                <label className='mb-2'>
                                                    So you can access your quote
                                                    securely any time
                                                </label>

                                                <TextField
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    suffix={
                                                        <div
                                                            onClick={() =>
                                                                setShowPassword(
                                                                    !showPassword,
                                                                )
                                                            }
                                                            className='flex items-center cursor-pointer'
                                                        >
                                                            <EyeIcon />
                                                            <label className='ml-2 cursor-pointer'>
                                                                {showPassword
                                                                    ? 'Hide'
                                                                    : 'Show'}
                                                            </label>
                                                        </div>
                                                    }
                                                    id='password'
                                                    name='password'
                                                    value={
                                                        formikProps.values.password?.toString() ||
                                                        ''
                                                    }
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>,
                                                    ) =>
                                                        formikProps.handleChange(
                                                            e,
                                                        )
                                                    }
                                                    onBlur={
                                                        formikProps.handleBlur
                                                    }
                                                    error={getError('password')}
                                                />
                                            </div>
                                        )}

                                        <div>
                                            <label className='text-xl'>
                                                By continuing, you:
                                            </label>
                                            <div className='bg-neutral-40 rounded p-4 flex items-center mt-4'>
                                                <div>
                                                    <CheckCircleIcon />
                                                </div>

                                                <label className='ml-2'>
                                                    Certify that the details you
                                                    provided are accurate and
                                                    complete.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {httpError && (
                                        <HttpError error={httpError} />
                                    )}
                                    <ErrorContainer formikProps={formikProps} />
                                    {errors &&
                                        keys(errors).map(
                                            (error: any, i: number) => {
                                                return (
                                                    <Info
                                                        type={'error'}
                                                        className={'mb-6'}
                                                    >
                                                        <ul
                                                            className='whitespace-pre-line'
                                                            key={`error_${i}`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: (
                                                                    errors as any
                                                                )[error],
                                                            }}
                                                        />
                                                    </Info>
                                                );
                                            },
                                        )}
                                    {success && (
                                        <Info type='success' className='mb-6'>
                                            {success}
                                        </Info>
                                    )}
                                    <div className='flex gap-5 justify-end mt-12'>
                                        <Button
                                            color='secondary'
                                            type='submit'
                                            size={'large'}
                                            className='w-full'
                                        >
                                            View my quote
                                        </Button>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <ProgressMenu />
        </div>
    );
});

export default OutgoingsView;
