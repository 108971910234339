import { FC } from 'react';

import clsx from 'clsx';

export interface TestimonialProps {
    className?: string;
    name: string;
    title: string;
    description: string;
}

const Testimonial: FC<TestimonialProps> = (props) => {
    return (
        <div
            className={clsx(
                'm-auto max-w-2xl text-center text-lg',
                props.className,
            )}
        >
            {props.description}
            <br />
            <br />
            <p>{props.name}</p>
            <p>{props.title}</p>
        </div>
    );
};

export default Testimonial;
