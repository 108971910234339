import { isValidDateFormat, isValidPostCode } from '@Helpers';
import { validateUKPhoneNumber } from '@Helpers';
import { format } from 'date-fns';
import * as Yup from 'yup';

import {
    formAddressDateParser,
    months,
    parseValidDate,
    toApiDate,
    toValidDate,
    years,
} from '@Shared/helpers/date';
import {
    isDateMoreNow,
    isLessThanSixMonth,
    isMoreThanThreeYears,
    isMoreThanTwoMonth,
    isToDateMore,
} from '@Shared/helpers/dateDifference';

const firstNameError = 'Please enter a valid first name';
const lastNameError = 'Please enter a valid surname';
export const requiredErrorMessage = 'This is a required field';

export const formItems = {
    // ----------------------------------------------------------------
    month: {
        items: months,
    },
    year: {
        items: years,
    },
    title: {
        items: [
            { label: 'Mr.', value: 'Mr.' },
            { label: 'Ms.', value: 'Ms.' },
            { label: 'Miss', value: 'Miss' },
            { label: 'Mrs.', value: 'Mrs.' },
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Other', value: 'Other' },
        ],
        validation: Yup.string().nullable().required(requiredErrorMessage),
        transform: { target: 'title' },
        default: '',
    },
    // ----------------------------------------------------------------
    firstName: {
        validation: Yup.string()
            .nullable()
            .required(firstNameError)
            .min(2, firstNameError)
            .max(255, firstNameError),
        transform: { target: 'firstName' },
    },
    // ----------------------------------------------------------------
    middleName: {
        validation: Yup.string().nullable(),
        transform: { target: 'middleName' },
    },
    // ----------------------------------------------------------------
    lastName: {
        validation: Yup.string()
            .nullable()
            .required(lastNameError)
            .min(2, lastNameError)
            .max(255, lastNameError),
        transform: { target: 'lastName' },
    },
    // ----------------------------------------------------------------
    dateOfBirth: {
        validation: Yup.string()
            .required('Please specify your birth date')
            .test('valid_date', 'Invalid date', (dt: any) => {
                const isValidDate = isValidDateFormat(dt);
                let isValidInterval = true;
                if (isValidDate) {
                    const min = new Date(1900, 0, 1);
                    const max = new Date();
                    isValidInterval =
                        parseValidDate(dt) >= min && parseValidDate(dt) <= max;
                }
                return isValidDate && isValidInterval;
            }),
        transform: {
            target: 'dateOfBirth',
            toRequest: (value: string) => toApiDate(value),
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    // ----------------------------------------------------------------
    kqUkResidency: {
        validation: Yup.boolean()
            .nullable()
            .required('This is a required field'),
        default: null,
        transform: {
            target: 'kqUkResidency',
        },
    },
    // ----------------------------------------------------------------
    mobilePhoneNumber: {
        label: 'What is your phone number?',
        validation: Yup.string().test(
            'validPhoneNumber',
            'Please enter a valid UK mobile number',
            (value: any) => {
                return validateUKPhoneNumber(value || '');
            },
        ),
        transform: {
            target: 'mobilePhoneNumber',
        },
    },
    // ----------------------------------------------------------------
    notHavePermanentRightToResideInTheUk: {
        validation: Yup.boolean()
            .when('kqUkResidency', {
                is: false,
                then: Yup.boolean().nullable().required(requiredErrorMessage),
            })
            .nullable(),
        default: null,
        transform: {
            target: 'notHavePermanentRightToResideInTheUk',
        },
    },
    // ----------------------------------------------------------------
    hasAnyCriminalConvictions: {
        validation: Yup.boolean().nullable().required(requiredErrorMessage),
        default: null,
        transform: {
            target: 'hasAnyCriminalConvictions',
        },
    },
    // ----------------------------------------------------------------
    hasSecondApplicant: {
        validation: Yup.boolean().nullable().required(requiredErrorMessage),
        default: null,
    },
    // ----------------------------------------------------------------
    addresses: {
        address: {
            placeholder: 'Search by postcode',
        },
        livedIn: {
            from: {
                label: 'When did you move in',
                mask: 'month-year',
            },
            to: {
                label: 'When did you move out',
                mask: 'month-year',
            },
        },
        default: [
            {
                address: undefined,
                livedIn: {},
            },
        ],
        validation: Yup.array()
            .of(
                Yup.object().shape({
                    address: Yup.object()
                        .shape({
                            postcode: Yup.string(),
                        })
                        .test(
                            'hasPostcode',
                            'Please enter a valid postcode',
                            (address: any) =>
                                address?.postcode &&
                                isValidPostCode(address.postcode || ''),
                        )
                        .test(
                            'hasAddressValues',
                            'This field is required',
                            (address: any) =>
                                !!address?.city && !!address?.line1,
                        )
                        .nullable(),
                    livedIn: Yup.object().shape({
                        from: Yup.string()
                            .required('Please select month and year')
                            .test(
                                'isValid',
                                'Please select month and year',
                                (value: any) => {
                                    return value && value.length === 7;
                                },
                            )
                            .test(
                                'isDateInPast',
                                'Your move in date should be in the past',
                                (value) => {
                                    const date = value
                                        ? formAddressDateParser(value)
                                        : null;

                                    return !isDateMoreNow(date);
                                },
                            ),
                        to: Yup.string()
                            .test(
                                'isValid',
                                'Please select month and year',
                                (value: any) => value && value.length === 7,
                            )
                            .test(
                                'isSixMonthsGap',
                                'Please make sure there are no gaps longer than 6 months in your address history',
                                (value, context: any) => {
                                    const parsedPath =
                                        context.path.match(/\d+/);
                                    const currentIndex =
                                        parsedPath && Number(parsedPath[0]);

                                    if (currentIndex && currentIndex > 0) {
                                        const previousFromDate =
                                            formAddressDateParser(
                                                context.from[2]?.value
                                                    ?.addresses[
                                                    currentIndex - 1
                                                ].livedIn.from,
                                            );

                                        const toDate = value
                                            ? formAddressDateParser(value)
                                            : null;

                                        const isLessThanSix =
                                            isLessThanSixMonth(
                                                previousFromDate,
                                                toDate,
                                            );

                                        return !isLessThanSix;
                                    }

                                    return true;
                                },
                            )
                            .test(
                                'isOverlapped',
                                "Please make sure your addresses don't overlap by more than 2 months",
                                (value, context: any) => {
                                    const parsedPath =
                                        context.path.match(/\d+/);
                                    const currentIndex =
                                        parsedPath && Number(parsedPath[0]);

                                    if (currentIndex && currentIndex > 0) {
                                        const previousFromDate =
                                            formAddressDateParser(
                                                context.from[2]?.value
                                                    ?.addresses[
                                                    currentIndex - 1
                                                ].livedIn.from,
                                            );

                                        const toDate = value
                                            ? formAddressDateParser(value)
                                            : null;

                                        const isMoreThanTwo =
                                            isMoreThanTwoMonth(
                                                previousFromDate,
                                                toDate,
                                            );

                                        return !isMoreThanTwo;
                                    }
                                    return true;
                                },
                            )
                            .test(
                                'isMoveInMoreThanMoveOut',
                                'The move out date cannot be earlier than the move in date',
                                (value, context: any) => {
                                    const toDate = value
                                        ? formAddressDateParser(value)
                                        : null;

                                    const fromDate = formAddressDateParser(
                                        context.parent.from,
                                    );
                                    return isToDateMore(fromDate, toDate);
                                },
                            )
                            .test(
                                'isDateInPast',
                                'Your move out date should be in the past',
                                (value) => {
                                    const date = value
                                        ? formAddressDateParser(value)
                                        : null;

                                    return !isDateMoreNow(date);
                                },
                            ),
                    }),
                }),
            )
            .test(
                'hasThreeYearsHistory',
                'Please enter at least 3 years of your address history',
                (value: any) => isMoreThanThreeYears(value),
            ),
        transform: {
            target: 'addresses',
            fromRequest: (_value: any, dependencies: any) => {
                return dependencies.addresses.map((address: any) => {
                    const from = address?.livedIn?.from;
                    const to = address?.livedIn?.to;
                    if (from) {
                        const isValidFromDate = isValidDateFormat(from);
                        const validFromDate = parseValidDate(from);
                        address.livedIn.from = isValidFromDate
                            ? format(validFromDate, 'MM/yyyy')
                            : '';
                    }
                    if (to) {
                        const isValidToDate = isValidDateFormat(to);
                        const validToDate = parseValidDate(to);
                        address.livedIn.to = isValidToDate
                            ? format(validToDate, 'MM/yyyy')
                            : '';
                    }
                    return address;
                });
            },
            toRequest: (_value: any, dependencies: any) => {
                return dependencies.addresses.map((address: any) => {
                    if (address?.livedIn?.from) {
                        address.livedIn.from = '01/' + address.livedIn.from;
                    }
                    if (address?.livedIn?.to) {
                        address.livedIn.to = '01/' + address.livedIn.to;
                    }
                    return address;
                });
            },
        },
    },
};
