import { FC, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';
import { LoaderProgress } from '@src/ui/components/LoaderProgress';

const Logout: FC = () => {
    const { logOut } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        logOut().then(() => {
            navigate('/login');
        });
    }, [logOut, navigate]);

    return <LoaderProgress fullScreen loading />;
};

export default Logout;
