import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Calendly } from '../Calendly';
import { PhoneIcon } from '../Icons';

const BookACall: FC<{ className?: string }> = (props) => {
    const { t } = useTranslation('goodNews', { keyPrefix: 'bookACall' });
    const [showCalendly, setShowCalendly] = useState<boolean>(false);

    return (
        <div className={props.className}>
            <p className='font-extrabold leading-120% text-28 text-steel-80 mb-1'>
                {t('title') as String}
            </p>
            <p className='text-lg text-steel-100 leading-5'>
                {t('description')}
            </p>
            <div className='bg-white border-1 border-neutral-100 flex flex-col gap-1 mt-4 p-6 md:p-28 rounded-t-lg'>
                <div className='flex font-black gap-1 items-center md:text-37 md:justify-start text-2xl text-steel-100'>
                    <PhoneIcon className='flex-shrink-0 md:h-10 md:w-10' />
                    <span className='md:whitespace-normal whitespace-pre-line'>
                        {t('phone') as String}
                    </span>
                </div>

                <div className='flex flex-col font-bold gap-2 md:flex-row md:items-center text-advance-100'>
                    <p className='text-lg'>
                        <span>{t('schedule.label') as String}</span>
                        <span
                            className='ml-1 underline cursor-pointer'
                            onClick={() => setShowCalendly(true)}
                        >
                            {t('schedule.book') as String}
                        </span>
                    </p>
                </div>

                {showCalendly && (
                    <Calendly onClose={() => setShowCalendly(false)} />
                )}
            </div>
            <div className='bg-neutral-10 border-1 border-neutral-100 border-t-0 p-28 rounded-b-lg text-steel-100 text-xl'>
                <p className='mb-1 font-bold'>{t('bullets.label') as String}</p>
                <ul className='leading-tight list-decimal ml-4'>
                    <li>{t('bullets.bullet1') as String}</li>
                    <li>{t('bullets.bullet2') as String}</li>
                    <li>{t('bullets.bullet3') as String}</li>
                </ul>
            </div>
        </div>
    );
};

export default BookACall;
