import { FC, useEffect } from 'react';

import clsx from 'clsx';

import { Info } from '@src/ui/components/Info';

export interface HttpErrorProps {
    className?: string;
    error?: string | null;
}

const HttpError: FC<HttpErrorProps> = (props) => {
    useEffect(
        () =>
            console.error(
                '[Form] Error updating the application: ',
                props.error,
            ),
        [props.error],
    );

    return (
        <Info
            className={clsx(props.className, 'mt-9 mb-15 text-lg')}
            type='error'
        >
            Oops, look likes something went wrong. Our tech team has been
            notified and will look into the issue soon.
            <br />
            <br />
            Please try again in a few hours or contact us on{' '}
            <a className={''} href={'tel:02045258044'}>
                020 4525 8044
            </a>
        </Info>
    );
};

export default HttpError;
