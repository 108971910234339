import { useContext, useEffect, useState } from 'react';

import cookie from 'cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import { AuthContext } from '@App/contexts/Auth';

import { LoaderProgress } from '../LoaderProgress';

const ApplicationFormPages = [
    '/eligibility/loan-details',
    '/eligibility/applicants',
    '/eligibility/property-details',
    '/eligibility/outgoings',
    '/eligibility/reject',
    '/eligibility/income',
    '/eligibility/create-account',
    '/eligibility/',
    '/eligibility',
];

const Protected = (props: { children: any }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fallback = '/login';
    const { isLoggedIn, updateCurrentUser } = useContext(AuthContext);
    const [waiting, setWaiting] = useState(true);

    useEffect(() => {
        isLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                updateCurrentUser().then(() => {
                    setWaiting(false);
                });
            } else {
                if (location.pathname !== fallback) {
                    const cookies = cookie.parse(document.cookie);

                    if (!cookies.application) {
                        navigate(fallback, { replace: true });
                        return;
                    }
                    const appInfo = JSON.parse(cookies.application);

                    if (
                        ApplicationFormPages.includes(location.pathname) &&
                        appInfo?.applicationId
                    ) {
                        setWaiting(false);
                    } else {
                        navigate(fallback, { replace: true });
                    }
                }
            }
        });
    }, [isLoggedIn, updateCurrentUser, navigate, fallback, location.pathname]);

    return waiting ? <LoaderProgress fullScreen /> : <>{props.children}</>;
};

export default Protected;
