const PercentageIncompleteIcon = () => {
    return (
        <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.9543 40.5 0 31.5457 0 20.5C0 9.4543 8.9543 0.5 20 0.5C31.0457 0.5 40 9.4543 40 20.5ZM4.946 20.5C4.946 28.8141 11.6859 35.554 20 35.554C28.3141 35.554 35.054 28.8141 35.054 20.5C35.054 12.1859 28.3141 5.446 20 5.446C11.6859 5.446 4.946 12.1859 4.946 20.5Z'
                fill='#E3DDD1'
            />
        </svg>
    );
};

export default PercentageIncompleteIcon;
