import { axiosInstance } from '@App/providers';
import {
    getRequestConfigWithUserProfile,
    httpErrorResponse,
} from '@App/requests/shared';

export const updateCreditCommitmentsData = async (
    accessToken: string,
    idToken: string,
    id: string,
) => {
    const url = window._env_.MS_D2C_BASE_URL as string;

    try {
        await axiosInstance.post(
            `${url}/credit-commitments/${id}`,
            {},
            getRequestConfigWithUserProfile(accessToken, idToken),
        );
        return { success: true };
    } catch (e) {
        return httpErrorResponse(e);
    }
};
