import { toCurrency } from '@Helpers';

import { Offer, Product } from '@src/app/interfaces/offer';
import { toPercentage } from '@src/shared/helpers/numbers';
import { getProductType } from '@src/ui/views/offer/ExploreView';

import ChevronIcon from '../Icons/Chevron';
import CrossIcon from '../Icons/CrossIcon';
import PopularIcon from '../Icons/PopularIcon';
import RadioSelected from '../Icons/RadioSelected';
import RadioUnselected from '../Icons/RadioUnselected';
import TickIcon from '../Icons/TickIcon';
import Legal from '../Legal/Legal';

interface OfferCardProps {
    offer: Offer;
    onSelect?: () => void;
    selectable?: boolean;
    showMoreInfo: boolean;
    toggle: () => void;
    selected: boolean;
}

const OfferCard = ({
    offer,
    onSelect,
    selectable,
    showMoreInfo,
    toggle,
    selected,
}: OfferCardProps) => {
    const productType = getProductType(offer.productCode);

    const getLoanType = () => {
        switch (productType) {
            case Product.HELOC:
                return 'Flexible drawdowns';
            case Product.TwoYearFixedWithEarlyCharges:
                return 'Shortest fixed period';
            case Product.FiveYearFixedWithEarlyCharges:
                return 'Lowest monthly payment';
            default:
                return '';
        }
    };

    const getInfoText = () => {
        switch (productType) {
            case Product.HELOC:
                return 'Pay only for what you use';
            case Product.TwoYearFixedWithEarlyCharges:
                return 'Ideal for refinancing after 2 years';
            case Product.FiveYearFixedWithEarlyCharges:
                return 'Popular for debt consolidation';
            default:
                return '';
        }
    };

    const getRateType = () => {
        switch (productType) {
            case Product.HELOC:
                return 'Variable';
            case Product.TwoYearFixedWithEarlyCharges:
                return '2 year fixed rate';
            case Product.FiveYearFixedWithEarlyCharges:
                return '5 year fixed rate';
            default:
                return '';
        }
    };

    const renderFeeInfo = () => {
        if (productType === Product.HELOC) {
            return (
                <div className='flex items-center'>
                    <TickIcon />
                    <span className='ml-1'>No early repayment fees</span>
                </div>
            );
        } else {
            return (
                <div className='flex items-center'>
                    <CrossIcon />
                    <span className='ml-1'>
                        Early repayment fees in fixed period
                    </span>
                </div>
            );
        }
    };

    const renderButton = (isSelected: boolean) => {
        return isSelected ? (
            <RadioSelected />
        ) : (
            <RadioUnselected onClick={onSelect} />
        );
    };

    return (
        <div
            className={`mb-4 py-3 px-6 rounded-lg w-full max-w-330 lg:max-w-none lg:mr-6 ${
                selected
                    ? 'bg-green_offer shadow-offerSelected'
                    : 'bg-neutral-40 shadow-offer'
            }`}
        >
            {selectable && (
                <div className='pb-3 lg:h-8'>
                    {productType === Product.FiveYearFixedWithEarlyCharges && (
                        <PopularIcon />
                    )}
                </div>
            )}
            <div className='flex justify-between items-center pb-5'>
                <div className='leading-120%'>
                    <span className='text-28 font-bold'>{`£${toCurrency(
                        offer.initialPayment,
                    )}`}</span>
                    <span className='text-xl'>/month</span>
                    <p className='text-xl font-bold'>{getLoanType()}</p>
                </div>
                {selectable && renderButton(selected ?? false)}
            </div>
            <div className='text-md pb-3'>
                <div className='flex items-center'>
                    <TickIcon />
                    <span className='ml-1'> {`${offer.initialRate}%`} APR</span>
                </div>
                <div className='flex items-center'>
                    <TickIcon />
                    <span className='ml-1'>{getInfoText()}</span>
                </div>
                {renderFeeInfo()}
            </div>
            {showMoreInfo && (
                <div className='animate-fadeIn pb-3 text-md'>
                    <div className='flex gap-2'>
                        <p>Rate type</p>
                        <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
                        <p className='font-bold'>{getRateType()}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p>Product</p>
                        <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
                        <p className='font-bold'>{offer.family}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p>Total cost of loan</p>
                        <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
                        <p className='font-bold'>{`£${toCurrency(
                            offer.totalAmountRepaid,
                        )}`}</p>
                    </div>
                    <div className='flex gap-2 pb-3'>
                        <p>APRC</p>
                        <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
                        <p className='font-bold'>{toPercentage(offer.aprc)}</p>
                    </div>

                    <Legal className='leading-120%' offer={offer} />
                </div>
            )}
            <div
                onClick={toggle}
                className='w-24 text-xs font-bold justify-center bg-steel-60 text-ghost items-center flex rounded-2xl cursor-pointer'
            >
                <span className='pr-1'>{`Show ${
                    !showMoreInfo ? 'more' : 'less'
                }`}</span>
                <ChevronIcon
                    isRotated={showMoreInfo}
                    height='20px'
                    width='20px'
                    color='white'
                />
            </div>
        </div>
    );
};

export default OfferCard;
