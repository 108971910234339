export const toCurrency = (number: number = NaN) => {
    if (isNaN(number)) return '-';
    return new Intl.NumberFormat('locale', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(number);
};

export const toPercentage = (number: number, accuracy?: number) => {
    const defaultAcc = number === Math.round(number) ? 0 : 2;

    return (
        formatNumber(number, {
            style: 'decimal',
            minimumFractionDigits: accuracy ?? defaultAcc,
            maximumFractionDigits: accuracy ?? defaultAcc,
        }) + '%'
    );
};

export const normalizeNumber = (value: any) => {
    const finalValue = (value + '').replace(/\s/g, '').replace(/,/g, '');
    return Number(finalValue);
};

export const formatNumber = (
    number: number,
    options: Intl.NumberFormatOptions,
) => {
    return new Intl.NumberFormat('en', options).format(number);
};
