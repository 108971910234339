import { differenceInYears, endOfToday, parse } from 'date-fns';
import * as Yup from 'yup';

import { IncomeType } from '@App/interfaces/IncomeType';
import {
    isValidDateFormat,
    months,
    toApiDate,
    toValidDate,
    years,
} from '@Shared/helpers/date';
import { normalizeNumber } from '@src/shared/helpers';

import { CustomFormPropsV2, currency, yesNo } from '../shared';

import {
    commonDecrease,
    commonRetirement,
    otherIncome,
    otherIncomeForSelfEmployed,
} from './incomings.shared';

export const contractor: CustomFormPropsV2 = {
    month: {
        items: months,
    },
    year: {
        items: years,
    },
    isSoleTrader: {
        ...yesNo,
        transform: {
            target: 'none',
            fromRequest: (_value: any, dependencies: any) => {
                return dependencies.employment.whenWasCompanyIncorporated
                    ? false
                    : true;
            },
            toRequest: null,
        },
        validation: Yup.boolean().required('This is a required field'),
    },
    registeredCompanyName: {
        label: "What is your company's registered name?",
        validation: Yup.string().when('isSoleTrader', {
            is: false,
            then: Yup.string()
                .required('Please enter a valid company name.')
                .min(2, 'please enter a valid company name.'),
        }),
        transform: {
            target: 'employment.registeredCompanyName',
        },
    },
    incorp_month: {
        validation: Yup.string().when('isSoleTrader', {
            is: false,
            then: Yup.string().required('Please select month'),
        }),
    },
    incorp_year: {
        validation: Yup.string().when('isSoleTrader', {
            is: false,
            then: Yup.string().required('Please select year'),
        }),
    },
    whenWasCompanyIncorporated: {
        label: 'When was your company incorporated?',
        mask: 'date',
        validation: Yup.string().when('isSoleTrader', {
            is: false,
            then: Yup.string().test(
                'validDate',
                'It is not a valid date',
                (value) => {
                    return isValidDateFormat(value + '');
                },
            ),
        }),
        transform: {
            target: 'employment.whenWasCompanyIncorporated',
            toRequest: (value: string, _dependencies: any) => toApiDate(value),
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    startMonth: {
        validation: Yup.string().required('Please select month'),
    },
    startYear: {
        validation: Yup.string().required('Please select year'),
    },
    contractStartDate: {
        label: 'When did your contract start?',
        mask: 'date',
        validation: Yup.string()
            .test('validDate', 'It is not a valid date', (value) => {
                return isValidDateFormat(value + '');
            })
            .test(
                'validDate60',
                'Contract cannot have started more than 60 years ago',
                (value) => {
                    const isValid = isValidDateFormat(value + '');
                    const diffInYears = differenceInYears(
                        endOfToday(),
                        parse(value + '', 'dd/MM/yyyy', new Date()),
                    );
                    return isValid && diffInYears < 60;
                },
            ),
        transform: {
            target: 'employment.contractStartDate',
            toRequest: (value: string) => toApiDate(value),
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    endMonth: {
        validation: Yup.string().required('Please select month'),
    },
    endYear: {
        validation: Yup.string().required('Please select year'),
    },
    contractEndDate: {
        label: 'When does your contract end?',
        mask: 'date',
        validation: Yup.string()
            .test('validDate', 'It is not a valid date', (value) => {
                return isValidDateFormat(value + '');
            })
            .test(
                'dateDiff',
                'Please input a contract end date that is after the start date',
                (endValue, context) => {
                    const startValue = context.parent.contractStartDate;
                    const isValidStart = isValidDateFormat(startValue + '');
                    if (!isValidStart) return true;

                    const start = parse(
                        startValue + '',
                        'dd/MM/yyyy',
                        new Date(),
                    );
                    const end = parse(endValue + '', 'dd/MM/yyyy', new Date());

                    if (start >= end) return false;
                    return true;
                },
            ),
        transform: {
            target: 'employment.contractEndDate',
            toRequest: (value: string) => toApiDate(value),
            fromRequest: (value: string) => toValidDate(value),
        },
    },
    firstTimeContractor: {
        ...yesNo,
        transform: {
            target: 'employment.firstTimeContractor',
        },
        validation: Yup.boolean().required('This is a required field'),
    },
    contractDayRateReported: {
        label: 'What is your contract day rate?',
        ...currency,
        validation: Yup.string()
            .typeError('This is a required field')
            .required('This is a required field')
            .max(99999999, 'Max £99,999,999'),
        transform: {
            target: 'income.income[]',
            fromRequest: (_value: any, dependencies: any) => {
                const found = (dependencies?.income?.income || []).find(
                    (item: any) => {
                        return item.type === IncomeType.CONTRACT_RATE;
                    },
                );

                return found?.contractDayRateReported || undefined;
            },
            toRequest: (value: any, dependencies: any) => {
                return {
                    type: IncomeType.CONTRACT_RATE,
                    amount: 0,
                    contractDayRateReported: normalizeNumber(value),
                    contractDaysWorkedWeeklyReported:
                        dependencies.contractDaysWorkedWeeklyReported,
                };
            },
        },
    },
    contractDaysWorkedWeeklyReported: {
        label: 'How many days do you work per week?',
        mask: 'number',
        maskProps: {
            min: 0,
            max: 7,
        },
        validation: Yup.number()
            .typeError('This is a required field')
            .required('This is a required field')
            .min(1, 'Please enter a number between 1 and 7')
            .max(7, 'Please enter a number between 1 and 7'),
        transform: {
            target: 'income.income[]',
            fromRequest: (_value: any, dependencies: any) => {
                const found = (dependencies?.income?.income || []).find(
                    (item: any) => {
                        return item.type === IncomeType.CONTRACT_RATE;
                    },
                );

                return found?.contractDaysWorkedWeeklyReported || undefined;
            },
            toRequest: null,
        },
    },
    jobTitle: {
        label: "What's your job title?",
        placeholder: 'e.g Manager',
        validation: Yup.string()
            .required('This is a required field')
            .min(2, 'Please enter a valid job title'),
        transform: {
            target: 'employment.jobTitle',
        },
    },
    ...otherIncome(otherIncomeForSelfEmployed),
    ...commonRetirement,
    ...commonDecrease,
};
