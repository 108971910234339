import { ReactElement } from 'react';

export const yesNo = {
    wrapperClassName: 'grid-cols-2',
    items: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    convert: 'boolean',
};

export const currency = {
    mask: 'number',
    startAdornment: '£',
    maskProps: {
        max: 99999999,
    },
};

export const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]).{8,}$/;

export const passwordErrorMessage =
    'Password must contain at least: 8 characters, 1 letter, 1 number, and 1 of the following ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ ` = + -';
export interface ItemList {
    label?: string | ReactElement;
    value: boolean | number | string;
}

export interface CustomFormProps {
    [key: string]: {
        // the following are reserved words
        className?: string;
        id?: string;
        label?: string | string[];
        items?: string[] | ItemList[];
        mask?: string;
        startAdornment?: string;
        endAdornment?: string;
        placeholder?: string;
        validation?: any;
        default?: any;
        maskProps?: {
            min?: number;
            max?: number;
            scale?: number;
            signed?: boolean;
            mask?: string;
        };
        [key2: string]: any; // this should be CustomFormProps and not allow label, items ...etc
    };
}

export interface CustomFormPropsV2 {
    [key: string]: {
        // the following are reserved words
        className?: string;
        id?: string;
        label?: string | string[];
        items?: any;
        mask?: string;
        startAdornment?: string;
        endAdornment?: string;
        placeholder?: string;
        validation?: any;
        default?: any;
        maskProps?: {
            min?: number;
            max?: number;
            scale?: number;
            signed?: boolean;
            mask?: string;
        };
        [key2: string]: any; // this should be CustomFormProps and not allow label, items ...etc
    };
}
