import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {
    Button,
    ChevronDownIcon,
    Controller,
    RadioButtonGroup,
    TextField,
} from '@selina-finance/ui';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@App/contexts/Application';
import {
    getDefaultValues,
    getFormHelpers,
    getValidationSchema,
    handleSubFormValidation,
    radioButtonOptions,
    radioButtonOptionsInvert,
} from '@Shared/helpers/input';
import AddressRepeaterView from '@Views/eligibility/addressRepeaterView/AddressRepeaterView';
import { AuthContext } from '@src/app/contexts/Auth';
import { formItems } from '@src/app/formModels/eligibility/applicantDetails';
import { HttpError } from '@src/ui/components/HttpError';

import ErrorContainer from '../../components/ErrorContainer/ErrorContainer';
import CheckIcon from '../../components/Icons/CheckIcon';
import LightbulbIcon from '../../components/Icons/LightbulbIcon';
import SelectBox from '../../components/SelectBox/SelectBox';

interface ApplicantViewProps {
    formRef: any;
    saveRef: any;
    className?: string;
    isPrimaryApplicant: boolean;
    applicantData?: any;
    saveApplicants?: (
        data: any,
        isPrimaryApplicant: boolean,
        noOfApplicants?: number,
    ) => Promise<any>;
    mobilePhoneNumber?: string;
    setActiveApplicant: Dispatch<SetStateAction<number>>;
    setApplicantCount?: Dispatch<SetStateAction<number>>;
    applicantCount: number;
}

const defaultValues = getDefaultValues(formItems);
const validationSchema = getValidationSchema(formItems);

const ApplicantView = forwardRef((props: ApplicantViewProps) => {
    const formikRef = useRef<any>(null);

    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const {
        setCurrentForm,
        externalApplicationId,
        applicationId,
        updatePhoneNumber,
        loanDetails,
        saveLoanDetails,
        setExternalApplicationId,
    } = useContext(ApplicationContext);

    const { loggedIn } = useContext(AuthContext);

    const url = new URL(window.location.href);

    const urlExternalApplicationId = url.searchParams.get(
        'externalApplicationId',
    );

    useEffect(() => {
        if (urlExternalApplicationId) {
            setExternalApplicationId(urlExternalApplicationId);
            localStorage.setItem(
                'externalApplicationId',
                urlExternalApplicationId,
            );
        }
    }, [setExternalApplicationId, urlExternalApplicationId]);

    const getExternalApplicationId = externalApplicationId
        ? externalApplicationId
        : localStorage.getItem('externalApplicationId');

    if (!props.isPrimaryApplicant) {
        props.applicantData.mobilePhoneNumber = props.mobilePhoneNumber;
    }

    // avoids validation failing for 2nd applicant
    if (!props.isPrimaryApplicant) {
        props.applicantData.hasSecondApplicant = false;
    }

    useEffect(() => {
        if (props.isPrimaryApplicant && loanDetails.amount && !loggedIn) {
            saveLoanDetails(loanDetails).then((data: any) => {
                if (!data.success) {
                    setError(data.error);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanDetails.amount, loggedIn]);

    useEffect(() => {
        formikRef.current.values = {
            ...defaultValues,
            ...props.applicantData,
        };
    }, [props.applicantData]);

    useEffect(() => {
        setCurrentForm(undefined);
    }, [setCurrentForm]);

    useEffect(() => {
        if (formikRef.current) {
            setCurrentForm(formikRef.current);
        }
    }, [formikRef, setCurrentForm]);

    const validateAndSubmit = async () => {
        setError(null);
        const isValid = await handleSubFormValidation(formikRef.current);
        if (!isValid) {
            return false;
        }

        const values = formikRef.current.values;

        if (
            values.mobilePhoneNumber &&
            getExternalApplicationId &&
            values.mobilePhoneNumber !== props.applicantData.mobilePhoneNumber
        ) {
            await updatePhoneNumber(
                values.mobilePhoneNumber,
                getExternalApplicationId,
                applicationId,
            ).then((data: any) => {
                if (!data.success) {
                    setError(data.error);
                    return false;
                }
            });
        }

        props.saveApplicants &&
            props
                .saveApplicants(
                    values,
                    props.isPrimaryApplicant,
                    props.applicantCount,
                )
                .then((data: any) => {
                    const redirectTo =
                        values.redirectTo || '/eligibility/property-details';

                    if (data.success && !data.hasCoApplicant) {
                        navigate(redirectTo);
                        return true;
                    }

                    if (!data.success) {
                        setError(data.error);
                        return false;
                    }
                });

        return false;
    };

    const saveForLater = async () => {
        const values = formikRef.current.values;

        if (
            values.mobilePhoneNumber &&
            getExternalApplicationId &&
            values.mobilePhoneNumber !== props.applicantData.mobilePhoneNumber
        ) {
            await updatePhoneNumber(
                values.mobilePhoneNumber,
                getExternalApplicationId,
                applicationId,
            ).then((data: any) => {
                if (!data.success) {
                    setError(data.error);
                    return false;
                }
            });
        }

        props.saveApplicants &&
            (await props
                .saveApplicants(
                    values,
                    props.isPrimaryApplicant,
                    props.applicantCount,
                )
                .then((data: any) => {
                    if (data.success) {
                        return true;
                    }
                    if (!data.success) {
                        setError(data.error);
                        return false;
                    }
                }));
    };

    useImperativeHandle(props.formRef, () => {
        return { validateAndSubmit };
    });

    useImperativeHandle(props.saveRef, () => ({
        saveMethod: saveForLater,
    }));

    if (!props.applicantData.mobilePhoneNumber) {
        props.applicantData.mobilePhoneNumber = '';
    }

    const showPhoneNumber = !props.applicantData.mobilePhoneNumber;

    return (
        <div className={props.className}>
            <Formik
                enableReinitialize
                initialValues={{ ...defaultValues, ...props.applicantData }}
                validationSchema={validationSchema}
                onSubmit={validateAndSubmit}
                innerRef={(ref) => (formikRef.current = ref)}
            >
                {(formikProps) => {
                    const { getError } = getFormHelpers(formItems, formikProps);

                    // dont validate field for 2nd applicant
                    if (!props.isPrimaryApplicant) {
                        delete formikProps.errors.hasSecondApplicant;
                    }
                    const addressesError = getError(`addresses`);

                    let toDateError = '';
                    if (Array.isArray(addressesError)) {
                        addressesError?.forEach((it: any, index: number) => {
                            const toDate =
                                formikProps.values.addresses[index]?.livedIn.to;
                            if (toDate) {
                                toDateError = it?.livedIn?.to;
                            }
                        });
                    }
                    const additionalError =
                        typeof addressesError === 'string'
                            ? addressesError
                            : toDateError;

                    return (
                        <Form>
                            <div className='grid grid-cols-1 mt-6 mb-6 gap-10'>
                                {!props.isPrimaryApplicant && (
                                    <SelectBox
                                        small='true'
                                        items={formItems.title.items}
                                        suffix={<ChevronDownIcon />}
                                        label='Title'
                                        name='title'
                                        validState={!!formikProps.values.title}
                                        error={getError('title')}
                                        value={formikProps.values.title || ' '}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            formikProps.setFieldValue(
                                                'title',
                                                e.currentTarget.value,
                                            );
                                        }}
                                        onBlur={() => {
                                            return formikProps.setTouched({
                                                ...formikProps.touched,
                                                title: true,
                                            });
                                        }}
                                    />
                                )}

                                {!props.isPrimaryApplicant && (
                                    <Controller
                                        label={`${
                                            props.isPrimaryApplicant
                                                ? 'Your'
                                                : 'Their'
                                        } name`}
                                        additionalInfo={`As it appears on ${
                                            props.isPrimaryApplicant
                                                ? 'your'
                                                : 'their'
                                        } passport or driving license.`}
                                    >
                                        <div className='grid gap-6'>
                                            <TextField
                                                labelSize='small'
                                                label='First name'
                                                name='firstName'
                                                maxLength={255}
                                                value={
                                                    formikProps.values
                                                        .firstName || ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError('firstName')}
                                            />

                                            <TextField
                                                labelSize='small'
                                                label='Middle name'
                                                name='middleName'
                                                maxLength={255}
                                                value={
                                                    formikProps.values
                                                        .middleName || ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError('middleName')}
                                            />

                                            <TextField
                                                labelSize='small'
                                                label='Last name'
                                                name='lastName'
                                                maxLength={255}
                                                value={
                                                    formikProps.values
                                                        .lastName || ''
                                                }
                                                onChange={(
                                                    e: ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    formikProps.handleChange(e)
                                                }
                                                onBlur={formikProps.handleBlur}
                                                error={getError('lastName')}
                                            />
                                        </div>
                                    </Controller>
                                )}

                                {!props.isPrimaryApplicant && (
                                    <TextField
                                        name='dateOfBirth'
                                        placeholder='DD/MM/YYYY'
                                        label='Date of birth'
                                        mask='date'
                                        value={
                                            formikProps.values.dateOfBirth || ''
                                        }
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) => formikProps.handleChange(e)}
                                        onBlur={formikProps.handleBlur}
                                        error={getError('dateOfBirth')}
                                    />
                                )}

                                {showPhoneNumber &&
                                    props.isPrimaryApplicant && (
                                        <TextField
                                            label='Phone number'
                                            placeholder='Mobile Preferred'
                                            name='mobilePhoneNumber'
                                            value={
                                                formikProps.values
                                                    .mobilePhoneNumber || ''
                                            }
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => formikProps.handleChange(e)}
                                            onBlur={formikProps.handleBlur}
                                            error={getError(
                                                'mobilePhoneNumber',
                                            )}
                                        />
                                    )}

                                <Controller
                                    label={`Are ${
                                        props.isPrimaryApplicant
                                            ? 'you'
                                            : 'they'
                                    } a British citizen?`}
                                >
                                    <RadioButtonGroup
                                        name='kqUkResidency'
                                        id='kqUkResidency'
                                        value={formikProps.values.kqUkResidency?.toString()}
                                        error={{
                                            hasError: false,
                                            errorMessage:
                                                getError('kqUkResidency'),
                                        }}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            formikProps.setTouched({
                                                ...formikProps.touched,
                                                kqUkResidency: true,
                                            });
                                            if (e.target.value === 'true') {
                                                formikProps.setFieldValue(
                                                    'notHavePermanentRightToResideInTheUk',
                                                    null,
                                                );
                                            }
                                            formikProps.handleChange(e);
                                        }}
                                        options={radioButtonOptions}
                                    />
                                </Controller>

                                {formikProps.values.kqUkResidency ===
                                    'false' && (
                                    <Controller
                                        label={`Do ${
                                            props.isPrimaryApplicant
                                                ? 'you'
                                                : 'they'
                                        } have permanent rights to reside in the UK?`}
                                    >
                                        <RadioButtonGroup
                                            name='notHavePermanentRightToResideInTheUk'
                                            id='notHavePermanentRightToResideInTheUk'
                                            value={formikProps.values.notHavePermanentRightToResideInTheUk?.toString()}
                                            error={{
                                                hasError: false,
                                                errorMessage: getError(
                                                    'notHavePermanentRightToResideInTheUk',
                                                ),
                                            }}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    notHavePermanentRightToResideInTheUk:
                                                        true,
                                                });
                                                formikProps.handleChange(e);
                                            }}
                                            options={radioButtonOptionsInvert}
                                        />
                                    </Controller>
                                )}

                                <Controller
                                    label={`Do ${
                                        props.isPrimaryApplicant
                                            ? 'you'
                                            : 'they'
                                    } have any criminal convictions?`}
                                >
                                    <RadioButtonGroup
                                        name='hasAnyCriminalConvictions'
                                        id='hasAnyCriminalConvictions'
                                        value={formikProps.values.hasAnyCriminalConvictions?.toString()}
                                        error={{
                                            hasError: false,
                                            errorMessage: getError(
                                                'hasAnyCriminalConvictions',
                                            ),
                                        }}
                                        onChange={(
                                            e: ChangeEvent<HTMLInputElement>,
                                        ) => {
                                            formikProps.setTouched({
                                                ...formikProps.touched,
                                                hasAnyCriminalConvictions: true,
                                            });
                                            formikProps.handleChange(e);
                                        }}
                                        options={radioButtonOptions}
                                    />
                                </Controller>

                                <Controller
                                    label={`${
                                        props.isPrimaryApplicant
                                            ? 'Your address history'
                                            : '2nd applicant address history'
                                    } `}
                                    additionalInfo={`We need to gather ${
                                        props.isPrimaryApplicant
                                            ? 'your'
                                            : 'their'
                                    } past 3 years address history as part of our lending criteria.`}
                                >
                                    <AddressRepeaterView
                                        addressFieldName={'addresses'}
                                        formikProps={formikProps}
                                        formItems={formItems}
                                        isPrimaryApplicant={
                                            props.isPrimaryApplicant
                                        }
                                    />
                                </Controller>

                                {props.isPrimaryApplicant && (
                                    <Controller label='Do you own the property with anyone else?'>
                                        <div className='bg-neutral-40 flex p-3 rounded-lg mb-4'>
                                            <div className='mr-2'>
                                                <LightbulbIcon />
                                            </div>
                                            <div className='leading-6'>
                                                <p className='text-steel-100 mb-5'>
                                                    Selina provides secured
                                                    loans for homeowners like
                                                    you, offering higher loan
                                                    values and lower monthly
                                                    repayments.
                                                </p>
                                                <p className='text-steel-100'>
                                                    So we need to know about
                                                    anyone else you own the
                                                    property with — on either
                                                    the mortgage or title deeds.
                                                </p>
                                            </div>
                                        </div>
                                        <RadioButtonGroup
                                            name='hasSecondApplicant'
                                            id='hasSecondApplicant'
                                            value={
                                                localStorage.getItem(
                                                    `hasSecondApplicantSelected${applicationId}`,
                                                ) === 'true'
                                                    ? formikProps.values.hasSecondApplicant?.toString()
                                                    : undefined
                                            }
                                            error={{
                                                hasError: false,
                                                errorMessage:
                                                    getError(
                                                        'hasSecondApplicant',
                                                    ),
                                            }}
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                formikProps.setTouched({
                                                    ...formikProps.touched,
                                                    hasSecondApplicant: true,
                                                });
                                                props.setApplicantCount &&
                                                    props.setApplicantCount(
                                                        e.target.value ===
                                                            'true'
                                                            ? 2
                                                            : 1,
                                                    );
                                                formikProps.handleChange(e);

                                                localStorage.setItem(
                                                    `hasSecondApplicantSelected${applicationId}`,
                                                    'true',
                                                );
                                            }}
                                            options={radioButtonOptions}
                                        />
                                    </Controller>
                                )}

                                {!props.isPrimaryApplicant && (
                                    <div className='bg-neutral-40 flex p-3 rounded-lg mb-2 items-center'>
                                        <div className='mr-2'>
                                            <CheckIcon />
                                        </div>
                                        <div>
                                            <p className='text-steel-100'>
                                                By continuing I confirm I have
                                                permission to provide
                                                information and make the
                                                application on behalf of the 2nd
                                                Applicant
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {error && <HttpError error={error} />}

                            <ErrorContainer
                                formikProps={formikProps}
                                additionalErrors={[additionalError]}
                            />

                            <div className='flex gap-5 justify-end mt-12'>
                                <Button
                                    color='secondary'
                                    type='submit'
                                    size={'large'}
                                    className='w-full'
                                >
                                    Continue
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

export default ApplicantView;
