import {
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { ApplicationContext } from '@App/contexts/Application';
import { ExperimentsConfig } from '@App/utils/experiments';
import WaitViewLoader from '@Views/wait/WaitViewLoader';
import { WaitViewLoaderV2 } from '@Views/wait/WaitViewLoaderV2';
import { AuthContext } from '@src/app/contexts/Auth';
import { useKameleoonFeature } from '@src/app/hooks/useKameleoon';
import { ApplicationStage } from '@src/app/interfaces/application-stage.enum';
import { KAMELEOON_FEATURE } from '@src/app/utils/kameleoon';

import EligibilityLayout from '../layouts/EligibilityLayout';
import DashboardView from '../views/eligibility/DashboardView';

const Dashboard = () => {
    const [showWaitScreen, setShowWaitScreen] = useState<boolean>(false);
    const [waitVariant, setWaitVariant] = useState<string>('');
    const getVariationKey = useKameleoonFeature(KAMELEOON_FEATURE.wait);

    const {
        loading,
        dashboardItemStatuses,
        applicationStage,
        applicationEmail,
        callDateTime,
    } = useContext(ApplicationContext);
    const { loggedIn } = useContext(AuthContext);

    const saveRef = useRef<any>(null);
    const navigate = useNavigate();

    const saveForLater = () => {
        return true;
    };

    useImperativeHandle(saveRef, () => ({
        saveMethod: saveForLater,
    }));

    useEffect(() => {
        if (
            loggedIn &&
            dashboardItemStatuses[4].completed &&
            !dashboardItemStatuses[5].completed
        ) {
            setShowWaitScreen(true);
        }
    }, [dashboardItemStatuses, loggedIn]);

    useEffect(() => {
        const fetchVariant = async () => {
            if (applicationEmail) {
                const variationKey = await getVariationKey();
                setWaitVariant(variationKey);
            }
        };

        fetchVariant();
    }, [getVariationKey, applicationEmail]);

    // TODO: get variant
    const newFlow = false;

    useEffect(() => {
        if (
            loggedIn &&
            newFlow &&
            dashboardItemStatuses[5].completed &&
            applicationStage === ApplicationStage.DECISIONING_ACCEPT
        ) {
            navigate(
                callDateTime
                    ? '/eligibility/offer/confirmation'
                    : '/eligibility/offer/explore',
            );
        }
    }, [
        dashboardItemStatuses,
        loggedIn,
        callDateTime,
        newFlow,
        navigate,
        applicationStage,
    ]);

    if (showWaitScreen) {
        return (
            <EligibilityLayout
                className='h-screen'
                loading={false}
                ref={saveRef}
            >
                {waitVariant === ExperimentsConfig.Variants.A ? (
                    <WaitViewLoader />
                ) : (
                    <WaitViewLoaderV2 loadingStopped />
                )}
            </EligibilityLayout>
        );
    }

    return (
        <EligibilityLayout className='h-screen' loading={loading} ref={saveRef}>
            <DashboardView waitLoaderFlowVariant={waitVariant} />
        </EligibilityLayout>
    );
};

export default Dashboard;
