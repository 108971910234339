import { useCallback, useRef } from 'react';

import * as Sentry from '@sentry/browser';
import { severityLevelFromString } from '@sentry/utils';

type LogMessage = {
    message: string;
    level: Sentry.SeverityLevel;
    error?: any;
    statusCode?: string;
};

const getLogObject = (
    level: Sentry.SeverityLevel,
    appMessage: string,
    error?: any,
): LogMessage => ({
    message: appMessage,
    level: severityLevelFromString(level),
    error:
        JSON.stringify(error?.data || error?.message || error) ||
        'unknown error',
    statusCode: `${error?.status || 'N/A'}`,
});

export const useSentryLogger = (appId: string, location: string) => {
    const title = 'User story - ' + appId;
    const log = useRef<LogMessage[]>([]);

    const flushLogs = useCallback(() => {
        if (log.current.length > 0) {
            const extra = {
                logs: log.current.map((logRow) => logRow.message).join('\n'),
                error: log.current[log.current.length - 1]?.error || '',
                location: location,
                statusCode: log.current[log.current.length - 1]?.statusCode,
            };
            try {
                Sentry.captureEvent({
                    message: title,
                    level: severityLevelFromString(
                        log.current[log.current.length - 1].level,
                    ),
                    extra,
                });
            } catch {}
            log.current = [];
        }
    }, [location, title, log]);

    const sentryWarning = useCallback(
        (appMessage: string, error?: any, callBack?: Function) => {
            log.current.push(getLogObject('warning', appMessage, error));
            if (callBack) callBack();
        },
        [],
    );

    const sentryError = useCallback(
        (appMessage: string, error?: any, callBack?: Function) => {
            log.current.push(getLogObject('error', appMessage, error));
            if (callBack) callBack();
        },
        [],
    );

    const sentryLog = useCallback((appMessage: string, callBack?: Function) => {
        log.current.push(getLogObject('info', appMessage, {}));
        if (callBack) callBack();
    }, []);

    return {
        sentryError,
        sentryLog,
        sentryWarning,
        flushLogs,
    };
};
