import { FC, useEffect, useState } from 'react';

import { toCurrency } from '@Helpers';
import clsx from 'clsx';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { Offer } from '@App/interfaces/offer';
import { InfoIcon } from '@src/ui/components/Icons';
import ChevronIcon from '@src/ui/components/Icons/Chevron';
import Legal from '@src/ui/components/Legal/Legal';

const ExpansionPanelHeaderItem: FC<{
    label: string;
    value: string | number;
    open?: boolean;
    className?: string;
    labelClassName?: string;
}> = ({ label, value, labelClassName, open, className }) => (
    <div className={clsx('font-bold', className)}>
        <p className='text-2xl'>{value}</p>
        {!open && (
            <p className={clsx('text-steel-60', labelClassName)}>{label}</p>
        )}
        {open &&
            label.split(',').map((l, i) => (
                <p key={i} className='text-steel-60'>
                    {l}
                </p>
            ))}
    </div>
);

const ExpansionPanelBodyItems: FC<{
    t: TFunction;
    offer: Offer;
    setShowInfoModal: Function;
}> = ({ t, offer, setShowInfoModal }) => (
    <>
        <div className='flex flex-col gap-6 sm:hidden'>
            <ExpansionPanelHeaderItem
                className='xs:hidden'
                label={t('monthlyCost')}
                value={`£${toCurrency(offer.initialPayment)}`}
            />
            <ExpansionPanelHeaderItem
                label={t('rate')}
                value={`${offer.initialRate}%`}
            />
        </div>

        <div className='flex gap-2'>
            <p>{t('rateType') as String}</p>
            <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
            <p className='font-bold'>{offer.product.split(',')[2]}</p>
        </div>
        <div className='flex gap-2'>
            <p className='flex gap-1'>
                {t('product') as String}
                <InfoIcon
                    className='cursor-pointer'
                    onClick={() => setShowInfoModal(true)}
                />
            </p>
            <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
            <p className='font-bold'>{offer.family}</p>
        </div>
        <div className='flex gap-2'>
            <p>{t('term') as String}</p>
            <div className='border-b-1 border-dashed border-steel-100 flex-1 h-0 mt-3'></div>
            <p className='font-bold'>{`${offer.term} years`}</p>
        </div>
    </>
);

const AdditionalInformation: FC<{ t: TFunction }> = ({ t }) => (
    <div>
        <p className='font-bold'>{t('additionalInfo.label') as String}</p>
        <p className='whitespace-pre-line'>
            {t('additionalInfo.info') as String}
        </p>
    </div>
);

const OfferExpansionPanel: FC<{
    offer: Offer;
    className?: string;
    loanDetails: any;
    setShowInfoModal: Function;
}> = ({ offer, className, loanDetails, setShowInfoModal }) => {
    const { t } = useTranslation('goodNews', { keyPrefix: 'offer' });
    const [open, setOpen] = useState<boolean>(false);

    const [purpose, setPurpose] = useState<string>('');

    useEffect(() => {
        if (loanDetails?.purpose) {
            setPurpose(loanDetails.purpose);
        }
    }, [loanDetails]);

    return (
        <div
            className={clsx(
                'border-b-1 border-neutral-100 py-4 text-md text-steel-100',
                className,
            )}
        >
            <div className='flex items-center mb-4'>
                <div className='border-neutral-100 border-t-1 w-full rounded-full'></div>
                <span className=' flex justify-center bg-green text-success_green font-semibold border border-success_green rounded-full mx-4 w-full sm:w-2/5 md:w-3/4 text-sm lg:text-lg'>
                    Selected offer
                </span>
                <div className='border-neutral-100 border-t-1 w-full rounded-full'></div>
            </div>
            <div className='flex items-center justify-between'>
                <ExpansionPanelHeaderItem
                    open={open}
                    label={purpose}
                    labelClassName='max-w-xs text-steel-60 truncate'
                    value={`£${toCurrency(offer.netLoanAmount)}`}
                />
                <ExpansionPanelHeaderItem
                    className='hidden xs:block'
                    label={t('monthlyCost')}
                    value={`£${toCurrency(offer.initialPayment)}`}
                />
                <ExpansionPanelHeaderItem
                    className='hidden sm:block'
                    label={t('rate')}
                    value={`${offer.initialRate}%`}
                />
                <ChevronIcon
                    onClick={() => setOpen((prevState) => !prevState)}
                />
            </div>
            {open && (
                <div className='animate-fadeIn flex flex-col gap-4 mt-6'>
                    <ExpansionPanelBodyItems
                        t={t}
                        offer={offer}
                        setShowInfoModal={setShowInfoModal}
                    />
                    <Legal className={'mt-0 text-steel-100'} offer={offer} />
                    <AdditionalInformation t={t} />
                </div>
            )}
        </div>
    );
};

export default OfferExpansionPanel;
